<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar
      title="Merchants"
      :author="{
        home: 'Home',
        cart: 'merchants',
      }"
    />
    <MerchantTable />
  </div>
</template>

<script>
// import Grid from './components/AdminGrid';
import MerchantTable from "./components/MerchantTable.vue";
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";

export default {
  name: "Admin",
  components: {
    MerchantTable,
    MainMenu,
    TopBar,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
    };
  },
  created() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>