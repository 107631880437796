import { API_URL, authHeader } from "@/Core/helpers/utils";
import axios from "axios";


const RESOURCE_URL = `${API_URL}/api/v1/orgs/`;

export default {
    facebook_connection(token, params) {
      return axios.post(`${RESOURCE_URL}facebook_ad_connection/`,params, {
        headers: authHeader(token),
      });
    },
    facebook_disconnect(token) {
        return axios.get(`${RESOURCE_URL}facebook_ad_disconnect/`, {
          headers: authHeader(token),
        });
    },
    woocommerce_connection(token, params) {
      return axios.post(`${RESOURCE_URL}woocommerce_connection/`,params, {
        headers: authHeader(token),
      });
    },
    woocommerce_disconnect(token) {
        return axios.get(`${RESOURCE_URL}woocommerce_disconnect/`, {
          headers: authHeader(token),
        });
    },
    shopify_connection(token, params) {
      return axios.post(`${RESOURCE_URL}shopify_connection/`,params, {
        headers: authHeader(token),
      });
    },
    shopify_disconnect(token) {
        return axios.get(`${RESOURCE_URL}shopify_disconnect/`, {
          headers: authHeader(token),
        });
    },
    sendgrid_connection(token, params) {
      return axios.post(`${RESOURCE_URL}sendgrid_connection/`,params, {
        headers: authHeader(token),
      });
    },
    sendgrid_disconnect(token) {
      return axios.get(`${RESOURCE_URL}sendgrid_disconnect/`, {
        headers: authHeader(token),
      });
    },
    google_disconnect(token) {
      return axios.get(`${RESOURCE_URL}google_ad_disconnect/`, {
        headers: authHeader(token),
      });
    },  
    google_analytics_disconnect(token) {
      return axios.get(`${RESOURCE_URL}google_analytics_disconnect/`, {
        headers: authHeader(token),
      });
    },
    get_google_analytics_account(token,id) {
      return axios.get(`${RESOURCE_URL}get_google_analytics_account_data/${id}/`, {
        headers: authHeader(token),
      });
    }, 
    set_google_analytics_account(token,params){
      return axios.post(`${RESOURCE_URL}set_google_analytics_account_data/${params.id}/`,params, {
        headers: authHeader(token),
      });
    },  
    change_default_currency(token,params){
      return axios.patch(`${RESOURCE_URL}change_currency/`,params, {
        headers: authHeader(token),
      });
    },
    change_clv_year(token,params){
      return axios.patch(`${RESOURCE_URL}change_clv_year/`,params, {
        headers: authHeader(token),
      });
    },
    get_sendgrid_contact_list(token){
      return axios.get(`${RESOURCE_URL}get_sendgrid_contact_list/`, {
        headers: authHeader(token),
      });
    },
    get_sendgrid_templates(token){
      return axios.get(`${RESOURCE_URL}get_sendgrid_templates/`, {
        headers: authHeader(token),
      });
    },
    get_sendgrid_template_version(token,params){
      return axios.get(`${RESOURCE_URL}get_sendgrid_template_version/`, {
        headers: authHeader(token),params
      });
    },
    change_subscription_status(token, obj) {
      return axios.post(`${RESOURCE_URL}update_subscription_status/`, obj, {
        headers: authHeader(token),
      });
    },
}  