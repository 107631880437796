<template>
  <div class="contant-box-main customer-list-box">
    <div class="h-100">
      <div class="contant-header">
        <h6>
          <img src="./../../../public/assets/images/pr-icon.png" alt="" />Top
          Performing Offers
        </h6>
        <div class="">
          <!-- <label for="adminfilter">Organization</label> -->
          <select
            class="for"
            id="adminfilter"
            v-model="time"
            @change="onTimeChange"
          >
            <option v-for="option in timeOptions" :key="option">
              {{ option }}
            </option>
          </select>
        </div>
        <!-- <span class="gray-text">from {{ one_month_ago_date() }}</span> -->
      </div>
      <div class="contant-details h-100">
        <div class="overflow-auto position-relative" :class="loadindShow?'h-100':''">
          <div class="loader-main" v-if="loadindShow">
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
           </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Order Count</th>
                <th>Amount</th>
                <th>Conversion</th>
                <th>Currency</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="offer in top_performing_offers" :key="offer.id">
                <td class="font-light-text" width="10%">
                  {{ offer.id }}
                </td>
                <td width="40%" class="font-text">
                  <router-link
                    :to="{
                      name: `OfferDetails`,
                      params: { offerId: offer.id },
                    }"
                    >
                  {{ offer.name }}</router-link></td>
                <td class="font-light-text" width="20%">
                  {{ offer.order_count }}
                </td>
                <td class="font-light-text" width="10%">
                  {{ offer.total_amount.toFixed(0) }}
                </td>
                <td class="font-light-text" width="10%">
                  {{ offer.conversion }}%
                </td>
                <td class="font-light-text" width="10%">
                  {{ offer.currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- most valuable customer list model -->
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import { GET_TOP_PERFORMING_OFFERS } from "@/Core/store/action-types";
export default {
  name: "TopPerformingOffers",

  data() {
    return {
      top_performing_offers: [],
      time: "month",
      timeOptions: ["week", "month", "quater", "half a year", "year"],
       loadindShow:false,
    };
  },
  computed: {
    ...mapGetters("org", ["default_currency"]),
  },
  methods: {
    ...mapActions("offer", [GET_TOP_PERFORMING_OFFERS]),
    initPerformingOffers() {
      let params = {
        duration:
          this.time == "month"
            ? 30
            : this.time == "week"
            ? 7
            : this.time == "quater"
            ? 90
            : this.time == "half a year"
            ? 180
            : 365,
        currency:this.default_currency
      };
      this.loadindShow = true
      this[GET_TOP_PERFORMING_OFFERS](params)
        .then((result) => {
          this.top_performing_offers = result;
        })
        .catch(() => (this.top_performing_offers = []))
        .finally(()=>this.loadindShow=false)
    },
    onTimeChange() {
      this.initPerformingOffers();
    },
  },
  mounted() {
    this.initPerformingOffers();
  },
};
</script>
<style scoped>
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>