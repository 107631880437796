<template>
    <div class="prs-bottom">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <img src="../../image/recommended-icon.png" alt="" />Connected Articles
            </h6>
             <div class="count-dropdown">
              <!-- <p class=" mb-1">Connected article count:</p> -->
              <Field v-model="connectedArticleCount" name="connectedArticleCount">
                <Multiselect
                  :id="'connectedArticleCount'"
                  :canClear="false"
                  v-model="connectedArticleCount"
                  :options="[2,3,4]"
                />
              </Field>
            </div>
          </div>
          <div class="recommended-section">
            <div class="recommended-section-row">
              <div
                class="recommended-section-table"
                v-for="(conn_articles, index) in connectedArticles.results"
                :key="conn_articles"
              >
                <button
                  type="button"
                  data-toggle="collapse"
                  :data-target="'#recommended' + index"
                  aria-expanded="true"
                  class="collapsed"
                  :aria-controls="'recommended' + index"
                  @click="
                    initArticleBoughtTogather(
                      articleObjToID(conn_articles.articles)
                    )
                  "
                >
                  <span class="hide-articles">Hide</span>
                  <span class="show-articles">Show</span>
                  <img src="../../image/right-sky.png" alt="" />
                </button>
                <div class="table-responsive-lg">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" width="10%">Article number</th>
                        <th scope="col" width="45%">Article Name</th>
                        <th scope="col" width="30%">Сategory</th>
                        <th scope="col" width="15%">Article connection</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(article, index) in conn_articles.articles"
                        :key="article.id"
                      >
                        <td>{{ article.number }}</td>
                        <td>{{ article.name }}</td>
                        <td>{{ article.product }}</td>
                        <td
                          :rowspan="conn_articles.articles.length"
                          v-show="index == 0"
                          class="article-connection"
                        >
                          {{ Math.round(conn_articles.correlation) }}%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="recommended-section-details collapse"
                  :id="'recommended' + index"
                >
                  <h6>Recommended associated articles</h6>
                  <div class="table-responsive-lg">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">Article name</th>
                          <th scope="col">Category</th>
                          <th scope="col">Probability to sell</th>
                          <!-- <th scope="col"></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(articles, index) in boughtTogatherArticles[
                            articleObjToID(conn_articles.articles)
                          ]"
                          :key="index"
                        >
                          <td>{{ articles.number }}</td>
                          <td>{{ articles.article_name }}</td>
                          <td>{{ articles.category }}</td>
                          <td>
                            <h6 class="high-article" v-if="articles.rank == 1">
                              High
                            </h6>
                            <h6
                              class="medium-article"
                              v-if="articles.rank == 2"
                            >
                              Medium
                            </h6>
                            <h6 class="low-article" v-if="articles.rank == 3">
                              Low
                            </h6>
                          </td>
                          <!-- <td>
                            <a href="#" class="add-article-btn"
                              ><img src="../../image/plus-sky.png" /> Add
                              article</a
                            >
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            :page="page"
            :totalPages="totalPages"
            :name="'conected articles'"
            :count="connectedArticles.count ? connectedArticles.count : 0"
            :incrementpage="incrementpage"
            :decrementpage="decrementpage"
            :setpage="setpage"
            :perpage="5"
          />
        </div>
      </div>
</template>
<script>
import { mapActions} from "vuex";
import {
  CONNECTED_ARTICLES,
  BOUGHT_TOGATHER_ARTICLES,
} from "@/Core/store/action-types";
import { Field } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import Pagination from "../../Core/Pagination";
export default {
  name: "ConArticles",
  components: {
    Pagination,
    Multiselect,
    Field
  },
  data() {
    return {
      connectedArticles: [],
      page: 1,
      connectedArticleCount:2,
      totalPages: [],
      boughtTogatherArticles: {},
    };
  },
  mounted(){
    this.initConnectedArticles()
  },
  methods: {
    ...mapActions("article", [
      CONNECTED_ARTICLES,
      BOUGHT_TOGATHER_ARTICLES,
    ]),
    //convert article object to array od ID and
    articleObjToID(obj) {
      let article_ids = [];
      obj.map((ob) => {
        article_ids.push(ob.id);
      });
      return article_ids;
    },
    //get connected articles data
    initConnectedArticles() {
          this[CONNECTED_ARTICLES]({
            product_ids: [],
            page: this.page,
            max_articles:this.connectedArticleCount
          }).then((resp) => {
            this.totalPages = Array(Math.ceil(resp.count / 5))
              .fill(0)
              .map((e, i) => i + 1);
            this.connectedArticles = resp;
          });
    },
    //get bought togather article data
    initArticleBoughtTogather(article_ids) {
      if (this.boughtTogatherArticles[article_ids]) {
        return;
      }
      this[BOUGHT_TOGATHER_ARTICLES](article_ids).then((resp) => {
        this.boughtTogatherArticles[article_ids] = resp;
      });
    },

    //go to new page
    incrementpage() {
      this.page = this.page + 1;
      this.initConnectedArticles();
    },
    //go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.initConnectedArticles();
    },
    //go to perticular page
    setpage(page) {
      this.page = page;
      this.initConnectedArticles();
    },
  },
  watch:{
    connectedArticleCount(){
      this.initConnectedArticles()
    }
  }
};
</script>
<style scoped>
.count-dropdown{
  width: 8%;
}
</style>