<template>
  <svg fill="#5e66fa" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" id="receipt">
    <g>
      <path
        d="M16 33h14.95v2H16zM28.627 41h8.323v2h-8.323zM28 11h-4a1 1 0 110-2h4a1 1 0 110 2zM37 15H26a1 1 0 110-2h11a1 1 0 110 2zM36 19H23a1 1 0 110-2h13a1 1 0 110 2zM37 23H23a1 1 0 110-2h14a1 1 0 110 2zM35 27H23a1 1 0 110-2h12a1 1 0 110 2zM36 31H22a1 1 0 110-2h14a1 1 0 110 2zM20.05 0H45v2H20.05z">
      </path>
      <path
        d="M24.95 47H0v-2c.827 0 1.424-.196 1.825-.601.951-.957.935-3.183.921-5.146-.016-2.194-.03-4.268 1.133-5.438C4.417 33.274 5.13 33 6 33h9.174C17.189 22.034 17.002 4.195 17 4.012 17.023 2.605 17.69 0 20.05 0v2C19.093 2 19 3.996 19 4.017c.009.747.2 19.103-2.02 30.18l-.161.803H6c-.428 0-.605.128-.701.225-.577.581-.564 2.405-.553 4.015.014 1.983.03 4.189-.885 5.76H24.95c.827 0 1.424-.196 1.825-.601.951-.957.935-3.183.921-5.146-.016-2.194-.03-4.268 1.133-5.438.538-.541 1.252-.815 2.121-.815 1.014 0 1.806.281 2.356.837.926.936.912 2.326.897 3.671-.012 1.133-.024 2.304.554 2.888.397.401 1.135.604 2.193.604.108 0 .438 0 .889-.463 4.051-4.159 4.341-27.766 4.111-36.511C41.973 2.605 42.64 0 45 0c2.61 0 3 5.013 3 8v1h-3.987c.017 9.426-.492 28.57-4.741 32.933-.69.708-1.471 1.067-2.322 1.067-1.634 0-2.817-.392-3.614-1.198-1.165-1.177-1.147-2.844-1.132-4.314.01-.935.02-1.9-.319-2.243-.2-.203-.618-.245-.935-.245-.428 0-.605.128-.701.225-.577.581-.564 2.405-.553 4.015.016 2.354.035 5.021-1.502 6.569C27.41 46.6 26.318 47 24.95 47zM44.001 7h1.977c-.103-2.446-.561-4.708-1.056-5.019-.879.019-.972 2.015-.972 2.036.013.482.037 1.53.051 2.983z">
      </path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "RecieptsUploadIcon",
};
</script>

<style scoped></style>