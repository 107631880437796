<template>
  <div>
    <RegistrationModal
      v-bind:modalId="createModalId"
      name="Admin"
      selectedRole="Admin"
    />
    <EditUserModal
      v-bind:modalId="editModalId"
      v-bind:userToEdit="editedResource"
    />
    <CustomModel
      v-if="deletedresource"
      :modalId="deleteUserModelID"
      :item="deletedresource"
      :onDelete="deleteUser"
      :resourseName="'Admin'"
      actionType="Delete"
    />
    <CustomModel
      v-if="deletedresource"
      :modalId="deactivateModelID"
      :item="deletedresource"
      :onDelete="deactivateUser"
      :resourseName="'Admin'"
      actionType="Deactivate"
    />
    <div>
      <div class="contant-box-main">
        <div class="data-heading-wrp">
          <div class="data-heading">
            <div class="search-box-wrp">
              <input
                type="text"
                class="form-control"
                v-model="search"
                name=""
                placeholder="Search by the admin email "
              />
              <a class="search_icon" href="#"
                ><img
                  src="../../../public/assets/images/search_icon.svg"
                  alt=""
              /></a>
            </div>
            <!-- <div class="tab-box-wrp">
              <ul>
                <li
                  @click="changeStatus('all')"
                  :class="status == 'all' ? 'active' : ''"
                >
                  All
                </li>
                <li
                  @click="changeStatus('active')"
                  :class="status == 'active' ? 'active' : ''"
                >
                  Active
                </li>
                <li
                  @click="changeStatus('inactive')"
                  :class="status == 'inactive' ? 'active' : ''"
                >
                  Inactive
                </li>
              </ul>
            </div> -->
          </div>

          <div class="data-heading-btn" @click="showCreateDialog">
            <button>+ create new Admin</button>
          </div>
        </div>
        <div class="table-wrp overflow-auto position-relative">
          <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <table class="table my-table-wrp table-sm" id="my-table">
            <thead>
              <tr>
                <th scope="col" v-for="f in fields" v-bind:key="f.id">
                  <div
                    class="table-head"
                    v-if="f === 'ID'"
                    @click="sortTable('id')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_id ? 'sort-active' : ''">&#8595;</span>
                      <span :class="desc_id ? 'sort-active' : ''">&#8593;</span>
                    </span>
                  </div>
                  <div
                    class="table-head"
                    v-else-if="f === 'Email'"
                    @click="sortTable('email')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_email ? 'sort-active' : ''"
                        >&#8595;</span
                      >
                      <span :class="desc_email ? 'sort-active' : ''"
                        >&#8593;</span
                      >
                    </span>
                  </div>
                  <div
                    class="table-head"
                    v-else-if="f === 'Created by'"
                    @click="sortTable('created')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_created ? 'sort-active' : ''"
                        >&#8595;</span
                      >
                      <span :class="desc_created ? 'sort-active' : ''"
                        >&#8593;</span
                      >
                    </span>
                  </div>
                  <div class="table-head" v-else>
                    {{ f.split("_").join(" ") }}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="user?.is_active ? 'active-row' : 'inactive-row'"
                v-for="user in users?.results"
                v-bind:key="user?.id"
              >
                <td width="5%" class="font-light-text">{{ user?.id }}</td>
                <td width="35%">{{ user?.email }}</td>
                <td width="20%" class="font-light-text">
                  {{ user.created_by?.email }}
                </td>
                <td width="10%" class="font-light-text">
                  {{ user?.date_joined }}
                </td>
                <td width="10%" class="font-light-text">
                  {{ user?.last_login || "-" }}
                </td>
                <td class="btn-align" width="7%">
                  <div class="d-flex align-item-center">
                    <button
                      class="action-btns ms-2 icon-effect"
                      @click="editUser(user)"
                    >
                      <PenIcon />
                    </button>
                    <button
                      class="action-btns ms-2 delete-profile"
                      @click="showDeleteModel(user)"
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            :page="page"
            :totalPages="totalPages"
            :name="'Users'"
            :count="users ? users.count : 0"
            :incrementpage="incrementpage"
            :decrementpage="decrementpage"
            :setpage="setpage"
            :perpage="10"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PenIcon from "../../icons/pen.vue";
import DeleteIcon from "../../icons/cancel";
import {
  LIST_USERS,
  LIST_ORGS,
  DEACTIVATE_USER,
  FULL_DELETE_USER,
} from "@/Core/store/action-types";
import { mapActions, mapGetters } from "vuex";
import { UserRoleMixin, MERCHANT } from "@/Core/mixins/UserRoleMixin";
import Pagination from "../../Core/Pagination";
import RegistrationModal from "./RegistrationModal";
import EditUserModal from "./EditUserModal";
// import FormModal from "./FormModal";
import { subject } from "@casl/ability";
import { RESOURCE_NAME } from "../user.vars";
import CustomModel from "../../Core/CustomModel.vue";
export default {
  name: "AllAdminTable",
  components: {
    Pagination,
    // FormModal,
    CustomModel,
    PenIcon,
    DeleteIcon,
    RegistrationModal,
    EditUserModal,
  },
  mixins: [UserRoleMixin],
  computed: {
    ...mapGetters("org", ["getOrgById", "orgsList"]),
    ...mapGetters("user", { userProfile: "userProfile" }),
  },
  data() {
    return {
      fields: ["ID", "Email", "Created by", "Creation Date", "Last Login", ""],
      status: "all",
      search: "",
      page: 1,
      users: null,
      totalPages: [],
      role: "all",
      createModalId: "createDialog",
      editModalId: "editDialog",
      editedResource: null,
      deleteUserModelID: "deleteuserModel",
      deactivateModelID: "deactivateuserModel",
      deletedresource: {},
      organization: "all",
      asc_email: null,
      desc_email: null,
      asc_id: null,
      desc_id: true,
      asc_created: null,
      desc_created: null,
      desc_status: null,
      loadindShow:false
    };
  },
  methods: {
    ...mapActions("user", [LIST_USERS, DEACTIVATE_USER, FULL_DELETE_USER]),
    ...mapActions("org", [LIST_ORGS]),
    initUser(pageNumber = null) {
      pageNumber ? (this.page = pageNumber) : null;
      let options = {
        persist: false,
        page: this.page,
        q: this.search?.length < 3 ? null : this.search,
        superadmin: 0,
        admin: 1,
        asc_email: this.asc_email ? this.asc_email : null,
        desc_email: this.desc_email ? this.desc_email : null,
        asc_id: this.asc_id ? this.asc_id : null,
        desc_id: this.desc_id ? this.desc_id : null,
        asc_created: this.asc_created ? this.asc_created : null,
        desc_created: this.desc_created ? this.desc_created : null,
      };
       this.loadindShow = true
      this[LIST_USERS](options)
        .then((res) => {
          this.users = res;
          this.totalPages = Array(Math.ceil(res.count / 10))
            .fill(0)
            .map((e, i) => i + 1);
        })
        .catch((err) => {
          console.log(err);
        }) .finally(()=>this.loadindShow=false)
    },
    editUser(user) {
      // this.onUpdateItem(RESOURCE_NAME, window.$(`#${this.editModalId}`), user);
      (this.editedResource = user),
        window.$(`#${this.editModalId}`).modal("toggle");
    },
    showDeleteModel(item) {
      this.deletedresource = item;
      if (this.getUserRole(item) == MERCHANT.name) {
        window.$(`#${this.deactivateModelID}`).modal("toggle");
      } else {
        window.$(`#${this.deleteUserModelID}`).modal("toggle");
      }
    },
    async deleteUser() {
      window.$(`#${this.deleteUserModelID}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "delete",
        subject(RESOURCE_NAME, this.deletedresource)
      );
      if (hasPermission) {
        this[FULL_DELETE_USER](this.deletedresource.id)
          .then(() => {
            this.initUser(1);
            this.$alertify.notify(
              `${this.deletedresource.email} successfully removed.`,
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    async deactivateUser() {
      window.$(`#${this.deactivateModelID}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "deactivate",
        subject(RESOURCE_NAME, this.deletedresource)
      );
      if (hasPermission) {
        this[DEACTIVATE_USER](this.deletedresource.id)
          .then(() => {
            this.initUser(1);
            this.$alertify.notify(
              `${this.deletedresource.email} successfully deactivated.`,
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    changeStatus(state) {
      this.status = state;
    },
    incrementpage() {
      this.page = this.page + 1;
      this.initUser();
    },
    decrementpage() {
      this.page = this.page - 1;
      this.initUser();
    },
    setpage(page) {
      this.page = page;
      this.initUser();
    },
    showCreateDialog() {
      window.$(`#${this.createModalId}`).modal("toggle");
    },
    sortTable(item) {
      if (item == "id") {
        (this.asc_email = null),
          (this.desc_email = null),
          (this.asc_created = null),
          (this.desc_created = null);
        if (this.asc_id) {
          this.asc_id = null;
          this.desc_id = true;
        } else if (this.desc_id) {
          this.desc_id = null;
          this.asc_id = true;
        } else {
          this.asc_id = true;
          this.desc_id = null;
        }
      } else if (item == "email") {
        (this.asc_id = null),
          (this.desc_id = null),
          (this.asc_created = null),
          (this.desc_created = null);
        if (!this.asc_email && !this.desc_email) {
          this.asc_email = true;
          this.desc_email = null;
        } else if (this.asc_email) {
          this.asc_email = null;
          this.desc_email = true;
        } else {
          this.asc_email = true;
          this.desc_email = null;
        }
      } else if (item == "created") {
        (this.asc_email = null),
          (this.desc_email = null),
          (this.asc_id = null),
          (this.desc_id = null);
        if (!this.asc_created && !this.desc_created) {
          this.asc_created = true;
          this.desc_created = null;
        } else if (this.asc_created) {
          this.asc_created = null;
          this.desc_created = true;
        } else {
          this.asc_created = true;
          this.desc_created = null;
        }
      } else {
        return;
      }
      this.initUser(1);
    },
  },
  mounted() {
    this.initUser();
  },

  watch: {
    search() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this.page = 1;
        this.initUser();
      }
    },
  },
};
</script>