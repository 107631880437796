import { LIST_ORDERS,GET_AVG_ORDER_VALUE } from "@/Core/store/action-types";
import api from "./order.api";

const actions = {
  async [LIST_ORDERS]({ rootState }, { params }) {
    const resp = await api.get(rootState.user.userProfile.access, params);
    return resp.data;
  },
  async [GET_AVG_ORDER_VALUE]({ rootState }, params) {
    const resp = await api.get_avg_order_value(rootState.user.userProfile.access, params);
    return resp.data;
  }
};
export default {
  namespaced: true,
  actions,
};
