<template>
<div>
  <div>
        <div class="contant-box-main">
          <div class="data-heading-wrp">
            <div class="data-heading">
              <!-- search box start -->
              <div class="search-box-wrp">
                <input
                  type="text"
                  class="form-control"
                  v-model="search"
                  name=""
                  placeholder="Search by the country"
                />
                <a class="search_icon" href="#"
                  ><img
                    src="../../../public/assets/images/search_icon.svg"
                    alt=""
                /></a>
              </div>
              <!-- search box end -->
              <!-- status filter end -->
            </div>
          </div>
          <!-- campaign table start -->
          <div class="table-wrp overflow-auto position-relative">
            <div class="loader-main" v-if="loadingShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
            <table class="table my-table-wrp table-sm" id="my-table">
              <thead>
                <tr>
                  <th scope="col" v-for="f in fields" v-bind:key="f.id">
                    <div class="table-head">{{ f.split("_").join(" ") }}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="country in countryWiseData.results"
                  v-bind:key="country.country_code"
                  class="active-row"
                >
                  <td width="10%" class="font-text">{{getCountryName(country?.country_code)}}</td>
                  <td width="20%" class="font-light-text"> 
                    {{(country?.avg_order_value || 0).toFixed(0) }}{{' '}}{{default_currency}}</td>
                  <td width="20%" class="font-light-text"> 
                    {{ (country?.avg_campaign_orders_value || 0).toFixed(0)}}{{' '}}{{default_currency}}</td>
                  <td width="17%"  class="font-light-text">
                    {{(country?.total_net_profit || 0).toFixed(0) }}{{' '}}{{default_currency}}
                  </td>
                  <td width="17%" class="font-text">
                    {{ (country?.net_profit_margin || 0 ).toFixed(1) }}%
                  </td>
                  <td width="16%"  class="font-light-text">
                     {{ (country?.shipping_charge || 0).toFixed(0)}}{{' '}}{{default_currency}}
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination
              :page="page"
              :totalPages="totalPages"
              name="Countries"
              :count="countryWiseData.count ? countryWiseData.count : 0"
              :incrementpage="incrementpage"
              :decrementpage="decrementpage"
              :setpage="setpage"
              :perpage="10"
            />
          </div>
          <!-- campaign table end -->
        </div>
      </div>
    </div>  
</template>
<script>
import Pagination from "../../Core/Pagination";
import { mapActions,mapGetters} from "vuex";
import {getCountryNameByID} from "../../Core/helpers/countryList"
import {
  GET_CAMPAIGN_COUNTRY_PERFORMANCE,
} from "@/Core/store/action-types";
export default {
  name: "CampaignCountryPerformance",
  components: {
    Pagination,
  },
  data() {
    return {
      fields: ["Country", "Avg country revenue", "Avg campaign revenue","Net Profit","Net margin","Shipping Charge/order"],
      countryWiseData: [],
      params: { persist: true },
      search: "",
      page: 1,
      loadingShow:false,
      totalPages: [],
    };
  },
    computed: {
    ...mapGetters("campaign", ["campaignDetails"]),
    ...mapGetters("org",["org","default_currency"]),
     },
     filters: {
        getName: function (value) {
            return getCountryNameByID(value)
        }
    },
  methods: {
    ...mapActions("campaign", [
      GET_CAMPAIGN_COUNTRY_PERFORMANCE,
    ]),
    getCountryName(value){
        return getCountryNameByID(value)
    },
    //get capmain data
    initCampaigns(pageNumber = null) {
    if (
        this?.$route?.params?.campaignId &&
        this.$route.params.campaignId != ""
      ){
        pageNumber ? (this.page = pageNumber) : null;
        let payload={
          objId:this?.$route?.params?.campaignId,
          params:{
             q: this.search.length < 3 ? null : this.search,
             currency:this.default_currency
          }
        }
        this.loadingShow = true
        this[GET_CAMPAIGN_COUNTRY_PERFORMANCE](payload).then((res) => {
            this.countryWiseData = res;
            this.totalPages = Array(Math.ceil(res.count / 10))
            .fill(0)
            .map((e, i) => i + 1);
        }).finally(()=>this.loadingShow=false)
      }  
   
    },
    //go to next page
    incrementpage() {
      this.page = this.page + 1;
      this.initCampaigns();
    },
    //go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.initCampaigns();
    },
    // go to perticular page
    setpage(page) {
      this.page = page;
      this.initCampaigns();
    },
  },
  async mounted() {
    this.initCampaigns();
    
  },
  watch: {
    search() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this.page = 1;
        this.initCampaigns();
      }
    },
  },
};
</script>
<style  scoped>
.table#my-table tbody tr td {
    font-size: 15px;
    padding: 5px !important;
    line-height: 2.3;
}
.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  width: 100px;
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>