<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4118 1.86589C10.3538 2.8524 8.89481 3.30436 7.4582 3.1085C6.20749 2.938 5.10831 3.75726 4.90776 4.85922C4.6487 6.28268 3.72651 7.49194 2.43485 8.15248C1.35276 8.70585 0.96354 9.94596 1.47212 10.9487C2.12785 12.2417 2.12785 13.7583 1.47212 15.0513C0.96354 16.054 1.35276 17.2941 2.43485 17.8475C3.72651 18.5081 4.6487 19.7173 4.90776 21.1408C5.10831 22.2427 6.20749 23.062 7.4582 22.8915C8.89481 22.6956 10.3538 23.1476 11.4118 24.1341C12.2925 24.9553 13.7075 24.9553 14.5882 24.1341C15.6462 23.1476 17.1052 22.6956 18.5418 22.8915C19.7925 23.062 20.8917 22.2427 21.0922 21.1408C21.3513 19.7173 22.2735 18.5081 23.5651 17.8475C24.6472 17.2941 25.0365 16.054 24.5279 15.0513C23.8721 13.7583 23.8721 12.2417 24.5279 10.9487C25.0365 9.94596 24.6472 8.70585 23.5651 8.15248C22.2735 7.49194 21.3513 6.28269 21.0922 4.85922C20.8917 3.75726 19.7925 2.938 18.5418 3.1085C17.1052 3.30436 15.6462 2.8524 14.5882 1.86589C13.7075 1.0447 12.2925 1.0447 11.4118 1.86589ZM15.4407 0.951653C14.0798 -0.317218 11.9202 -0.317218 10.5593 0.951653C9.78115 1.67725 8.69911 2.01611 7.62705 1.86996C5.75231 1.61438 4.0051 2.83791 3.67796 4.6354C3.49089 5.66329 2.82215 6.55045 1.86571 7.03957C0.193158 7.8949 -0.474219 9.87461 0.357297 11.5141C0.832795 12.4517 0.832794 13.5483 0.357297 14.4859C-0.474219 16.1254 0.193158 18.1051 1.86571 18.9604C2.82215 19.4495 3.49089 20.3367 3.67796 21.3646C4.0051 23.1621 5.75231 24.3856 7.62705 24.13C8.69911 23.9839 9.78115 24.3228 10.5593 25.0483C11.9202 26.3172 14.0798 26.3172 15.4407 25.0483C16.2188 24.3228 17.3009 23.9839 18.3729 24.13C20.2477 24.3856 21.9949 23.1621 22.322 21.3646C22.5091 20.3367 23.1778 19.4495 24.1343 18.9604C25.8068 18.1051 26.4742 16.1254 25.6427 14.4859C25.1672 13.5483 25.1672 12.4517 25.6427 11.5141C26.4742 9.87461 25.8068 7.8949 24.1343 7.03957C23.1778 6.55045 22.5091 5.66329 22.322 4.6354C21.9949 2.83791 20.2477 1.61438 18.3729 1.86996C17.3009 2.01611 16.2189 1.67725 15.4407 0.951653Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 10.75C10.1904 10.75 10.75 10.1904 10.75 9.5C10.75 8.80964 10.1904 8.25 9.5 8.25C8.80964 8.25 8.25 8.80964 8.25 9.5C8.25 10.1904 8.80964 10.75 9.5 10.75ZM9.5 12C10.8807 12 12 10.8807 12 9.5C12 8.11929 10.8807 7 9.5 7C8.11929 7 7 8.11929 7 9.5C7 10.8807 8.11929 12 9.5 12Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4419 8.55806C17.686 8.80214 17.686 9.19786 17.4419 9.44194L9.44194 17.4419C9.19786 17.686 8.80214 17.686 8.55806 17.4419C8.31398 17.1979 8.31398 16.8021 8.55806 16.5581L16.5581 8.55806C16.8021 8.31398 17.1979 8.31398 17.4419 8.55806Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5 17.75C17.1904 17.75 17.75 17.1904 17.75 16.5C17.75 15.8096 17.1904 15.25 16.5 15.25C15.8096 15.25 15.25 15.8096 15.25 16.5C15.25 17.1904 15.8096 17.75 16.5 17.75ZM16.5 19C17.8807 19 19 17.8807 19 16.5C19 15.1193 17.8807 14 16.5 14C15.1193 14 14 15.1193 14 16.5C14 17.8807 15.1193 19 16.5 19Z"
      fill="#49494A"
    />
  </svg>
</template>

<script>
export default {
  name: "OffersIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>