<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="13" fill="#0BC984" />
    <path
      d="M8.66602 17.3334L17.3327 8.66669M17.3327 8.66669H8.66602M17.3327 8.66669V17.3334"
      stroke="white"
      stroke-width="1.25"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "UpArrow",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>