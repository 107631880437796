<template>
  <Form
    @submit="onSubmit"
    :validation-schema="schema"
    v-slot="{ errors }"
    class="form-horizontal"
    action="#"
  >
    <div class="modal-body">
     <div >
      <div
        class="form-group"
        :class="{ 'has-error': errors.number }"
      >
        <label for="number"
          >Product Number<span class="required-field text-danger">*</span></label
        >
       <Field
          as="input"
          type="text"
          class="form-control"
          name="number"
          placeholder="Enter product unique number"
          v-model="formValues.number"
        />
        <div class="form-control-feedback">{{ errors.number }}</div>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.name }">
        <label for="name"
          >Product name<span class="required-field text-danger"
            >*</span
          ></label
        >
        <Field
          as="input"
          type="text"
          class="form-control"
          name="name"
          placeholder="Enter product name"
          v-model="formValues.name"
        />
        <div class="form-control-feedback">{{ errors.name }}</div>
      </div>
      <div class="form-group add-aditnoal" :class="{ 'has-error': errors.category }" v-if="formValues.category !== 0">
        <label for="category"
          >category</label>
        <Field v-model="formValues.category" name="category">
           <Multiselect
              v-model="formValues.category"
              label="name"
              valueProp="id"
              trackBy="name"
              :searchable="true"
              :options="[{ id: 0, name: '+ Add new category' },...productCategories]"
              placeholder="Select product category"
            />
        </Field>
        <div class="form-control-feedback">{{ errors.category }}</div>
      </div>
       <div v-else>
              <div
                class="d-flex align-item-center justify-content-between mb-3"
              >
                <h6 class="m-0 offer-header">New Category Details</h6>
                <span class="offer-close" @click="clearCategoryForm">
                  <!-- <img
                    src="../../../public/assets/images/cancle-icon.png"
                    alt=""
                  /> -->
                  <CrossIcon />
                </span>
              </div>
                <div class="modal-body offer-body-modal">
                  <div class="form-group" :class="{ 'has-error': errors.category_name }">
                    <label for="category_name"
                      >Category Name</label>
                    <Field
                      as="input"
                      type="text"
                      class="form-control"
                      name="category_name"
                      placeholder="Enter Category Name"
                      v-model="formValues.category_name"
                    />
                    <div class="form-control-feedback">{{ errors.category_name }}</div>
                  </div>
                </div>
      </div>
     </div>
     <div class="form-group" :class="{ 'has-error': errors.detail }">
        <Field as="input" type="hidden" class="form-control" name="detail" />
        <div class="form-control-feedback">{{ errors.detail }}</div>
      </div>
      <!--end form-group-->
    </div>
    <div class="modal-footer">
      <button  type="submit" class="save-btn">
        {{ isEditMode ? "Save" : "Create" }}
      </button>
      <button type="button" class="cancle-btn" data-bs-dismiss="modal">
        Cancel
      </button>
    </div> </Form
  ><!--end form-->
</template>

<script>
import { Field, Form } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import { mapActions } from "vuex";
import {
  LIST_PRODUCT_CATEGORIES
} from "@/Core/store/action-types";
// import {PHONE_REGEX} from "../../Core/helpers/utils"
import * as yup from "yup";
import CrossIcon from "../../icons/cross.vue";
export default {
  name: "ProductForm",
  components: {
    Form,
    Multiselect,
    Field,
    CrossIcon
  },
  props: {
    isEditMode: {
      required: false,
      default: false,
    },
    onSubmit: {
      required: true,
    },
    initData: {
      required: false,
    }
  },
  methods:{
    ...mapActions('product',[LIST_PRODUCT_CATEGORIES]),
    fetchProductCategories() {
      let params = {}
      this[LIST_PRODUCT_CATEGORIES](params).then((res)=>{
        this.productCategories = res
      })
    },
    clearCategoryForm() {
      this.formValues.category = null;
      this.formValues.category_name=null
    }
  },
  emits: ["form-change"],

  watch: {
    formValues: {
      handler: function (newForm) {
        this.$emit("form-change", newForm);
      },
      deep: true,
    },
    initData: function (newProduct) {
      this.formValues = {
        name: newProduct.name,
        number: newProduct.number,
        category:newProduct?.category?.id,
        category_name:null
      };
    },
  },
  mounted(){
    this.fetchProductCategories()
  },
  data() {
    return {
      formValues: {},
      productCategories:[],
      schema: yup.object().shape({
        name: yup.string().required("please enter product name").nullable(true,"please enter product name"),
        number: yup.string().required("please enter product unique number").nullable(true,"please enter product unique number"),
        category: yup.number().nullable().notRequired(),
        category_name: yup
          .string()
          .nullable().notRequired(),
        detail: yup
          .string() // use it for backend errors
          .nullable()
          .notRequired(),
      }),
    };
  },
}
</script>
