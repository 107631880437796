<template>
  <div>
    <div class="col-lg-6" v-if="offerDetails">
      <!-- delete audience model -->
      <CustomModel
        v-if="offerDetails"
        :modalId="deleteOfferModelID"
        :item="offerDetails"
        :onDelete="deleteOffer"
        :resourseName="'Offer'"
        actionType="Delete"
      ></CustomModel>
      <!-- delete audience model close -->
      <div class="contant-box">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <img
                src="./../../../public/assets/images/pr-icon.png"
                alt=""
              />Offer details
            </h6>
            <button
              title="Delete Offer"
              class="action-btns ms-2 delete-profile"
              @click="showDeleteModel()"
              v-if="deletepermission"
            >
              <DeleteIcon />
            </button>
          </div>
          <div class="profile-details">
            <div class="profile-field_wrp">
              <div class="profile_field_data">
                <h6 class="profile-title">Name</h6>
                <p class="profile-title">{{ offerDetails.name }}</p>
              </div>
              <div class="profile_field_data">
                <h6 class="profile-title">Details</h6>
                <p class="profile-title">{{ offerDetails.details }}</p>
              </div>
              <div class="profile_field_data">
                <h6 class="profile-title">Date Range</h6>
                <p class="profile-title">
                  {{ offerDetails.start_date }}-{{ offerDetails.end_date }}
                </p>
              </div>
              <div class="profile_field_data">
                <h6 class="profile-title">Article</h6>
                <div class="sub_profile-title">
                  <p
                    class="profile-title sub_p_title"
                    v-for="article in offerDetails.articles"
                    :key="article.id"
                  >
                    {{ article.name }}
                  </p>
                </div>
              </div>
              <div class="profile_field_data">
                <h6 class="profile-title">Status</h6>
                <p class="profile-title">{{ getOfferStatus() }}</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="edit-button form-btns">
              <button type="submit" class="save-btn" @click="GoToEditOffer()">
                <EditIcon />
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditIcon from "../../icons/edit.vue";
import { DELETE_OFFER, OFFER_DETAILS } from "@/Core/store/action-types";
import { mapGetters, mapActions } from "vuex";
import { subject } from "@casl/ability";
import { RESOURCE_NAME } from "../offer.vars";
import CustomModel from "../../Core/CustomModel.vue";
import DeleteIcon from "../../icons/cancel";
export default {
  name: "OfferDetailsGrid",
  components: {
    EditIcon,
    CustomModel,
    DeleteIcon,
  },
  data() {
    return {
      deleteOfferModelID: "deleteofferModel",
      deletepermission: this.$ability.can("delete", subject(RESOURCE_NAME, {})),
    };
  },
  computed: {
    ...mapGetters("offer", ["offerDetails"]),
  },
  methods: {
    ...mapActions("offer", [DELETE_OFFER, OFFER_DETAILS]),
    InItOfferDetails() {
      if (this?.$route?.params?.offerId && this.$route.params.offerId != "") {
        this[OFFER_DETAILS](this?.$route?.params?.offerId)
          .then(() => {
            // console.log(res);
            // this.formValues.name = res.name;
            // this.formValues.offer = res.offer.id;
            // this.formValues.audience = res.audience.id;
            // if (res.is_active === true) this.status = "Active";
            // else if (res.is_active === false && res.is_archive === false)
            //   this.status = "Pending";
            // else this.status = "Expired";
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    showDeleteModel() {
      window.$(`#${this.deleteOfferModelID}`).modal("show");
    },
    //delete offer functionality
    async deleteOffer() {
      window.$(`#${this.deleteOfferModelID}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "delete",
        subject(RESOURCE_NAME, this.offerDetails)
      );
      if (hasPermission) {
        this[DELETE_OFFER](this.offerDetails.id).then(() => {
          this.$router.push({
            name: "Offers",
          });
          this.$alertify.notify(
            `${this.offerDetails.name} successfully removed.`,
            "success",
            3
          );
        });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    getOfferStatus() {
      if (this.offerDetails.is_active === true) return "Active";
      else if (
        this.offerDetails.is_active === false &&
        this.offerDetails.is_archive === false
      )
        return "Pending";
      else return "Expired";
    },
    GoToEditOffer() {
      let hasPermission = this.$ability.can(
        "update",
        subject(RESOURCE_NAME, this.offerDetails)
      );
      if (hasPermission) {
        this.$router.push({
          name: `EditOffer`,
          params: { offerId: this.offerDetails.id },
        });
      } else {
        this.$alertify.notify("Active offer can not be edited!", "error", 3);
      }
    },
  },
  mounted() {
    this.InItOfferDetails();
  },
};
</script>
<style scoped>
.sub_profile-title {
  width: 70%;
}
</style>