<template>
  <div class="contant-box-main customer-list-box">
    <div class="h-100">
      <div class="contant-header">
        <h6>
          <img src="@/../public/assets/images/star-icon.png" alt="" />Top
          Selling Products
        </h6>
        <div class="">
          <select
            class="for"
            id="adminfilter"
            v-model="time"
            @change="onTimeChange"
          >
            <option v-for="option in timeOptions" :key="option">
              {{ option }}
            </option>
          </select>
        </div>
      </div>
      <div class="contant-details h-100">
        <div class="overflow-auto position-relative" :class="loadindShow?'h-100':''">
          <div class="loader-main" v-if="loadindShow">
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
           </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Purchase Count</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in top_selling_products" :key="product.id">
                <td class="font-light-text" width="20%">
                  {{ product.article__product__number }}
                </td>
                <td width="60%">{{ product.article__product__name }}</td>
                <td class="font-light-text" width="20%">
                  {{ product.quantity }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { TOP_SELLING_PRODUCTS } from "@/Core/store/action-types";
export default {
  name: "TopSellingProducts",

  data() {
    return {
      top_selling_products: [],
      time: "month",
      timeOptions: ["week", "month", "quater", "half a year", "year"],
      loadindShow:false,
    };
  },
  methods: {
    ...mapActions("product", [TOP_SELLING_PRODUCTS]),
    initSellingProducts() {
      let params = {
        duration:
          this.time == "month"
            ? 30
            : this.time == "week"
            ? 7
            : this.time == "quater"
            ? 90
            : this.time == "half a year"
            ? 180
            : 365,
        
      };
      this.loadindShow = true
      this[TOP_SELLING_PRODUCTS](params)
        .then((result) => {
          this.top_selling_products = result;
        })
        .catch(() => (this.top_selling_products = []))
        .finally(()=>this.loadindShow=false)
    },
    onTimeChange() {
      this.initSellingProducts();
    },
  },
  mounted() {
    this.initSellingProducts();
  },
};
</script>
<style scoped>
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>