<template>
    <div class="email-page">
        <div class="your-email-title">
            <p>Your emails</p>
            <button id="main-email-btn" @click="loadMainPage">{{ showNewEmailButton ? 'New Email' : 'Home Page' }}</button>
        </div>
        <div class="email-page-tab" v-show="showEmailPageTab">
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <!-- <button class="nav-link email_menu active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home"
                        type="button" role="tab" aria-controls="nav-home" aria-selected="true">One-off</button> -->
                    <!-- <button class="nav-link email_menu" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                        type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Automated</button> -->
                </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <div class="d-flex align-items-start row">
                        <div class="col-lg-2 menu-tabs">
                            <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                <button class="nav-link active tab-buttons" id="v-pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
                                    aria-selected="true" @click="filterByStatus('all')">
                                    <div class="button-text email_th_sidebar">
                                        All
                                    </div>
                                    <div class="button-text-number">
                                        {{ allSends.length }}
                                    </div>
                                </button>

                                <button class="nav-link tab-buttons" id="v-pills-profile-tab"  data-bs-toggle="pill" type="button" role="tab"
                                aria-selected="false" @click="filterByStatus('draft')" >
                                    <div class="button-text email_th_sidebar">
                                        Draft
                                    </div>
                                    <div class="button-text-number">
                                        {{ allSends.filter(item => item.status === 'draft').length }}
                                    </div>
                                </button>

                                <button class="nav-link tab-buttons" id="v-pills-messages-tab" data-bs-toggle="pill"
                                    type="button" role="tab"
                                    aria-controls="v-pills-messages" aria-selected="false" @click="filterByStatus('sent')">
                                    <div class="button-text email_th_sidebar">
                                        Sent
                                    </div>
                                    <div class="button-text-number">
                                        {{ allSends.filter(item => item.status === 'sent').length }}
                                    </div>
                                </button>

                                <button class="nav-link tab-buttons" id="v-pills-settings-tab" data-bs-toggle="pill"
                                    type="button" role="tab"
                                    aria-controls="v-pills-settings" aria-selected="false" @click="filterByStatus('bin')">
                                    <div class="button-text email_th_sidebar">
                                        Bin
                                    </div>
                                    <div class="button-text-number">
                                        {{ allSends.filter(item => item.status === 'bin').length }}
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="tab-content" id="v-pills-tabContent">
                                <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                    aria-labelledby="v-pills-home-tab">
                                    <div class="email-table">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="email-table-detail">
                                                    <div class="search-container">
                                                        <form>
                                                            <input type="text" placeholder="Search.." name="" v-model="search">
                                                            <button type="submit" class="serach-icon">

                                                                <img src="img/search_icon.1406aa06.svg" alt="">
                                                            </button>
                                                        </form>
                                                    </div>

                                                    <div class="table-wrp overflow-auto position-relative"
                                                        data-v-ed24485a=""><!--v-if-->
                                                        <table class="table my-table-wrp table-sm" id="my-table"
                                                            data-v-ed24485a="">
                                                            <thead data-v-ed24485a="">
                                                                <tr data-v-ed24485a="">
                                                                    <th width="50%" data-v-ed24485a="">Description</th>
                                                                    <th width="10%" data-v-ed24485a="">Informotion</th>
                                                                    <th width="10%" data-v-ed24485a="">Status</th>
                                                                    <th width="10%" data-v-ed24485a="">Addition</th>
                                                                    <th width="20%" data-v-ed24485a="">Created at</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody data-v-ed24485a="">
                                                                <tr class="active-row" v-for="item in allSends" :key="item.index" data-v-ed24485a="">
                                                                    <td class="font-text" data-v-ed24485a=""><a
                                                                            href="#/Audience/125" class=""
                                                                            data-v-ed24485a="">
                                                                            <div class="author-emil-img">
                                                                                <img src="https://upload.wikimedia.org/wikipedia/commons/9/99/Black_square.jpg"
                                                                                    alt="">
                                                                                <div class="author-details">
                                                                                    <p class="author-title">{{ item.name }}</p>
                                                                                    <p>Fri Aktivite till alla matgaster
                                                                                        pa
                                                                                        o'Learys Linkoping</p>
                                                                                </div>
                                                                            </div>
                                                                        </a></td>
                                                                    <td class="font-light-text" data-v-ed24485a="">{{ item.send_at }}</td>
                                                                    <td class="font-light-text" data-v-ed24485a="">
                                                                        <div :class="{
                                                                            'table-draf': item.status === 'draft',
                                                                            'table-sent': item.status === 'triggered',
                                                                            'other-class': item.status !== 'draft' && item.status !== 'triggered'
                                                                        }">
                                                                            {{ item.status }}
                                                                        </div>
                                                                    </td>
                                                                    <td class="font-light-text" data-v-ed24485a="">
                                                                        --</td>
                                                                    <td class="font-light-text" data-v-ed24485a="">
                                                                        {{ item.created_at }}</td>
                                                                    <!-- <td class="font-light-text"><button class="eye-view" @click="editMember(item)"><img src="./../../public/assets/images/coolicon.png" alt="" /></button></td> -->
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <nav class="main-pagination" aria-label="Page navigation"
                                                            data-v-ed24485a="">
                                                            <div class="main-title"> showed 1 to 10 out of 39 Emails
                                                            </div>
                                                            <div class="main-pagination">
                                                                <ul class="pagination">
                                                                    <li class="page-item"><!--v-if--></li>
                                                                    <li class="page-item">
                                                                        <!--v-if--><!--v-if--><!--v-if--><button
                                                                            type="button"
                                                                            class="page-link-active">1</button><button
                                                                            type="button"
                                                                            class="page-link">2</button><button
                                                                            type="button" class="page-link">3</button>
                                                                    </li>
                                                                    <li><!--v-if--></li>
                                                                    <li><button type="button" class="page-link">4</button>
                                                                    </li>
                                                                    <li class="page-item"><button type="button"
                                                                            class="page-link"><svg width="12" height="12"
                                                                                viewBox="0 0 12 12" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M6 11L11 6M11 6L6 1M11 6L1 6"
                                                                                    stroke="#49494A" stroke-width="1.25"
                                                                                    stroke-linecap="round"></path>
                                                                            </svg></button></li>
                                                                </ul>
                                                            </div>
                                                        </nav>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="v-pills-profile" role="tabpanel"
                                    aria-labelledby="v-pills-profile-tab">...</div>
                                <div class="tab-pane fade" id="v-pills-messages" role="tabpanel"
                                    aria-labelledby="v-pills-messages-tab">...</div>
                                <div class="tab-pane fade" id="v-pills-settings" role="tabpanel"
                                    aria-labelledby="v-pills-settings-tab">...</div>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...</div>
            </div>
        </div>

        <div class="mainpage" v-if="showMainPage">
            <div class="pagelist">
                <ul>
                    <li>
                        <button @click="loadPage(2)" :class="{ active: currentPage === 2, completed: isCompleted.page2 }">
                            <span>1</span>
                            <p>Template</p>
                        </button>
                    </li>
                    <li>
                        <button @click="loadPage(3)" :class="{ active: currentPage === 3, completed: isCompleted.page3 }">
                            <span>2</span>
                            <p>Content</p>
                        </button>
                    </li>
                    <li>
                        <button @click="loadPage(4)" :class="{ active: currentPage === 4, completed: isCompleted.page4 }">
                            <span>3</span>
                            <p>Information</p>
                        </button>
                    </li>
                    <li>
                        <button @click="loadPage(5)" :class="{ active: currentPage === 5, completed: isCompleted.page5 }">
                            <span>4</span>
                            <p>Receipts</p>
                        </button>
                    </li>
                    <li>
                        <button @click="loadPage(6)" :class="{ active: currentPage === 6, completed: isCompleted.page6 }">
                            <span>5</span>
                            <p>Review</p>
                        </button>
                    </li>
                    <li>
                        <button @click="loadPage(7)" :class="{ active: currentPage === 7, completed: isCompleted.page7 }">
                            <span>6</span>
                            <p>Send</p>
                        </button>
                    </li>
                </ul>
            </div>
            <!-- First page start -->
            <div v-if="currentPage === 2">
                <div class="searchbarButton">
                    <div class="searchBar">
                        <a class="search_icon"><img src="img/search_icon.1406aa06.svg" alt=""></a>
                        <input type="text" placeholder="Search by the template name" />
                        <span></span>
                    </div>
                    <div class="createButton">
                        <button @click="loadPage(3, 0)">+ Create</button>
                    </div>
                </div>
                <div class="listing">
                    <div class="row">
                        <!-- <div class="col-lg-3">
                            <div class="listing-card">
                                New
                            </div>
                        </div> -->

                        <div class="col-lg-3" v-for="template in templateList" :key="template.id">                            
                            <div class="listing-card">
                                <!-- <button type="button" class="mx-2" @click="loadPage(3, template.id)">
                                    {{ template.name }}
                                </button> -->
                                <input type="radio" name="templateSelect" v-on:change="selectTemplate(template.id)" />
                                <label for={{template.id}}>{{template.name}}</label>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>

            <div v-if="currentPage === 3">
                <div id="editor-container"></div>
            </div>

            <div v-if="currentPage === 4">
                <div>
                    <form class="information-form" :v-model="formValidate" ref="form">
                        <div class="row">
                            <div class="form-title">
                                <h1>Email information</h1>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label w-100">Name<span
                                            class="required-field text-danger">*</span></label>
                                    <input type="text" class="form-control" placeholder="Name" v-model.lazy="saveName" required/>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label w-100">Subject<span
                                            class="required-field text-danger">*</span></label>
                                    <input type="text" class="form-control" placeholder="Subject" v-model.lazy="saveSubject" required/>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-title">
                                <h1>Sender</h1>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label w-100">Sender name<span
                                            class="required-field text-danger">*</span></label>
                                    <input type="text" class="form-control" placeholder="O'Learys" />
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="form-label w-100">Sender email<span
                                            class="required-field text-danger">*</span></label>
                                    <input type="text" class="form-control" placeholder="abc@gmail.com" />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-title">
                                <h1>Setting</h1>
                            </div>

                            <div class="col-12">
                                <div class="form-group">
                                    <label for="name">Subscription Type</label>

                                    <Field name="settings">
                                        <Multiselect :options="settingsChoices" placeholder="please select a settings" />
                                    </Field>
                                    <div class="form-control-feedback"></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div v-if="currentPage === 5">
                <div class="recipients-form">
                    <p>Recipients</p>
                    <span class="title-desc">Select one of the below contact list to send this email to.</span>

                    <div class="Receipts-content">
                        <button data-toggle="modal" data-target="#createSendGrindContact">
                            <UpRightArrow />Create new list
                        </button>
                        <!-- <div class="content-block">
                            <label class="checkbox-container">
                                <input type="checkbox" v-model="contactCheckedBoxValue[index]" />
                                <span class="checkmark"></span>
                            </label>
                            <div class="checkbox-content">
                                <p>All contacts</p>
                                <span>Number of member in list 1110,478</span>
                            </div>
                        </div> -->
                        <div class="content-block" v-for="element in sendgridLists" :key="element.id">
                            <label class="radio-container">
                                <input type="radio" :value="element.id" v-model="selectedRadio" />
                                <span class="checkmark"></span>
                            </label>
                            <div class="radio-content">
                                <p>{{ element.name }}</p>
                                <span>Number of member in list {{ element.contact_count }}</span>
                            </div>
                        </div>
                        <!-- <div class="form-group">
                            <label class="form-label w-100">Gender</label>
                            <select
                                class="form-control custom-select"
                                v-model="gender"
                            >
                                <option value="All">All</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Corporate">Corporate</option>
                            </select>
                        </div> -->
                    </div>
                </div>
            </div>

            <div v-if="currentPage === 6">
                <form class="information-form">
                    <div class="row">
                        <div class="form-title">
                            <h1>Email information</h1>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label w-100">Name</label>
                                <input type="text" class="form-control" v-model="saveName" placeholder="Name" disabled style="background: transparent;">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label class="form-label w-100">Subject</label>
                                <input type="text" class="form-control" v-model="saveSubject" placeholder="Name" disabled style="background: transparent;">
                            </div>
                        </div>
                    </div>
                    <div class="form-title" v-if="selectedRadio">
                        <h1>Contact</h1>
                    </div>
                    <div class="content-block" v-if="selectedRadio">
                        <div class="checkbox-content">
                            <p class="selected-list-name">{{ sendgridLists.find(list => list.id === selectedRadio).name }}</p>
                            <span>Number of member in list {{ sendgridLists.find(list => list.id === selectedRadio).contact_count }}</span>
                        </div>
                    </div>

                </form>


            </div>

            <div v-if="currentPage === 7">
                <div>
                    <p>Are you sure you want to send mail?</p>
                    <button @click="sendEmail" type="button">Yes</button> <button type="button">No</button>
                </div>
            </div>
            <div class="stepper_btn">
                <button :disabled="currentPage <= 2" @click="loadPage(currentPage - 1)">Previous</button>
                <button :disabled="currentPage >= 7" @click="loadPage(currentPage + 1, templateId)">Next</button>
            </div>
        </div>
    </div>

    <div class="modal fade" id="createSendGrindContact" tabindex="-1" role="dialog"
        aria-labelledby="createSendGrindContactmodelLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="sendMailModelmodelLabel">
                        New Contact List Details
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!-- :validation-schema="schema"  -->
                <Form @submit="onSend" class="form-horizontal" v-slot="{ errors }" action="#">
                    <div>
                        <div class="modal-body offer-body-modal">
                            <div class="form-group" :class="{ 'has-error': errors.name }">
                                <label for="name">contact list name<span class="required-field text-danger">*</span></label>
                                <Field as="input" type="text" class="form-control" name="name"
                                    placeholder="Enter contact list name" v-model="new_contactlist.name" required />
                                <div class="form-control-feedback"></div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="account">
                                Select audience group
                                <span class="required-field text-danger">*</span>
                            </label>
                            <select class="form-control custom-select" @change="updateSelectedAudience" name="audience_id">
                                <option value="initial_option">
                                    Please select audience group
                                </option>
                                <option v-for="option in audienceListData" :key="option.id" :value="option.id">
                                    <span>{{ option.name }} - </span>
                                    <span>{{ option.members }} - </span>
                                    <span>{{ option.timestamp }}</span>
                                </option>
                            </select>
                            <div class="form-control-feedback"></div>
                        </div>
                        <div class="form-group">
                            <Field as="input" type="hidden" class="form-control" name="detail" />
                            <div class="form-control-feedback"></div>
                        </div>
                        <button type="submit" ref="submit" class="save-btn">
                            create
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import { mapActions } from "vuex";
import {
    GET_UNLAYER_TEMPLATES,
    GET_SENDGRID_CONTACT_LIST,
    SAVE_CONTACT_LIST,
    SEND_EMAIL_TO_CUSTOMER,
    GET_SINGLE_SENDS
} from "@/Core/store/action-types";
import UpRightArrow from "../../icons/upRightArrow.vue";
import * as yup from "yup";
import { Form, Field } from "vee-validate";
import { LIST_AUDIENCE } from "@/Core/store/action-types";

// import { RESOURCE_NAME } from "../email.vars";
export default {
    components: {
        Field,
        Multiselect,
        UpRightArrow,
        Form,
    },
    data() {
        var settingsChoices = ["1", "2", "3"];
        return {
            currentPage: 2,
            pages: [2, 3, 4, 5, 6, 7],
            showMainPage: false,
            showEmailPageTab: true,
            showNewEmailButton: true,
            templateList: [],
            settingsChoices: settingsChoices,
            templateDesign: {},
            sendgridLists: [],
            sendgrid: {},
            audience: {},
            schema: yup.object().shape({
                name: yup.string().required().nullable('name is required').nullable(),
                audience_id: yup.string().when('audience', {
                    is: 'initial_option',
                    then: yup.string().required().nullable('Please select audience group').nullable(),
                    otherwise: yup.string().nullable()
                }),
            }),
            audienceListData: [],
            status: 'audience',
            version_dynamic_fields: {},
            new_contactlist: {},
            selectedAudienceId: "",
            contact_list_id: "",
            saveName: "",
            saveSubject: "",
            selectedRadio: null,
            isCompleted: {
                page2: false,
                page3: false,
                page4: false,
                page5: false,
                page6: false,
                page7: false,
            },
            templateId: '',
            formValidate: false,
            allSends: [],
            search: "",
            filterStatus: "",
        };
    },
    methods: {
        ...mapActions("audience", [LIST_AUDIENCE, SAVE_CONTACT_LIST]),
        ...mapActions("email", [GET_UNLAYER_TEMPLATES, GET_SENDGRID_CONTACT_LIST, SEND_EMAIL_TO_CUSTOMER, GET_SINGLE_SENDS]),
        // Place the Unlayer editor initialization code here
        // Define your custom tool function
        registerCustomTool() {
            // eslint-disable-next-line no-undef
            unlayer.registerTool({
                name: 'Test tool',
                label: 'Test Tool',
                icon: 'yelp', // Font Awesome icon class
                supportedDisplayModes: ['email'],
                options: {
                    // Your custom tool options/configuration here
                },
                values: {
                    // Initial values for your custom tool
                }
            });
        },
        loadUnlayerEditor() {
            console.log("before------------>");
            // Initialize Unlayer with customJS array
            // eslint-disable-next-line no-undef
            unlayer.init({
                id: 'editor-container',
                projectId: 202507,
                displayMode: 'email',
                customJS: [this.registerCustomTool]
            });

            if (Object.keys(this.templateDesign).length === 0 && this.templateDesign.constructor === Object) {
                console.log("inside last if condition---->");
            } else {
                console.log("inside last else condition---->");
                var finalDesign = JSON.parse(this.templateDesign) // Serialize the design object
                // eslint-disable-next-line no-undef
                unlayer.loadDesign(finalDesign);
            }
        },
        loadPage(pageNumber, templateId) {
            this.currentPage = pageNumber; // Update the currentPage based on the button clicked
            if (this.currentPage === 3) {
                this.isCompleted.page2 = true;
                if (templateId !== 0) {
                    const matchedTemplate = this.templateList.find(template => template.id === templateId);
                    if (matchedTemplate && matchedTemplate.design) {
                        this.templateDesign = JSON.stringify(matchedTemplate.design); // Serialize the design object
                    }
                } else {
                    this.templateDesign = {}
                    console.log("No matched template or design found");
                }
                this.$nextTick(() => {
                    this.loadUnlayerEditor();
                });
            }
            for (let i = 2; i <= this.currentPage; i++) {
                this.isCompleted[`page${i-1}`] = true;
            }
        },
        loadMainPage() {
            this.showNewEmailButton = !this.showNewEmailButton; // Toggle the showNewEmailButton variable
            if (!this.showNewEmailButton) {
                this.showMainPage = true;
                this.showEmailPageTab = false;
            } else {
                this.showMainPage = false;
                this.showEmailPageTab = true;
            }
        },

        // get templates from Unlayer
        getUnlayerTemplates() {
            let options = { params: null };
            this[GET_UNLAYER_TEMPLATES](options)
                .then((resp) => {
                    this.templateList = resp;
                })
                .catch((err) => {
                    console.log("err=>", err);
                });
        },

        // get all single sends from the sendgrid
        getAllSingleSends() {
            let options = {
                status: this.filterStatus ? this.filterStatus : "all",
                q: this.search?.length < 3 ? null : this.search,
            };
            this[GET_SINGLE_SENDS](options)
                .then((resp) => {
                    this.allSends = resp;
                })
                .catch((err) => {
                    console.log("err=>", err);
                });
        },

        // // get sendgrid lists
        // getSendgridLists() {
        //     let options = { params: null };
        //     this[GET_SENDGRID_CONTACT_LIST](options)
        //         .then((resp) => {
        //             this.sendgridLists = resp;
        //             console.log("this.sendgridLists=>", this.sendgridLists);
        //         })
        //         .catch((err) => {
        //             console.log("err=>", err);
        //         });
        // },
        getSendgridLists() {
            if (!this.sendgridLists || this.sendgridLists.length == 0) this.isLoadingShow = true;
            this[GET_SENDGRID_CONTACT_LIST]()
                .then((resp) => {
                    if (resp["result"].length == 0) {
                        this.$alertify.notify(
                            "There is no contacts found,Please check your account",
                            "error",
                            3
                        );
                    }
                    console.log("--->", resp['result']);
                    this.sendgridLists = resp["result"];
                    console.log("this.sendgridLists--->", this.sendgridLists);
                })
                .catch((e) => {
                    this.$alertify.notify(
                        e?.response?.data?.detail || "There is some error to load the contacts, Try again",
                        "error",
                        3
                    );
                    this.sendgridLists = [];
                })
                .finally(() => (this.isLoadingShow = false));
        },
        filterByStatus(status){
            this.filterStatus = status
            this.getAllSingleSends();

        },
        //get audience data
        initAudience() {
            let params = {
                is_reference_group: this.status == "all"
                    ? null
                    : this.status == "reference"
                        ? true
                        : false,
            };
            this.loadindShow = true
            this[LIST_AUDIENCE](params).then((resp) => {
                this.audienceListData = resp;
                console.log("audienceListData--->", this.audienceListData);
            }).finally(() => this.loadindShow = false)
        },
        onSend(values) {
            console.log("send---->", values);
            let ids = []
            for (let customer of this.audienceListData) {
                ids.push(customer.id);
            }
            values['ids'] = ids
            values['dynamic_fields'] = this.version_dynamic_fields
            values['new_contact_list'] = this.new_contactlist
            values['selected_audience_id'] = this.selectedAudienceId
            values['contact_list_id'] = this.contact_list_id
            console.log("send---->", values);

            this[SAVE_CONTACT_LIST](values).then((resp) => {
                this.$alertify.notify(
                    `${resp.Success}`,
                    "success",
                    3
                );
            }).catch((e) => {
                this.$alertify.notify(
                    e?.response?.data?.detail || "Some error occured while saving contact details, please try again!",
                    "error",
                    3
                );
            }).finally(() =>
                document.getElementById("createSendGrindContact").click(),
                this.loadPage(5),
                console.log("5 called----->"),
            )
        },
        updateSelectedAudience(event) {
            console.log("event--->", event);
            const selectedIndex = event.target.selectedIndex;
            const selectedOption = event.target.options[selectedIndex];
            this.selectedAudienceId = selectedOption.value
            console.log("--->>>", this.selectedAudienceId);
        },
        sendEmail(data) {
            console.log("send-->");
            data['title'] = this.saveName,
            data['subject'] = this.saveSubject,
            data['contact_id'] = this.selectedRadio,
            console.log("templateId-->", this.templateId);
            data['template_id'] = this.templateId

            this[SEND_EMAIL_TO_CUSTOMER](data).then((resp) => {
                this.$alertify.notify(
                    `${resp.Success}`,
                    "success",
                    3
                );
            }).catch((e) => {
                this.$alertify.notify(
                    e?.response?.data?.detail || "Some error occured while sending Email, please try again!",
                    "error",
                    3
                );
            })
            // .finally(() =>
            //     document.getElementById("main-email-btn").click()
            // )
        },
        selectTemplate(id) {
            this.templateId = id
        },
    },
    watch: {
        search() {
            if (this.search.length >= 3 || this.search.length == 0) {
                this.page = 1;
                this.getAllSingleSends();
            }
        },

    },

    mounted() {
        // Load the Unlayer script
        const script = document.createElement('script');
        script.src = 'https://editor.unlayer.com/embed.js';
        script.onload = () => {
            // Define the unlayer object globally
            window.unlayer = window.unlayer || {};
            // Now you can call the loadUnlayerEditor method
            // this.loadUnlayerEditor();
        };
        document.head.appendChild(script);

        // Call the method to get Unlayer templates
        this.getUnlayerTemplates();

        // Call the method to get all sibgles sends of sengrid
        this.getAllSingleSends();
        setTimeout(() => {
            this.getSendgridLists();
        }, 1000); // Adjust the delay as needed
        this.initAudience();

        console.log("...this.sendgridLists", this.sendgridLists);
    },
};
</script>

<style>
button.active span {
    color: #5e66fa !important;
    border: 1px solid #5e66fa !important;
}

button.active p {
    color: #5e66fa !important;
}

button.completed p {
    color: green !important;
}

.mainpage {
    background: #fff;
    padding: 15px;
    height: calc(100vh - 220px);
    overflow-x: hidden;
    overflow-y: auto;
    border-top: 1px solid #dee2e6;
}

.searchbarButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding-bottom: 15px;
}

.searchBar input {
    height: 50px;
    border-radius: 8px;
    border: 1px solid lightgray;
    width: 100%;
    padding: 5px 5px 5px 35px;
    font-size: 15px;
    font-weight: 400;
}

.searchBar {
    max-width: 500px;
    width: 100%;
    position: relative;
}


.searchBar .search_icon {
    position: absolute;
    top: 13px;
    left: 15px;
}

.createButton button {
    padding: 10px 20px;
    border: 1px solid #5e66fa;
    border-radius: 8px;
    background: #5e66fa;
    color: #fff;
}

.listing {
    margin-top: 15px;
}

.listing-card {
    height: 300px;
    width: 100%;
    background-color: lightblue;
    border-radius: 10px;
    border: 1px solid gray;
    margin-bottom: 15px;
}

.pagelist {
    margin-bottom: 15px;
}

.pagelist ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.pagelist ul li button {
    padding: 0;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
}

.pagelist ul li button span {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid gray;
    border-radius: 100px;
    color: gray;
    font-size: 15px;
}

.pagelist ul li button p {
    margin: 0;
    font-size: 18px;
    color: gray;
    font-weight: 600;
}


.tab-buttons {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 15px 10px 15px !important;
    border-radius: 7px !important;

}

.email_menu {
    color: #302FF2 !important;
    font-weight: 500;
}

.email_th_sidebar,
.button-text-number {
    color: #302FF2 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    border-radius: 12px;
    padding: 10px 0px;
    background-color: #5e66fa !important;
}

.nav-pills .nav-link.active>.email_th_sidebar,
.nav-pills .nav-link.active>.button-text-number {
    color: white !important;
}

.menu-tabs {
    padding: 20px 25px 0 25px !important;
}

.author-emil-img {
    display: flex;
    align-items: center;
    gap: 10px;
}

.author-emil-img img {
    width: 30px;
    height: 30px;
}

.author-details p {
    margin: 0 0 0 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    color: #3f3f40 !important;
}

.author-details .author-title {
    margin: 0 0 0 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
    color: #302FF2 !important;
}

.table-draf {
    background: #f6f7d8;
    width: 50px;
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: center;
    border-radius: 7px;
    font-size: 15px;
    color: #ffc500;
}

.table-sent {
    background: #ecfeef;
    width: 50px;
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: center;
    border-radius: 7px;
    font-size: 15px;
    color: #00b558;
}

.your-email-title {
    margin-bottom: 35px;
}

.your-email-title button {
    background-color: #5e66fa !important;
}

.email-page table#my-table thead tr th {
    font-size: 18px !important;
    padding: 10px 0 10px 0;
}

.search-container form input {
    border: 1px solid #ced4da;
    max-width: 400px;
    width: 100%;
    position: relative;
    padding: 5px 5px 5px 30px;
    font-size: 15px;
    font-weight: 400;
    border-radius: 0.25rem;
    color: #aaabad !important;
}

.search-container .serach-icon {
    left: 3px;
    top: 3px;
}

#editor-container {
    height: 100vh;
}

.form-title h1 {
    margin: 5px 0 25px 0;
    padding: 0;
    font-size: 20px;
}

.information-form {
    width: 50%;
    margin: 0 auto;
}

.search-container {
    position: relative;
    margin: 25px 0 !important;
}











.recipients-form p {
    margin: 0;
    padding: 0;
    margin: 0px 0 0 0;
    padding: 0;
    font-size: 20px;
    font-weight: 500;
}

.recipients-form .title-desc {
    font-size: 12px;
    color: #3f3f40;
    margin: 0 0 50px 0;
    line-height: normal;
}

.recipients-form {
    width: 50%;
    margin: 0 auto;
}

.Receipts-content button {
    border: 0;
    padding: 7px;
    border-radius: 9px;
    background: #fff;
    font-size: 15px;
    border: 1px solid #cacbcd;
    margin-bottom: 10px;
}

.content-block {
    display: flex;
    align-items: center;
    border-radius: 11px;
    font-size: 15px;
    border: 1px solid #cacbcd;
    font-weight: 300;
    color: #3f3f40;
    padding: 10px 10px;
    gap: 30px;
    margin-bottom: 10px;
}

.checkbox-content p {
    margin: 0;
    padding: 0;
    font-size: 15px;
}

.checkbox-content span {
    margin: 0;
    padding: 0;
}

.checkbox-content {
    line-height: 1;
}

.checkbox-container {
    padding: 0px !important;
}

.checkbox-container .checkmark {
    top: -7px !important;
    border: 1px solid #cacbcd !important
}

.selected-list-name {
    font-weight: 600;
}

.stepper_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>