<template>
  <div>
    <div class="col-lg-6" v-if="campaignDetails">
      <!-- delete audience model -->
      <CustomModel
        v-if="campaignDetails"
        :modalId="deleteCampaignModelID"
        :item="campaignDetails"
        :onDelete="deleteCampaign"
        :resourseName="'Campaign'"
        actionType="Delete"
      ></CustomModel>
      <!-- delete audience model close -->
      <div class="contant-box">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <img
                src="./../../../public/assets/images/campaigns-icon.png"
                alt=""
              />campaign details
            </h6>
            <button
              title="Delete Campaign"
              class="action-btns ms-2 delete-profile"
              @click="showDeleteModel()"
              v-if="deletepermission"
            >
              <DeleteIcon />
            </button>
          </div>
          <div class="profile-details">
            <div class="profile-field_wrp">
              <div class="profile_field_data">
                <h6 class="profile-title">Name</h6>
                <p class="profile-title">{{ campaignDetails?.name }}</p>
              </div>
              <div class="profile_field_data">
                <h6 class="profile-title">Offer</h6>
                <p class="profile-title">{{ campaignDetails?.offer?.name }}</p>
              </div>
              <div class="profile_field_data">
                <h6 class="profile-title">Audience</h6>
                <p class="profile-title">{{ campaignDetails?.audience?.name }}</p>
              </div>
              <div class="profile_field_data">
                <h6 class="profile-title">Facebook Campaign ID </h6>
                <p class="profile-title">{{ campaignDetails?.facebook_campaign || '-' }}</p>
              </div>
              <div class="profile_field_data">
                <h6 class="profile-title">Google Ads Campaign </h6>
                <p class="profile-title" v-if="campaignDetails.google_campaign_data?.campaign_id">{{ (campaignDetails.google_campaign_data?.campaign_id + ' | ' + campaignDetails?.google_campaign_data?.campaign_name)  || '-' }}</p>
                <p class="profile-title" v-else> - </p>             
              </div>
              <div class="profile_field_data">
                <h6 class="profile-title">Offline Budget</h6>
                <p class="profile-title">{{ campaignDetails?.offline_budget?.toFixed(2) || 0 }}{{' '}} {{ this.default_currency}}</p>
              </div>
              <div class="profile_field_data">
                <h6 class="profile-title">Status</h6>
                <p class="profile-title">{{ getCampaignStatus() }}</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="edit-button form-btns">
                <button type="submit" class="save-btn" @click="GoToEditCampaign()">
                  <EditIcon />
                  Edit
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditIcon from "../../icons/edit.vue";
import { CAMPAIGN_DETAILS, DELETE_CAMPAIGN } from "@/Core/store/action-types";
import { mapGetters, mapActions } from "vuex";
import { subject } from "@casl/ability";
import { RESOURCE_NAME } from "../campaign.vars";
import CustomModel from "../../Core/CustomModel.vue";
import DeleteIcon from "../../icons/cancel";
export default {
  name: "CampaignDetailsGrid",
  components: {
    EditIcon,
    CustomModel,
    DeleteIcon,
  },
  data() {
    return {
      deleteCampaignModelID: "deletecampaignModel",
      deletepermission: this.$ability.can("delete", subject(RESOURCE_NAME, {})),
    };
  },
  computed: {
    ...mapGetters("campaign", ["campaignDetails"]),
    ...mapGetters("org",["org","default_currency"]),
  },
  methods: {
    ...mapActions("campaign", [CAMPAIGN_DETAILS, DELETE_CAMPAIGN]),
    InItCampaignDetails() {
      if (
        this?.$route?.params?.campaignId &&
        this.$route.params.campaignId != ""
      ) {
        let payload={
          objId:this?.$route?.params?.campaignId,
          params:{
            currency:this.default_currency
          }
        }
        this[CAMPAIGN_DETAILS](payload)
          .then(() => {
            // console.log(res);
            // this.formValues.name = res.name;
            // this.formValues.offer = res.offer.id;
            // this.formValues.audience = res.audience.id;
            // if (res.is_active === true) this.status = "Active";
            // else if (res.is_active === false && res.is_archive === false)
            //   this.status = "Pending";
            // else this.status = "Expired";
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    showDeleteModel() {
      window.$(`#${this.deleteCampaignModelID}`).modal("show");
    },
    //delete campaign functionality
    async deleteCampaign() {
      window.$(`#${this.deleteCampaignModelID}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "delete",
        subject(RESOURCE_NAME, this.campaignDetails)
      );
      if (hasPermission) {
        this[DELETE_CAMPAIGN](this.campaignDetails.id).then(() => {
          this.$router.push({
            name: "Campaigns",
          });
          this.$alertify.notify(
            `${this.campaignDetails.name} successfully removed.`,
            "success",
            3
          );
        });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    getCampaignStatus() {
      if (this.campaignDetails.is_active === true) return "Active";
      else if (
        this.campaignDetails.is_active === false &&
        this.campaignDetails.is_archive === false
      )
        return "Pending";
      else return "Expired";
    },
    GoToEditCampaign() {
      let hasPermission = this.$ability.can(
        "update",
        subject(RESOURCE_NAME, this.campaignDetails)
      );
      if (hasPermission) {
        this.$router.push({
          name: `EditCampaign`,
          params: { campaignId: this.campaignDetails.id },
        });
      } else {
        this.$alertify.notify("Active Campaign can not be edited!", "error", 3);
      }
    },
  },
  mounted() {
    this.InItCampaignDetails();
  },
};
</script>