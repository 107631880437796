import {
  LIST_AUDIENCE,
  DELETE_AUDIENCE,
  UPDATE_AUDIENCE,
  CREATE_AUDIENCE,
  AUDIENCE_DETAIL,
  AUDIENCE_CUSTOMER_DETAIL,
  GET_TOP_PERFORMING_AUDIENCES,
  CREATE_FACEBOOK_CUSTOM_AUDIENCE,
  DELETE_FACEBOOK_CUSTOM_AUDIENCE,
  UPDATE_FACEBOOK_CUSTOM_AUDIENCE,
  SAVE_CONTACT_LIST
} from "@/Core/store/action-types";

import {
  SET_AUDIENCE_LIST,
  REMOVE_AUDIENCE,
  ADD_AUDIENCE,
  EDIT_AUDIENCE,
  SET_AUDIENCE_DETAIL,
} from "@/Core/store/mutation-types";

import api from "./audience.api";
import router from "../Router";

const initialState = {};
const state = { ...initialState };

const actions = {
  async [LIST_AUDIENCE]({ commit, rootState }, params) {
    let response = await api.get(rootState.user.userProfile.access, params);

    if (params == null) {
      commit(SET_AUDIENCE_LIST, response.data);
    }
    return response.data;
  },
  async [DELETE_AUDIENCE]({ rootState }, objId) {
    return await api
      .delete(rootState.user.userProfile.access, objId)
      .then(function () {
        // commit(REMOVE_AUDIENCE, objId)
      })
      .catch(function () {
        throw new Error("");
      });
  },
  async [CREATE_AUDIENCE]({ rootState }, obj) {
    return await api
      .create(rootState.user.userProfile.access, obj)
      .then((resp) => {
        // commit(ADD_AUDIENCE, resp.data)
        return resp.data;
      })
      .catch(function (e) {
        throw new Error(e.response.data.details);
      });
  },
  async [UPDATE_AUDIENCE]({ rootState }, obj) {
    return await api
      .update(rootState.user.userProfile.access, obj)
      .then(function (response) {
        // commit(EDIT_AUDIENCE, response.data);
        return response.data;
      });
  },
  async [AUDIENCE_DETAIL]({ rootState, commit }, audience_id) {
    return await api
      .get_audience_details(rootState.user.userProfile.access, audience_id)
      .then(function (response) {
        commit(SET_AUDIENCE_DETAIL, response.data);
        return response.data;
      });

  },
  async [CREATE_FACEBOOK_CUSTOM_AUDIENCE]({ rootState }, obj) {
    return await api
      .create_facebook_audience(rootState.user.userProfile.access, obj)
      .then(function (response) {
        return response.data;
      })
      .catch(function (e) {
        throw new Error(e.response.data.details);
      });
  },
  async [DELETE_FACEBOOK_CUSTOM_AUDIENCE]({ rootState },obj) {
    return await api
      .delete_facebook_audience(rootState.user.userProfile.access, obj)
      .then(function (response) {
        return response.data;
      })
      .catch(function (e) {
        throw new Error(e.response.data.details);
      });
  },
  async [UPDATE_FACEBOOK_CUSTOM_AUDIENCE]({ rootState }, obj) {
    return await api
      .update_facebook_audience(rootState.user.userProfile.access, obj)
      .then(function (response) {
        return response.data;
      })
      .catch(function (e) {
        throw new Error(e.response.data.details);
      });
  },
  async [AUDIENCE_CUSTOMER_DETAIL]({ rootState }, obj) {
    return await api
      .get_audience_member(
        rootState.user.userProfile.access,
        obj.audience_id,
        obj.params
      )
      .then(function (response) {
        return response.data;
      });
  },
  async [GET_TOP_PERFORMING_AUDIENCES]({ rootState }, params) {
    const response = await api.get_top_performing_audiences(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [SAVE_CONTACT_LIST]({ rootState }, obj) {
    return await api
      .send_email(rootState.user.userProfile.access, obj)
      .then(function (response) {
        return response.data;
      })
      .catch(function (e) {
        throw new Error(e.response.data.details);
      });
  },
};

const mutations = {
  [SET_AUDIENCE_LIST](state, audienceList) {
    state.audienceList = audienceList;
  },
  [SET_AUDIENCE_DETAIL](state, audienceDetail) {
    state.audienceDetail = audienceDetail;
  },
  [ADD_AUDIENCE](state, obj) {
    state.audienceList.push(obj);
  },
  [EDIT_AUDIENCE](state, obj) {
    let idx = state.audienceList.findIndex((u) => u.id == obj.id);

    if (idx > -1) {
      state.audienceList[idx] = obj;
    }
  },
  [REMOVE_AUDIENCE](state, objId) {
    let idx = state.audienceList.findIndex((u) => u.id == objId);

    if (idx > -1) {
      state.audienceList.splice(idx, 1);
    }
    router.push("/Audience");
  },
};

const getters = {
  audienceList(state) {
    return state.audienceList;
  },
  audienceDetail(state) {
    return state.audienceDetail;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
