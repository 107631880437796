<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="{{modalId}}"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="modalId">{{ header_text }}</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors }"
          class="form-horizontal"
          action="#"
        >
          <div class="modal-body">
            <div class="form-group" :class="{ 'has-error': errors.name }">
              <label for="name"
                >Name<span class="required-field text-danger">*</span></label
              >
              <Field
                as="input"
                type="text"
                class="form-control"
                name="name"
                placeholder="Enter Org Name"
                v-model="formValues.name"
              />
              <div class="form-control-feedback">{{ errors.name }}</div>
            </div>
            <!--end form-group-->

            <div
              class="form-group"
              :class="{ 'has-error': errors.billing_address }"
            >
              <label for="email"
                >Billing Address<span class="required-field text-danger"
                  >*</span
                ></label
              >
              <Field
                as="input"
                type="text"
                class="form-control"
                name="billing_address"
                placeholder="Enter Billing Address"
                v-model="formValues.billing_address"
              />
              <div class="form-control-feedback">
                {{ errors.billing_address }}
              </div>
            </div>
            <div class="form-group" :class="{ 'has-error': errors.postal_id }">
              <label for="email"
                >Postal Code<span class="required-field text-danger"
                  >*</span
                ></label
              >
              <Field
                as="input"
                type="text"
                class="form-control"
                name="postal_id"
                placeholder="Enter Postal Code"
                v-model="formValues.postal_id"
              />
              <div class="form-control-feedback">{{ errors.postal_id }}</div>
            </div>
            <div class="form-group" :class="{ 'has-error': errors.city }">
              <label for="email"
                >City<span class="required-field text-danger">*</span></label
              >
              <Field
                as="input"
                type="text"
                class="form-control"
                name="city"
                placeholder="Enter City"
                v-model="formValues.city"
              />
              <div class="form-control-feedback">{{ errors.city }}</div>
            </div>
            <div class="form-group" :class="{ 'has-error': errors.country }">
              <label for="email"
                >Country<span class="required-field text-danger">*</span></label
              >
              <Field
                as="input"
                type="text"
                class="form-control"
                name="country"
                placeholder="Enter Country"
                v-model="formValues.country"
              />
              <div class="form-control-feedback">{{ errors.country }}</div>
            </div>
            <div class="form-group" :class="{ 'has-error': errors.vat_no }">
              <label for="email">Vat No.</label>
              <Field
                as="input"
                type="text"
                class="form-control"
                name="vat_no"
                placeholder="Enter Vat No."
                v-model="formValues.vat_no"
              />
              <div class="form-control-feedback">{{ errors.vat_no }}</div>
            </div>
            <div class="form-group" :class="{ 'has-error': errors.detail }">
              <Field
                as="input"
                type="hidden"
                class="form-control"
                name="detail"
              />
              <div class="form-control-feedback">{{ errors.detail }}</div>
            </div>
            <!--end form-group-->
          </div>
          <div class="modal-footer">
            <button type="submit" class="save-btn">{{ btn_name }}</button>
            <button type="button" class="cancle-btn" data-bs-dismiss="modal">
              Cancel
            </button>
          </div> </Form
        ><!--end form-->
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { mapGetters, mapActions } from "vuex";

import { UPDATE_ORG, CREATE_ORG } from "@/Core/store/action-types";

export default {
  name: "OrgModal",
  components: {
    Form,
    Field,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
    orgToEdit: {
      required: false,
      type: Object,
    },
    modalType: {
      required: true,
      type: Number,
    },
    initOrgData: {
      required: false,
      type: Function,
    },
  },
  computed: {
    ...mapGetters("user", ["userProfile"]),
  },
  watch: {
    orgToEdit: function (org) {
      this.formValues = {
        name: org.name,
        billing_address: org.billing_address,
        postal_id: org.postal_id,
        country: org.country,
        city: org.city,
        vat_no: org.vat_no,
      };
    },
  },
  methods: {
    ...mapActions("org", [UPDATE_ORG, CREATE_ORG]),

    onSubmit(values, actions) {
      if (this.modalType == 1) this.updateOrg(values, actions);
      if (this.modalType == 2) this.createOrg(values, actions);
    },

    updateOrg(orgToUpdate, actions) {
      orgToUpdate.id = this.orgToEdit.id;
      this[UPDATE_ORG]({ orgToUpdate, actions })
        .then(() => {
          this.initOrgData(1);
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify(
            "Organization successfully updated.",
            "success",
            3
          );
          actions.resetForm();
        })
        .catch(() => {});
    },

    createOrg(orgToCreate, actions) {
      this[CREATE_ORG]({ orgToCreate, actions })
        .then(() => {
          this.initOrgData(1);
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify(
            "Organization successfully created.",
            "success",
            3
          );
          actions.resetForm();
        })
        .catch(() => {});
    },
  },

  beforeCreate() {
    if (this.modalType == 1) {
      // Edit mode
      this.btn_name = "Save";
      this.header_text = "Edit Company Details";
    } else if (this.modalType == 2) {
      // Create mode
      this.btn_name = "Create";
      this.header_text = "Create new Company";
    }
  },

  data() {
    return {
      formValues: {},
      schema: yup.object().shape({
        name: yup
          .string()
          .min(6, "Name must be at least 6 characters")
          .required("Please enter organization name"),
        billing_address: yup.lazy(() => {
          return yup.string().required("billing address is required");
        }),
        country: yup.lazy(() => {
          return yup.string().required("country is required");
        }),
        city: yup.lazy(() => {
          return yup.string().required("city is required");
        }),
        postal_id: yup.lazy(() => {
          return yup.number().required("postal code is required");
        }),
        detail: yup
          .string() // use it for backend errors
          .nullable()
          .notRequired(),
      }),
    };
  },
};
</script>