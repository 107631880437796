<template>
  <!-- Register modal window -->
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createDialogLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createDialogLabel">
            Create new {{ name }}
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <UserForm
          :onSubmit="onSubmit"
          :isEditMode="false"
          :roles="selectedRole ? [selectedRole] : getAvailableRoles('create')"
          :existingUser="is_existing"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { REGISTER } from "@/Core/store/action-types";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";
import UserForm from "./UserForm";

export default {
  name: "Registration",
  mixins: [UserRoleMixin],
  components: {
    UserForm,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
    name: {
      required: false,
      type: String,
      default: "account",
    },
    selectedRole: {
      required: false,
      type: String,
      default: null,
    },
    is_existing: {
      required: false,
      type: Boolean,
      default: false,
    },
    initFunction: {
      required: false,
      type: Function,
      default: null,
    },
  },
  computed: {
    ...mapGetters("user", ["userProfile"]),
    ...mapGetters("org", ["orgsList"]),
  },
  methods: {
    ...mapActions("user", [REGISTER]),

    onSubmit(values, actions) {
      console.log(values);
      let userToCreate = this.getUser(values.role);
      userToCreate.email = values.email;
      userToCreate.password = values.password;
      userToCreate.phone = values.phone || null;
      if (this.is_existing)
        userToCreate.organization = parseInt(values.organization);
      else {
        userToCreate.billing_address = values.billing_address || null;
        userToCreate.city = values.city || null;
        userToCreate.country = values.country || null;
        userToCreate.postal_id = values.postal_id || null;
        userToCreate.company_name = values.company_name || null;
        userToCreate.vat_no = values.vat_no || null;
        userToCreate.phone = values.phone || null;
      }
      this[REGISTER]({ userToCreate, actions })
        .then(() => {
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify("User successfully created.", "success", 3);
          actions.resetForm();
          this.initFunction ? this.initFunction(1) : this.$parent.initUser(1);
        })
        .catch(() => {});
    },
  },

  data() {
    return {};
  },
};
</script>