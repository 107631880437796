<template>
  <!-- Edit user modal window -->
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="editDialogLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editDialogLabel">Edit Sales Channel</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <StoreForm
          v-on:form-change="onFormChange"
          :onSubmit="onSubmit"
          :isEditMode="true"
          :initData="storeToEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import StoreForm from "./StoreForm";
import { UPDATE_STORE } from "@/Core/store/action-types";
import {getCountryIdByName} from "../../Core/helpers/countryList"

export default {
  name: "EditStore",
  components: {
    StoreForm,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
    storeToEdit: {
      required: true,
      type: Object,
    },
  },

  methods: {
    ...mapActions("_store", [UPDATE_STORE]),

    onSubmit(values) {
      if (!values.address) values.address = null;
      values.id = this.storeToEdit.id;
      values.country_code = values.country?getCountryIdByName(values.country):null
      this[UPDATE_STORE](values)
        .then(() => {
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify(
            "sales channel successfully updated.",
            "success",
            3
          );
          // actions.resetForm();
          this.$parent.initStores(1);
        })
        .catch(() => {
          // this.errMsg = e.message;
          this.$alertify.notify(
            "sales channel with this reg number already exists.",
            "error",
            3
          );
          // actions.setFieldError('detail', "store with this reg number already exists.");
        });
    },

    onFormChange(form) {
      this.formValues = form;
    },
  },

  data() {
    return {
      formValues: {},
    };
  },
};
</script>