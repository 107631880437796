<template>
  <section class="conect_section">
    <CustomModel
      :modalId="disconnectModelID"
      :item="{}"
      :onDelete="Disconnect"
      :resourseName="disconnectedresource"
      actionType="Disconnect"
    />
    <div class="container-fluid">
      <div class="row align-items-center py-3 line_section">
        <div class="col-lg-6">
          <div class="social_img_section">
            <div class="social_img">
              <MetaIcon />
            </div>
            <div class="title">
              <p class="main_title">Facebook &amp; Instagram</p>
              <p class="sub_title">https://www.meta.com/</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="button">
            <!-- <button class="connected">Connected</button> -->
            <button
              :class="this.org?.is_facebook_ads_connected ? 'connected' : ''"
              @click="logInWithFacebook"
            >
              {{ this.org?.is_facebook_ads_connected ? "Connected" : "Connect" }}
            </button>
            <span
              v-if="this.org?.is_facebook_ads_connected"
              role="button"
              title="Disconnect"
              class="ml-2 icon-effect"
              @click="openDisconnectModel('facebook ad')"
            >
              <DisconnectIcon />
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="row align-items-center py-3 line_section">
        <div class="col-lg-6">
          <div class="social_img_section">
            <div class="social_img">
              <InstagramIcon />
            </div>
            <div class="title">
              <p class="main_title">Instagram</p>
              <p class="sub_title">www.instagram.com</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="button">
            <button>Connect</button>
          </div>
        </div>
      </div> -->
      <div class="row align-items-center py-3 line_section">
        <div class="col-lg-6">
          <div class="social_img_section">
            <div class="social_img">
              <GoogleAdIcon />
            </div>
            <div class="title">
              <p class="main_title">Google Adwords</p>
              <p class="sub_title">adwords.google.com</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="button">
            <button
              :class="this.org?.is_google_ads_connected ? 'connected' : ''"
              @click="handleGoogleLogin('googleAds')"
            >
              {{ this.org?.is_google_ads_connected ? "Connected" : "Connect" }}
            </button>
            <span
              v-if="this.org?.is_google_ads_connected"
              role="button"
              title="Disconnect"
              class="ml-2 icon-effect"
              @click="openDisconnectModel('Google Ads')"
            >
              <DisconnectIcon />
            </span>
          </div>
        </div>
      </div>
      <div class="row align-items-center py-3 line_section">
        <div class="col-lg-6">
          <div class="social_img_section">
            <div class="social_img">
              <GoogleAnalyticsIcon />
            </div>
            <div class="title">
              <p class="main_title">Google Analytics</p>
              <p class="sub_title">analytics.google.com</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="button">
            <button
              v-if="
                this.org?.is_google_analytics_connected && !this.org?.is_google_analytics_account_added
              "
              id="pending-btn"
              class="pending"
              data-toggle="modal"
              data-target="#googleAnalytics"
              @click="getGaAccountData"
            >
              Pending
            </button>
            <button
              v-else
              :class="
                this.org?.is_google_analytics_connected ? 'connected' : ''
              "
              @click="handleGoogleLogin('googleAnalytics')"
            >
              {{
                this.org?.is_google_analytics_connected
                  ? "Connected"
                  : "Connect"
              }}
            </button>
            <span
              v-if="this.org?.is_google_analytics_connected"
              role="button"
              title="Disconnect"
              class="ml-2 icon-effect"
              @click="openDisconnectModel('Google Analytics')"
            >
              <DisconnectIcon />
            </span>
          </div>
        </div>
      </div>
      <div class="row align-items-center py-3 line_section">
        <div class="col-lg-6">
          <div class="social_img_section">
            <div class="social_img">
              <WoocommerceIcon />
            </div>
            <div class="title">
              <p class="main_title">Woocommerce</p>
              <p class="sub_title">https://woocommerce.com/</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="button">
            <button
              data-toggle="modal"
              data-target="#woocommerceForm"
              :class="this.org?.is_woocommerce_connected ? 'connected' : ''"
            >
              {{ this.org?.is_woocommerce_connected ? "Connected" : "Connect" }}
            </button>
            <span
              v-if="this.org?.is_woocommerce_connected"
              role="button"
              title="Disconnect"
              class="ml-2 icon-effect"
              @click="openDisconnectModel('woocommerce')"
            >
              <DisconnectIcon />
            </span>
          </div>
        </div>
      </div>
      <div class="row align-items-center py-3 line_section">
        <div class="col-lg-6">
          <div class="social_img_section">
            <div class="social_img">
              <ShopifyIcon />
            </div>
            <div class="title">
              <p class="main_title">Shopify</p>
              <p class="sub_title">https://www.shopify.com</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="button">
            <button
              data-toggle="modal"
              data-target="#shopify"
              :class="this.org?.is_shopify_connected ? 'connected' : ''">
              Connect
            </button>
            <span
              v-if="this.org?.is_shopify_connected"
              role="button"
              title="Disconnect"
              class="ml-2 icon-effect"
              @click="openDisconnectModel('shopify')"
            >
              <DisconnectIcon />
            </span>
          </div>
        </div>
      </div>
      <div class="row align-items-center py-3 line_section">
        <div class="col-lg-6">
          <div class="social_img_section">
            <div class="social_img">
              <SendGridIcon />
            </div>
            <div class="title">
              <p class="main_title">SendGrid</p>
              <p class="sub_title">https://app.sendgrid.com/</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="button">
            <button
              data-toggle="modal"
              data-target="#sendgrid"
              :class="this.org?.is_sendgrid_connected ? 'connected' : ''">
              Connect
            </button>
            <span
              v-if="this.org?.is_sendgrid_connected"
              role="button"
              title="Disconnect"
              class="ml-2 icon-effect"
              @click="openDisconnectModel('sendgrid')"
            >
              <DisconnectIcon />
            </span>
          </div>
        </div>
      </div>
      <!-- <div class="row align-items-center py-3 line_section">
        <div class="col-lg-6">
          <div class="social_img_section">
            <div class="social_img">
              <GoogleAnalyticsIcon />
            </div>
            <div class="title">
              <p class="main_title">Google Analytics</p>
              <p class="sub_title">analytics.google.com</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="button">
            <button>Connect{{$route?.query}}</button>
          </div>
        </div>
      </div> -->
    </div>
    <div
      class="modal fade"
      id="woocommerceForm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="woocommerceFormmodelLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="woocommerceFormmodelLabel">
              WooCommerce Integration Details
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-3">
            <div>
              <div
                :class="
                  woocommerce_error.woocommerce_url_error ? 'has-error' : ''
                "
              >
                <div class="main-section">
                  <label for="woocommerce_url">WooCommerce URL</label>
                </div>
                <div class="input-section">
                  <div class="main-input">
                    <input
                      type="text"
                      name="woocommerce_url"
                      class="form-control"
                      placeholder="Enter Woocommerce URL"
                      v-model="woocommerce.woocommerce_url"
                      @keyup="validateWoocommerceUrl"
                      @blur="validateWoocommerceUrl"
                    />
                  </div>
                  <span class="error">{{
                    woocommerce_error.woocommerce_url_error
                  }}</span>
                </div>
              </div>
              <div
                class="mt-1"
                :class="
                  woocommerce_error.woocommerce_consumer_key_error
                    ? 'has-error'
                    : ''
                "
              >
                <div class="main-section">
                  <label for="woocommerce_url">Consumer Key</label>
                </div>
                <div class="input-section">
                  <div class="main-input">
                    <input
                      type="text"
                      name="woocommerce_url"
                      class="form-control"
                      placeholder="Enter Consumer Key"
                      v-model="woocommerce.woocommerce_consumer_key"
                      @keyup="validateWoocommerceConsumerKey"
                      @blur="validateWoocommerceConsumerKey"
                    />
                  </div>
                  <span class="error">{{
                    woocommerce_error.woocommerce_consumer_key_error
                  }}</span>
                </div>
              </div>
              <div
                class="mt-1"
                :class="
                  woocommerce_error.woocommerce_consumer_secret_error
                    ? 'has-error'
                    : ''
                "
              >
                <div class="main-section">
                  <label for="woocommerce_url">Consumer Secret</label>
                </div>
                <div class="input-section">
                  <div class="main-input">
                    <input
                      type="text"
                      name="woocommerce_url"
                      class="form-control"
                      placeholder="Enter Consumer Secret"
                      v-model="woocommerce.woocommerce_consumer_secret"
                      @keyup="validateWoocommerceConsumerSecret"
                      @blur="validateWoocommerceConsumerSecret"
                    />
                  </div>
                  <span class="error">{{
                    woocommerce_error.woocommerce_consumer_secret_error
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="save-btn"
              @click="handleWoocommerceConnections"
            >
              Connect
            </button>
            <button
              type="button"
              class="cancle-btn"
              data-dismiss="modal"
              aria-label="Close"
              id="close-woocommerce-model"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="googleAnalytics"
      tabindex="-1"
      role="dialog"
      aria-labelledby="googleAnalyticsmodelLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="googleAnalyticsFormmodelLabel">
              Select Google Analytics View
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Form
            @submit="onGASubmit"
            :validation-schema="schema"
            v-slot="{ errors }"
            class="form-horizontal"
            action="#"
          >
            <div class="modal-body p-3">
              <div class="loader-main" v-if="isLoadingShow">
                <div
                  class="spinner-border"
                  role="status"
                  style="width: 36px; height: 36px; color: #5e66fa"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div class="form-group" :class="{ 'has-error': errors.account }">
                <label for="account"
                  >Account Name<span class="required-field text-danger"
                    >*</span
                  ></label
                >
                <Field v-model="googleAnalytics.account" name="account">
                  <Multiselect
                    :id="'account-id'"
                    v-model="googleAnalytics.account"
                    :options="this.accountOptions"
                    valueProp="account_id"
                    label="account_name"
                    trackBy="account_name"
                    :canClear="false"
                    @change="
                      () => {
                        googleAnalytics.property = '';
                        googleAnalytics.view = '';
                      }
                    "
                    placeholder="please select account"
                  />
                </Field>
                <div class="form-control-feedback">{{ errors.account }}</div>
              </div>
              <div class="form-group" :class="{ 'has-error': errors.property }">
                <label for="property"
                  >Property Name<span class="required-field text-danger"
                    >*</span
                  ></label
                >
                <Field v-model="googleAnalytics.property" name="property">
                  <Multiselect
                    :id="'property-id'"
                    v-model="googleAnalytics.property"
                    :options="propertyOptions"
                    valueProp="property_id"
                    label="property_name"
                    trackBy="property_name"
                    :canClear="false"
                    @select="
                      () => {
                        googleAnalytics.view = '';
                      }
                    "
                    placeholder="please select property"
                  />
                </Field>
                <div class="form-control-feedback">{{ errors.property }}</div>
              </div>
              <div class="form-group" :class="{ 'has-error': errors.view }">
                <label for="view"
                  >View Name<span class="required-field text-danger"
                    >*</span
                  ></label
                >
                <Field v-model="googleAnalytics.view" name="view">
                  <Multiselect
                    :id="'view-id'"
                    v-model="googleAnalytics.view"
                    :options="viewOptions"
                    valueProp="view_id"
                    label="view_name"
                    trackBy="view_name"
                    :canClear="false"
                    placeholder="please select view"
                  />
                </Field>
                <div class="form-control-feedback">{{ errors.view }}</div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="save-btn">Connect</button>
              <button
                type="button"
                class="cancle-btn"
                data-dismiss="modal"
                aria-label="Close"
                id="close-ga-model"
              >
                Cancel
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="shopify"
      tabindex="-1"
      role="dialog"
      aria-labelledby="shopifysmodelLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="shopifysmodelLabel">
              Shopify Connection
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Form
            @submit="onShopifyConnect"
            :validation-schema="shopify_schema"
            v-slot="{ errors }"
            class="form-horizontal"
            action="#"
          >
            <div class="alert alert-warning mx-3 my-2 p-1" role="alert">
           <b>Required Scopes : </b>read_orders, read_all_orders, read_products, read_inventory, read_customers.
          </div>
            <div class="modal-body p-3">
               <div class="form-group" :class="{ 'has-error': errors.shopify_shop_url }">
                 <label for="shopify_shop_url"
                >Shopify Shop URL<span class="required-field text-danger">*</span></label
              >
              <Field
                as="input"
                type="text"
                class="form-control"
                name="shopify_shop_url"
                placeholder="Enter Shop URL"
                v-model="shopify.shopify_shop_url"
              />
              <div class="form-control-feedback">{{ errors.shopify_shop_url }}</div>
            </div>
               <div class="form-group" :class="{ 'has-error': errors.shopify_access_token }">
                 <label for="shopify_access_token"
                >Shopify Access Token<span class="required-field text-danger">*</span></label
              >
              <Field
                as="input"
                type="text"
                class="form-control"
                name="shopify_access_token"
                placeholder="Enter Shop Access token"
                v-model="shopify.shopify_access_token"
              />
              <div class="form-control-feedback">{{ errors.shopify_access_token }}</div>
            </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="save-btn">Connect</button>
              <button
                type="button"
                class="cancle-btn"
                data-dismiss="modal"
                aria-label="Close"
                id="close-shopify-model"
              >
                Cancel
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="sendgrid"
      tabindex="-1"
      role="dialog"
      aria-labelledby="sendgridmodelLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="sendgridmodelLabel">
              SendGrid Connection
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Form
            @submit="onSendgridConnect"
            :validation-schema="sendgrid_schema"
            v-slot="{ errors }"
            class="form-horizontal"
            action="#"
          >
            <div class="modal-body p-3">
              <div class="form-group" :class="{ 'has-error': errors.sendgrid_api_key }">
                <label for="sendgrid_email">
                  SendGrid Sender Email Address<span class="required-field text-danger">*</span>
                </label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="sendgrid_email"
                  placeholder="Enter SendGrid Sender Email address"
                  v-model="sendgrid.sendgrid_email"
                />
                <label for="sendgrid_api_key">
                  SendGrid API Key<span class="required-field text-danger">*</span>
                </label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="sendgrid_api_key"
                  placeholder="Enter SendGrid API Key"
                  v-model="sendgrid.sendgrid_api_key"
                />
                <div class="form-control-feedback">{{ errors.sendgrid_api_key }}</div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="save-btn">Connect</button>
              <button
                type="button"
                class="cancle-btn"
                data-dismiss="modal"
                aria-label="Close"
                id="close-sendgrid-model"
              >
                Cancel
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import FacebookIcon from "../../icons/facebook.vue";
import MetaIcon from "../../icons/meta.vue";
// import InstagramIcon from "../../icons/instagram.vue";
import GoogleAdIcon from "../../icons/googleAd.vue";
import GoogleAnalyticsIcon from "../../icons/googleAnalytics.vue";
import WoocommerceIcon from "../../icons/woocommerce.vue";
import DisconnectIcon from "../../icons/disconnect";
import ShopifyIcon from "../../icons/shopify.vue";
import SendGridIcon from "../../icons/sendgrid.vue";
import {
  FACEBOOK_CONNECTION,
  GET_ORG,
  FACEBOOK_DISCONNECT,
  GOOGLE_DISCONNECT,
  GOOGLE_ANALYTICS_DISCONNECT,
  WOOCOMMERCE_CONNECTION,
  SHOPIFY_CONNECTION,
  SENDGRID_CONNECTION,
  SENDGRID_DISCONNECT,
  WOOCOMMERCE_DISCONNECT,
  GET_GOOGLE_ANALYTICS_ACCOUNT,
  SHOPIFY_DISCONNECT,
  SET_GOOGLE_ANALYTICS_ACCOUNT,
  
} from "@/Core/store/action-types";
import { API_URL } from "../../Core/helpers/utils";
import { Form, Field } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import * as yup from "yup";
import Multiselect from "@vueform/multiselect";
import CustomModel from "../../Core/CustomModel.vue";
export default {
  name: "Integrations",
  components: {
    // FacebookIcon,
    MetaIcon,
    Multiselect,
    Form,
    Field,
    // InstagramIcon,
    GoogleAdIcon,
    GoogleAnalyticsIcon,
    DisconnectIcon,
    CustomModel,
    WoocommerceIcon,
    ShopifyIcon,
    SendGridIcon
  },
  data() {
    return {
      disconnectModelID: "disconnectModel",
      disconnectedresource: "",
      woocommerce: {
        woocommerce_url: "",
        woocommerce_consumer_key: "",
        woocommerce_consumer_secret: "",
      },
      woocommerce_error: {
        woocommerce_url_error: "",
        woocommerce_consumer_key_error: "",
        woocommerce_consumer_secret_error: "",
      },
      googleAnalytics: {
        account: "",
        property: "",
        view: "",
      },
      schema: yup.object().shape({
        account: yup.string().required().nullable(),
        property: yup.string().required().nullable(),
        view: yup.string().required().nullable(),
      }),

      shopify: {
        shopify_shop_url: "",
        shopify_access_token: "",
      },
      shopify_schema: yup.object().shape({
        shopify_shop_url: yup.string().matches(/^(https?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]*\.myshopify\.com$/ , 'enter valid shopify shop URL')
            .required("shopify shop URL is required"),
        shopify_access_token: yup.string().matches(/^shpat_[a-z0-9]{32}$/ , 'enter valid shopify access token')
            .required('shopify access token is required')
      }),
      sendgrid:{
        sendgrid_api_key: "",
        sendgrid_email: ""
      },

      ga_data: [],
      isLoadingShow: false,
    };
  },
  computed: {
    ...mapGetters("org", ["org"]),
    ...mapGetters("user", ["userProfile"]),
    accountOptions() {
      // `this` points to the component instance
      return this.ga_data
        .map((item) => ({
          account_id: item.account_id,
          account_name: item.account_name,
        }))
        .filter((value, index, self) => {
          return (
            index ===
            self.findIndex((item) => item.account_id === value.account_id)
          );
        });
    },
    propertyOptions() {
      if (this.googleAnalytics.account) {
        let property_data = this.ga_data
          .filter((item) => item.account_id == this.googleAnalytics.account)
          .map((item) => ({
            property_id: item.property_id,
            property_name: item.property_name,
          }))
          .filter((value, index, self) => {
            return (
              index ===
              self.findIndex((item) => item.property_id === value.property_id)
            );
          });
        return property_data;
      }
      return [];
    },
    viewOptions() {
      if (this.googleAnalytics.property && this.googleAnalytics.account) {
        return this.ga_data
          .filter(
            (item) =>
              item.account_id == this.googleAnalytics.account &&
              item.property_id == this.googleAnalytics.property
          )
          .map((item) => ({
            view_id: item.view_id,
            view_name: item.view_name,
          }))
          .filter((value, index, self) => {
            return (
              index === self.findIndex((item) => item.view_id === value.view_id)
            );
          });
      }
      return [];
    },
  },
  methods: {
    ...mapActions("org", [
      FACEBOOK_CONNECTION,
      GET_ORG,
      FACEBOOK_DISCONNECT,
      GOOGLE_DISCONNECT,
      GOOGLE_ANALYTICS_DISCONNECT,
      WOOCOMMERCE_CONNECTION,
      SHOPIFY_CONNECTION,
      SENDGRID_CONNECTION,
      SENDGRID_DISCONNECT,
      WOOCOMMERCE_DISCONNECT,
      SHOPIFY_DISCONNECT,
      GET_GOOGLE_ANALYTICS_ACCOUNT,
      SET_GOOGLE_ANALYTICS_ACCOUNT
    ]),
    logOutWithFacebook() {
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          window.FB.logout(function (response) {
            console.log(response);
          });
        }
      });
    },
    getGaAccountData() {
      if (!this.ga_data || this.ga_data.length == 0) this.isLoadingShow = true;
      this[GET_GOOGLE_ANALYTICS_ACCOUNT]()
        .then((resp) => {
          if (resp.length == 0) {
            this.$alertify.notify(
              "There is no data found in your google analytics account,Please check your account",
              "error",
              3
            );
          }
          this.ga_data = resp;
        })
        .catch(() => {
          this.$alertify.notify(
            "There is some error to load the data, Try again",
            "error",
            3
          );
          this.ga_data = [];
        })
        .finally(() => (this.isLoadingShow = false));
    },
    logInWithFacebook() {
      let vm = this;
      window.FB.login(
        (response) => {
          console.log(response);
          if (response.authResponse) {
            vm.onSuccessAuth(response.authResponse, vm);
          } else {
            vm.$alertify.notify(
              "Facebook connection unsuccessfull, Try again",
              "error",
              3
            );
          }
        },
        { config_id: '784775083338604' }
      );
    },
    handleGoogleLogin(service) {
      // this.$router.push('www.google.com')
      window.location.href = `${API_URL}/api/v1/orgs/authorize_google_services/${this.org.id}?service=${service}`;
    },
    openDisconnectModel(resource) {
      this.disconnectedresource = resource;
      window.$(`#${this.disconnectModelID}`).modal("toggle");
    },
    Disconnect() {
      window.$(`#${this.disconnectModelID}`).modal("hide");
      if (this.disconnectedresource == "facebook ad") {
        this[FACEBOOK_DISCONNECT]()
          .then(() => {
            this[GET_ORG](this.userProfile.organization.id);
            this.$alertify.notify(
              "Facebook ad disconnected successfully",
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify(
              "Some error Occured, please try again",
              "error",
              3
            );
          });
      } else if (this.disconnectedresource == "Google Ads") {
        this[GOOGLE_DISCONNECT]()
          .then(() => {
            this[GET_ORG](this.userProfile.organization.id);
            this.$alertify.notify(
              "google ads disconnected successfully",
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify(
              "Some error Occured, please try again",
              "error",
              3
            );
          });
      } else if (this.disconnectedresource == "Google Analytics") {
        this[GOOGLE_ANALYTICS_DISCONNECT]()
          .then(() => {
            this[GET_ORG](this.userProfile.organization.id);
            this.$alertify.notify(
              "google analytics disconnected successfully",
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify(
              "Some error Occured, please try again",
              "error",
              3
            );
          });
      } else if (this.disconnectedresource == "woocommerce") {
        this[WOOCOMMERCE_DISCONNECT]()
          .then(() => {
            this[GET_ORG](this.userProfile.organization.id);
            this.$alertify.notify(
              "woocommerce disconnected successfully",
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify(
              "Some error Occured, please try again",
              "error",
              3
            );
          });
      }
      else if (this.disconnectedresource == "shopify") {
        this[SHOPIFY_DISCONNECT]()  
          .then(() => {
            this[GET_ORG](this.userProfile.organization.id);
            this.$alertify.notify(
              "shopify disconnected successfully",
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify(
              "Some error Occured, please try again",
              "error",
              3
            );
          });
      }
      else if (this.disconnectedresource == "sendgrid") {
        this[SENDGRID_DISCONNECT]()  
          .then(() => {
            this[GET_ORG](this.userProfile.organization.id);
            this.$alertify.notify(
              "sendgrid disconnected successfully",
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify(
              "Some error Occured, please try again",
              "error",
              3
            );
          });
      }
    },
    onSuccessAuth(obj, vm) {
      let params = {
        "access-token": obj.accessToken,
        userID: obj.userID,
      };
      this[FACEBOOK_CONNECTION](params)
        .then(function (response) {
          console.log(response);
          vm[GET_ORG](vm.userProfile.organization.id);
          vm.$alertify.notify("Facebook Successfully connected", "success", 3);
        })
        .catch((e) => {
          console.log(e);
          vm.$alertify.notify(
            "No ad account found with given facebook Account",
            "error",
            3
          );
        })
        .finally(() => {
          window.FB.getLoginStatus(function (response) {
            if (response.status === "connected") {
              window.FB.logout(function () {
                console.log("logout");
              });
            }
          });
        });
      return false;
    },
    handleWoocommerceConnections() {
      let vm = this;
      this.validateWoocommerceUrl();
      this.validateWoocommerceConsumerKey();
      this.validateWoocommerceConsumerSecret();
      if (
        this.woocommerce_error.woocommerce_url_error == "" &&
        this.woocommerce_error.woocommerce_consumer_key_error == "" &&
        this.woocommerce_error.woocommerce_consumer_secret_error == ""
      )
        this[WOOCOMMERCE_CONNECTION](this.woocommerce)
          .then((response) => {
            console.log(response);
            vm[GET_ORG](this.userProfile.organization.id);
            this.$alertify.notify(
              "Woocommerce Successfully connected",
              "success",
              3
            );
            document.getElementById("close-woocommerce-model").click();
          })
          .catch((e) => {
            console.log(e);
            this.$alertify.notify(
              e?.response?.data?.detail || "Some error occured, please try again",
              "error",
              3
            );
          });
    },
    validateWoocommerceUrl() {
      if (!this.woocommerce.woocommerce_url)
        this.woocommerce_error.woocommerce_url_error = "Enter woocommerce URL";
      else this.woocommerce_error.woocommerce_url_error = "";
    },
    validateWoocommerceConsumerKey() {
      if (!this.woocommerce.woocommerce_consumer_key)
        this.woocommerce_error.woocommerce_consumer_key_error =
          "Enter woocommerce consumer key";
      else this.woocommerce_error.woocommerce_consumer_key_error = "";
    },
    validateWoocommerceConsumerSecret() {
      if (!this.woocommerce.woocommerce_consumer_secret)
        this.woocommerce_error.woocommerce_consumer_secret_error =
          "Enter woocommerce consumer secret";
      else this.woocommerce_error.woocommerce_consumer_secret_error = "";
    },
    onShopifyConnect(){
      let vm = this;
      this[SHOPIFY_CONNECTION](this.shopify)
          .then((response) => {
            console.log(response);
            vm[GET_ORG](this.userProfile.organization.id);
            this.$alertify.notify(
              "Shopify Successfully connected",
              "success",
              3
            );
            document.getElementById("close-shopify-model").click();
          })
          .catch((e) => {
            console.log(e?.response?.data?.detail);
            this.$alertify.notify(
              e?.response?.data?.detail || "Some error occured, please try again",
              "error",
              3
            );
          });
    },
    onSendgridConnect(){
      let vm = this;
      this[SENDGRID_CONNECTION](this.sendgrid)
        .then((response) => {
          console.log(response);
          vm[GET_ORG](this.userProfile.organization.id);
          this.$alertify.notify(
            "Sendgrid Successfully connected",
            "success",
            3
          );
          document.getElementById("close-sendgrid-model").click();
        })
        .catch((e) => {
          console.log(e?.response?.data?.detail);
          this.$alertify.notify(
            e?.response?.data?.detail || "Some error occured, please try again",
            "error",
            3
          );
        });
    },
    showGAModel(){
      console.log(document.getElementById("pending-btn"))
      // document.getElementById("pending-btn").click();
    },
    onGASubmit() {
      let vm = this;
      let params={
        analytics_account_data:this.googleAnalytics
      }
      this[SET_GOOGLE_ANALYTICS_ACCOUNT](params).then(() => {
              this.$alertify.notify(
              "Google Analytics Successfully connected",
              "success",
              3
            );
            vm[GET_ORG](this.userProfile.organization.id);
            document.getElementById("close-ga-model").click();
        })
        .catch(() => {
          this.$alertify.notify(
            "There is some error to connect google analytics, Try again",
            "error",
            3
          );
        })
    },
  },
  mounted(){
    if(this.$route?.query?.google_connection){
      if(this.$route?.query?.google_connection == '1'){
         if(this.$route?.query?.service == 'googleAds')
          this.$alertify.notify(
            "Google Ads connected successfully",
            "success",
            3
          );
        else{
          this.$alertify.notify(
              "Google Analytics connected successfully,Please complete further step",
              "success",
              3
            );
          this.showGAModel()
        }
      }
      else
          this.$alertify.notify(
            "connection unsuccessfull, Try again",
            "error",
            3
          );
       
      this.$router.replace({'query': null});
    } 
  }
};
</script>
<style scoped>
.social_img_section {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: flex-start;
}
.title p {
  margin: 0;
}

.button button {
  background: #5e66fa;
  border: 0;
  padding: 8px 48px;
  border-radius: 19px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.title .main_title {
  font-size: 16px;
  font-weight: 500;
  color: #000352;
}
.button {
  text-align: center;
}

button.connected {
  background: #0bc984;
  pointer-events: none;
}
button.pending {
  background: #ffa010;
}

.line_section {
  border-bottom: 1px solid #e8ebf3;
}

section.conect_section {
  width: 100%;
  min-height: 80vh;
  background: #fff;
}
.error {
  margin-top: 0;
}
.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  z-index: 10000;
  background-color: rgb(255 255 255 / 50%);
  width: 100px;
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>



