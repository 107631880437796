<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43px" height="43px" viewBox="0 0 43 43" version="1.1">
<g id="surface1">
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(24.705882%,24.705882%,25.098039%);fill-opacity:1;" d="M 41.308594 39.839844 L 3.160156 39.839844 L 3.160156 1.691406 C 3.160156 0.84375 2.5 0.183594 1.652344 0.183594 C 0.808594 0.183594 0.148438 0.84375 0.148438 1.691406 L 0.148438 41.347656 C 0.148438 42.191406 0.808594 42.851562 1.652344 42.851562 L 41.308594 42.851562 C 42.15625 42.851562 42.816406 42.191406 42.816406 41.347656 C 42.816406 40.5 42.15625 39.839844 41.308594 39.839844 Z M 41.308594 39.839844 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(36.862745%,40%,98.039216%);fill-opacity:1;" d="M 17.75 34.03125 L 17.75 12.347656 C 17.75 11.503906 17.089844 10.84375 16.246094 10.84375 C 15.398438 10.84375 14.738281 11.503906 14.738281 12.347656 L 14.738281 34.03125 C 14.738281 34.878906 15.398438 35.539062 16.246094 35.539062 C 17.089844 35.539062 17.75 34.878906 17.75 34.03125 Z M 17.75 34.03125 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(36.862745%,40%,98.039216%);fill-opacity:1;" d="M 10.472656 34.03125 L 10.472656 12.347656 C 10.472656 11.503906 9.8125 10.84375 8.96875 10.84375 C 8.121094 10.84375 7.460938 11.503906 7.460938 12.347656 L 7.460938 34.03125 C 7.460938 34.878906 8.121094 35.539062 8.96875 35.539062 C 9.8125 35.539062 10.472656 34.878906 10.472656 34.03125 Z M 10.472656 34.03125 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(36.862745%,40%,98.039216%);fill-opacity:1;" d="M 32.304688 34.03125 L 32.304688 12.347656 C 32.304688 11.503906 31.644531 10.84375 30.796875 10.84375 C 29.953125 10.84375 29.292969 11.503906 29.292969 12.347656 L 29.292969 34.03125 C 29.292969 34.878906 29.953125 35.539062 30.796875 35.539062 C 31.644531 35.539062 32.304688 34.878906 32.304688 34.03125 Z M 32.304688 34.03125 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(36.862745%,40%,98.039216%);fill-opacity:1;" d="M 39.582031 34.03125 L 39.582031 23.191406 C 39.582031 22.34375 38.921875 21.683594 38.074219 21.683594 C 37.230469 21.683594 36.570312 22.34375 36.570312 23.191406 L 36.570312 34.03125 C 36.570312 34.878906 37.230469 35.539062 38.074219 35.539062 C 38.921875 35.539062 39.582031 34.878906 39.582031 34.03125 Z M 39.582031 34.03125 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(36.862745%,40%,98.039216%);fill-opacity:1;" d="M 25.027344 34.03125 L 25.027344 23.191406 C 25.027344 22.34375 24.367188 21.683594 23.519531 21.683594 C 22.675781 21.683594 22.015625 22.34375 22.015625 23.191406 L 22.015625 34.03125 C 22.015625 34.878906 22.675781 35.539062 23.519531 35.539062 C 24.367188 35.539062 25.027344 34.878906 25.027344 34.03125 Z M 25.027344 34.03125 "/>
</g>
</svg>
</template>

<script>
export default {
  name: "CampaignKPIIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>