<template>
  <div class="contant-box-main">
    <div class="contant-header">
      <h6>
        <img src="./../../../public/assets/images/star-icon.png" alt="" />
        {{`${customerDetails?.first_name?.length>14?customerDetails?.first_name.slice(0,14)+'...':customerDetails?.first_name } is likely to purchase:`}}
      </h6>
      <ul class="nav nav-tabs align-items-center" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            :class="article ? 'nav-link active' : 'nav-link'"
            id="articles-tab"
            data-bs-toggle="tab"
            data-bs-target="#articles"
            type="button"
            role="tab"
            aria-controls="articles"
            aria-selected="false"
            @click="toggleTabs('article')"
          >
            Articles
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            :class="is_similar_item ? 'nav-link active' : 'nav-link'"
            id="similar-items-tab"
            data-bs-toggle="tab"
            data-bs-target="#similar_items"
            type="button"
            role="tab"
            aria-controls="similar_items"
            aria-selected="false"
            @click="toggleTabs('similar_item')"
          >
            Similar Articles
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            :class="is_buy_these_again ? 'nav-link active' : 'nav-link'"
            id="buy-again-tab"
            data-bs-toggle="tab"
            data-bs-target="#buy-again"
            type="button"
            role="tab"
            aria-controls="buy-again"
            aria-selected="true"
            @click="toggleTabs('buy_these_again')"
          >
            Buy These Again
          </button>
        </li>
      </ul>
    </div>
    <div class="tab-content position-relative h-100" id="myTabContent">
      <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
      </div>
      <div
        :class="article ? 'tab-pane fade show active' : 'tab-pane fade'"
        id="articles"
        role="tabpanel"
        aria-labelledby="articles-tab"
      >
        <div class="contant-details">
          <div class="overflow-auto no-pagination">
            <table class="table table-sm" id="my-table">
              <thead>
                <tr>
                  <th scope="col" v-for="f in article_fields" v-bind:key="f.id">
                    {{ f.split("_").join(" ") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" v-bind:key="item.id">
                  <td class="font-light-text">{{ item.number }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.next_buy_again_date ? item.next_buy_again_date : "-" }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        :class="is_similar_item ? 'tab-pane fade show active' : 'tab-pane fade'"
        id="similar_items"
        role="tabpanel"
        aria-labelledby="similar-items-tab"
      >
        <div class="contant-details">
          <div class="overflow-auto no-pagination">
            <table class="table table-sm" id="my-table">
              <thead>
                <tr>
                  <th scope="col" v-for="f in similar_items_fields" v-bind:key="f.id">
                    {{ f.split("_").join(" ") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in similar_items" v-bind:key="item.id">
                  <td class="font-light-text">{{ item.number }}</td>
                  <td>{{ item.name }}</td>
                  <!-- <td class="font-light-text">
                    {{ item.article__product__category__name }}
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        :class="is_buy_these_again ? 'tab-pane fade show active' : 'tab-pane fade'"
        id="buy-again"
        role="tabpanel"
        aria-labelledby="buy-again-tab"
      >
        <div class="contant-details">
          <div class="overflow-auto no-pagination">
            <table class="table table-sm" id="my-table">
              <thead>
                <tr>
                  <th scope="col" v-for="f in buy_again_items_fields" v-bind:key="f.id">
                    {{ f.split("_").join(" ") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in buy_again_items" v-bind:key="item.id">
                  <td class="font-light-text">{{ item.number }}</td>
                  <td>{{ item.name }}</td>
                  <!-- <td>{{item.buy_again_period}}</td> -->
                  <td>{{item.next_buy_again_date}}</td>
                  <!-- <td class="font-light-text">
                    {{ item.article__product__category__name }}
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination
              :page="buy_again_items_paginator.page"
              :totalPages="buy_again_items_paginator.totalPages"
              :name="'Items'"
              :count="buy_again_items_paginator.countPages"
              :incrementpage="incrementpage"
              :decrementpage="decrementpage"
              :setpage="setpage"
              :perpage="buy_again_items_paginator.perpage"
              v-show="buy_again_items.length"
            />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import Pagination from "@/Core/Pagination";
 // MEMBER_ARTICLES,
import {
  SIMILAR_ARTICLES, BUT_AGAIN_ARTICLES, MOST_LIKELY_TO_BUY_ARTICLES
} from "@/Core/store/action-types";

export default {
  name: "SimilarItems",
  components: {Pagination},
  data() {
    return {
      article: true,
      is_similar_item: false,
      is_buy_these_again: false,
      params: {
        persist: true,
      },
      article_fields: ["No.", "Article Name", "Likely purchase period"],
      similar_items_fields: ["No.", "Article Name"],
      buy_again_items_fields: ["No.", "Article Name", "Likely purchase period"],
      buy_again_items_paginator: {
        page: 1,
        perpage: 12,
        totalPages: [],
        countPages: 0
      },
      items: [],
      similar_items: [],
      buy_again_items:[],
      loadindShow:false,
    };
  },
  computed:{
    ...mapGetters("customer", ["customerDetails"]),
  },
  methods: {
    ...mapActions("article", [MOST_LIKELY_TO_BUY_ARTICLES, SIMILAR_ARTICLES, BUT_AGAIN_ARTICLES]),
    //get member articles data
    InItArticleDetails() {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") {
        const customerId = this.$route.params.customerId;
         this.loadindShow = true
        this[MOST_LIKELY_TO_BUY_ARTICLES](customerId)
          .then((resp) => {
            this.items = resp;
          })
          .catch((err) => {
            console.log("error=>", err);
          }).finally(()=>this.loadindShow=false)
      }
    },
    //get member articles data
    InItSimilarArticleDetails() {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") {
        const customerId = this.$route.params.customerId;
        this.loadindShow = true
        this[SIMILAR_ARTICLES](customerId)
          .then((resp) => {
            this.similar_items = resp;
          })
          .catch((err) => {
            console.log("error=>", err);
          }).finally(()=>this.loadindShow=false)
      }
    },
    InItBuyAgainArticleDetails() {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") {
        const customerId = this.$route.params.customerId;
        const payload = {
          member_id: customerId,
          params: {
            page: this.buy_again_items_paginator.page,
          },
        };
        this.loadindShow = true
        this[BUT_AGAIN_ARTICLES](payload)
          .then((resp) => {
            this.buy_again_items = resp.results;
            this.buy_again_items_paginator.countPages = resp.count;
            let total = Math.ceil(resp.count / this.buy_again_items_paginator.perpage);
            this.buy_again_items_paginator.totalPages = Array(total)
              .fill(0)
              .map((e, i) => i + 1);
          })
          .catch((err) => {
            console.log("error=>", err);
          }).finally(()=>this.loadindShow=false)
      }
    },
    //go to next page
    incrementpage() {
      this.buy_again_items_paginator.page = this.buy_again_items_paginator.page + 1;
      this.InItBuyAgainArticleDetails();
    },
    //go to previous page
    decrementpage() {
      this.buy_again_items_paginator.page = this.buy_again_items_paginator.page - 1;
      this.InItBuyAgainArticleDetails();
    },
    // go to perticular page
    setpage(page) {
      this.buy_again_items_paginator.page = page;
      this.InItBuyAgainArticleDetails();
    },
    toggleTabs(tab) {
      this.article = tab === 'article';
      this.is_similar_item = tab === 'similar_item';
      this.is_buy_these_again = tab === 'buy_these_again';
    }
  },
  mounted() {
    this.InItArticleDetails();
    this.InItSimilarArticleDetails();
    this.InItBuyAgainArticleDetails()
  },
};
</script>
<style>
</style>