import {
  LIST_ORGS,
  DELETE_ORG,
  CREATE_ORG,
  UPDATE_ORG,
  CHANGE_ORG_CURRENCY,
  CHANGE_ORG_CLV_YEAR,
  UPDATE_ORG_EXTERNAL_API,
  GET_EXTERNAL_API,
  GET_SHIPPING_COGS,
  GET_CAMPAIGN_KPI_SETTINGS,
  UPDATE_SHIPPING_COGS,
  UPDATE_CAMPAIGN_KPI_SETTINGS,
  FACEBOOK_CONNECTION,
  GET_ORG,
  GET_ORG_STATS,
  FACEBOOK_DISCONNECT,
  WOOCOMMERCE_CONNECTION,
  SHOPIFY_CONNECTION,
  SENDGRID_CONNECTION,
  SENDGRID_DISCONNECT,
  SHOPIFY_DISCONNECT,
  WOOCOMMERCE_DISCONNECT,
  GOOGLE_DISCONNECT,
  GOOGLE_ANALYTICS_DISCONNECT,
  UPDATE_INITIAL_CURRENCY_CLV,
  GET_GOOGLE_ANALYTICS_ACCOUNT,
  SET_GOOGLE_ANALYTICS_ACCOUNT,
  GET_SENDGRID_TEMPLATES,
  GET_SENDGRID_CONTACT_LIST,
  GET_SENDGRID_TEMPLATE_VERSION,
  SET_SUBSCRIPTION_STATUS
} from "@/Core/store/action-types";

import {
  SET_ORGS_LIST,
  ADD_NEW_ORG,
  EDIT_ORG_FROM_LIST,
  REMOVE_ORG_FROM_LIST,
  SET_ORG,
  SET_CURRENCY,
  SET_CLV_YEAR,
  SET_INITIAL_CURRENCY_CLV
} from "@/Core/store/mutation-types";

import { API_URL, authHeader } from "@/Core/helpers/utils";
import ServerErrorHandler from "@/Core/helpers/ServerErrorHandler";
import api from "./org.api";
import axios from "axios";

const initialState = {
  default_clv_year: JSON.parse(localStorage.getItem("userProfile"))?.organization?.clv_year || 3,
  default_currency: JSON.parse(localStorage.getItem("userProfile"))?.organization?.currency || "EUR",
};
const state = { ...initialState };

const actions = {
  async [LIST_ORGS]({ commit, rootState }, params) {
    let url = `${API_URL}/api/v1/orgs/`;

    return axios
      .get(url, {
        params,
        headers: authHeader(rootState.user.userProfile.access),
      })
      .then(function (response) {
        if (params == undefined || params?.persist === true)
          commit(SET_ORGS_LIST, response.data);
        return response.data;
      });
  },
  async [GET_ORG]({ commit, rootState }, orgID){
    let url = `${API_URL}/api/v1/orgs/${orgID}`;
    return axios
      .get(url, {
        headers: authHeader(rootState.user.userProfile.access),
      })
      .then(function (response) {
          commit( SET_ORG, response.data);
        return response.data;
      });
  },
  async [DELETE_ORG]({ rootState, commit }, orgId) {
    let url = `${API_URL}/api/v1/orgs/${orgId}/`;

    return axios
      .delete(url, {
        headers: authHeader(rootState.user.userProfile.access),
      })
      .then(function () {
        commit(REMOVE_ORG_FROM_LIST, orgId);
      })
      .catch(function () {
        throw new Error("");
      });
  },

  async [UPDATE_ORG_EXTERNAL_API]({ rootState }, params) {
    const resp = await axios.patch(
      `${API_URL}/api/v1/update_org_external_api/`,
      params,
      {
        headers: authHeader(rootState.user.userProfile.access),
      }
    );
    return resp.data;
  },
  async [GET_EXTERNAL_API]({ rootState }, sales_channel_online_status) {
    const resp = await axios.get(
      `${API_URL}/api/v1/get_org_external_api/${sales_channel_online_status}`,
      {
        headers: authHeader(rootState.user.userProfile.access),
      }
    );
    return resp.data;
  },
  async [CREATE_ORG]({ rootState, commit }, { orgToCreate, actions }) {
    let url = `${API_URL}/api/v1/orgs/`;

    return axios
      .post(url, orgToCreate, {
        headers: authHeader(rootState.user.userProfile.access),
      })
      .then(function (response) {
        commit(ADD_NEW_ORG, response.data);
        return response.data;
      })
      .catch(function (error) {
        ServerErrorHandler(actions, error.response);
        throw new Error("");
      });
  },
  async [UPDATE_ORG]({ rootState, commit }, { orgToUpdate, actions }) {
    let url = `${API_URL}/api/v1/orgs/${orgToUpdate.id}/`;

    return axios
      .patch(url, orgToUpdate, {
        headers: authHeader(rootState.user.userProfile.access),
      })
      .then(function (response) {
        commit(EDIT_ORG_FROM_LIST, response.data);
        return response.data;
      })
      .catch(function (error) {
        ServerErrorHandler(actions, error.response);
        throw new Error("");
      });
  },
  async [GET_SHIPPING_COGS]({ rootState},params){
    const resp = await axios.get(
      `${API_URL}/api/v1/get_shipping_cogs/`,
       {
        headers: authHeader(rootState.user.userProfile.access),
        params
       }
    );
    return resp.data;
  },

  async [GET_CAMPAIGN_KPI_SETTINGS]({ rootState}){
    const resp = await axios.get(
      `${API_URL}/api/v1/orgs/get_campaign_kpi_settings/`,
       {
        headers: authHeader(rootState.user.userProfile.access)
       }
    );
    return resp.data;
  },
  async [UPDATE_SHIPPING_COGS]({ rootState},params){
    const resp = await axios.patch(
      `${API_URL}/api/v1/update_shipping_cogs/`,params,
       {
        headers: authHeader(rootState.user.userProfile.access),
       }
    );
    return resp.data;
  },
  async [UPDATE_CAMPAIGN_KPI_SETTINGS]({ rootState},params){
    const resp = await axios.patch(
      `${API_URL}/api/v1/orgs/set_campaign_kpi_settings/`,params,
       {
        headers: authHeader(rootState.user.userProfile.access),
       }
    );
    return resp.data;
  },
  async [GET_ORG_STATS]({ rootState},id){
    const resp = await axios.get(
      `${API_URL}/api/v1/orgs/get_stats/${id}/`,
       {
        headers: authHeader(rootState.user.userProfile.access),
       }
    );
    return resp.data;
  },
  async [FACEBOOK_CONNECTION]({ rootState},params){
    const resp = await api.facebook_connection(rootState.user.userProfile.access, params)
    return resp.data;
  },
  async [FACEBOOK_DISCONNECT]({ rootState}){
    const resp = await api.facebook_disconnect(rootState.user.userProfile.access)
    return resp.data;
  },
  async [WOOCOMMERCE_CONNECTION]({ rootState},params){
    const resp = await api.woocommerce_connection(rootState.user.userProfile.access, params)
    return resp.data;
  },
  async [WOOCOMMERCE_DISCONNECT]({ rootState}){
    const resp = await api.woocommerce_disconnect(rootState.user.userProfile.access)
    return resp.data;
  },
  async [SHOPIFY_CONNECTION]({ rootState},params){
    const resp = await api.shopify_connection(rootState.user.userProfile.access, params)
    return resp.data;
  },
  async [SHOPIFY_DISCONNECT]({rootState}){
    const resp = await api.shopify_disconnect(rootState.user.userProfile.access)
    return resp.data;
  },
  async [SENDGRID_CONNECTION]({ rootState}, params){
    const resp = await api.sendgrid_connection(rootState.user.userProfile.access, params)
    return resp.data;
  },
  async [SENDGRID_DISCONNECT]({ rootState}){
    const resp = await api.sendgrid_disconnect(rootState.user.userProfile.access)
    return resp.data;
  },
  async [GOOGLE_DISCONNECT]({ rootState}){
    const resp = await api.google_disconnect(rootState.user.userProfile.access)
    return resp.data;
  },
  async [GOOGLE_ANALYTICS_DISCONNECT]({ rootState}){
    const resp = await api.google_analytics_disconnect(rootState.user.userProfile.access)
    return resp.data;
  },
  async [GET_GOOGLE_ANALYTICS_ACCOUNT]({ rootState}){
    const resp = await api.get_google_analytics_account(rootState.user.userProfile.access,rootState.user.userProfile.organization.id)
    return resp.data;
  },
  async [SET_GOOGLE_ANALYTICS_ACCOUNT]({ rootState},params){
    const resp = await api.set_google_analytics_account(rootState.user.userProfile.access,{...params,id:rootState.user.userProfile.organization.id})
    return resp.data;
  },
  // async [CHANGE_ORG_CURRENCY]({ rootState},params){
  //   const resp = await api.change_default_currency(rootState.user.userProfile.access, params)
  //   return resp.data;
  // },
  async [CHANGE_ORG_CURRENCY]({ rootState,commit }, params) {
    const resp = await api.change_default_currency(rootState.user.userProfile.access, params)
    commit(SET_CURRENCY, params.currency);
    return resp.data;
  },
  async [CHANGE_ORG_CLV_YEAR]({rootState, commit }, params) {
    const resp = await api.change_clv_year(rootState.user.userProfile.access, params)
    commit(SET_CLV_YEAR, params.clv_year);
    return resp.data;
  },
  [UPDATE_INITIAL_CURRENCY_CLV]({commit}){
    commit(SET_INITIAL_CURRENCY_CLV)
    return 
  },
  async [GET_SENDGRID_CONTACT_LIST]({ rootState}){
    const resp = await api.get_sendgrid_contact_list(rootState.user.userProfile.access)
    return resp.data;
  },
  async [GET_SENDGRID_TEMPLATES]({ rootState}){
    const resp = await api.get_sendgrid_templates(rootState.user.userProfile.access)
    return resp.data;
  },
  async [GET_SENDGRID_TEMPLATE_VERSION]({ rootState}, params){
    const resp = await api.get_sendgrid_template_version(rootState.user.userProfile.access,params)
    return resp.data;
  },
  async [SET_SUBSCRIPTION_STATUS]({ rootState }, obj) {
    return await api
      .change_subscription_status(rootState.user.userProfile.access, obj)
      .then(function (response) {
        return response.data;
      })
      .catch(function (e) {
        throw new Error(e.response.data.details);
      });
  },
};

const mutations = {
  [SET_ORGS_LIST](state, orgsList) {
    state.orgsList = orgsList;
  },
  [SET_ORG](state, org){
    state.org=org
  },
  [SET_INITIAL_CURRENCY_CLV](state){
    state.default_clv_year= JSON.parse(localStorage.getItem("userProfile"))?.organization?.clv_year 
    state.default_currency=JSON.parse(localStorage.getItem("userProfile"))?.organization?.currency 
  },
  [ADD_NEW_ORG](state, org) {
    state.orgsList ? state.orgsList.push(org) : "";
  },
  [SET_CLV_YEAR](state, value) {
    state.default_clv_year = value;
    localStorage.setItem("clv_year", value);
  },
  [SET_CURRENCY](state, value) {
    state.default_currency = value;
    localStorage.setItem("currency", value);
  },
  [EDIT_ORG_FROM_LIST](state, editedOrg) {
    if (state.orgsList) {
      let idx = state.orgsList.findIndex((u) => u.id == editedOrg.id);

      if (idx > -1) {
        state.orgsList[idx] = editedOrg;
      }
    }
  },
  [REMOVE_ORG_FROM_LIST](state, orgId) {
    if (state.orgsList) {
      let idx = state.orgsList.findIndex((u) => u.id == orgId);

      if (idx > -1) {
        state.orgsList.splice(idx, 1);
      }
    }
  },
};
const getters = {
  orgsList(state) {
    return state.orgsList;
  },
  org(state){
    return state.org
  },
  getOrgById: (state) => (id) => {
    return state.orgsList?.find((org) => org.id === id);
  },
  default_clv_year(state) {
    return state.default_clv_year;
  },
  default_currency(state) {
    return state.default_currency;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
