<template>
  <div class="main-contant-box">
    <div class="main-contant-box-inner">
      <div class="contant-box">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <!-- src="./../../../public/assets/images/pr-icon.png" -->
              <img
                src="./../../../public/assets/images/pr-icon.png"
                alt=""
              />Edit offer details
            </h6>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group" :class="{ 'has-error': errors.name }">
                    <label class="form-label w-100"
                      >Name<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      v-model="formValues.name"
                      @keyup="validateName"
                      @blur="validateName"
                    />
                    <div class="form-control-feedback">{{ errors.name }}</div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="form-group"
                    :class="{ 'has-error': errors.details }"
                  >
                    <label class="form-label w-100"
                      >Offer Details<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Offer details"
                      v-model="formValues.details"
                      @keyup="validateDetails"
                      @blur="validateDetails"
                    />
                    <div class="form-control-feedback">
                      {{ errors.details }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div
                    class="form-group"
                    :class="{
                      'has-error': errors.start_date || errors.end_date,
                    }"
                  >
                    <label for="date"
                      >Offer Date Range<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      ref="date"
                      class="form-control"
                      placeholder="Select offer date range"
                    />
                    <div class="form-control-feedback">
                      {{ errors.start_date }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div
                    class="form-group"
                    :class="{ 'has-error': errors.articles }"
                  >
                    <label for="offer"
                      >Offer Articles<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <Field v-model="formValues.articles" name="article">
                      <Multiselect
                        v-model="formValues.articles"
                        mode="tags"
                        :minChars="3"
                        :resolveOnLoad="false"
                        :filterResults="false"
                        label="name"
                        valueProp="id"
                        :delay="0"
                        :searchable="true"
                        :options="fetchArticles"
                        placeholder="Select offer articles"
                        ref="article_select"
                        @select="validateArticle"
                        @deselect="validateArticle"
                      />
                    </Field>
                    <div class="form-control-feedback">
                      {{ errors.articles }}
                    </div>
                    <!-- <span class="error">{{ errMsg.email }}</span> -->
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-btns">
                    <button
                      type="button"
                      class="cancle-btn"
                      @click.prevent="onCancel"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="save-btn"
                      @click.prevent="OnOfferUpdate()"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Field } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import {
  UPDATE_OFFER,
  OFFER_DETAILS,
  LIST_ARTICLES,
} from "@/Core/store/action-types";
import { mapGetters, mapActions } from "vuex";
import { DATE_FORMAT } from "@/Core/helpers/utils";

export default {
  name: "EditOfferForm",
  components: {
    Field,
    Multiselect,
  },
  data() {
    return {
      formValues: {
        id: this?.$route?.params?.offerId,
        name: null,
        details: null,
        start_date: null,
        end_date: null,
        articles: [],
      },
      errors: {
        name: null,
        details: null,
        start_date: null,
        end_date: null,
        articles: null,
      },
    };
  },
  computed: {
    ...mapGetters("offer", ["offerDetails"]),
    // ...mapGetters("audience", ["audienceList"]),
    // ...mapGetters("campaign", ["campaignDetails"]),
  },
  methods: {
    // ...mapActions("offer", [LIST_OFFERS]),
    // ...mapActions("audience", [LIST_AUDIENCE]),
    ...mapActions("offer", [UPDATE_OFFER, OFFER_DETAILS]),
    ...mapActions("article", [LIST_ARTICLES]),

    InItOfferDetails() {
      if (this?.$route?.params?.offerId && this.$route.params.offerId != "") {
        this[OFFER_DETAILS](this?.$route?.params?.offerId).then((res) => {
          console.log(res);
          this.setDateRange(res.start_date, res.end_date);
          this.formValues.id = res.id;
          this.formValues.start_date = res.start_date;
          this.formValues.end_date = res.end_date;
          this.formValues.name = res.name;
          this.formValues.details = res.details;
          this.formValues.articles = this.ObjToIDs(res.articles);
          if (res.start_date && res.end_date) {
            let start_date = window
              .moment(res.start_date, DATE_FORMAT)
              .toDate();
            let end_date = window.moment(res.end_date, DATE_FORMAT).toDate();
            this.datepicker.data("daterangepicker").setStartDate(start_date);
            this.datepicker.data("daterangepicker").setEndDate(end_date);
          }
        });
      }
    },
    validateName() {
      this.errors.name = null;
      if (!this.formValues.name) {
        this.errors.name = "Please enter offer name";
      } else if (this.formValues.name.trim().length < 6) {
        this.errors.name = "Name must be at least 6 characters";
      } else null;
    },
    validateDetails() {
      this.errors.details = null;
      if (!this.formValues.details) {
        this.errors.details = "Please enter offer details";
      } else null;
    },
    validateArticle() {
      this.errors.articles = null;
      if (!this.formValues.articles || this.formValues.articles.length === 0) {
        this.errors.articles = "Please enter offer articles";
      } else null;
    },

    OnOfferUpdate() {
      this.validateName();
      this.validateDetails();
      this.validateArticle();
      if (!this.errors.name && !this.errors.details && !this.errors.articles) {
        let obj = this.formValues;
        this[UPDATE_OFFER]({ obj })
          .then(() => {
            this.$alertify.notify("offer successfully updated.", "success", 3);
            this.$router.push({
              name: "OfferDetails",
            });
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden!", "error", 3);
          });
      }
    },
    onCancel() {
      this.$router.push({
        name: "OfferDetails",
      });
    },
    async fetchArticles(query) {
      return await this[LIST_ARTICLES]({ params: { name: query } });
    },
    onDateChange(from_date, to_date) {
      this.formValues.start_date = from_date.format(DATE_FORMAT);
      this.formValues.end_date = to_date.format(DATE_FORMAT);

      this.setDateRange(this.formValues.start_date, this.formValues.end_date);
    },

    setDateRange(start_date, end_date) {
      var dateRange =
        start_date && end_date ? `${start_date} - ${end_date}` : "";
      this.datepicker.val(dateRange);
    },
    ObjToIDs(obj) {
      let Ids = [];
      obj.map((ob) => Ids.push(ob.id));
      return Ids;
    },
  },
  mounted() {
    this.datepicker = window.$(this.$refs.date);

    this.datepicker.daterangepicker(
      {
        autoUpdateInput: false,
      },
      this.onDateChange
    );
    this.InItOfferDetails();
  },
};
</script>