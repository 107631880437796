<template>
  <div class="contant-box-main audience_table">
    <div class="data-heading-wrp">
      <div class="data-heading">
        <div class="search-box-wrp">
          <input
            type="text"
            class="form-control"
            name=""
            v-model="search"
            placeholder="Search by the audience name"
          />
          <a class="search_icon" href="#"
            ><img src="../../../public/assets/images/search_icon.svg" alt=""
          /></a>
        </div>
        <div class="tab-box-wrp">
            <ul>
              <!-- <li
                @click="changeStatus('all')"
                :class="status == 'all' ? 'active' : ''"
              >
                All
              </li> -->
              <li
                @click="changeStatus('audience')"
                :class="status == 'audience' ? 'active' : ''"
              >
                Audience group
              </li>
              <li
                @click="changeStatus('reference')"
                :class="status == 'reference' ? 'active' : ''"
              >
                Reference group
              </li>
            </ul>
          </div>
      </div>
      <div class="data-heading-btn d-flex gap-3" v-if="createpermission">
        <button data-toggle="modal" :data-target="'#' + createModelID" @click="createRefGroupClick">
          + Create reference group
        </button>
        <button data-toggle="modal" :data-target="'#' + createModelID" @click="createAudienceClick">
          + Add new audience
        </button>
      </div>
    </div>
    <div class="table-wrp overflow-auto  position-relative">
      <div class="loader-main" v-if="loadindShow" >
          <div
            class="spinner-border"
            role="status"
            style="width: 36px; height: 36px; color: #5e66fa"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
      </div>
      <table class="table my-table-wrp table-sm" id="my-table">
        <thead>
          <tr>
            <th width="5%">ID</th>
            <th width="60%">Audience name</th>
            <th width="10%">Members</th>
            <th width="20%">Date of creation</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="audienceListData">
          <tr
            class="active-row"
            v-for="(audience, index) in audienceListData.results"
            :key="index"
          >
            <td class="font-light-text">{{ audience.id }}</td>
            <td class="font-text">
              <router-link
                :to="{
                  name: `AudienceDetails`,
                  params: { audienceid: audience.id },
                }"
                >{{ audience.name }}</router-link
              >
            </td>
            <td class="font-light-text">{{ audience.members }}</td>
            <td class="font-light-text">
              {{ audience.timestamp.split("-").reverse().join("/") }}
            </td>
            <td class="btn-align" width="10%">
              <div class="d-flex align-item-center">
                <button
                  title="Export Audience Details"
                  @click="tempAud = audience"
                  class="action-btns ms-2 icon-effect"
                  data-toggle="modal"
                  data-target="#exportCsvModel"
                >
                  <DownloadIcon />
                </button>
                <router-link
                  :to="{
                    name: `AudienceDetails`,
                    params: { audienceid: audience.id },
                  }"
                >
                  <button
                    class="action-btns ms-2 icon-effect"
                    title="See Audience Details"
                  >
                    <EyeIcon />
                  </button>
                </router-link>
              </div>
            </td>
            <!-- <td class="font-light-text"><button class="eye-view" @click="editMember(item)"><img src="./../../public/assets/images/coolicon.png" alt="" /></button></td> -->
          </tr>
        </tbody>
      </table>
      <Pagination
        :page="page"
        :totalPages="totalPages"
        :name="'Audience'"
        :count="audienceListData ? audienceListData?.count : 0"
        :incrementpage="incrementpage"
        :decrementpage="decrementpage"
        :setpage="setpage"
        :perpage="10"
      />
    </div>
    <!-- create audeince Modal -->
    <div
      class="modal fade"
      :id="createModelID"
      tabindex="-1"
      role="dialog"
      aria-labelledby="createAudienceModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="createAudienceModalTitle">
              {{!is_ref_group? 'Create new audience':'Create new reference group'}}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="oncancel"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="contant-details">
              <form class="profile-form">
                <div class="row">
                  <div class="col-12">
                    <div class="contant-header" v-if="!is_ref_group">
                      <h6>
                        <img
                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFESURBVHgBhVHLUcNADJVkkwk30wElhA5CBxRAfjMJww1TQUIFhGty2RAOHCkhVIA7wHTg4ULi2BJaEzPGxsmb2bGsfW/03gqhhO4ovkHENgh4gmIeZ41F8R7/kK/iN/209AS7Vlar6CznUF50RnHfEljYtwR7BPjW9uzUioAIe2ojeJo3H/LectacqolArbUrAkUEdRD0KgJhWWmiVme49n9tZrW0EMHAvtC4Cy37QltkQQUGjBAxSKgKv0i2cIs/fV88WCchM6zscEL+gBKw6BeRxtrxSolDpU3yBdKP980Eie7VcyCC7c/46EQJKI5uHNC+nukON5NsQv/665TZeVfvZjlvDOAfXA43xu6JOT0nEWdsx2636R3UwE0avj5A5DhOj4ThQm2sns1xWCcwBiMkeLFcFxCnwskrHECapgsiN/wGgX6SW5zKhKcAAAAASUVORK5CYII="
                          alt=""
                        />Audience details
                      </h6>
                    </div>
                    <div
                      class="form-group"
                      :class="nameError || errMsg != '' ? 'has-error' : ''"
                    >
                      <label class="form-label w-100"
                        >Name<span class="required-field text-danger"
                          >*</span
                        ></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        v-model="audience.name"
                        @keyup="validateName"
                        @blur="validateName"
                      />
                      <span class="error">{{ errMsg }}</span>
                      <!-- <span class="error" v-if="errMsg!=''"
                        >{{errMsg}}</span
                      > -->
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="contant-details mt-3">
              <form class="profile-form">
                <div class="contant-header">
                  <h6>
                    <img
                      src="../../../public/assets/images/Members.png"
                      alt=""
                    />Customers
                  </h6>
                  <div class="members_view">
                    <p>
                      Customers selected: {{ audience.members.length }}
                      <span>(from {{ totalMembers }})</span>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div
                      class="form-group"
                      :class="{ 'has-error': main_options_model_error }"
                    >
                      <label for="dropdownField">Preference </label>
                      <Field name="main_options">
                        <Multiselect
                          v-model="main_options_model_desc"
                          :options="main_options"
                          label="key"
                          valueProp="value"
                          placeholder="Select an option"
                        />
                        <span class="error">{{ main_options_model_error }}</span>
                      </Field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12" v-if="!is_ref_group && main_options_model_desc==='article'">
                    <div
                      class="form-group"
                      :class="{ 'has-error': pref_type_error }"
                    >
                      <label for="apref_type">Article Preference Type</label
                      ><em class="box-info ml-2"
                        ><i
                          class="fa fa-info-circle"
                          data-toggle="tooltip"
                          :title="article_pref_rules"
                          id="article-pref-rule-tooltip"
                        ></i>
                      </em>
                      <Field v-model="pref_type" name="pref_type">
                        <Multiselect
                          v-model="pref_type"
                          :options="pref_options"
                          label="key"
                          valueProp="value"
                          placeholder="Select article preference type"
                          @change="showDateRef"
                        />
                        <span class="error">{{ pref_type_error }}</span>
                      </Field>
                    </div>
                  </div>
                  <div class="col-lg-12" v-if="!is_ref_group && main_options_model_desc==='article'">
                    <div class="row">
                      <div class="form-group col">
                        <label for="articles">Article Preferences</label>
                        <Field v-model="articles" name="articles">
                          <Multiselect
                            v-model="articles"
                            mode="tags"
                            :minChars="3"
                            :resolveOnLoad="false"
                            :filterResults="false"
                            label="name"
                            valueProp="id"
                            :delay="0"
                            :searchable="true"
                            :options="fetchArticles"
                            placeholder="Select articles"
                            ref="article_select"
                          />
                        </Field>
                      </div>

                      <div class="form-group col-5" v-show="pref_type === 'likely_to_buy_again' || pref_type === 'likely_to_buy'">
                        <label for="date">Likely purchase period</label>
                        <div class="input-group">
                          <input type="text" ref="date" placeholder="Enter date" class="form-control" v-model="buy_again_date_func"/>
                          <span class="input-group-append">
                            <button class="close border border-secondary border-1 rounded-right" type="button" @click="(e) => buy_again_date_func = ''">
                              <i class="bi bi-x"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12" v-if="!is_ref_group && main_options_model_desc==='product'">
                    <div
                      class="form-group"
                      :class="{ 'has-error': product_pref_type_error }"
                    >
                      <label for="apref_type">Product Preference Type</label
                      ><em class="box-info ml-2"
                        ><i
                          class="fa fa-info-circle"
                          data-toggle="tooltip"
                          :title="article_pref_rules"
                          id="article-pref-rule-tooltip"
                        ></i>
                      </em>
                      <Field v-model="product_pref_type" name="product_pref_type">
                        <Multiselect
                          v-model="product_pref_type"
                          :options="pref_options"
                          label="key"
                          valueProp="value"
                          placeholder="Select product preference type"
                          @change="showDateRefProduct"
                        />
                        <span class="error">{{ product_pref_type_error }}</span>
                      </Field>
                    </div>
                  </div>
                  <div class="col-lg-12" v-if="!is_ref_group && main_options_model_desc==='product'">
                    <div class="row">
                      <div class="form-group col">
                        <label for="products">Product Preferences</label>
                        <Field v-model="products" name="products">
                          <Multiselect
                            v-model="products"
                            mode="tags"
                            :minChars="3"
                            :resolveOnLoad="false"
                            :filterResults="false"
                            label="name"
                            valueProp="id"
                            :delay="0"
                            :searchable="true"
                            :options="fetchProducts"
                            placeholder="Select products"
                            ref="product_select"
                          />
                        </Field>
                      </div>

                      <div class="form-group col-5" v-show="product_pref_type === 'likely_to_buy_again' || product_pref_type === 'likely_to_buy'">
                        <label for="date">Likely purchase period</label>
                        <div class="input-group">
                          <input type="text" ref="date" placeholder="Enter date" class="form-control" v-model="buy_again_date"/>
                          <span class="input-group-append">
                            <button class="close border border-secondary border-1 rounded-right" type="button" @click="(e) => buy_again_date = ''">
                              <i class="bi bi-x"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="audience-checkbox">
                    <label class="checkbox-container"
                      >Bought all selected articles
                      <input
                        type="checkbox"
                        :checked="checkStatus"
                        @change="onCheckStatusChanged"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div> -->
                  <div class="col-12"  v-if="is_ref_group">
                    <div class="form-group">
                      <label class="form-label w-100"
                        >Customer Amount</label>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="customer amount"
                        v-model="customer_amount"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label w-100">Gender</label>
                      <select
                        class="form-control custom-select"
                        v-model="gender"
                      >
                        <option value="All">All</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Corporate">Corporate</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-12"  v-if="!is_ref_group">
                    <div class="form-group">
                      <label class="form-label w-100">Churn status</label>
                      <select
                        class="form-control custom-select"
                        v-model="churn_status"
                      >
                        <option value="All">All</option>
                        <option value="churned">Churned</option>
                        <option value="possible_churn">Possible Churn</option>
                        <option value="no_churn">No Churn</option>
                        <option value="soon_to_churn">Soon To Churn</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label class="form-label w-100">Age</label>
                      <div class="slider_form">
                        <input
                          disabled
                          type="text"
                          class="form-control mr-2 ageSliderinput"
                          v-model="ageSlider[0]"
                        />
                        <Slider v-model="ageSlider" @change="ageChanged" />
                        <input
                          disabled
                          type="text"
                          class="form-control ml-2 ageSliderinput"
                          v-model="ageSlider[1]"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label for="customers"> Choosen customers:</label>
                    </div>
                    <div class="model_table">
                      <div class="table-wrp overflow-auto customer-table-wrap">
                        <table
                          class="table my-table-wrp table-sm"
                          id="my-table"
                        >
                          <thead>
                            <tr>
                              <th width="10%">ID</th>
                              <th width="25%">First Name</th>
                              <th width="25%">Last Name</th>
                              <th width="15%">Gender</th>
                              <th class="birth_date" width="25%">
                                Date of Birth
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="articles.length > 0 || products.length > 0">
                            <tr 
                              :class="'active-row selected'"
                              v-for="customer in choosencustomer
                                ? choosencustomer.slice(
                                    5 * (page_model - 1),
                                    page_model * 5
                                  )
                                : []"
                              :key="customer.id"
                            >
                              <!-- <td>
                                <div class="checkbox-list">
                                  <label class="checkbox">
                                    <input
                                      @change="AddRemoveCustomer(member.id)"
                                      class="checkbox-input"
                                      type="checkbox"
                                      :checked="checkedFilter(member.id)"
                                    />
                                    <span class="checkbox-checkmark-box">
                                      <span class="checkbox-checkmark"></span>
                                    </span>
                                  </label>
                                </div>
                              </td> -->
                              <td class="font-light-text">{{ customer.id }}</td>
                              <td class="font-text">{{ customer.first_name }}</td>
                              <td class="font-text">{{ customer.last_name }}</td>
                              <td class="font-light-text">
                                {{ customer.sex }}
                              </td>
                              <td class="font-light-text birth_date">
                                {{ customer.birth_date }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <Pagination v-if="articles.length > 0 || products.length > 0"
                          :page="page_model"
                          :totalPages="
                            choosencustomer
                              ? Array(Math.ceil(choosencustomer.length / 5))
                                  .fill(0)
                                  .map((e, i) => i + 1)
                              : []
                          "
                          :name="'Customers'"
                          :count="choosencustomer ? choosencustomer.length : 0"
                          :incrementpage="incrementpage_model"
                          :decrementpage="decrementpage_model"
                          :setpage="setpage_model"
                          :perpage="5"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <div class="form-btns">
              <button
                type="button"
                class="cancle-btn"
                @click="oncancel"
                id="close-audience-model"
                data-dismiss="modal"
                aria-label="Close"
              >
                Cancel</button
              ><button type="submit" class="save-btn" @click="onsave">
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- create audience Modal end -->
    <!-- export csv Modal start -->
    <div
      class="modal fade"
      id="exportCsvModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exportCsvModelTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content custom-modal-content">
          <div class="modal-body cusom-main-title">
            <h5 class="modal-title custom-heading" id="exportCsvModelTitle">
              Do you want to export CSV for {{ tempAud?.name }} ?
            </h5>
            <!-- <p>Select Data That You want Include To Export</p> -->
            <!-- <div class="form-check custom-check-title">
              <label class="checkbox-container">
                Article Preferences (has bought)
                <input
                  type="checkbox"
                  :checked="checkStatus_bought"
                  @change="checkStatus_bought = !checkStatus_bought"
                />
                <span class="checkmark"></span>
              </label> -->
            <!-- </div>
            <div class="form-check custom-check-title">
              <label class="checkbox-container">
                From All Preferences (Article, Gender and Age)
                <input
                  type="checkbox"
                  :checked="checkStatus_prefrences"
                  @change="checkStatus_prefrences = !checkStatus_prefrences"
                />
                <span class="checkmark"></span>
              </label>
            </div> -->
            <!-- <div class="form-check custom-check-title">
              <label class="checkbox-container">
                Manually added Customers
                <input
                  type="checkbox"
                  :checked="checkStatus_manual"
                  @change="checkStatus_manual = !checkStatus_manual"
                />
                <span class="checkmark"></span>
              </label>
            </div> -->
            <div class="modal-footer custom-button">
              <button type="button" class="cancle-btn" data-dismiss="modal">
                Close
              </button>
              <button type="button" class="save-btn" @click="generateMemberCSV">
                Yes,export CSV
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- export csv Modal end -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  LIST_AUDIENCE,
  DELETE_AUDIENCE,
  LIST_ARTICLES,
  LIST_PRODUCTS,
  LIST_CUSTOMERS,
  CREATE_AUDIENCE,
  AUDIENCE_CUSTOMER_DETAIL,
} from "@/Core/store/action-types";
import Pagination from "../../Core/Pagination.vue";
import { deleteItem } from "@/Core/helpers/gridUtils";
import { RESOURCE_NAME } from "../audience.vars";
import Slider from "@vueform/slider";
import { Field } from "vee-validate";
import { subject } from "@casl/ability";
import Multiselect from "@vueform/multiselect";
import EyeIcon from "../../icons/eye.vue";
import DownloadIcon from "../../icons/download";
const DEFAULT_AGE_SLIDER = [0, 100];
export default {
  name: "AudienceTable",
  components: {
    Pagination,
    Field,
    Slider,
    Multiselect,
    EyeIcon,
    DownloadIcon,
  },
  data() {
    return {
      page: 1,
      page_model: 1,
      totalPages: [],
      nameError: false,
      errMsg: "",
      search: "",
      asc: null,
      des: null,
      totalMembers: 0,
      sortCount: 1,
      gender: "All",
      churn_status: 'All',
      is_ref_group: false,
      articles: [],
      products: [],
      articles_most_likely_buy: [],
      buy_again_date: "",
      ageSlider: [...DEFAULT_AGE_SLIDER],
      flag: true,
      showmodel: true,
      audienceListData:null,
      audience: {
        name: "",
        members: [],
      },
      createpermission: this.$ability.can("create", subject(RESOURCE_NAME, {})),
      // checkStatus_bought: false,
      checkStatus_prefrences: false,
      checkStatus_manual: false,
      tempAud: {},
      createModelID: "createAudienceModal",
      checkStatus: false,
      pref_options: [
        { value: "has_bought", key: "Has bought" },
        {
          value: "likely_to_buy",
          key: "Likely to buy ",
        },
        {
          value: "similar_articles",
          key: "Similar articles",
        },
        {
          value: "likely_to_buy_again",
          key: "Likely to buy Again",
        },
        { value: "all_fields", key: "All Fields" },
      ],
      main_options: [
        { value: "product", key: "Product" },
        { value: "article", key: "Article" },
      ],
      pref_type: "",
      product_pref_type: "",
      pref_type_error: "",
      product_pref_type_error: "",
      main_options_model_error: "",
      main_options_model_desc: "",
      buy_again_date_func: "",
      preferenceError:false,
      choosencustomer: [],
      customer_amount:null,
      loadindShow:false,
      status:'audience',
      article_pref_rules: `1. IF “Likely to buy” /“Buy again” /”order history” or ALL then it should be an exact match with all articles matching.
2. IF “Similar” then only 1 article must match. `,
    };
  },
  computed: {
    ...mapGetters("audience", ["audienceList"]),
    ...mapGetters("customer", ["customersList"]),
    ...mapGetters("article", ["articlesList"]),
    // choosencustomer() {
    //   return this?.customersList?.filter((customer) =>
    //     this.audience.members.includes(customer.id)
    //   );
    // },
  },
  methods: {
    ...mapActions("audience", [
      LIST_AUDIENCE,
      DELETE_AUDIENCE,
      CREATE_AUDIENCE,
      AUDIENCE_CUSTOMER_DETAIL,
    ]),
    ...mapActions("customer", [LIST_CUSTOMERS]),
    ...mapActions("article", [LIST_ARTICLES]),
    ...mapActions("product", [LIST_PRODUCTS]),
    showDateRefProduct(value) {
      this.product_pref_type = value;
      this.pref_type = value;
      if(this.product_pref_type === 'likely_to_buy_again' || this.product_pref_type === 'likely_to_buy'){
        this.datepicker = window.$(this.$refs.date);
        this.datepicker.daterangepicker({
          opens: 'left',
          autoUpdateInput: false,
          autoApply: true
        }, this.onDateChange);
      }
    },
    showDateRef(value) {
      this.pref_type = value;
      if( this.pref_type === 'likely_to_buy_again' || this.pref_type === 'likely_to_buy'){
        this.datepicker = window.$(this.$refs.date);
        this.datepicker.daterangepicker({
          opens: 'left',
          autoUpdateInput: false,
          autoApply: true
        }, this.onDateChange);
      }
    },
    //get membere data
    initCustomerList(count = false) {
      if (count) {
        let options = {
          persist: false,
          params: {
            page: 1,
          },
        };
        this[LIST_CUSTOMERS](options)
          .then((resp) => {
            this.totalMembers = resp.count;
          })
          .catch((err) => {
            console.log("error", err);
          });
      } else {
        let options = {
          persist: count,
          params: {
            age_min: this.ageSlider[0] > 0 ? this.ageSlider[0] : null,
            age_max: this.ageSlider[1] < 100 ? this.ageSlider[1] : null,
            sex: this.gender != "All" ? this.gender : null,
            churn_status: this.churn_status != "All" ? this.churn_status : null,
            pref_type: this.articles.length != 0 ? this.pref_type : null,
            product_pref_type: this.products.length != 0 ? this.product_pref_type : null,
            prefs: this.articles.length != 0 ? this.articles : this.products,
            customer_amount:this.customer_amount,
            buy_again_date: ((this.product_pref_type === 'likely_to_buy_again' || this.product_pref_type === 'likely_to_buy') &&
                this.products.length && this.buy_again_date) ? this.buy_again_date : null,
            buy_again_date_func: ((this.pref_type === 'likely_to_buy_again' || this.pref_type === 'likely_to_buy') &&
                this.articles.length && this.buy_again_date_func) ? this.buy_again_date_func : null,
            // bought_all: this.checkStatus,
          },
        };
        if (Object.values(options.params).some((v) => v)){
          this[LIST_CUSTOMERS](options)
            .then((resp) => {
              this.audience.members = [];
              this.choosencustomer = resp;
              resp.map((member) => this.audience.members.push(member.id));
            })
            .catch((err) => {
              console.log("error", err);
            });
        }
        else {
          (this.audience.members = []), (this.choosencustomer = []);
        }
      }
    },
    //get audience data
    initAudience() {
      let params = {
        page: this.page ? this.page : null,
        q: this.search?.length < 3 ? null : this.search,
        is_reference_group: this.status == "all"
                              ? null
                              : this.status == "reference"
                              ? true
                              : false,
      };
      this.loadindShow = true
      this[LIST_AUDIENCE](params).then((resp) => {
        this.totalPages = Array(Math.ceil(resp.count / 10))
          .fill(0)
          .map((e, i) => i + 1);
        this.audienceListData = resp;
      }).finally(()=>this.loadindShow=false)
    },
    changeStatus(state) {
      this.status = state;
    },
    //add or remove customer at create audeince
    AddRemoveCustomer(id) {
      setTimeout(() => {
        if (this.audience.members.includes(id)) {
          this.audience.members = this.audience.members.filter(
            (member_id) => member_id != id
          );
        } else {
          this.audience.members.push(id);
        }
      }, 300);
    },
    //age change logic
    ageChanged() {
      if (this.flag) {
        this.page = 1;
        this.initCustomerList();
      }
    },
    //sort customer table in create audeince model
    sortTable() {
      this.sortCount++;
      if (this.sortCount > 3) this.sortCount = 1;
      if (this.sortCount === 1) {
        this.asc = null;
        this.des = null;
      } else if (this.sortCount === 2) {
        this.asc = true;
        this.des = null;
      } else if (this.sortCount === 3) {
        this.asc = null;
        this.des = true;
      } else {
        return 0;
      }
    },
    // check customer includes in list or not.
    checkedFilter(id) {
      if (this.audience.members.includes(id)) return true;
      else return false;
    },
    onCheckStatusChanged() {
      this.checkStatus = !this.checkStatus;
      this.initCustomerList();
    },
    deleteAudience(audience) {
      deleteItem(
        this.$alertify,
        this.$ability,
        this[DELETE_AUDIENCE],
        RESOURCE_NAME,
        audience
      );
    },
    //fetch articles for multiselect options
    async fetchArticles(query) {
      return await this[LIST_ARTICLES]({ params: { name: query } });
    },
    //fetch products for multiselect options
    async fetchProducts(query) {
      return await this[LIST_PRODUCTS]({ params: { name: query } });
    },
    //on cancel in create audeince model
    oncancel() {
      this.page_model = 1;
      this.audience.name = "";
      this.audience.members = [];
      this.ageSlider = DEFAULT_AGE_SLIDER;
      this.gender = "All";
      this.churn_status = "All";
      this.buy_again_date = "";
      this.buy_again_date_func = "";
      this.customer_amount=null
      this.is_ref_group=false
      this.articles = [];
      this.products = [];
      this.choosencustomer = [];
      this.audience.members = [];
      (this.pref_type = ""), (this.pref_type_error = "");
      this.flag = false;
      this.nameError = false;
      this.preferenceError = false;
      this.main_options_model_desc = '';
    },
    //on save check validation and craete audience
    onsave() {
      this.errMsg = "";
      if (!this.audience.name) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audience.name.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else if (this.main_options_model_desc == "") {
        this.preferenceError = true;
        this.main_options_model_error = "Please select your preference";
      } else if (this.audience.members.length <= 0) {
        this.nameError = false;
        this.$alertify.notify("Please select customers", "error", 3);
      } else {
        this.nameError = false;
        this.preferenceError = false;
        let payload = {
          members: this.audience.members,
          name: this.audience.name,
          articles: this.main_options_model_desc === 'article' ? this.articles : [],
          article_pref_type: this.main_options_model_desc === 'article' ? this.pref_type : '',
          products: this.main_options_model_desc !== 'article' ? this.products : [],
          product_pref_type: this.main_options_model_desc !== 'article' ? this.product_pref_type : '',
          main_options_model: this.main_options_model_desc,
          // article_most_likely: this.articles_most_likely_buy,
          gender: this.gender,
          churn_status: this.churn_status,
          buy_again_date: this.buy_again_date,
          buy_again_date_func: this.buy_again_date_func,
          age_min: this.ageSlider[0],
          age_max: this.ageSlider[1],
          is_reference_group:this.is_ref_group,
          customer_amount:this.customer_amount,
        };

        this[CREATE_AUDIENCE](payload)
          .then(() => {
            window.$(`#${this.createModelID}`).modal("hide");
            this.$alertify.notify(
              `${this.audience.name} successfully created`,
              "success",
              3
            );
            document.getElementById("close-audience-model").click();
            this.initAudience();
            this.oncancel();
          })
          .catch((e) => {
            this.errMsg = e.message;
            this.$alertify.notify(e.message, "error", 3);
          });
      }
    },
    // validate name
    validateName() {
      this.errMsg = "";
      if (!this.audience.name) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audience.name.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else this.nameError = false;
    },
    validatePreference() {
      if (!this.main_options_model_desc) {
        this.main_options_model_error = "Please select an option";
      } else {
        this.main_options_model_error = '';
      }
    },
    // hide model
    hideModal() {
      window.$("#createAudienceModal").modal("hide");
    },
    //go to next page
    incrementpage() {
      this.page = this.page + 1;
      this.initAudience();
    },
    // go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.initAudience();
    },
    createAudienceClick(){
      this.customer_amount=null
      this.is_ref_group=false
    },
    createRefGroupClick(){
      this.is_ref_group=true
    },
    // go to perticular page
    setpage(page) {
      this.page = page;
      this.initAudience();
    },
    // go to next page in customer table in model
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
    // genarate Csv logic
    generateMemberCSV() {
      // if (
      //   !this.checkStatus_prefrences &&
      //   // !this.checkStatus_bought &&
      //   !this.checkStatus_manual
      // ) {
      //   window.$("#exportCsvModel").modal("hide");
      // } else {
      let options = {
        audience_id: this.tempAud?.id,
        params: {
          // bought: this.checkStatus_bought ? this.checkStatus_bought : null,
          // manual: this.checkStatus_manual ? this.checkStatus_manual : null,
          // prefrences: this.checkStatus_prefrences ? this.checkStatus_prefrences : null,
        },
      };
      this[AUDIENCE_CUSTOMER_DETAIL](options).then((resp) => {
        let blankData = [
          {
            id: "",
            name: "",
            email: "",
            birth_date: "",
            is_active: "",
            phone: "",
            sex: "",
            external_id: "",
            created_at: "",
            store: null,
          },
        ];
        let arrData = [
          {
            customers: resp.length > 0 ? resp : blankData,
          },
        ];
        window.$(`#exportCsvModel`).modal("hide");
        console.log("ArrrrData===>", arrData);
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += [
          Object.keys(arrData[0].customers[0] || {}).join(","),
          ...arrData[0].customers.map((item) => Object.values(item).join(",")),
        ].join("\n");
        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute(
          "download",
          `${this.tempAud.id}-${this.tempAud.name}-customers.csv`
        );
        link.click();
      });
      // }
    },
    // convert articles obj to array of article id
    getprefarticle() {
      let preArr = [];
      this.tempAud.article_boughts.map((article) => {
        preArr.push(article.id);
      });
      return preArr;
    },
    onDateChange(start_date, end_date) {
      let date_str = start_date.format('YYYY/MM/DD') + " - " + end_date.format('YYYY/MM/DD');
      this.buy_again_date = date_str;
      this.setDateRange(date_str);
    },
    setDateRange(dates_str) {
      this.datepicker.val(dates_str);
    },
  },
  watch: {
    main_options_model_desc() {
      this.validatePreference();
    },
    main_options_model(newVal) {
      if (newVal === 'product') {
        // Set predefined values for products
        this.products = [];
        this.product_pref_type = '';
      } else if (newVal === 'article') {
        // Set predefined values for articles
        this.articles = [];
        this.pref_type = '';
      }
    },
    search() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this.page = 1;
        this.initAudience();
      }
    },
    status() {
      this.page = 1;
      this.initAudience();
    },
    gender() {
      if (this.flag) this.initCustomerList();
      this.page_model = 1;
    },
    customer_amount() {
      if (this.flag) this.initCustomerList();
      this.page_model = 1;
    },
    churn_status() {
      if (this.flag) this.initCustomerList();
      this.page_model = 1;
    },
    buy_again_date() {
      if (this.buy_again_date.length < 23 && this.buy_again_date.length) {
        this.buy_again_date = "";
        this.setDateRange("");
      }
      if (this.flag && (this.product_pref_type === 'likely_to_buy_again' || this.product_pref_type === 'likely_to_buy') && this.products.length) {
        this.initCustomerList()
      }
      this.page_model = 1;
    },
    buy_again_date_func() {
      if (this.buy_again_date_func.length < 23 && this.buy_again_date_func.length) {
        this.buy_again_date_func = "";
        this.setDateRange("");
      }
      if (this.flag && (this.pref_type === 'likely_to_buy_again' || this.pref_type === 'likely_to_buy') && this.articles.length) {
        this.initCustomerList()
      }
      this.page_model = 1;
    },
    sortCount() {
      this.page_model = 1;
      this[LIST_CUSTOMERS]({
        params: {
          asc: this.asc,
          des: this.des,
        },
        persist: true,
      });
    },
    articles() {
      if (this.articles.length > 0 && !this.pref_type) {
        this.audience.members = [];
        this.choosencustomer = [];
        this.pref_type_error = "please select articles preference type";
      } else {
        this.pref_type_error = "";
        if (this.flag) this.initCustomerList();
        else this.flag = true;
        this.page_model = 1;
      }
    },
    pref_type() {
      // this.initCustomerList();
      if (this.articles.length > 0 && !this.pref_type) {
        this.audience.members = [];
        this.choosencustomer = [];
        this.pref_type_error = "please select articles preference type";
      } else {
        this.pref_type_error = "";
        if (this.flag && this.articles.length > 0) {
          this.initCustomerList();
        }
        else {
          this.page_model = 1;
        }
      }
    },
    products() {
      if (this.products.length > 0 && !this.product_pref_type) {
        this.audience.members = [];
        this.choosencustomer = [];
        this.product_pref_type_error = "please select products preference type";
      } else {
        this.product_pref_type_error = "";
        if (this.flag) {
          this.initCustomerList();
        }
        else {
          this.flag = true;
        }
        this.page_model = 1;
      }
    },
    product_pref_type() {
      // this.initCustomerList();
      if (this.products.length > 0 && !this.product_pref_type) {
        this.audience.members = [];
        this.choosencustomer = [];
        this.product_pref_type_error = "please select products preference type";
      } else {
        this.product_pref_type_error = "";
        if (this.flag && this.products.length > 0) {
          this.initCustomerList();
        } else {
          this.page_model = 1;
        }
      }
    },
  },

  created() {
    this.initCustomerList(true);
    this.initAudience();
    // this.audienceList ? "" : this[LIST_AUDIENCE](null);
  },
  mounted() {
    // this.datepicker = window.$(this.$refs.date);
    // console.log("mounted--------", this.datepicker);
    // this.datepicker.daterangepicker({
    //   opens: 'left',
    //   autoUpdateInput: false,
    //   autoApply: true
    // }, this.onDateChange);
  },
};
</script>
<style scoped>
.modal-footer.custom-button {
  background-color: #ffff;
  border-color: #fff;
  float: left;
}
.modal-content.custom-modal-content {
  padding: 3%;
  border-radius: 0;
}
.custom-check-title {
  padding: 10px;
}
.cusom-main-title {
  padding-bottom: 15px;
}
.custom-heading {
  font-size: 25px;
  padding-bottom: 10px;
  font-weight: 600;
}
.modal-content .modal-body p,
.modal-content h4 {
  color: #7081b9;
  font-size: 16px;
  color: #3f3f40;
}
.customer-table-wrap {
  min-height: 0 !important;
}
</style>