import { API_URL, authHeader } from "@/Core/helpers/utils";
import axios from "axios";

const RESOURCE_URL = `${API_URL}/api/v1/campaigns/`;

export default {
  get(token, params) {
    return axios.get(RESOURCE_URL, {
      params,
      headers: authHeader(token),
    });
  },
  getDetails(token, {objId,params}) {
    let url = `${RESOURCE_URL}${objId}/`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
  update(token, obj) {
    let url = `${RESOURCE_URL}${obj.id}/`;
    return axios.patch(url, obj, {
      headers: authHeader(token),
    });
  },
  create(token, obj) {
    return axios.post(RESOURCE_URL, obj, {
      headers: authHeader(token),
    });
  },
  delete(token, objId) {
    let url = `${RESOURCE_URL}${objId}/`;
    return axios.delete(url, {
      headers: authHeader(token),
    });
  },
  duplicate(token, objId) {
    let url = `${RESOURCE_URL}${objId}/duplicate/`;
    return axios.post(
      url,
      {},
      {
        headers: authHeader(token),
      }
    );
  },
  metrics(token, params) {
    return axios.get(RESOURCE_URL, {
      params,
      headers: authHeader(token),
    });
  },
  get_cac_value(token,params){
    return axios.get(`${API_URL}/api/v1/get_ltv_cac_ratio/`, {
      params,
      headers: authHeader(token),
    });
  },
  getAnalyticsData(token, {objId,params}) {
    let url = `${RESOURCE_URL}analytics/${objId}/`;
    return axios.get(url, {
      headers: authHeader(token),
      params
    });
  },
  getFacebookCampaignData(token, {objId,params}) {
    let url = `${API_URL}/api/v1/get_campaign_insights/${objId}/`;
    return axios.get(url, {
      headers: authHeader(token),params
    });
  },
  getGoogleCampaignData(token, {objId,params}) {
    let url = `${API_URL}/api/v1/get_google_campaign_insights/${objId}/`;
    return axios.get(url, {
      headers: authHeader(token),params
    });
  },
  get_top_performing_campaigns(token, params) {
    let url = `${API_URL}/api/v1/top_performing_campaigns/`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
  get_facebook_campaigns(token) {
    let url = `${API_URL}/api/v1/get_facebook_campaigns/`;
    return axios.get(url, {
      headers: authHeader(token),
    });
  },
  get_facebook_adaccounts(token) {
    let url = `${API_URL}/api/v1/get_facebook_ad_accounts/`;
    return axios.get(url, {
      headers: authHeader(token),
    });
  },
  get_google_campaigns(token) {
    let url = `${API_URL}/api/v1/get_google_campaigns/`;
    return axios.get(url, {
      headers: authHeader(token),
    });
  },
  getCountryPerformance(token, {objId,params}) {
    let url = `${RESOURCE_URL}country_analytics/${objId}/`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
};
