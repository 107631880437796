<template>
  <Form
    @submit="onSubmit"
    :validation-schema="schema"
    v-slot="{ errors }"
    class="form-horizontal"
    action="#"
  >
    <div class="modal-body">
        <span as="button" class="d-flex justify-content-end file-upload-title" v-if="!isFileUploadMode && !isEditMode" @click="isFileUploadMode = true"><i class="bi bi-file-earmark-arrow-up-fill"></i>Import From File</span>
      <span as="button" class="d-flex justify-content-end file-upload-title mb-2" v-if="isFileUploadMode" @click="isFileUploadMode = false"><i class="bi bi-file-text"></i>Add manually</span>
      <div v-if="!isFileUploadMode">
        <div class="form-group" :class="{ 'has-error': errors.name }">
          <label for="name"
            >Name<span class="required-field text-danger">*</span></label
          >
          <Field
            as="input"
            type="text"
            class="form-control"
            name="name"
            placeholder="Enter Sales channel name"
            v-model="formValues.name"
          />
          <div class="form-control-feedback">{{ errors.name }}</div>
        </div>
        <!--end form-group-->
        <div class="form-group" :class="{ 'has-error': errors.reg_number }">
          <label for="reg_number"
            >Registration Number<span class="required-field text-danger"
              >*</span
            ></label
          >
          <Field
            as="input"
            type="text"
            class="form-control"
            name="reg_number"
            placeholder="Enter Sales channel Reg No"
            v-model="formValues.reg_number"
          />
          <div class="form-control-feedback">{{ errors.reg_number }}</div>
        </div>
        <!--end form-group-->
        <div class="form-group" :class="{ 'has-error': errors.is_online }">
          <label for="status"
            >Status<span class="required-field text-danger">*</span></label
          >
          <Field v-model="formValues.is_online" name="is_online">
            <Multiselect
              :id="'-status'"
              valueProp="value"
              label="status"
              trackBy="status"
              v-model="formValues.is_online"
              :options="[
                { value: 1, status: 'Online' },
                { value: 0, status: 'Offline' },
              ]"
              placeholder="please select an sales channel status"
            />
          </Field>
          <div class="form-control-feedback">{{ errors.is_online }}</div>
        </div>
        <!--end form-group-->

        <div
          class="form-group"
          v-if="!formValues.is_online"
          :class="{ 'has-error': errors.address }"
        >
          <label for="address"
            >Address</label
          >
          <Field
            as="input"
            type="text"
            class="form-control"
            name="address"
            placeholder="Enter Sales channel address"
            v-model="formValues.address"
          />
          <div class="form-control-feedback">{{ errors.address }}</div>
        </div>
        <!--end form-group-->
        <div
          class="form-group"
          v-if="!formValues.is_online"
          :class="{ 'has-error': errors.city }"
        >
          <label for="city"
            >City</label
          >
          <Field
            as="input"
            type="text"
            class="form-control"
            name="city"
            placeholder="Enter Sales channel city"
            v-model="formValues.city"
          />
          <div class="form-control-feedback">{{ errors.city }}</div>
        </div>
        <!--end form-group-->
        <div
          class="form-group"
          v-if="!formValues.is_online"
          :class="{ 'has-error': errors.zipcode }"
        >
          <label for="zipcode"
            >Zip Code</label
          >
          <Field
            as="input"
            type="text"
            class="form-control"
            name="zipcode"
            placeholder="Enter Sales channel zipcode"
            v-model="formValues.zipcode"
          />
          <div class="form-control-feedback">{{ errors.zipcode }}</div>
        </div>
        <div
          class="form-group"
          :class="{ 'has-error': errors.country }"
        >
          <label for="country"
            >Country<span v-if="!formValues.is_online" class="required-field text-danger">*</span></label
          >
          <Field
            name="country"
            v-model="formValues.country"
          >
          <Multiselect
              :id="isEditMode+'country_name'"
              valueProp="name"
              label="name"
              trackBy="name"
              :searchable="true"
              v-model="formValues.country"
              :options="country_list"
              placeholder="please select an sales channel country"
            />
          </Field>
          <div class="form-control-feedback">{{ errors.country }}</div>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.phone }">
          <label for="phone">Phone</label>
          <Field
            as="input"
            type="text"
            class="form-control"
            name="phone"
            placeholder="Enter Sales channel phone"
            v-model="formValues.phone"
          />
          <div class="form-control-feedback">{{ errors.phone }}</div>
        </div>
      </div>
      <div v-else>
        <div class="alert alert-success" role="alert">
            You can send sales channel by upload them in MS Excel format
            (.xlxs). This will update the existed sales channel, and add new sales channel.
            Template for the Excel structure:
            <a href="/assets/files/Store_example_file.xlsx" download
              ><ul>
                click here
              </ul></a
            >
          </div>
          <div>
            <div class="d-flex">
              <input
                type="file"
                id="file-upload-sales_channel"
                class="form-control d-none"
                @change="onSalesChannelFileChange"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <label for="file-upload-sales_channel" class="save-btn" role="button">
                Select File</label
              >
              <span class="p-2">{{ salesChannelsFile?.name }}</span>
            </div>
            <div class="form-control-feedback">
              {{ salesChannelsFileErrMSg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
     </div>
      <div class="form-group" :class="{ 'has-error': errors.detail }">
        <Field as="input" type="hidden" class="form-control" name="detail" />
        <div class="form-control-feedback">{{ errors.detail }}</div>
      </div>
      <!--end form-group-->
    </div>
    <div class="modal-footer">
       <button
        type="submit"
        class="save-btn"
        v-if="isFileUploadMode"
        @click="HandleSalesChannelFileUpload"
      >       
        <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span v-else>Add File</span>
      </button>
      <button v-else type="submit" class="save-btn">
        {{ isEditMode ? "Save" : "Create" }}
      </button>
      <button type="button" class="cancle-btn" data-bs-dismiss="modal">
        Cancel
      </button>
    </div> </Form
  ><!--end form-->
</template>

<script>
import { Field, Form } from "vee-validate";
// import { mapGetters } from 'vuex';
import Multiselect from "@vueform/multiselect";
// import {PHONE_REGEX} from "../../Core/helpers/utils"
import * as yup from "yup";
import countryListAllIsoData from "../../Core/helpers/countryList"
export default {
  name: "StoreForm",
  components: {
    Form,
    Multiselect,
    Field,
  },
  props: {
    isEditMode: {
      required: false,
      default: false,
    },
    onSubmit: {
      required: true,
    },
    initData: {
      required: false,
    },
    onFileUpload:{
      required: false,
    }
  },
  emits: ["form-change"],
  watch: {
    formValues: {
      handler: function (newForm) {
        this.$emit("form-change", newForm);
      },
      deep: true,
    },
    initData: function (newStore) {
      this.formValues = {
        name: newStore.name,
        reg_number: newStore.reg_number,
        address: newStore.address,
        zipcode: newStore.zipcode,
        country: newStore.country,
        city: newStore.city,
        phone: newStore.phone,
        is_online: newStore.is_online ? 1 : 0,
      };
    },
  },
  methods:{
      onSalesChannelFileChange(e) {
        this.salesChannelsFile= e.target.files[0];
      },
      HandleSalesChannelFileUpload(){
      this.isLoading=true
      if (this.salesChannelsFile) {
        this.onFileUpload(this.salesChannelsFile)
      } else {
        this.salesChannelsFileErrMSg= "Please select file";
      }
      this.isLoading=false
    }
  },
  data() {
    return {
      formValues: {},
      salesChannelsFile:null,
      salesChannelsFileErrMSg:"",
      isLoading: false,
      isFileUploadMode:false,
      country_list:countryListAllIsoData,
      schema: yup.object().shape({
        name: yup
          .string()
          .min(6, "Name must be at least 6 characters")
          .required("Please enter sales channel name"),
        reg_number: yup
          .string()
          // .min(4, "Reg No must be at least 4 characters")
          .required("Please enter Reg No"),
        address:yup.string().notRequired().nullable(),
        // address: yup.lazy(() => {
        //   if (!this.formValues.is_online) {
        //     return yup.string().required("please enter sales channel address");
        //   }
        //   return yup.string().notRequired();
        // }),
        zipcode:yup.string().notRequired().nullable(),
        // zipcode: yup.lazy(() => {
        //   if (!this.formValues.is_online) {
        //     return yup.string().required("please enter sales channel zipcode");
        //   }
        //   return yup.string().notRequired();
        // }),
        city: yup.string().notRequired().nullable(),
        // city: yup.lazy(() => {
        //   if (!this.formValues.is_online) {
        //     return yup.string().required("please enter sales channel city");
        //   }
        //   return yup.string().notRequired();
        // }),
        phone: yup.string().notRequired().nullable(),
        country: yup.lazy(() => {
          if (!this.formValues.is_online) {
            return yup.string().required("please enter sales channel country").nullable(true,"please enter sales channel country");
          }
          return yup.string().nullable();
        }),
        is_online: yup.lazy(() => {
          return yup
            .number()
            .required('please enter sales channel status')
            .oneOf([1, 0], "please enter sales channel status");
        }),
        detail: yup
          .string() // use it for backend errors
          .nullable()
          .notRequired(),
      }),
    };
  },
};
</script>
