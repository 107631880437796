<template>
  <div>
    <FormModal
      :modalId="editModalId"
      :offerToEdit="editedResource"
      :modalType="1"
      :initOffers="initOffers"
    />
    <FormModal
      :modalId="createModalId"
      :modalType="2"
      :initOffers="initOffers"
    />
    <div>
      <div class="contant-box-main">
        <div class="data-heading-wrp">
          <div class="data-heading">
            <div class="search-box-wrp">
              <input
                type="text"
                class="form-control"
                v-model="search"
                name=""
                placeholder="Search by the offer name "
              />
              <a class="search_icon" href="#"
                ><img
                  src="../../../public/assets/images/search_icon.svg"
                  alt=""
              /></a>
            </div>
            <div class="tab-box-wrp">
              <ul>
                <li
                  @click="changeStatus('all')"
                  :class="status == 'all' ? 'active' : ''"
                >
                  All
                </li>
                <li
                  @click="changeStatus('active')"
                  :class="status == 'active' ? 'active' : ''"
                >
                  Active
                </li>
                <li
                  @click="changeStatus('pending')"
                  :class="status == 'pending' ? 'active' : ''"
                >
                  Pending
                </li>
                <li
                  @click="changeStatus('expired')"
                  :class="status == 'expired' ? 'active' : ''"
                >
                  Expired
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="col-lg-3 col-md-6 res-date-field dt-col inc-index">
            <label>Date Range</label>
            <div class="input-group input-daterange">
              <input
                id="icon-date"
                type="text"
                ref="date"
                class="form-control daterange"
                readonly
                name="daterange"
                :value="this.selectedDateRange"
              />
            </div>
          </div> -->
          <div
            class="data-heading-btn"
            @click="showCreateDialog"
            v-if="createpermission"
          >
            <button>+ create new offer</button>
          </div>
        </div>
        <div class="table-wrp overflow-auto  position-relative">
          <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <table class="table my-table-wrp table-sm" id="my-table">
            <thead>
              <tr>
                <th scope="col" v-for="f in fields" v-bind:key="f?.id">
                  <div class="table-head">{{ f.split("_").join(" ") }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="offer in offers?.results"
                v-bind:key="offer?.id"
                class="active-row"
              >
                <td width="5%" class="font-light-text">{{ offer?.id }}</td>
                <td width="20%" class="font-text">
                  <router-link
                    :to="{
                      name: `OfferDetails`,
                      params: { offerId: offer?.id },
                    }"
                    >{{ offer?.name }}</router-link
                  >
                </td>
                <td width="20%" class="font-light-text">{{ offer?.details }}</td>
                <td width="40%" class="font-light-text">
                  {{ offer?.articles.map((article) => article?.name).toString() }}
                </td>
                <!-- <td width="15%" class="font-light-text">
                  {{ offer.date_range }}
                </td> -->
                <td width="8%">
                  <label
                    class="status-active-btn"
                    v-if="offer?.is_active === true"
                    >Active</label
                  >
                  <label
                    class="status-pending"
                    v-if="
                      offer?.is_active === false && offer?.is_archive === false
                    "
                    >Pending</label
                  >
                  <label class="status-expired" v-if="offer?.is_archive === true"
                    >Expired</label
                  >
                </td>
                <td class="btn-align" width="7%">
                  <div class="d-flex align-item-center">
                    <router-link
                      :to="{
                        name: `OfferDetails`,
                        params: { offerId: offer?.id },
                      }"
                    >
                      <button title="See Offer Details" class="action-btns ms-2 icon-effect">
                        <!-- <DisableEyeIcon /> -->
                        <!-- <EyeIcon v-on:click="editCampaign(campaign)" /> -->
                        <EyeIcon />
                      </button>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            :page="page"
            :totalPages="totalPages"
            :name="'Offers'"
            :count="offers ? offers.count : 0"
            :incrementpage="incrementpage"
            :decrementpage="decrementpage"
            :setpage="setpage"
            :perpage="10"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EyeIcon from "../../icons/eye.vue";
// import { DATE_FORMAT } from "../../Core/helpers/utils";
import { LIST_OFFERS, DELETE_OFFER } from "@/Core/store/action-types";
import { mapActions } from "vuex";
import Pagination from "../../Core/Pagination";
import FormModal from "./FormModal";
import { subject } from "@casl/ability";
import { RESOURCE_NAME } from "../offer.vars";
export default {
  name: "offerTable",
  components: {
    Pagination,
    FormModal,
    EyeIcon,
  },
  data() {
    return {
      fields: ["ID", "Name", "Proposition details", "Articles", "Status", ""],
      status: "active",
      search: "",
      page: 1,
      totalPages: [],
      createModalId: "createDialog",
      editModalId: "editDialog",
      offers: null,
      loadindShow:false,
      // predefindedDates: {
      //   Yesterday: [window.moment().subtract(1, "days"), window.moment()],
      //   "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
      //   "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
      //   "This Month": [
      //     window.moment().startOf("month"),
      //     window.moment().endOf("month"),
      //   ],
      //   "This Year": [
      //     window.moment().startOf("year"),
      //     window.moment().endOf("year"),
      //   ],
      // },
      show: false,
      // selectedDateRange: null,
      editedResource: null,
      createpermission: this.$ability.can("create", subject(RESOURCE_NAME, {})),
    };
  },
  methods: {
    ...mapActions("offer", [LIST_OFFERS, DELETE_OFFER]),
    changeStatus(state) {
      this.status = state;
    },
    // onDateChange(start, end) {
    //   this.selectedDateRange =
    //     start.format(DATE_FORMAT) + " - " + end.format(DATE_FORMAT);
    //   this.initOffers();
    // },
    initOffers(pageNumber = null) {
      pageNumber ? (this.page = pageNumber) : null;
      let options = {
        persist: false,
        q: this.search?.length < 3 ? null : this.search,
        is_active:
          this.status == "active" ? 1 : this.status == "pending" ? 0 : null,
        is_archive:
          this.status == "expired" ? 1 : this.status == "pending" ? 0 : null,
        page: this.page,
        // date_after: this.selectedDateRange
        //   ? this.selectedDateRange.split("-")[0]
        //   : null,
        // date_before: this.selectedDateRange
        //   ? this.selectedDateRange.split("-")[1]
        //   : null,
      };
      this.loadindShow = true
      this[LIST_OFFERS](options).then((res) => {
        console.log(res);
        this.offers = res;
        this.totalPages = Array(Math.ceil(res.count / 10))
          .fill(0)
          .map((e, i) => i + 1);
        console.log(this.offers);
      }).finally(()=>this.loadindShow=false)
    },

    incrementpage() {
      this.page = this.page + 1;
      this.initOffers();
    },
    decrementpage() {
      this.page = this.page - 1;
      this.initOffers();
    },
    setpage(page) {
      this.page = page;
      this.initOffers();
    },
    showCreateDialog() {
      window.$(`#${this.createModalId}`).modal("toggle");
    },
    editOffer(item) {
      let hasPermission = this.$ability.can(
        "update",
        subject(RESOURCE_NAME, item)
      );
      if (hasPermission) {
        this.editedResource = item;
        window.$(`#${this.editModalId}`).modal("toggle");
      } else {
        this.$alertify.notify("Active offer can not be edited!", "error", 3);
      }
    },
  },
  mounted() {
    // this.datepicker = window.$(this.$refs.date);
    // this.datepicker.daterangepicker(
    //   {
    //     autoUpdateInput: false,
    //     ranges: this.predefindedDates,
    //     locale: {
    //       format: "DD/MM/YYYY",
    //     },
    //   },
    //   this.onDateChange
    // );
    // this.selectedDateRange =
    //   this.predefindedDates["This Month"][0].format(DATE_FORMAT) +
    //   " - " +
    //   this.predefindedDates["This Month"][1].format(DATE_FORMAT);
    this.initOffers();
  },
  watch: {
    status() {
      this.page = 1;
      this.initOffers();
    },
    search() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this.page = 1;
        this.initOffers();
      }
    },
  },
};
</script>
<style>
.dashboard-top-section .dts-box {
  background: #ffffff;
  padding: 40px;
}
.dashboard-top-section .dts-box span {
  color: #5e66fa;
  font-weight: 600;
  font-size: 54px;
  line-height: 43px;
  /* margin-bottom: 15px; */
}
.date-input {
  background-color: white !important;
  font-weight: normal !important;
  font-size: 15px !important;
  color: #3f3f40 !important;
}
.dashboard-top-section .dts-box h6 {
  margin: 0;
  color: #3f3f40;
  font-weight: 500;
  font-size: 18px;
}
.data-heading-wrp {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.data-heading-wrp .data-heading {
  position: relative;
}

.data-heading-wrp .data-heading-btn button {
  background: #5e66fa;
  border-radius: 4px;
  font-style: normal;
  font-size: 15px;
  line-height: 150%;
  border: 0;
  color: #fff;
  font-weight: 400;
  padding: 11px 19px;
}
.search-box-wrp {
  position: relative;
  min-width: 300px;
  display: inline-block;
  margin-right: 20px;
}
.search-box-wrp input {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #aaabad;
}
.search-box-wrp input::placeholder {
  color: #aaabad;
}
.tab-box-wrp {
  position: relative;
  display: inline-block;
}
.tab-box-wrp ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.tab-box-wrp ul li {
  background: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #3f3f40;
  list-style-type: none;
  border: 1px solid #e2e2e2;
  padding: 9px 20px;
  cursor: pointer;
}
.tab-box-wrp ul li:first-child {
  border-radius: 4px 0px 0px 4px;
}
.tab-box-wrp ul li:last-child {
  border-radius: 0px 4px 4px 0px;
}
.tab-box-wrp ul li.active {
  background: #5e66fa;
  color: #fff;
  border: 1px solid #5e66fa;
}
.table-wrp table tr:hover {
  background: #f6f6f7;
}
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
.sort-active {
  font-weight: bold;
  color: #000000;
  font-size: 12px;
}
#icon-date {
  background: url("../../../public/assets/images/calendar.png") no-repeat scroll
    left center / 15px auto;
  background-position: 95%;
}
.res-date-field {
  top: -16px;
}
.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  width: 100px;
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>