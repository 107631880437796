import { createRouter, createWebHashHistory } from "vue-router";

import Admin from "./User/Admin.vue";
import Login from "./User/Login.vue";
import Profile from "./User/Profile.vue";
import Dashboard from "./Dashboard/Dashboard.vue";
import Cart from "./Cart/Cart.vue";
import Recommendations from "./Cart/CartRecommendation.vue";
import ArticleAnalysis from "./Articles/ArticleAnalysis.vue";
import CartComposition from "./Products/CartComposition.vue";
import Organizations from "./Orgs/Organizations.vue";
import OrgDetails from "./Orgs/OrgDetails.vue";
import Offers from "./Offers/OffersNew.vue";
import OfferDetails from "./Offers/OfferDetails.vue";
import EditOffer from "./Offers/EditOffer.vue";
import Analytics from "./Analytics/Products.vue";
import Audience from "./Audience/AudienceNew.vue";
import AudienceDetails from "./Audience/AudienceDetails.vue";
import AudienceEdit from "./Audience/AudienceEdit";
//import Audience from './Audience/Audience.vue';
//import Campaigns from './Campaigns/Campaigns.vue';
import Email from "./Email/Email.vue";
import Campaigns from "./Campaigns/CampaignNew";
import CampaignAnalytics from "./Campaigns/CampaignAnalytics.vue";
import CampaignDetails from "./Campaigns/CampaignDetails.vue";
import EditCampaign from "./Campaigns/EditCampaign.vue";
// import Members from './Members/Members.vue';
import Customers from "./Customers/Customers.vue";
// import MemberDetail from './Members/MemberDetail.vue';
import CustomerDetailsGrid from "./Customers/CustomerDetailsGrid.vue";
import EditCustomer from "./Customers/EditCustomer.vue";
import NotFound from "./Core/404.vue";
import ForgotPassword from "./User/ForgotPassword.vue";
import ChangePassword from "./User/ChangePassword.vue";
import Merchants from "./User/Merchants.vue";
import MerchantDetails from "./User/MerchantDetails.vue";
import EditMerchant from "./User/EditMerchant.vue";
import AllAdmins from "./User/AllAdmins.vue";

const routes = [
  {
    path: "/Admins",
    name: "Admin",
    component: Admin,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("list", "User")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/AllAdmins",
    name: "AllAdmins",
    component: AllAdmins,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("list", "Admin")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Users",
    name: "Merchants",
    component: Merchants,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("list", "Merchant")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Users/:merchantid",
    name: "MerchantDetails",
    component: MerchantDetails,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Merchant")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Users/Edit/:merchantid",
    name: "EditMerchant",
    component: EditMerchant,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("edit", "Merchant")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Companies",
    name: "Organizations",
    component: Organizations,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("list", "Merchant")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Companies/:id",
    name: "OrgDetails",
    component: OrgDetails,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("list", "Merchant")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  // {
  //     path: "/admin/offers",
  //     name: "Offers",
  //     component: Offers,
  // },
  {
    path: "/Audience",
    name: "Audience",
    component: Audience,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Audience")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Audience/:audienceid",
    name: "AudienceDetails",
    component: AudienceDetails,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Audience")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Audience/Edit/:audienceid",
    name: "AudienceEdit",
    component: AudienceEdit,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Audience")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Email",
    name: "Email",
    component: Email,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Email")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/admin/customers",
    name: "Customers",
    component: Customers,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Customer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  // {
  //     path: "/admin/members/:memberId/",
  //     name: "MemberDetail",
  //     component: MemberDetail,
  // },
  {
    path: "/Customers/:customerId",
    name: "CustomerDetailsGrid",
    component: CustomerDetailsGrid,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Customer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Customers/Edit/:customerId",
    name: "EditCustomer",
    component: EditCustomer,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Customer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "User")) {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPassword,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "User")) {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/changepassword/:id/:token",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/analytics",
    name: "Analytics",
    component: Analytics,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Customer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Customer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Cart/Recommendations",
    name: "Recommendations",
    component: Recommendations,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Customer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Offers",
    name: "Offers",
    component: Offers,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Offer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Offers/:offerId",
    name: "OfferDetails",
    component: OfferDetails,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Offer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Offers/edit/:offerId",
    name: "EditOffer",
    component: EditOffer,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Offer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Customers",
    name: "Customers",
    component: Customers,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Customer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Campaigns",
    name: "Campaigns",
    component: Campaigns,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Campaign")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Campaigns/:campaignId",
    name: "CampaignDetails",
    component: CampaignDetails,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Campaign")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Campaigns/analytics/:campaignId",
    name: "CampaignAnalytics",
    component: CampaignAnalytics,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Campaign")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/Campaigns/edit/:campaignId",
    name: "EditCampaign",
    component: EditCampaign,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Campaign")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Customer")) {
        next();
      } else if (router.$global.$ability.can("list", "Merchant")) {
        next({ name: "Organizations" });
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/articles",
    name: "ArticleAnalysis",
    component: ArticleAnalysis,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Customer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/cart/composition",
    name: "CartComposition",
    component: CartComposition,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "Customer")) {
        next();
      } else {
        next({ name: "Profile" });
      }
    },
  },
  {
    path: "/",
    name: "Profile",
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (router.$global.$ability.can("read", "User")) {
        next();
      } else {
        next({ name: "Login" });
      }
    },
  },
  {
    name: "404",
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
