<template>
  <div id="product-list">
    <button class="multiselect-btn" @click="getProductRecommendations">
      Analyze
    </button>
    <Multiselect
      v-model="value"
      mode="tags"
      :options="productsList"
      :searchable="true"
      :createTag="false"
      trackBy="name"
      label="name"
      valueProp="id"
      @deselect="onDeselect"
      @select="onSelect"
      placeholder="Select products"
    />
    <!--<input type="text" class="form-control" v-model="$parent.searchProductTerm" v-on:keypress="$parent.searchProduct">
        <ul v-if="$parent.productLength" class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10" style="height: 178px; overflow-y: auto;">
            <li v-for="product in $parent.searchProducts" :key="product.name" @click="$parent.selectProduct(product.name, product.id)" class="cursor-pointer hover:bg-gray-100 p-1">
                {{ product.name }}`
            </li>
        </ul>-->
    <div class="toolbar-holder">
      <!--<div class="select-holder">
                <select>
                  <option>Product</option>
                  <option>Product</option>
                  <option>Product</option>
                  <option>Product</option>
                </select>
            </div>-->
      <!--<button class="btn btn-primary">Analyze</button>-->
    </div>
    <p>Start enter the product name and then select from matches list</p>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<script>
import Multiselect from "@vueform/multiselect";

export default {
  name: "ProductList",
  components: {
    Multiselect,
  },
  props: ["productsList"],
  data() {
    return {
      value: null,
    };
  },
  methods: {
    onDeselect(option) {
      this.$parent.removeProduct(option);
    },
    onSelect(option) {
      let selectedProduct = this.productsList.filter(
        (item) => item.id === option
      );
      this.$parent.selectProduct(selectedProduct[0].name, option);
    },
    getProductRecommendations() {
      this.$parent.$refs.productTab.getRecommendedProducts();
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
