<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="48px"
    height="32px"
    viewBox="0 0 48 32"
    version="1.1"
  >
    <defs>
      <linearGradient
        id="linear0"
        gradientUnits="userSpaceOnUse"
        x1="62.34"
        y1="101.45"
        x2="260.34"
        y2="91.45"
        gradientTransform="matrix(0.166667,0,0,-0.167539,0.0366667,32.167539)"
      >
        <stop
          offset="0"
          style="stop-color: rgb(0%, 39.215686%, 88.235294%); stop-opacity: 1"
        />
        <stop
          offset="0.4"
          style="stop-color: rgb(0%, 39.215686%, 88.235294%); stop-opacity: 1"
        />
        <stop
          offset="0.83"
          style="stop-color: rgb(0%, 45.098039%, 93.333333%); stop-opacity: 1"
        />
        <stop
          offset="1"
          style="stop-color: rgb(0%, 50.980392%, 98.431373%); stop-opacity: 1"
        />
      </linearGradient>
      <linearGradient
        id="linear1"
        gradientUnits="userSpaceOnUse"
        x1="41.42"
        y1="53"
        x2="41.42"
        y2="126"
        gradientTransform="matrix(0.166667,0,0,-0.167539,0.0366667,32.167539)"
      >
        <stop
          offset="0"
          style="stop-color: rgb(0%, 50.980392%, 98.431373%); stop-opacity: 1"
        />
        <stop
          offset="1"
          style="stop-color: rgb(0%, 39.215686%, 87.843137%); stop-opacity: 1"
        />
      </linearGradient>
    </defs>
    <g id="surface1">
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(0%, 50.588235%, 98.431373%);
          fill-opacity: 1;
        "
        d="M 5.214844 21.109375 C 5.214844 22.953125 5.613281 24.363281 6.140625 25.214844 C 6.710938 26.207031 7.765625 26.8125 8.902344 26.804688 C 10.25 26.804688 11.488281 26.472656 13.867188 23.160156 C 15.773438 20.507812 18.019531 16.792969 19.535156 14.449219 L 22.09375 10.496094 C 23.871094 7.75 25.925781 4.695312 28.289062 2.621094 C 30.214844 0.9375 32.292969 0 34.386719 0 C 37.898438 0 41.242188 2.046875 43.800781 5.882812 C 46.601562 10.085938 47.96875 15.375 47.96875 20.839844 C 47.96875 24.085938 47.332031 26.472656 46.25 28.355469 C 45.203125 30.179688 43.15625 32 39.726562 32 L 39.726562 26.804688 C 42.664062 26.804688 43.390625 24.09375 43.390625 20.984375 C 43.390625 16.558594 42.363281 11.648438 40.101562 8.136719 C 38.5 5.648438 36.417969 4.125 34.128906 4.125 C 31.65625 4.125 29.664062 6.003906 27.425781 9.347656 C 26.234375 11.125 25.011719 13.292969 23.640625 15.738281 L 22.132812 18.417969 C 19.097656 23.824219 18.328125 25.054688 16.8125 27.085938 C 14.160156 30.660156 11.890625 32 8.902344 32 C 5.355469 32 3.113281 30.457031 1.734375 28.132812 C 0.59375 26.238281 0.0351562 23.75 0.0351562 20.917969 Z M 5.214844 21.109375 "
      />
      <path
        style="stroke: none; fill-rule: nonzero; fill: url(#linear0)"
        d="M 4.117188 6.25 C 6.492188 2.570312 9.917969 0 13.84375 0 C 16.121094 0 18.382812 0.671875 20.742188 2.617188 C 23.328125 4.734375 26.078125 8.222656 29.515625 13.976562 L 30.746094 16.039062 C 33.71875 21.019531 35.414062 23.578125 36.402344 24.789062 C 37.675781 26.339844 38.566406 26.800781 39.726562 26.800781 C 42.664062 26.800781 43.390625 24.085938 43.390625 20.980469 L 47.957031 20.835938 C 47.957031 24.082031 47.320312 26.46875 46.238281 28.351562 C 45.203125 30.179688 43.15625 32 39.726562 32 C 37.589844 32 35.703125 31.535156 33.613281 29.550781 C 32.003906 28.03125 30.125 25.328125 28.683594 22.898438 L 24.382812 15.683594 C 22.226562 12.058594 20.25 9.359375 19.101562 8.140625 C 17.871094 6.820312 16.289062 5.230469 13.761719 5.230469 C 11.714844 5.230469 9.980469 6.675781 8.527344 8.882812 Z M 4.117188 6.25 "
      />
      <path
        style="stroke: none; fill-rule: nonzero; fill: url(#linear1)"
        d="M 13.761719 5.230469 C 11.714844 5.230469 9.980469 6.675781 8.527344 8.882812 C 6.472656 12 5.214844 16.644531 5.214844 21.109375 C 5.214844 22.953125 5.613281 24.363281 6.140625 25.214844 L 1.726562 28.132812 C 0.59375 26.238281 0.0351562 23.75 0.0351562 20.917969 C 0.0351562 15.765625 1.441406 10.394531 4.117188 6.25 C 6.492188 2.570312 9.917969 0 13.84375 0 Z M 13.761719 5.230469 "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MetaIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>