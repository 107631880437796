<template>
  <div class="contant-box-main customer-list-box">
    <div class="h-100">
      <div class="contant-header">
        <h6>
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFESURBVHgBhVHLUcNADJVkkwk30wElhA5CBxRAfjMJww1TQUIFhGty2RAOHCkhVIA7wHTg4ULi2BJaEzPGxsmb2bGsfW/03gqhhO4ovkHENgh4gmIeZ41F8R7/kK/iN/209AS7Vlar6CznUF50RnHfEljYtwR7BPjW9uzUioAIe2ojeJo3H/LectacqolArbUrAkUEdRD0KgJhWWmiVme49n9tZrW0EMHAvtC4Cy37QltkQQUGjBAxSKgKv0i2cIs/fV88WCchM6zscEL+gBKw6BeRxtrxSolDpU3yBdKP980Eie7VcyCC7c/46EQJKI5uHNC+nukON5NsQv/665TZeVfvZjlvDOAfXA43xu6JOT0nEWdsx2636R3UwE0avj5A5DhOj4ThQm2sns1xWCcwBiMkeLFcFxCnwskrHECapgsiN/wGgX6SW5zKhKcAAAAASUVORK5CYII="
            alt=""
          />
          Most Re-occuring Customers
        </h6>
        <div class="">
          <!-- <label for="adminfilter">Organization</label> -->
          <select
            class="for"
            id="adminfilter"
            v-model="reoccuringcustomerTime"
            @change="onTimeChange"
          >
            <option v-for="option in timeOptions" :key="option">
              {{ option }}
            </option>
          </select>
        </div>
        <!-- <span class="gray-text">from {{ one_month_ago_date() }}</span> -->
      </div>
      <div class="contant-details h-100">
        <div class="overflow-auto position-relative " :class="loadindShow?'h-100':''">
           <div class="loader-main" v-if="loadindShow">
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
           </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Order count</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="customer in reoccuring_customers"
                :key="customer.member__id"
              >
                <td class="font-light-text" width="15%">
                  {{ customer.member__id }}
                </td>
                <td width="40%" class="font-text"><router-link
                    :to="{
                      name: `CustomerDetailsGrid`,
                      params: { customerId: customer.member__id },
                    }"
                    >{{ customer.member__first_name }}</router-link></td>
                <td class="font-light-text" width="30%">
                  {{ customer.member__last_name }}
                </td>
                <td class="font-light-text" width="15%">
                  {{ customer.count }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="contant-footer d-flex justify-content-end">
      <a
        role="button"
        data-toggle="modal"
        data-target="#reoccuringCustomerListModel"
        class="view-more-btn"
        @click="initReOccuringCustomers(true)"
      >
        View All
        <img src="@/../public/assets/images/right-sky.png" alt=""
      /></a>
    </div>
  </div>
  <!-- most valuable customer list model -->
  <div
    class="modal fade"
    id="reoccuringCustomerListModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="valubaleCustomerListModelTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="valubaleCustomerListModelTitle">
            Most Re-occuring Customers
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-12">
                  <div class="contant-header">
                    <h6>
                      <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAYAAAAmL5yKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHXSURBVHgBjVJBbtpQEJ0Z29+N2lS+QeEGcIKSE5TuqihBlhrSRReQEwROELproBWmRN2GngBuEHIDjkClVBQb/8kMwY4SJQqz8HyP/3vz3owRNrEf3pQc3zTAQkFeB8OeiWCLIH18ChcF1zNjtFDVCiL0D+txf2sCz3dOAWFOTloenps9YPgmJOHnr/xuKwLpGQhoHn3fmembZRxpXi7/F18icNdwtteiu1o7Tk4t4DWBPZPy7OLHziS7WKvHDUktURowYGfY9U5yBb+6fktlA3BLwJdyDojjjxn44CipCLDDAFO5M0DgZu142YJ7C0LSM02itLjiuEyvvWLUezPNfZKtaJbN7EmzUI5TZnyfW6iGHLw1q4ZNuemiE6T/ksnB0aKdWbApTMkRGxuLUikRQZQr2PWSS5XPyALgtmygQOSMhWTd+eKnP3pgUeaDmLbX81N/RDxmsCfD7quOFkNRZE1yxQwzlZ1Z2a/flDT/fmAv87cBa0QRzqXjH1FSySyqfBfM2EVzdfgl7uvPl8/gyUCey2N93PWTMyEMUXzLJv4iwwdjnIooLT9PsAntJIBQZzM4l3WDrnUx0hmlXlIlZjtjhMljYFYnzwk0rzgZZd90O3eYFG4Bk3jRLFulvBYAAAAASUVORK5CYII="
                        alt=""
                      />
                      Re-occuring Customers
                    </h6>
                    <a
                      href="javascript:void(0);"
                      class="view-more-btn"
                      @click="createAudienceMode = !createAudienceMode"
                      >+ create audience</a
                    >
                    <button
                      type="button "
                      class="save-btn"
                      @click="generateCSV()"
                    >
                      Export List
                    </button>
                  </div>
                  <!-- <div class="d-flex d-flex justify-content-between"> -->
                  <div
                    v-if="createAudienceMode"
                    class="form-group"
                    :class="nameError || errMsg != '' ? 'has-error' : ''"
                  >
                    <label class="form-label w-80">Campaign Name<span class="required-field text-danger">*</span></label>
                    <div class="d-flex" :style="'gap:16px'">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        v-model="audienceName"
                        @keyup="validateName"
                        @blur="validateName"
                      />
                      <button
                        type="button"
                        class="save-btn"
                        @click="onAudienceCreate"
                      >
                        Create
                      </button>
                    </div>
                    <span class="error">{{ errMsg }}</span>
                  </div>
                  <!-- <div class="mt-4"> -->
                  <!-- <button type="button " class="save-btn">Create</button> -->
                  <!-- </div> -->
                  <!-- </div> -->
                </div>
              </div>
            </form>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-lg-12">
                  <div class="model_table">
                    <div class="table-wrp overflow-auto position-relative">
                      <div class="loader-main" v-if="loadindShow">
                          <div
                            class="spinner-border"
                            role="status"
                            style="width: 36px; height: 36px; color: #5e66fa"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      <table class="table my-table-wrp table-sm" id="my-table">
                        <thead>
                          <tr>
                            <th width="15%">ID</th>
                            <th width="40%">Name</th>
                            <th width="20%">Purchase Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            :class="''"
                            v-for="customer in all_reoccuring_customers
                              ? all_reoccuring_customers.slice(
                                  10 * (page_model - 1),
                                  page_model * 10
                                )
                              : []"
                            :key="customer.id"
                          >
                            <td class="font-light-text">
                              {{ customer.member__id }}
                            </td>
                            <td class="font-text">
                              {{ customer.member__name }}
                            </td>
                            <td class="font-light-text">
                              {{ customer.count }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      :page="page_model"
                      :totalPages="
                        all_reoccuring_customers
                          ? Array(
                              Math.ceil(all_reoccuring_customers.length / 10)
                            )
                              .fill(0)
                              .map((e, i) => i + 1)
                          : []
                      "
                      :name="'Customers'"
                      :count="
                        all_reoccuring_customers
                          ? all_reoccuring_customers.length
                          : 0
                      "
                      :incrementpage="incrementpage_model"
                      :decrementpage="decrementpage_model"
                      :setpage="setpage_model"
                      :perpage="10"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="form-btns">
            <button
              type="button"
              class="cancle-btn"
              @click="oncancel"
              data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  CREATE_AUDIENCE,
  GET_REOCCURING_CUSTOMERS,
} from "@/Core/store/action-types";
import Pagination from "../../Core/Pagination.vue";
export default {
  name: "ReoccuringCustomersCard",
  components: {
    Pagination,
  },
  data() {
    return {
      page_model: 1,
      reoccuring_customers: [],
      all_reoccuring_customers: [],
      reoccuringcustomerTime: "month",
      timeOptions: ["week", "month", "quater", "half a year", "year"],
      createAudienceMode: false,
      nameError: false,
      errMsg: "",
      audienceName: "",
      loadindShow:false,
    };
  },
  methods: {
    ...mapActions("audience", [CREATE_AUDIENCE]),
    ...mapActions("customer", [GET_REOCCURING_CUSTOMERS]),
    initReOccuringCustomers(isAll = false) {
      let params = {
        duration:
          this.reoccuringcustomerTime == "month"
            ? 30
            : this.reoccuringcustomerTime == "week"
            ? 7
            : this.reoccuringcustomerTime == "quater"
            ? 90
            : this.reoccuringcustomerTime == "half a year"
            ? 180
            : 365,
        all: isAll ? isAll : null,
      };
      this.loadindShow = true
      this[GET_REOCCURING_CUSTOMERS](params)
        .then((result) => {
          if (isAll) this.all_reoccuring_customers = result;
          else this.reoccuring_customers = result;
        })
        .catch(() => (this.reoccuring_customers = []))
        .finally(()=>this.loadindShow=false)
    },
    onTimeChange() {
      this.initReOccuringCustomers();
    },
    incrementpage_model() {
      this.page_model = this.page_model + 1;
    },
    // go to previous page in customer table in model
    decrementpage_model() {
      this.page_model = this.page_model - 1;
    },
    // go to perticular page in customer table in model
    setpage_model(page) {
      this.page_model = page;
    },
    validateName() {
      this.errMsg = "";
      if (!this.audienceName) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audienceName.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else this.nameError = false;
    },
    generateCustomerIdArray() {
      let customerIds = [];
      this.all_reoccuring_customers.map((customer) =>
        customerIds.push(customer.member__id)
      );
      return customerIds;
    },
    onAudienceCreate() {
      this.errMsg = "";
      if (!this.audienceName) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audienceName.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else if (this.all_reoccuring_customers.length <= 0) {
        this.nameError = false;
        this.$alertify.notify(
          "There in no customers to create audience",
          "error",
          3
        );
      } else {
        this.nameError = false;
        let payload = {
          members: this.generateCustomerIdArray(),
          name: this.audienceName,
          articles: [],
          article_pref_type:'',
          gender: "All",
          age_min: 0,
          churn_status: 'All',
          age_max: 100,
        };

        this[CREATE_AUDIENCE](payload)
          .then(() => {
            window.$(`#${this.createModelID}`).modal("hide");
            this.$alertify.notify(
              `${this.audienceName} successfully created`,
              "success",
              3
            );
            this.createAudienceMode = false;
          })
          .catch((e) => {
            console.log("in aud", e.message);
            this.errMsg = e.message;
            this.$alertify.notify(e.message, "error", 3);
          });
      }
    },
    generateCSV() {
      let arrData = [
        {
          customers: this.all_reoccuring_customers,
        },
      ];
      window.$(`#exportCsvModel`).modal("hide");
      console.log("ArrrrData===>", arrData);
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0].customers[0] || {}).join(","),
        ...arrData[0].customers.map((item) => Object.values(item).join(",")),
      ].join("\n");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute(
        "download",
        `reoccuring-customers-${this.reoccuringcustomerTime}.csv`
      );
      link.click();
    },
  },
  mounted() {
    this.initReOccuringCustomers();
  },
};
</script>
<style scoped>
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.view-more-btn {
  color: #5f66fa !important;
  font-weight: 500;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>