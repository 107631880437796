<template>
  <!-- Register modal window -->
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createDialogLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createDialogLabel">
            Create Product
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ProductForm :onSubmit="onSubmit" :isEditMode="false"  />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
import { CREATE_PRODUCT} from "@/Core/store/action-types";
// import {getCountryIdByName} from "../../Core/helpers/countryList"
// import { UserRoleMixin } from '@/Core/mixins/UserRoleMixin';
import ProductForm from "./ProductForm.vue";

export default {
  name: "Registration",
  components: {
    ProductForm,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters("org",["org"]),
  },
  methods: {
    ...mapActions("product", [CREATE_PRODUCT]),

    onSubmit(values, actions) {
      console.log(values,actions)
      // values.organization=this.org.id
      // values.country_code = values.country_name?getCountryIdByName(values.country_name):null
      this[CREATE_PRODUCT](values)
        .then(() => {
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify(
            "product successfully created.",
            "success",
            3
          );
          actions.resetForm();
          this.$parent.initProducts(1);
        })
        .catch((e) => {
          console.log(e.message);
          // this.$alertify.notify(
          //   "sales channel with this reg number already exists.",
          //   "error",
          //   3
          // );
          
          this.errMsg = e.message
          actions.setFieldError('detail', e.message || 'something went wrong');
        });
    }
  },
};
</script>