<template>
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 11.25C6.76991 11.2653 5.55253 10.9998 4.4405 10.4738C3.57852 10.0532 2.80449 9.47234 2.15975 8.76226C1.47682 8.0281 0.939101 7.17126 0.575 6.23701L0.5 6.00001L0.57875 5.76301C0.943111 4.82959 1.47968 3.97297 2.1605 3.23776C2.80501 2.52775 3.57878 1.94691 4.4405 1.52626C5.55253 1.00021 6.76991 0.734723 8 0.750015C9.23009 0.734749 10.4475 1.00023 11.5595 1.52626C12.4215 1.94682 13.1955 2.52766 13.8403 3.23776C14.5245 3.97093 15.0624 4.82804 15.425 5.76301L15.5 6.00001L15.4213 6.23701C14.2447 9.29986 11.2806 11.302 8 11.25ZM8 2.25001C5.4469 2.17001 3.10356 3.65634 2.08775 6.00001C3.1034 8.34383 5.44684 9.83023 8 9.75001C10.553 9.82981 12.8963 8.34355 13.9122 6.00001C12.8978 3.6552 10.5536 2.16832 8 2.25001ZM8 8.25001C6.91799 8.25718 5.98203 7.49803 5.76572 6.43784C5.54942 5.37765 6.1132 4.31253 7.11154 3.89526C8.10987 3.47799 9.26393 3.82512 9.86641 4.72389C10.4689 5.62267 10.3515 6.82207 9.58625 7.58701C9.16724 8.01096 8.59607 8.24969 8 8.25001Z"
      fill="#3F3F40"
    />
  </svg>
</template>

<script>
export default {
  name: "EyeIcon",
};
</script>

<style scoped>
</style>