<template>
  <div class="contant-box-main">
    <div class="contant-header">
      <h6>
        <img
          src="./../../../public/assets/images/campaigns-icon.png"
          alt=""
        />Campaigns
      </h6>
    </div>
    <div class="contant-details">
      <div class="table-wrp overflow-auto position-relative">
        <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <table class="table table-sm" id="my-table">
          <thead>
            <tr>
              <th scope="col" v-for="f in fields" v-bind:key="f.id">
                {{ f.split("_").join(" ") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items.results" v-bind:key="item.id">
              <td class="font-light-text" width="10%">{{ item.id }}</td>
              <td class="font-text"  width="40%"><router-link
                    :to="{
                      name: `CampaignDetails`,
                      params: { campaignId: item.id },
                    }"
                    >{{ item.name }}</router-link></td>
              <td class="font-light-text" width="20%">{{ item.offer.name }}</td>
              <td class="font-light-text" width="20%">{{ item.audience.name }}</td>
              <td  width="10%">
                <label
                  class="status-active-btn"
                  v-show="item.is_active === true"
                >
                  Active
                </label>
                <label
                  class="status-pending"
                  v-show="item.is_active === false && item.is_archive === false"
                >
                  Pending
                </label>
                <label class="status-expired" v-show="item.is_archive === true">
                  Expired
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :page="page"
          :totalPages="totalPages"
          :name="'Campaigns'"
          :count="items.count ? items.count : 0"
          :incrementpage="incrementpage"
          :decrementpage="decrementpage"
          :setpage="setpage"
          :perpage="10"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { LIST_CUSTOMER_CAMPAIGNS } from "@/Core/store/action-types";
import Pagination from "../../Core/Pagination";
export default {
  name: "Campaigns",
  components: {
    Pagination,
  },
  data() {
    return {
      page: 1,
      totalPages: [],
      fields: ["ID", "Campaign name", "Offer name", "Audience", "Status"],
      items: [],
      loadindShow:false,
    };
  },
  methods: {
    ...mapActions("customer", [LIST_CUSTOMER_CAMPAIGNS]),
    //get member's campaign data
    async InItCustomerCampaignDetails(search = null, status = null) {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") {
        const customerId = this.$route.params.customerId;
        const payload = {
          params: {
            page: this.page,
            search: search,
            status: status,
          },
          objId: customerId,
        };
        this.loadindShow = true
        this[LIST_CUSTOMER_CAMPAIGNS](payload)
          .then((res) => {
            this.items = res;
            this.items.results = this.getStatusWiseCampaign(res.results);
            var total = Math.ceil(res.count / 10);
            this.totalPages = Array(total)
              .fill(0)
              .map((e, i) => i + 1);
          })
          .catch((e) => {
            console.log("error=>", e);
          }).finally(()=>this.loadindShow=false)
      }
    },
    //filter based on status 
    getStatusWiseCampaign(data) {
      let campaign_active = [];
      let campaign_expired = [];
      let campaign_pending = [];
      data.map((d) => {
        if (d.is_active) campaign_active.push(d);
        else if (!d.is_active && !d.is_archive) campaign_pending.push(d);
        else campaign_expired.push(d);
      });
      return [...campaign_active, ...campaign_expired, ...campaign_pending];
    },
    //go to next page
    incrementpage() {
      this.page = this.page + 1;
      this.InItCustomerCampaignDetails();
    },
    // go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.InItCustomerCampaignDetails();
    },
    // go to perticular page
    setpage(page) {
      this.page = page;
      this.InItCustomerCampaignDetails();
    },
  },
  mounted() {
    this.InItCustomerCampaignDetails();
  },
};
</script>
<style>
.page-item {
  display: flex;
}
.page-link-active {
  color: #5e66fa;
  border: 1px solid #e2e2e2 !important;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 13px !important;
}
</style>