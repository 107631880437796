<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2921 19.707L10.5861 11H6.0001V8.99997H8.5861L6.5861 6.99997H6.0001C4.34324 6.99997 3.0001 8.34311 3.0001 9.99997C3.0001 11.6568 4.34324 13 6.0001 13H9.0001V15H6.0001C3.48165 15.002 1.35474 13.1307 1.03615 10.6325C0.717556 8.13427 2.30674 5.78902 4.7451 5.15897L1.2921 1.70697L2.7071 0.292969L20.7071 18.293L19.2931 19.706L19.2921 19.707ZM19.1361 13.893L17.7081 12.466C18.7866 11.7188 19.2544 10.3575 18.8631 9.1052C18.4718 7.85291 17.3121 7.00017 16.0001 6.99997H13.0001V4.99997H16.0001C18.1201 5.00022 20.0095 6.33741 20.7147 8.33667C21.42 10.3359 20.7877 12.5626 19.1371 13.893H19.1361Z"
      fill="#2E3A59"
    />
  </svg>
</template>

<script>
export default {
  name: "Disconnect",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>