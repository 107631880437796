import { API_URL, authHeader } from "@/Core/helpers/utils";
import axios from "axios";

const RESOURCE_URL = `${API_URL}/api/v1/stores/`;

export default {
  get(token, params) {
    return axios.get(RESOURCE_URL, {
      params,
      headers: authHeader(token),
    });
  },
  create(token, obj) {
    return axios.post(RESOURCE_URL, obj, {
      headers: authHeader(token),
    });
  },
  delete(token, objId) {
    let url = `${RESOURCE_URL}${objId}/`;
    return axios.delete(url, {
      headers: authHeader(token),
    });
  },
  update(token, obj) {
    let url = `${RESOURCE_URL}${obj.id}/`;
    return axios.patch(url, obj, {
      headers: authHeader(token),
    });
  },
  metrics(token, params) {
    return axios.head(RESOURCE_URL, {
      params,
      headers: authHeader(token),
    });
  },
  get_store_by_merchant(token, merchant_id) {
    return axios.get(
      `${API_URL}/api/v1/get_store_by_merchant/${merchant_id}/`,
      {
        headers: authHeader(token),
      }
    );
  },
  update_store_external_api(token, params) {
    return axios.patch(
      `${API_URL}/api/v1/update_store_external_api/${params.selectedStore}/`,
      params,
      {
        headers: authHeader(token),
      }
    );
  },
  sales_channel_file_upload(token, file) {
    let params = new FormData();
    params.append("file", file);
    return axios.post(`${API_URL}/api/v1/upload_sales_channel_file/`, params, {
      headers: authHeader(token),
    });
  },
};
