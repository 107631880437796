<template>
  <div>
    <div>
      <!-- delete campaign model end -->

      <!-- duplicate campaign model start -->
      <CustomModel
        v-if="duplicateResource"
        :modalId="duplicateCampaignModelID"
        :item="duplicateResource"
        :onDelete="duplicateCampaign"
        :resourseName="'Campaign'"
        actionType="Duplicate"
      ></CustomModel>
      <!-- duplicate campaign model end -->

      <!-- edit campaign model start -->
      <FormModal
        :modalId="editModalId"
        :campaignToEdit="editedResource"
        :modalType="1"
      />
      <!-- edit campaign model end -->
      
      <!-- create campaign model start -->
      <FormModal
        :modalId="createModalId"
        :modalType="2"
        :initCampaigns="initCampaigns"
      />
      <!-- edit campaign model end -->
      <div>
        <div class="contant-box-main">
          <div class="data-heading-wrp">
            <div class="data-heading">
              <!-- search box start -->
              <div class="search-box-wrp">
                <input
                  type="text"
                  class="form-control"
                  v-model="search"
                  name=""
                  placeholder="Search by the campaign name"
                />
                <a class="search_icon" href="#"
                  ><img
                    src="../../../public/assets/images/search_icon.svg"
                    alt=""
                /></a>
              </div>
              <!-- search box end -->
              <!-- status filter start -->
              <div class="tab-box-wrp">
                <ul>
                  <li
                    @click="changeStatus('all')"
                    :class="status == 'all' ? 'active' : ''"
                  >
                    All
                  </li>
                  <li
                    @click="changeStatus('active')"
                    :class="status == 'active' ? 'active' : ''"
                  >
                    Active
                  </li>
                  <li
                    @click="changeStatus('pending')"
                    :class="status == 'pending' ? 'active' : ''"
                  >
                    Pending
                  </li>
                  <li
                    @click="changeStatus('expired')"
                    :class="status == 'expired' ? 'active' : ''"
                  >
                    Expired
                  </li>
                </ul>
              </div>
              <!-- status filter end -->
            </div>
            <!-- create button start -->
            <div class="data-heading-btn" v-if="createpermission">
              <button @click="showCreateDialog">+ create new campiagn</button>
            </div>
            <!-- create button end -->
          </div>
          <!-- campaign table start -->
          <div class="table-wrp overflow-auto position-relative">
            <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
            <table class="table my-table-wrp table-sm" id="my-table">
              <thead>
                <tr>
                  <th scope="col" v-for="f in fields" v-bind:key="f.id">
                    <div class="table-head">{{ f.split("_").join(" ") }}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="campaign in campaigns.results"
                  v-bind:key="campaign.id"
                  class="active-row"
                >
                  <td width="5%" class="font-light-text">{{ campaign?.id }}</td>
                  <td width="20%" class="font-text">
                    <router-link
                      :to="{
                        name: `CampaignAnalytics`,
                        params: { campaignId: campaign.id },
                      }"
                      >{{ campaign?.name }}</router-link
                    >
                  </td>
                  <td width="15%" class="font-light-text">
                    {{ campaign?.offer?.name }}
                  </td>
                  <td width="10%">
                    <label
                      class="campaign-status-active-btn"
                      v-show="campaign?.is_active === true"
                      >Active</label
                    >
                    <label
                      class="campaign-status-pending"
                      v-show="
                        campaign?.is_active === false &&
                        campaign?.is_archive === false
                      "
                      >Pending</label
                    >
                    <label
                      class="campaign-status-expired"
                      v-show="campaign?.is_archive === true"
                      >Expired</label
                    >
                    <!-- <button class="status-inactive" v-else>Inactive</button> -->
                  </td>
                  <!-- <td width="20%" class="font-light-text">
                    {{ campaign?.audience?.name || '-'}}
                  </td> -->
                  <td width="10%"  class="font-text">
                    {{(campaign?.avg_order_value || 0).toFixed(0) }}{{' '}}{{default_currency}}
                  </td>
                  <td width="10%"  class="font-text">
                    {{ ((campaign?.metadata.online_sales *100 / campaign?.metadata.total_orders ) || 0 ).toFixed(0) }}
                    / {{ ((campaign?.metadata.offline_sales *100 / campaign?.metadata.total_orders ) || 0 ).toFixed(0) }} %
                  </td>
                  <td width="10%"  class="font-text">
                    {{ (campaign?.gross_margin_percent || 0 ).toFixed(1) }}%
                  </td>
                  <td width="8%"  class="font-text">
                    {{ (campaign?.metadata.roi || 0).toFixed(0) }} %
                  </td>
                  <td width="4%"  class="font-text">
                    <span :class="'campaign-light '+ (campaign?.kpi_summary || 'red')" ></span>
                  </td>
                  <td class="btn-align" width="8%">
                    <div class="d-flex align-item-center">
                      <!-- <span class="green-light"></span> -->
                      <router-link
                        :to="{
                          name: `CampaignDetails`,
                          params: { campaignId: campaign?.id },
                        }"
                      >
                        <button class="action-btns ms-2 icon-effect" title="See Campaign Details">
                          <!-- <DisableEyeIcon /> -->
                          <!-- <EyeIcon v-on:click="editCampaign(campaign)" /> -->
                          <EyeIcon />
                        </button>
                      </router-link>
                      <router-link
                        :to="{
                          name: `CampaignAnalytics`,
                          params: { campaignId: campaign.id },
                        }"
                      >
                        <button class="action-btns ms-2 icon-effect" title="Campaign insights">
                          <!-- <DisableEyeIcon /> -->
                          <!-- <EyeIcon v-on:click="editCampaign(campaign)" /> -->
                          <AnalyticsIcon />
                        </button>
                      </router-link>
                      <button
                        title="Duplicate campaign"
                        class="action-btns ms-2 icon-effect"
                        @click="showDuplicateDialog(campaign)"
                      >
                        <CopyIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination
              :page="page"
              :totalPages="totalPages"
              name="Campaigns"
              :count="campaigns.count ? campaigns.count : 0"
              :incrementpage="incrementpage"
              :decrementpage="decrementpage"
              :setpage="setpage"
              :perpage="10"
            />
          </div>
          <!-- campaign table end -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CopyIcon from "../../icons/copy";
// import DisableEyeIcon from "../../icons/disableEye.vue";
import EyeIcon from "../../icons/eye.vue";
import AnalyticsIcon from "../../icons/analyticsicon";
import Pagination from "../../Core/Pagination";
import FormModal from "./FormModal";
import { mapActions , mapGetters} from "vuex";
import { subject } from "@casl/ability";
import { RESOURCE_NAME } from "../campaign.vars";
import CustomModel from "../../Core/CustomModel.vue";
import {
  GET_ORG,
  LIST_CAMPAIGNS,
  // DELETE_CAMPAIGN,
  //   UPDATE_CAMPAIGN,
  //   CREATE_CAMPAIGN,
  GET_FACEBOOK_CAMPAIGNS_LIST,
  GET_GOOGLE_CAMPAIGNS_LIST,
  DUPLICATE_CAMPAIGN,
} from "@/Core/store/action-types";
export default {
  name: "CampaignTable",
  components: {
    //CampaignsTableBar,
    // DeleteIcon,
    EyeIcon,
    // DisableEyeIcon,
    CopyIcon,
    Pagination,
    FormModal,
    CustomModel,
    AnalyticsIcon,
  },
  data() {
    return {
      fields: ["ID", "Name", "Offer","Status","Average Sale","Online/Offline Sale","Gross margin","ROI","KPI status",""],
      campaigns: [],
      params: { persist: true },
      status: "active",
      search: "",
      page: 1,
      loadindShow:false,
      totalPages: [],
      createModalId: "createDialog",
      editModalId: "editDialog",
      editedResource: {},
      duplicateResource: {},
      duplicateCampaignModelID: "duplicateCampaignModel",
      createpermission: this.$ability.can("create", subject(RESOURCE_NAME, {})),
      facebook_campaigns_list:[],
      google_campaigns_list:[]
    };
  },
  computed: {
     ...mapGetters("org",["org","default_currency"]),
     ...mapGetters("user", ["userProfile"]),  
  },
  methods: {
    ...mapActions("campaign", [
      LIST_CAMPAIGNS,
      //   UPDATE_CAMPAIGN,
      // DELETE_CAMPAIGN,
      DUPLICATE_CAMPAIGN,
      GET_FACEBOOK_CAMPAIGNS_LIST,
      GET_GOOGLE_CAMPAIGNS_LIST
      //   CREATE_CAMPAIGN,
    ]),
    ...mapActions("org", [GET_ORG]),
    //get capmain data
    initCampaigns(pageNumber = null) {
      pageNumber ? (this.page = pageNumber) : null;
      let options = {
        persist: null,
        q: this.search.length < 3 ? null : this.search,
        is_active:
          this.status == "active" ? 1 : this.status == "pending" ? 0 : null,
        is_archive:
          this.status == "expired" ? 1 : this.status == "pending" ? 0 : null,
        page: this.page,
        currency:this.default_currency
      }
      this.loadindShow = true
      this[LIST_CAMPAIGNS](options).then((res) => {
        this.campaigns = res;
        this.totalPages = Array(Math.ceil(res.count / 10))
          .fill(0)
          .map((e, i) => i + 1);
        console.log(this.campaigns);
      }).finally(()=>this.loadindShow=false)
    },
    //open duplicate alert model
    showDuplicateDialog(campaign) {
      this.duplicateResource = campaign;
      window.$(`#${this.duplicateCampaignModelID}`).modal("show");
    },
    //duplicate campaign functionality
    async duplicateCampaign() {
      window.$(`#${this.duplicateCampaignModelID}`).modal("hide");
      let hasPermission = this.$ability.can(
        "duplicate",
        subject(RESOURCE_NAME, this.duplicateResource)
      );
      if (!hasPermission) {
        this.$alertify.notify(
          "Active campaigns should not be duplicated.",
          "error",
          3
        );
      } else {
        await this[DUPLICATE_CAMPAIGN](this.duplicateResource.id).then(() => {
          this.$alertify.notify(
            "Campaign successfully duplicated.",
            "success",
            3
          );
          this.initCampaigns(1);
        });
      }
    },
    initFacebookCampaigns(){
      if(this?.org?.is_facebook_ads_connected){
        this[GET_FACEBOOK_CAMPAIGNS_LIST]().then((response)=>{
          this.facebook_campaigns_list=response
        }).catch((e)=>{
          this.$alertify.notify(
                e?.response?.data?.detail || "Some error occured with Facebook Ads connection, please try again",
                "error",
                3
              );
        })
      }
    },
    initGoogleCampaigns(){
      if(this?.org?.is_google_ads_connected)
        this[GET_GOOGLE_CAMPAIGNS_LIST]().then((response)=>{
          this.google_campaigns_list=response
        }).catch((e)=>{
          this.$alertify.notify(
                e?.response?.data?.detail || "Some error occured with Google Ads connection, please try again",
                "error",
                3
              );
        })
    },
    //open craete campaign model
    showCreateDialog() {
      window.$(`#${this.createModalId}`).modal("toggle");
    },
    //change status for filter
    changeStatus(state) {
      this.status = state;
    },
    //go to next page
    incrementpage() {
      this.page = this.page + 1;
      this.initCampaigns();
    },
    //go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.initCampaigns();
    },
    // go to perticular page
    setpage(page) {
      this.page = page;
      this.initCampaigns();
    },
  },
  async mounted() {
    this.initCampaigns();
    
    if(!this.org){
      await this[GET_ORG](this.userProfile.organization.id)
       this.initFacebookCampaigns()
       this.initGoogleCampaigns();
    } 
    else{ 
      this.initFacebookCampaigns()
      this.initGoogleCampaigns();
      }
  },
  watch: {
    status() {
      this.page = 1;
      this.initCampaigns();
    },

    search() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this.page = 1;
        this.initCampaigns();
      }
    },
  },
};
</script>
<style>
.dashboard-top-section .dts-box {
  background: #ffffff;
  padding: 40px;
}
.dashboard-top-section .dts-box span {
  color: #5e66fa;
  font-weight: 600;
  font-size: 54px;
  line-height: 43px;
  /* margin-bottom: 15px; */
}
.dashboard-top-section .dts-box h6 {
  margin: 0;
  color: #3f3f40;
  font-weight: 500;
  font-size: 18px;
}
.data-heading-wrp {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.data-heading-wrp .data-heading {
  position: relative;
}

.data-heading-wrp .data-heading-btn button {
  background: #5e66fa;
  border-radius: 4px;
  font-style: normal;
  font-size: 15px;
  line-height: 150%;
  border: 0;
  color: #fff;
  font-weight: 400;
  padding: 11px 19px;
}
.search-box-wrp {
  position: relative;
  min-width: 300px;
  display: inline-block;
  margin-right: 20px;
}
.search-box-wrp input {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #aaabad;
}
.search-box-wrp input::placeholder {
  color: #aaabad;
}
.tab-box-wrp {
  position: relative;
  display: inline-block;
}
.tab-box-wrp ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.tab-box-wrp ul li {
  background: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #3f3f40;
  list-style-type: none;
  border: 1px solid #e2e2e2;
  padding: 9px 20px;
  cursor: pointer;
}
.tab-box-wrp ul li:first-child {
  border-radius: 4px 0px 0px 4px;
}
.tab-box-wrp ul li:last-child {
  border-radius: 0px 4px 4px 0px;
}
.tab-box-wrp ul li.active {
  background: #5e66fa;
  color: #fff;
  border: 1px solid #5e66fa;
}
.table-wrp table tr:hover {
  background: #f6f6f7;
}
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
.sort-active {
  font-weight: bold;
  color: #000000;
  font-size: 12px;
}
.campaign-status-expired {
  border: none;
  width: 67px;
  height: 32px;
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #ec4424;
  background: rgba(236, 68, 36, 0.1);
  border-radius: 4px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}
.campaign-status-active-btn {
  border: none;
  width: 67px;
  height: 32px;
  background: rgba(11, 201, 132, 0.1);
  border-radius: 4px;
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #0bc984;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}
.campaign-status-pending {
  border: none;
  width: 67px;
  height: 32px;
  font-family: Lexend;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  color: #f29d4f;
  background: rgba(242, 157, 79, 0.1);
  border-radius: 4px;
  padding-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}
.action-btns {
  background: #fff;
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 36px;
  min-height: 36px;
}
.campaign-light{
  margin-top: 5px!important;
}
</style>