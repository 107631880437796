import { API_URL, authHeader } from "@/Core/helpers/utils";
import axios from "axios";

const RESOURCE_URL = `${API_URL}/api/v1/shipping_charges/`;

export default {
  get(token, params) {
    return axios.get(RESOURCE_URL, {
      params,
      headers: authHeader(token),
    });
  },
  create(token, obj) {
    return axios.post(RESOURCE_URL, obj, {
      headers: authHeader(token),
    });
  },
  getDetails(token, objId) {
    let url = `${RESOURCE_URL}${objId}/`;
    return axios.get(url, {
      headers: authHeader(token),
    });
  },
  delete(token, objId) {
    let url = `${RESOURCE_URL}${objId}/`;
    return axios.delete(url, {
      headers: authHeader(token),
    });
  },
  update(token, obj) {
    let url = `${RESOURCE_URL}${obj.id}/`;
    return axios.patch(url, obj, {
      headers: authHeader(token),
    });
  },
  shipping_charges_file_upload(token, file) {
    let params = new FormData();
    params.append("file", file);
    return axios.post(`${API_URL}/api/v1/upload_shipping_charges_file/`, params, {
      headers: authHeader(token),
    });
  },
};
