<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="{{modalId}}"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="modalId">{{ header_text }}</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors }"
          class="form-horizontal"
          action="#"
          ref="CampaignForm"
        >
          <div class="modal-body">
            <div class="form-group" :class="{ 'has-error': errors.name }">
              <label for="name"
                >Name<span class="required-field text-danger">*</span></label
              >
              <Field
                as="input"
                type="text"
                class="form-control"
                name="name"
                placeholder="Enter Campaign Name"
                v-model="formValues.name"
              />
              <div class="form-control-feedback">{{ errors.name }}</div>
            </div>

            <div
              class="form-group add-aditnoal"
              :class="{ 'has-error': errors.offer }"
              v-if="formValues.offer != 0"
            >
              <label for="offer"
                >Offer<span class="required-field text-danger">*</span></label
              >
              <Field v-model="formValues.offer" name="offer">
                <Multiselect
                  :id="modalId + '-offer'"
                  v-if="offersList"
                  valueProp="id"
                  label="name"
                  trackBy="name"
                  v-model="formValues.offer"
                  :options="[{ id: 0, name: '+ Add new offer' }, ...offersList]"
                  placeholder="please select an offer"
                  @select="setSelectedoffer"
                />
              </Field>
              <!-- <select class="form-control" >
                <option value="All">All</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Corporate">+ Corporate</option>
              </select> -->
              <div class="form-control-feedback">{{ errors.offer }}</div>
            </div>
            <div class="mb-3" v-if="formValues.offer && formValues.offer !== 0">
              <h6 class="mb-3 offer-header">Offer Details</h6>
              <table class="offer-details-table">
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{{ selectedOffer.name }}</td>
                  </tr>
                  <tr>
                    <td>Details</td>
                    <td>{{ selectedOffer.details }}</td>
                  </tr>
                  <tr>
                    <td>Articles</td>
                    <td>
                      <div
                        v-for="article in selectedOffer.articles"
                        :key="article.id"
                      >
                        {{ article.name }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Date Range</td>
                    <td>
                      {{ selectedOffer.date_range }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-show="formValues.offer == 0">
              <div
                class="d-flex align-item-center justify-content-between mb-3"
              >
                <h6 class="m-0 offer-header">New Offer Details</h6>
                <span class="offer-close" @click="clearOfferForm">
                  <!-- <img
                    src="../../../public/assets/images/cancle-icon.png"
                    alt=""
                  /> -->
                  <CrossIcon />
                </span>
              </div>
              <Form
                @submit="createOffer"
                :validation-schema="offer_schema"
                v-slot="{ errors }"
                class="form-horizontal offer-form"
                action="#"
                ref="offerForm"
              >
                <div class="modal-body offer-body-modal">
                  <div class="form-group" :class="{ 'has-error': errors.name }">
                    <label for="name"
                      >Name<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <Field
                      as="input"
                      type="text"
                      class="form-control"
                      name="name"
                      placeholder="Enter Offer Name"
                      v-model="offer_formvalues.name"
                    />
                    <div class="form-control-feedback">{{ errors.name }}</div>
                  </div>
                  <div
                    class="form-group"
                    :class="{ 'has-error': errors.details }"
                  >
                    <label for="details"
                      >Offer Details<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <Field
                      as="input"
                      type="text"
                      class="form-control"
                      name="details"
                      placeholder="Enter Offer details"
                      v-model="offer_formvalues.details"
                    />
                    <div class="form-control-feedback">
                      {{ errors.details }}
                    </div>
                  </div>

                  <div
                    class="form-group col-lg-6 offer-date"
                    :class="{
                      'has-error': errors.start_date || errors.end_date,
                    }"
                  >
                    <label for="date"
                      >Offer Date Range<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <input
                      id="icon-date"
                      type="text"
                      ref="date"
                      class="form-control"
                      placeholder="Select offer date range"
                    />
                    <div class="form-control-feedback">
                      {{ errors.start_date }}
                    </div>
                  </div>

                  <div
                    class="form-group"
                    :class="{ 'has-error': errors.articles }"
                  >
                    <label for="articles"
                      >Offer articles<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <Field v-model="offer_formvalues.articles" name="articles">
                      <Multiselect
                        v-model="offer_formvalues.articles"
                        mode="tags"
                        :minChars="3"
                        :resolveOnLoad="false"
                        :filterResults="false"
                        label="name"
                        valueProp="id"
                        :delay="0"
                        :searchable="true"
                        :options="fetchArticles"
                        placeholder="Select offer articles"
                        ref="article_select"
                      />
                      <!-- :selectedOptions="selectedOptions" -->
                    </Field>
                    <div class="form-control-feedback">
                      {{ errors.articles }}
                    </div>
                  </div>
                  <div class="form-group">
                    <Field
                      as="input"
                      type="hidden"
                      class="form-control"
                      name="start_date"
                      v-model="offer_formvalues.start_date"
                    />
                  </div>
                  <div class="form-group">
                    <Field
                      as="input"
                      type="hidden"
                      class="form-control"
                      name="end_date"
                      v-model="offer_formvalues.end_date"
                    />
                  </div>
                  <div class="form-group">
                    <Field
                      as="input"
                      type="hidden"
                      class="form-control"
                      name="detail"
                    />
                    <div class="form-control-feedback">{{ errors.detail }}</div>
                  </div>
                </div>
                <button
                  type="submit"
                  ref="submit"
                  class="save-btn"
                  v-show="false"
                >
                  create
                </button>
              </Form>
            </div>
            <div
              class="form-group add-aditnoal"
              :class="{ 'has-error': errors.audience }"
              v-if="formValues.audience != 0"
            >
              <label for="audience"
                >Audience</label
              >
              <Field v-model="formValues.audience" name="audience">
                <Multiselect
                  v-if="audienceList"
                  :id="modalId + '-audience'"
                  valueProp="id"
                  label="name"
                  trackBy="name"
                  v-model="formValues.audience"
                  :options="[
                    { id: 0, name: '+ Add new audience' },
                    ...audienceGroupList,
                  ]"
                  placeholder="please select an audience"
                />
              </Field>
              <div class="form-control-feedback">{{ errors.audience }}</div>
            </div>
            <div v-show="formValues.audience == 0">
              <div
                class="d-flex align-item-center justify-content-between mb-3"
              >
                <h6 class="m-0 offer-header">New Audience Details</h6>
                <span class="offer-close" @click="clearAudience">
                  <!-- <img
                    src="../../../public/assets/images/cancle-icon.png"
                    alt=""
                  /> -->
                  <CrossIcon />
                </span>
              </div>
              <div>
                <div class="contant-details">
                  <form class="profile-form">
                    <div class="row">
                      <div class="col-12">
                        <div
                          class="form-group"
                          :class="nameError || errMsg != '' ? 'has-error' : ''"
                        >
                          <label class="form-label w-100"
                            >Name<span class="required-field text-danger"
                              >*</span
                            ></label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Name"
                            v-model="audience.name"
                            @keyup="validateName"
                            @blur="validateName"
                          />
                          <span class="error">{{ errMsg }}</span>
                          <!-- <span class="error" v-if="errMsg!=''"
                        >{{errMsg}}</span
                      > -->
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="contant-details mt-3">
                  <form class="profile-form">
                    <div class="contant-header">
                      <h6>
                        <img
                          src="../../../public/assets/images/Members.png"
                          alt=""
                        />Customers
                      </h6>
                      <div class="members_view">
                        <p>
                          Customers selected: {{ audience.members.length }}
                          <span>(from {{ totalMembers }})</span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div
                          class="form-group"
                          :class="{ 'has-error': main_options_model_error }"
                        >
                          <label for="dropdownField">Preference </label>
                          <Field name="main_options">
                            <Multiselect
                              v-model="main_options_model_desc"
                              :options="main_options"
                              label="key"
                              valueProp="value"
                              placeholder="Select an option"
                            />
                            <span class="error">{{ main_options_model_error }}</span>
                          </Field>
                        </div>
                      </div>
                      <div class="col-lg-12" v-if="main_options_model_desc==='article'">
                        <div class="form-group" :class="{ 'has-error': pref_type_error }">
                          <label for="pref_type"
                            >Article Preference Type</label
                          >
                          <Field
                            v-model="pref_type"
                            name="pref_type"
                          >
                            <Multiselect
                              v-model="pref_type"
                              :options="pref_options"
                              label="key"
                              valueProp="value"
                              placeholder="Select article preference type"
                              @select="onPrefChange"
                              @close="onPrefChange"
                            />
                            <span class="error">{{ pref_type_error }}</span>
                          </Field>
                        </div>
                      </div>
                      <div class="col-lg-12" v-if="main_options_model_desc==='article'">
                        <div class="form-group">
                          <label for="articles"
                            >Article Preferences</label
                          >
                          <Field v-model="articles" name="articles">
                            <Multiselect
                              v-model="articles"
                              mode="tags"
                              :minChars="3"
                              :resolveOnLoad="false"
                              :filterResults="false"
                              label="name"
                              valueProp="id"
                              :delay="0"
                              :searchable="true"
                              :options="fetchArticles"
                              placeholder="Select articles"
                              ref="article_select"
                              @select="boughtArticleChanged"
                              @deselect="boughtArticleChanged"
                              @close='boughtArticleChanged'
                            />
                          </Field>
                        </div>
                      </div>
                      <div class="col-lg-12" v-if="main_options_model_desc==='product'">
                        <div class="form-group" :class="{ 'has-error': product_pref_type_error }">
                          <label for="apref_type"
                            >Product Preference Type</label
                          >
                          <Field
                            v-model="product_pref_type"
                            name="product_pref_type"
                          >
                            <Multiselect
                              v-model="product_pref_type"
                              :options="pref_options"
                              label="key"
                              valueProp="value"
                              placeholder="Select product preference type"
                              @select="onProductPrefChange"
                              @close="onProductPrefChange"
                            />
                            <span class="error">{{ product_pref_type_error }}</span>
                          </Field>
                        </div>
                      </div>
                      <div class="col-lg-12" v-if="main_options_model_desc==='product'">
                        <div class="form-group">
                          <label for="products"
                            >Product Preferences</label
                          >
                          <Field v-model="products" name="products">
                            <Multiselect
                              v-model="products"
                              mode="tags"
                              :minChars="3"
                              :resolveOnLoad="false"
                              :filterResults="false"
                              label="name"
                              valueProp="id"
                              :delay="0"
                              :searchable="true"
                              :options="fetchProducts"
                              placeholder="Select products"
                              ref="product_select"
                              @select="boughtProductChanged"
                              @deselect="boughtProductChanged"
                              @close='boughtProductChanged'
                            />
                          </Field>
                        </div>
                      </div>
                      
                      <!-- <div class="audience-checkbox">
                        <label class="checkbox-container"
                          >Bought all selected articles
                          <input
                            type="checkbox"
                            :checked="checkStatus"
                            @change="onCheckStatusChanged"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div> -->
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label class="form-label w-100">Gender</label>
                          <select
                            class="form-control custom-select"
                            v-model="gender"
                            @change="genderChanged"
                          >
                            <option value="All">All</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Corporate">Corporate</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-8">
                        <div class="form-group">
                          <label class="form-label w-100">Age</label>
                          <div class="slider_form">
                            <input
                              disabled
                              type="text"
                              class="form-control mr-2 ageSliderinput"
                              v-model="ageSlider[0]"
                            />
                            <Slider v-model="ageSlider" @change="ageChanged" />
                            <input
                              disabled
                              type="text"
                              class="form-control ml-2 ageSliderinput"
                              v-model="ageSlider[1]"
                            />
                          </div>
                        </div>
                      </div>
                       <div class="col-12">
                      <div class="form-group">
                        <label class="form-label w-100">Churn status</label>
                        <select
                          class="form-control custom-select"
                          v-model="churn_status"
                          @change="churnStatusChanged"
                        >
                          <option value="All">All</option>
                          <option value="churned">Churned</option>
                          <option value="possible_churn">Possible Churn</option>
                          <option value="no_churn">No Churn</option>
                          <option value="soon_to_churn">Soon To Churn</option>
                        </select>
                      </div>
                    </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="form-group" :class="{ 'has-error': errors.detail }">
              <Field
                as="input"
                type="hidden"
                class="form-control"
                name="detail"
              />
              <div class="form-control-feedback">{{ errors.detail }}</div>
            </div>
            <div
              class="form-group"
              :class="{ 'has-error': errors.articles }"
            >
              <label for="articles"
                >Facebook ads campaign
                </label
              >
              <Field v-model="formValues.facebook_campaign" name="facebok_campaign">
                <Multiselect
                  :id="modalId + 'fb'"
                  v-if="$parent.facebook_campaigns_list"
                  valueProp="id"
                  label="name"
                  trackBy="name"
                  :searchable="true"
                  v-model="formValues.facebook_campaign"
                  :options="$parent.facebook_campaigns_list"
                  placeholder="please select an facebook ad campaign"
                />
              </Field>
              <div class="form-control-feedback">
                {{ errors.articles }}
              </div>
            </div>
            <div
              class="form-group"
              :class="{ 'has-error': errors.articles }"
            >
              <label for="articles"
                >Google ads campaign
                </label
              >
              <Field v-model="formValues.google_campaign_data" name="google_campaign">
                <Multiselect
                  :id="modalId + 'google'"
                  v-if="$parent.google_campaigns_list"
                  label="campaign_name"
                  valueProp="campaign_id"
                  trackBy="campaign_name"
                  :searchable="true"
                  :object = "true"
                  v-model="formValues.google_campaign_data"
                  :options="$parent.google_campaigns_list"
                  placeholder="please select an google ad campaign"
                />
              </Field>
              <div class="form-control-feedback">
                {{ errors.articles }}
              </div>
            </div>
            <div class="form-group" :class="{ 'has-error': errors.offline_budget }">
              <label for="offline_budget"
                >Offline Marketing Budget</label
              >
              <div class="d-flex align-items-center">
              <Field
                as="input"
                type="number"
                class="form-control "
                name="offline_budget"
                placeholder="Enter Offline Marketing Budget"
                v-model="formValues.offline_budget"
              />
              <span class="position-absolute currency-title">{{default_currency}}</span>
              </div>
              <div class="form-control-feedback">{{ errors.offline_budget }}</div>
            </div>
            <div
              class="form-group"
              :class="{ 'has-error': errors.reference_group }"
            >
              <label for="reference_group"
                >Reference Group</label>
              <Field v-model="formValues.reference_group" name="reference_group">
                <Multiselect
                  v-if="audienceList"
                  :id="modalId + '-reference_group'"
                  valueProp="id"
                  label="name"
                  trackBy="name"
                  v-model="formValues.reference_group"
                  :options="[...referenceGroupList]"
                  placeholder="please select an reference group"
                />
              </Field>
              <div class="form-control-feedback">{{ errors.reference_group }}</div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="save-btn">{{ btn_name }}</button>
            <button
              type="button"
              class="cancle-btn"
              data-bs-dismiss="modal"
              v-on:click="clearForm()"
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { mapGetters, mapActions } from "vuex";
import Slider from "@vueform/slider";
import Multiselect from "@vueform/multiselect";
import { DATE_FORMAT, DATE_REGEX } from "@/Core/helpers/utils";
import {
  UPDATE_CAMPAIGN,
  CREATE_CAMPAIGN,
  LIST_OFFERS,
  LIST_AUDIENCE,
  CREATE_OFFER,
  LIST_ARTICLES,
  LIST_PRODUCTS,
  LIST_CUSTOMERS,
  CREATE_AUDIENCE,
} from "@/Core/store/action-types";
import ServerErrorHandler from "@/Core/helpers/ServerErrorHandler";
import CrossIcon from "../../icons/cross.vue";
const DEFAULT_AGE_SLIDER = [0, 100];

export default {
  name: "CampaignModal",
  components: {
    Form,
    Field,
    Multiselect,
    CrossIcon,
    Slider,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
    campaignToEdit: {
      required: false,
      type: Object,
    },
    modalType: {
      required: true,
      type: Number,
    },
    initCampaigns: {
      required: false,
      type: Function,
    },
  },
  computed: {
    ...mapGetters("user", ["userProfile"]),
    ...mapGetters("offer", ["offersList"]),
    ...mapGetters("audience", ["audienceList"]),
    ...mapGetters("org",["org","default_currency"]),

    getOfferByID() {
      return this.offersList.find((offer) => offer.id == 19);
    },
    audienceGroupList(){
      return this.audienceList.filter((audience) =>!audience.is_reference_group)
    },
    referenceGroupList(){
      return this.audienceList.filter((audience) =>audience.is_reference_group)
    }
  },
  watch: {
    campaignToEdit: function (obj) {
      this.originalResource = {
        name: obj.name,
        offer: obj.offer.id,
        audience: obj.audience.id,
      };
      this.formValues = Object.assign({}, this.originalResource);
    },
    main_options_model(newVal) {
      if (newVal === 'product') {
        // Set predefined values for products
        this.products = [];
        this.product_pref_type = '';
      } else if (newVal === 'article') {
        // Set predefined values for articles
        this.articles = [];
        this.pref_type = '';
      }
    },
    // gender() {
    //  this.initCustomerList();
    // },
    // articles() {
    //     this.initCustomerList();
    // },
  },
  methods: {
    ...mapActions("campaign", [UPDATE_CAMPAIGN, CREATE_CAMPAIGN]),
    ...mapActions("offer", [LIST_OFFERS, CREATE_OFFER]),
    ...mapActions("audience", [LIST_AUDIENCE, CREATE_AUDIENCE]),
    ...mapActions("article", [LIST_ARTICLES]),
    ...mapActions("product", [LIST_PRODUCTS]),
    ...mapActions("customer", [LIST_CUSTOMERS]),

    onSubmit(values, actions) {
      if (this.modalType == 1) this.updateCampaign(values, actions);
      if (this.modalType == 2) this.onCreateCampaign(values, actions);
    },
    updateCampaign(obj, actions) {
      obj.id = this.campaignToEdit.id;
      this[UPDATE_CAMPAIGN](obj)
        .then(() => {
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify("Campaign successfully updated.", "success", 3);
          this.clearForm();
        })
        .catch(function (error) {
          ServerErrorHandler(actions, error.response);
          throw new Error("");
        });
    },
    genderChanged() {
       this.initCustomerList();
    },
    churnStatusChanged(){
      this.initCustomerList();
    },
    boughtArticleChanged() {
      if (this.articles.length > 0 && !this.pref_type) {
        this.audience.members = [];
        this.pref_type_error = "please select articles preference type";
      } else {
        this.pref_type_error = "";
        this.initCustomerList();
      }
    },
    boughtProductChanged() {
      if (this.products.length > 0 && !this.product_pref_type) {
        this.audience.members = [];
        this.product_pref_type_error = "please select products preference type";
      } else {
        this.product_pref_type_error = "";
        this.initCustomerList();
      }
    },
    onPrefChange(){
      if (this.articles.length > 0 && !this.pref_type) {
        this.audience.members = [];
        this.pref_type_error = "please select articles preference type";
      } else {
        this.pref_type_error = "";
        if (this.articles.length > 0) this.initCustomerList();
      }
    },
    onProductPrefChange(){
      if (this.products.length > 0 && !this.product_pref_type) {
        this.audience.members = [];
        this.product_pref_type_error = "please select products preference type";
      } else {
        this.product_pref_type_error = "";
        if (this.products.length > 0) this.initCustomerList();
      }
    },
    onCreateCampaign(actions) {
      if (this.formValues.offer == 0) {
        this.$refs.submit.click();
      } else if (this.formValues.audience == 0) {
        this.createAudience();
      } else {
        this.CreateCampaign(actions);
      }
    },
    createOffer(obj, actions) {
      this[CREATE_OFFER]({ obj, actions })
        .then((res) => {
          this.formValues.offer = res.id;
          if (this.formValues.audience == 0) {
            this.createAudience();
          } else {
            this.CreateCampaign();
          }
        })
        .catch(function (error) {
          ServerErrorHandler(actions, error.response);
          throw new Error("");
        });
    },
    clearOfferForm(actions) {
      this.formValues.offer = null;
      this.offer_formvalues = {};
      this.$refs.article_select.clear();
      if (actions) actions.resetForm();
      else this.$refs.offerForm.resetForm();
      this.setDateRange();
    },
    async fetchArticles(query) {
      return await this[LIST_ARTICLES]({ params: { name: query } });
    },
    async fetchProducts(query) {
      return await this[LIST_PRODUCTS]({ params: { name: query } });
    },
    onDateChange(from_date, to_date) {
      this.offer_formvalues.start_date = from_date.format(DATE_FORMAT);
      this.offer_formvalues.end_date = to_date.format(DATE_FORMAT);

      this.setDateRange(
        this.offer_formvalues.start_date,
        this.offer_formvalues.end_date
      );
    },

    setDateRange(start_date, end_date) {
      var dateRange =
        start_date && end_date ? `${start_date} - ${end_date}` : "";
      this.datepicker.val(dateRange);
    },
    clearForm(actions) {
      this.formValues = this.initForm();
      if (actions) actions.resetForm();
      else this.$refs.CampaignForm.resetForm();
    },
    setSelectedoffer() {
      this.selectedOffer = this.offersList.find(
        (offer) => offer.id == this.formValues.offer
      );
    },
    initForm() {
      if (this.originalResource) {
        return Object.assign({}, this.originalResource);
      }

      return {
        name: "",
        offer: null,
        audience: null,
      };
    },
    initCustomerList(count = false) {
      if (count) {
        let options = {
          persist: false,
          params: {
            page: 1,
          },
        };
        this[LIST_CUSTOMERS](options)
          .then((resp) => {
            this.totalMembers = resp.count;
          })
          .catch((err) => {
            console.log("error", err);
          });
      } else {
      let options = {
        params: {
          page: count ? 1 : null,
          age_min: this.ageSlider[0] > 0 ? this.ageSlider[0] : null,
          age_max: this.ageSlider[1] < 100 ? this.ageSlider[1] : null,
          sex: this.gender != "All" ? this.gender : null,
          churn_status: this.churn_status != "All" ? this.churn_status : null,
          pref_type: this.articles.length != 0 ? this.pref_type : null,
          prefs: this.articles.length != 0 ? this.articles : null,
          product_pref_type: this.products.length != 0 ? this.product_pref_type : null,
          product_prefs: this.products.length != 0 ? this.products : null,
        },
      };
      if (Object.values(options.params).some((v) => v))
      this[LIST_CUSTOMERS](options)
        .then((resp) => {
            this.audience.members = [];
            resp.map((member) => this.audience.members.push(member.id));
        })
        .catch((err) => {
          console.log("error", err);
        });
        else {
          (this.audience.members = [])
        }
      }
    },
    ageChanged() {
      this.initCustomerList();
    },
    clearAudience() {
      this.formValues.audience = null;
      this.page_model = 1;
      this.audience.name = "";
      this.audience.members = [];
      this.ageSlider = DEFAULT_AGE_SLIDER;
      this.gender = "All";
      this.articles = [];
      this.products = [];
      this.nameError = false;
    },
    createAudience() {
      this.errMsg = "";
      if (!this.audience.name) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audience.name.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else if (this.audience.members.length <= 0) {
        this.nameError = false;
        this.$alertify.notify("Please select customers", "error", 3);
      } else {
        this.nameError = false;
        let payload = {
          members: this.audience.members,
          name: this.audience.name,
          articles: this.main_options_model_desc === 'article' ? this.articles : [],
          article_pref_type: this.main_options_model_desc === 'article' ? this.pref_type : '',
          products: this.main_options_model_desc !== 'article' ? this.products : [],
          product_pref_type: this.main_options_model_desc !== 'article' ? this.product_pref_type : '',
          main_options_model: this.main_options_model_desc,
          gender: this.gender,
          churn_status: this.churn_status,
          age_min: this.ageSlider[0],
          age_max: this.ageSlider[1],
        };

        this[CREATE_AUDIENCE](payload)
          .then((res) => {
            this.formValues.audience = res.id;
            this.CreateCampaign();
          })
          .catch((e) => {
            this.errMsg = e.message;
            this.$alertify.notify(e.message, "error", 3);
          });
      }
    },
    CreateCampaign(actions = null) {
      this.formValues.offline_budget_currency=this.default_currency
      this[CREATE_CAMPAIGN](this.formValues)
        .then(() => {
          this.initCampaigns(1);
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify("Campaign successfully created.", "success", 3);
          this.clearForm();
          this.clearOfferForm(actions);
          this.clearAudience();
        })
        .catch(function (error) {
          if (actions) ServerErrorHandler(actions, error.response);
          throw new Error("");
        });
    },
    validateName() {
      this.errMsg = "";
      if (!this.audience.name) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.audience.name.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else this.nameError = false;
    },
    onCheckStatusChanged() {
      this.checkStatus = !this.checkStatus;
      this.initCustomerList();
    },
  },

  beforeCreate() {
    if (this.modalType == 1) {
      // Edit mode
      this.btn_name = "Edit";
      this.header_text = "Edit campaign";
    } else if (this.modalType == 2) {
      // Create mode
      this.btn_name = "Create";
      this.header_text = "Create new campaign";
    }
  },

  mounted() {
    if (this.modalType == 2) {
      this[LIST_OFFERS]({ persist: true });
      this.initCustomerList(true);
      this[LIST_AUDIENCE](null);
    }
    console.log(this.$refs);
    this.datepicker = window.$(this.$refs.date);
    this.datepicker.daterangepicker(
      {
        autoUpdateInput: false,
      },
      this.onDateChange
    );
  },
  data() {
    let offerErr = "Offer is required";
    let audienceErr = "Audience is required";
    return {
      nameError: false,
      errMsg: "",
      totalMembers: 0,
      gender: "All",
      churn_status: 'All',
      articles: [],
      products: [],
      articles_most_likely_buy: [],
      formValues: this.initForm(),
      offer_formvalues: {},
      ageSlider: [...DEFAULT_AGE_SLIDER],
      audience_formvalues: {},
      checkStatus: false,
      selectedOffer: {},
      pref_options: [
        { value: "has_bought", key: "Has bought" },
        {
          value: "likely_to_buy",
          key: "Likely to buy ",
        },
        {
          value: "similar_articles",
          key: "Similar articles",
        },
        {
          value: "likely_to_buy_again",
          key: "Likely to buy Again",
        },
        { value: "all_fields", key: "All Fields" },
      ],
      main_options_model_desc: "",
      pref_type: "",
      product_pref_type: "",
      pref_type_error: "",
      product_pref_type_error: "",
      main_options_model_error: "",
      main_options: [
        { value: "product", key: "Product" },
        { value: "article", key: "Article" },
      ],
      audience: {
        name: "",
        members: [],
      },
      schema: yup.object().shape({
        name: yup
          .string()
          .min(6, "Name must be at least 6 characters")
          .required("Please enter campaign name"),

        offline_budget:yup.lazy(() => {
          if (this.formValues.offline_budget) {
            return yup.string().matches(/^[0-9]/,"offline marketing budget is not valid");
          }
          return yup.string().notRequired();
        }),
        offer: yup.lazy(() => {
          if (this.offersList)
            return yup
              .number()
              .oneOf([...this.offersList.map((o) => o.id), 0], offerErr);
          else return yup.number();
        }),
        audience: yup.lazy(() => {
          if (this.audienceList && this.formValues.audience)
            return yup
              .number()
              .oneOf([...this.audienceList.map((a) => a.id), 0], audienceErr)
          else return yup.number().nullable().notRequired();
        }),
        reference_group: yup.lazy(() => {
          if (this.audienceList && this.formValues.reference_group)
            return yup
              .number()
              .oneOf([...this.audienceList.map((a) => a.id), 0], 'invalid reference group')
          else return yup.number().nullable().notRequired();
        }),
        detail: yup
          .string() // use it for backend errors
          .nullable()
          .notRequired(),
      }),
      offer_schema: yup.object().shape({
        name: yup
          .string()
          .min(6, "Name must be at least 6 characters")
          .required("Please enter offer name"),
        details: yup
          .string()
          .min(6, "Offer details must be at least 6 characters")
          .required("Please enter offer details"),
        articles: yup.array().min(1).required("Please select article to offer"),
        products: yup.array().min(1).required("Please select product to offer"),
        start_date: yup
          .string()
          .matches(DATE_REGEX, "Please select valid date")
          .required("Please select offer date"),
        end_date: yup.string().matches(DATE_REGEX),
        detail: yup
          .string() // use it for backend errors
          .nullable()
          .notRequired(),
      }),
    };
  },
};
</script>
