<template>
  <!-- Register modal window -->
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createDialogLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createDialogLabel">
            Create Shipping Charge
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ShippingChargeForm :onSubmit="onSubmit" :isEditMode="false" :onFileUpload="HandleShippingFileUpload" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE_SHIPPING_CHARGE,SHIPPING_CHARGES_FILE_UPLOAD } from "@/Core/store/action-types";
import {getCountryIdByName} from "../../Core/helpers/countryList"
// import { UserRoleMixin } from '@/Core/mixins/UserRoleMixin';
import ShippingChargeForm from "./ShippingChargeForm.vue";

export default {
  name: "Registration",
  components: {
    ShippingChargeForm,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
  },
  methods: {
    ...mapActions("shipping", [CREATE_SHIPPING_CHARGE,SHIPPING_CHARGES_FILE_UPLOAD]),

    onSubmit(values, actions) {
      values.country_code = values.country_name?getCountryIdByName(values.country_name):null
      this[CREATE_SHIPPING_CHARGE](values)
        .then(() => {
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify(
            "shipping charge successfully created.",
            "success",
            3
          );
          actions.resetForm();
          this.$parent.initShippingCharge(1);
        })
        .catch((e) => {
          // this.$alertify.notify(
          //   "sales channel with this reg number already exists.",
          //   "error",
          //   3
          // );
          
          this.errMsg = e.message;
          actions.setFieldError('detail', e.message || 'something went wrong');
        });
    },
    HandleShippingFileUpload(file){
      if (file) {
        console.log("shippingChargesFile",file)
        this.isLoading=true
        this[SHIPPING_CHARGES_FILE_UPLOAD](file)
          .then(() => {
            window.$(`#${this.modalId}`).modal("hide");
            this.$alertify.notify(
              "Shipping file Uploaded successfully",
              "success",
              3
            );

            this.$parent.initShippingCharge(1);
            // this.articleFile = null;
          })
          .catch((e) => {
            // this.errMsg = e.message;
            console.log(e.message)
              this.$alertify.notify(
              e.message || "There is some error, please check your file",
              "error",
              3
            );
            // actions.setFieldError('detail', e.message || 'something went wrong');
          })
          .finally(()=>{
            this.isLoading=false
          })
      }
    }
    
  },

  data() {
    return {
      isLoading:true
    };
  },
};
</script>