import axios from "axios";
export default async function currencyConverter(
  fromCurrency,
  toCurrency,
  value
) {
  const result = await axios
    .get(
      `https://api.exchangerate.host/convert?from=${fromCurrency}&to=${toCurrency}&amount=${value}`
    )
    // .then((res) => res.json())
    .then((res) => {
      // const new_rate = res.rates[toCurrency];
      // // const rate = `1 ${fromCurrency} = ${new_rate} ${toCurrency}`;
      // const output_amount= (value * res.result).toFixed(2);
      return res.data.result;
    });
  return result;
}
//   https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${fromCurrency}.json`
// https://cdn.jsdelivr.net/gh/fa0/currency-api@1/latest/currencies/usd/sek.jsonwazahmed
