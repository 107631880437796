<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>

    <MainMenu />
    <TopBar
      title="Companies"
      :author="{
        home: org ? org?.name : '',
        cart: 'Companies',
      }"
    />
    <OrgDetailsGrid />
  </div>
</template>
<script>
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
import OrgDetailsGrid from  "./components/orgDetailsGrid.vue"

export default {
  name: "OrgDetails",
  components: {
    MainMenu,
    TopBar,
    Loading,
    OrgDetailsGrid
  },
  computed: {
    ...mapGetters("org", ["org"]),
  },
  data() {
    return {
      fullPage: true,
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>