<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.25 7C10.25 7.9665 11.0335 8.75 12 8.75C12.9665 8.75 13.75 7.9665 13.75 7C13.75 6.0335 12.9665 5.25 12 5.25C11.0335 5.25 10.25 6.0335 10.25 7ZM12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.99988 15.625C7.6547 15.625 7.37045 15.3436 7.41654 15.0016C7.72099 12.7421 9.65699 11 11.9999 11C14.3428 11 16.2788 12.7421 16.5832 15.0016C16.6293 15.3436 16.3451 15.625 15.9999 15.625C15.6547 15.625 15.3809 15.3426 15.3177 15.0033C15.0259 13.4363 13.6514 12.25 11.9999 12.25C10.3483 12.25 8.97382 13.4363 8.68202 15.0033C8.61883 15.3426 8.34506 15.625 7.99988 15.625Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.375 19C7.375 18.6548 7.65482 18.375 8 18.375H16C16.3452 18.375 16.625 18.6548 16.625 19C16.625 19.3452 16.3452 19.625 16 19.625H8C7.65482 19.625 7.375 19.3452 7.375 19Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 1.25H4C2.48122 1.25 1.25 2.48122 1.25 4V20C1.25 21.5188 2.48122 22.75 4 22.75H20C21.5188 22.75 22.75 21.5188 22.75 20V4C22.75 2.48122 21.5188 1.25 20 1.25ZM4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4Z"
      fill="#49494A"
    />
  </svg>
</template>

<script>
export default {
  name: "MembersIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>