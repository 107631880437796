<template>
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0647 25.3145C16.535 25.3199 16.007 25.5246 15.6028 25.9288C14.7837 26.7479 14.7837 28.0761 15.6028 28.8952C16.422 29.7144 17.7501 29.7144 18.5693 28.8952C18.9735 28.4911 19.1782 27.9631 19.1836 27.4334M18.6917 22.3457L27.6454 13.3919L30.4352 13.6391L30.8636 15.7809C30.9762 16.3438 30.5927 16.8857 30.0244 16.9669L29.5047 17.0412C28.7012 17.156 28.1044 17.8441 28.1044 18.6557L28.1044 19.0005C28.1044 19.9013 27.3742 20.6315 26.4735 20.6315L26.3451 20.6315C25.4444 20.6315 24.7142 21.3617 24.7142 22.2624V22.3908C24.7142 23.2915 23.984 24.0217 23.0832 24.0217H22.9548V24.0217C22.4673 24.0217 22.0748 24.4218 22.0841 24.9092L22.098 25.6449M22.098 25.6449C21.8953 25.0661 21.5891 24.5155 21.1795 24.0217C21.0744 23.895 20.9625 23.7721 20.8437 23.6533C18.7738 21.5833 15.4176 21.5833 13.3477 23.6533C11.2777 25.7233 11.2777 29.0794 13.3477 31.1494C15.4176 33.2194 18.7738 33.2194 20.8437 31.1494C22.3323 29.6608 22.7504 27.5071 22.098 25.6449Z"
      stroke="#5E66FA"
      stroke-width="1.75"
    />
    <path
      d="M8.5 5C6.567 5 5 6.567 5 8.5V18C5 20.2091 3.20914 22 1 22C3.20914 22 5 23.7909 5 26V35.5C5 37.433 6.567 39 8.5 39"
      stroke="#49494A"
      stroke-width="1.75"
    />
    <path
      d="M35.5 5C37.433 5 39 6.567 39 8.5V18C39 20.2091 40.7909 22 43 22C40.7909 22 39 23.7909 39 26V35.5C39 37.433 37.433 39 35.5 39"
      stroke="#49494A"
      stroke-width="1.75"
    />
  </svg>
</template>

<script>
export default {
  name: "ApiIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>