<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 1.25H2C1.58579 1.25 1.25 1.58579 1.25 2V8.5C1.25 8.91421 1.58579 9.25 2 9.25H8.5C8.91421 9.25 9.25 8.91421 9.25 8.5V2C9.25 1.58579 8.91421 1.25 8.5 1.25ZM2 0C0.895431 0 0 0.89543 0 2V8.5C0 9.60457 0.89543 10.5 2 10.5H8.5C9.60457 10.5 10.5 9.60457 10.5 8.5V2C10.5 0.895431 9.60457 0 8.5 0H2Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 1.25H15.5C15.0858 1.25 14.75 1.58579 14.75 2V8.5C14.75 8.91421 15.0858 9.25 15.5 9.25H22C22.4142 9.25 22.75 8.91421 22.75 8.5V2C22.75 1.58579 22.4142 1.25 22 1.25ZM15.5 0C14.3954 0 13.5 0.89543 13.5 2V8.5C13.5 9.60457 14.3954 10.5 15.5 10.5H22C23.1046 10.5 24 9.60457 24 8.5V2C24 0.895431 23.1046 0 22 0H15.5Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5 14.75H2C1.58579 14.75 1.25 15.0858 1.25 15.5V22C1.25 22.4142 1.58579 22.75 2 22.75H8.5C8.91421 22.75 9.25 22.4142 9.25 22V15.5C9.25 15.0858 8.91421 14.75 8.5 14.75ZM2 13.5C0.895431 13.5 0 14.3954 0 15.5V22C0 23.1046 0.89543 24 2 24H8.5C9.60457 24 10.5 23.1046 10.5 22V15.5C10.5 14.3954 9.60457 13.5 8.5 13.5H2Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 14.75H15.5C15.0858 14.75 14.75 15.0858 14.75 15.5V22C14.75 22.4142 15.0858 22.75 15.5 22.75H22C22.4142 22.75 22.75 22.4142 22.75 22V15.5C22.75 15.0858 22.4142 14.75 22 14.75ZM15.5 13.5C14.3954 13.5 13.5 14.3954 13.5 15.5V22C13.5 23.1046 14.3954 24 15.5 24H22C23.1046 24 24 23.1046 24 22V15.5C24 14.3954 23.1046 13.5 22 13.5H15.5Z"
      fill="#49494A"
    />
  </svg>
</template>

<script>
export default {
  name: "DashboardIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>