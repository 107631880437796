import { API_URL, authHeader } from "@/Core/helpers/utils";
import axios from "axios";

const RESOURCE_URL = `${API_URL}/api/v1/audience/`;

export default {
  get(token, params) {
    return axios.get(RESOURCE_URL, {
      headers: authHeader(token),
      params: params,
    });
  },
  get_audience_details(token, id) {
    let url = `${RESOURCE_URL}${id}/`;
    return axios.get(url, {
      headers: authHeader(token),
    });
  },
  update(token, obj) {
    let url = `${RESOURCE_URL}${obj.id}/`;
    return axios.patch(url, obj, {
      headers: authHeader(token),
    });
  },
  create_facebook_audience(token,obj) {
    let url = `${RESOURCE_URL}${obj.id}/create_facebook_custom_audience/`;
    return axios.post(url,obj, {
      headers: authHeader(token),
    });
  },
  update_facebook_audience(token,obj) {
    let url = `${RESOURCE_URL}${obj.id}/update_facebook_custom_audience/`;
    return axios.patch(url,obj, {
      headers: authHeader(token),
    });
  },
  delete_facebook_audience(token,obj) {
    let url = `${RESOURCE_URL}${obj.id}/delete_facebook_custom_audience/`;
    return axios.delete(url,{
      headers: authHeader(token),
    });
  },
  create(token, obj) {
    return axios.post(RESOURCE_URL, obj, {
      headers: authHeader(token),
    });
  },
  delete(token, objId) {
    let url = `${RESOURCE_URL}${objId}/`;
    return axios.delete(url, {
      headers: authHeader(token),
    });
  },
  get_audience_member(token, id, params) {
    let url = `${RESOURCE_URL}members/${id}`;
    return axios.get(url, {
      headers: authHeader(token),
      params: params,
    });
  },
  get_top_performing_audiences(token, params) {
    let url = `${API_URL}/api/v1/top_performing_audience/`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
  send_email(token, obj) {
    return axios.post(`${API_URL}/api/v1/orgs/send_email/`, obj, {
      headers: authHeader(token),
    });
  }
};
