<template>
  <!-- Log In page -->
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-12 align-self-center">
        <div class="">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-5 mx-auto">
                <div class="media mb-5 justify-content-center">
                  <img
                    src="../../public/assets/images/logo-sm.png"
                    height="55"
                    alt="logo"
                    class="auth-logo"
                  />
                </div>
                <div class="card">
                  <div class="card-body">
                    <div class="forgot-title-box">
                      <h2>Reset your password</h2>
                      <p>Please set the new password for your account</p>
                    </div>
                    <Form
                      @submit="onSubmit"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                      class="form-horizontal auth-form my-4"
                      action="index.html"
                    >
                      <!--end form-group-->

                      <div
                        class="form-group"
                        :class="{ 'has-error': errors.new_password }"
                      >
                        <label class="form-label w-100">New password</label>
                        <div class="position-relative">
                          <Field
                            as="input"
                            :type="[
                              showPassword.indexOf(1) > 0 ? 'text' : 'password',
                            ]"
                            class="form-control"
                            name="new_password"
                            placeholder="Enter new password"
                          />
                          <div class="control">
                            <button
                              type="button"
                              class="button"
                              @click="toggleShow(1)"
                            >
                              <span
                                class="eye-iocon"
                                :class="{
                                  'eye-off': showPassword.indexOf(1) > 0,
                                  'eye-on': showPassword.indexOf(2) < 0,
                                }"
                              >
                                <img
                                  src="@/../public/assets/images/eyeicon.png"
                                  alt=""
                                />
                              </span>
                            </button>
                          </div>
                        </div>
                        <div class="form-control-feedback">
                          {{ errors.new_password }}
                        </div>
                      </div>
                      <div
                        class="form-group"
                        :class="{ 'has-error': errors.confirm_password }"
                      >
                        <label class="form-label w-100">Confirm password</label>
                        <div class="position-relative">
                          <Field
                            as="input"
                            :type="[
                              showPassword.indexOf(2) > 0 ? 'text' : 'password',
                            ]"
                            class="form-control"
                            name="confirm_password"
                            placeholder="Enter Confirm Password"
                          />
                          <div class="control">
                            <button
                              type="button"
                              class="button"
                              @click="toggleShow(2)"
                            >
                              <span
                                class="eye-iocon"
                                :class="{
                                  'eye-off': showPassword.indexOf(2) > 0,
                                  'eye-on': showPassword.indexOf(2) < 0,
                                }"
                              >
                                <img
                                  src="@/../public/assets/images/eyeicon.png"
                                  alt=""
                                />
                              </span>
                            </button>
                          </div>
                        </div>
                        <div class="form-control-feedback">
                          {{ errors.confirm_password }}
                        </div>
                      </div>
                      <!--end form-group-->

                      <div
                        class="form-group"
                        :class="{ 'has-error': errors.detail }"
                      >
                        <Field
                          as="input"
                          type="hidden"
                          class="form-control"
                          name="detail"
                        />
                        <div class="form-control-feedback">
                          {{ errors.detail }}
                        </div>
                      </div>
                      <!--end form-group-->

                      <div class="form-group mb-0 row">
                        <div class="col-12 mt-2 d-flex align-items-center">
                          <button
                            class="
                              w-auto
                              btn btn-block
                              waves-effect waves-light
                              login-button
                            "
                            type="submit"
                          >
                            Set new password
                          </button>
                          <router-link to="/login" class="login-page-link"
                            >Sign in</router-link
                          >
                        </div>
                        <!--end col-->
                      </div>
                      <!--end form-group--> </Form
                    ><!--end form-->
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>

            <!--end row-->
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- End Log In page -->
</template>

<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";
import { mapActions } from "vuex";

import { RESET_PASSWORD } from "@/Core/store/action-types";

export default {
  name: "ChangePassword",
  components: {
    Form,
    Field,
  },
  mixins: [UserRoleMixin],
  methods: {
    ...mapActions("user", [RESET_PASSWORD]),

    onSubmit(values, actions) {
      console.log("egfewgfeg", values);
      let id = this?.$route?.params?.id;
      let token = this?.$route?.params?.token;
      console.log(id, token);
      this[RESET_PASSWORD]({ values, actions, token, id })
        .then(() => {
          this.$alertify.notify(`password changed successfully.`, "success", 3);
          // got to the login
          this.$router.push("/login");
        })
        .catch(() => {}); //stay on a page
    },
    toggleShow(id) {
      let i = this.showPassword.indexOf(id);
      if (i > 0) {
        this.showPassword.splice(i, 1);
      } else {
        this.showPassword.push(id);
      }
    },
  },
  data() {
    const schema = yup.object().shape({
      new_password: yup
        .string()
        .min(6, "Password must be at least 6 characters")
        .required("New password is required"),
      confirm_password: yup
        .string()
        .oneOf([yup.ref("new_password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      detail: yup
        .string() // use it for backend errors
        .nullable()
        .notRequired(),
    });

    return {
      schema,
      showPassword: [0],
    };
  },
};
</script>
<style scoped>
.login-button {
  background-color: #5e66fa;
  color: #ffffff;
}
.login-button:hover {
  background-color: #727aff;
  color: #fff;
}
.forgot-title-box {
  text-align: center;
  padding-top: 15px;
}

.forgot-title-box h2 {
  color: #3f3f40;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}

.forgot-title-box p {
  color: #3f3f40;
  font-weight: 400;
  font-size: 14px;
}
.login-page-link {
  margin-left: 35px;
  color: #3f3f40;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}
/* .form-group .control {position: absolute;right: 10px;bottom: 15px;}

.form-group .control button {padding: 0;background: transparent;border: none;}

.form-group {position: relative;} */
.control {
  position: absolute;
  /* top: 50%; */
  transform: translateY(-225%);
  right: 17px;
}
.control .button {
  border: none;
  background: transparent;
  padding: 0;
  margin-right: 5px;
}
.control .button .eye-iocon.eye-off {
  position: relative;
}
.control .button .eye-iocon.eye-off:before {
  content: "";
  position: absolute;
  width: 21px;
  height: 1px;
  background: black;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate3d(1, 1, 1, -45deg);
  top: 50%;
}
</style>


