<template>
  <svg
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 1.1118C0 0.766623 0.279822 0.486801 0.625 0.486801L3.88508 0.486814C4.18714 0.486814 4.44597 0.702829 4.5 1.00001L5.14661 4.375H23.3851C23.5704 4.375 23.7462 4.45725 23.8649 4.59954C23.9837 4.74182 24.0332 4.92947 24 5.11181L22.2986 14.4696C22.0716 15.7177 20.9846 16.625 19.7159 16.625H8.29415C7.02553 16.625 5.93843 15.7177 5.71149 14.4696L3.36347 1.73681L0.625 1.7368C0.279822 1.7368 0 1.45698 0 1.1118ZM6.94133 14.246C7.0602 14.8998 7.62963 15.375 8.29415 15.375H19.7159C20.3804 15.375 20.9499 14.8998 21.0687 14.246L22.6362 5.625H5.37388L6.94133 14.246Z"
      fill="#49494A"
    />
    <path
      d="M10.625 19.5C10.625 20.3284 9.95343 21 9.125 21C8.29657 21 7.625 20.3284 7.625 19.5C7.625 18.6716 8.29657 18 9.125 18C9.95343 18 10.625 18.6716 10.625 19.5Z"
      fill="#49494A"
    />
    <path
      d="M20.5 19.5C20.5 20.3284 19.8284 21 19 21C18.1716 21 17.5 20.3284 17.5 19.5C17.5 18.6716 18.1716 18 19 18C19.8284 18 20.5 18.6716 20.5 19.5Z"
      fill="#49494A"
    />
  </svg>
</template>

<script>
export default {
  name: "CartIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>