<template>
  <div id="product-list">
    <!--<input type="text" class="form-control" v-model="$parent.searchTerm" v-on:keypress="$parent.searchArticle">-->
    <button class="multiselect-btn" @click="getArticleRecommendations">
      Analyze
    </button>
    <Multiselect
      v-model="value"
      mode="tags"
      track-by="name"
      :searchable="true"
      :createTag="false"
      label="name"
      valueProp="id"
      @deselect="onDeselect"
      @select="onSelect"
      :minChars="3"
      :resolveOnLoad="false"
      :filterResults="false"
      :delay="0"
      :options="fetchArticles"
      placeholder="Select articles"
    />
    <!--<ul v-if="$parent.articleLength" class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10" style="height: 178px; overflow-y: auto;">
            <li v-for="article in $parent.searchArticles" :key="article.name" @click="$parent.selectArticle(article.name, article.id)" class="cursor-pointer hover:bg-gray-100 p-1">
                {{ article.name }}`
            </li>
        </ul>-->

    <!--<div class="toolbar-holder">
            <button class="btn btn-primary">Analyze</button>
        </div>-->
    <p>Start enter the article name and then select from matches list</p>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>
<script>
import Multiselect from "@vueform/multiselect";
import { mapActions } from "vuex";
import { LIST_ARTICLES } from "@/Core/store/action-types";
export default {
  name: "ArticleList",
  components: {
    Multiselect,
  },
  props: ["articlesList"],
  data() {
    return {
      value: null,
    };
  },
  methods: {
    ...mapActions("article", [LIST_ARTICLES]),
    onDeselect(option) {
      this.$parent.removeArticle(option);
    },
    onSelect(option) {
      // let selectedArticle = this.articlesList.filter((item) => item.id === option);
      this.$parent.selectArticle(option);
    },
    fetchArticles(query) {
      return this[LIST_ARTICLES]({ params: { name: query } });
    },
    getArticleRecommendations() {
      this.$parent.$refs.articleTab.getRecommendedArticles();
    },
  },
  created() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
