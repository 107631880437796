<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <TopBar
      title="Customers"
      :author="{
        home: 'Home',
        cart: 'Customers',
      }"
    />
    <MainMenu />
    <CustomersTable />
  </div>
</template>

<script>
import TopBar from "../Menu/TopBar.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";
import MainMenu from "../Menu/MainMenu.vue";
import CustomersTable from "./components/CustomersTable.vue";
export default {
  name: "Customers",
  components: {
    Loading,
    TopBar,
    MainMenu,
    CustomersTable,
  },
  data() {
    return {
      fullPage: true,
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>