<template>
  <div class="dashboard-top-section">
    <div class="dts-row">
      
      <div
        class="dts-box-outer"
        type="button"
        data-toggle="modal"
        data-target="#articleFileImportModel"
      >
        <div class="dts-box">
          <div class="upload-info" v-if="this?.org?.last_uploaded_article_count && this?.org?.last_uploaded_article_timestamp">
                <span>Last updated : <em>{{getdate(this?.org?.last_uploaded_article_timestamp)}} 
                  <!-- at {{getTime(this?.org?.last_uploaded_article_timestamp)}} -->
                  </em></span><br>
                <span>{{this?.org?.last_uploaded_article_count}} articles uploaded</span>
            </div>
          <div class="box_section">
            <div class="box_svg">
              <FormManuallyIcon /> 
            </div>
            
            <div class="box_text">
              <h1>Upload articles manually from file</h1>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="dts-box-outer">
        <div class="dts-box">
          <div class="box_section">
            <div class="box_svg">
              <FileUploadIcon />
            </div>
            <div class="box_text">
              <h1>Upload articles from file</h1>
            </div>
          </div>
        </div>
      </div> -->
      <div
        class="dts-box-outer"
        type="button"
        data-toggle="modal"
        data-target="#receiptFileModel"
      >
        <div class="dts-box">
          <div class="box_section">
            <div class="box_svg">
              <OrderUploadIcon />
            </div>
            <div class="box_text">
              <h1>Upload receipts/orders from file</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="dts-box-outer" type="button" data-toggle="modal" data-target="#customerFileImportModel">
        <div class="dts-box">
           <div class="upload-info" v-if="this?.org?.last_uploaded_customer_count  != null && this?.org?.last_uploaded_customer_timestamp">
                <span>Last updated : <em>{{getdate(this?.org?.last_uploaded_customer_timestamp)}} 
                  <!-- at {{getTime(this?.org?.last_uploaded_customer_timestamp)}} -->
                  </em></span><br>
                <span>{{this?.org?.last_uploaded_customer_count}} customers uploaded</span>
            </div>
          <div class="box_section">
            <div class="box_svg">
             <CustomerUploadIcon />
            </div>
            <div class="box_text">
              <h1>Upload customers from file</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="dts-box-outer" type="button" data-toggle="modal" data-target="#salesChannelFileImportModel">
        <div class="dts-box">
           <div class="upload-info" v-if="this?.org?.last_uploaded_sales_channel_count  != null && this?.org?.last_uploaded_sales_channel_timestamp">
                <span>Last updated : <em>{{getdate(this?.org?.last_uploaded_sales_channel_timestamp)}} 
                  <!-- at {{getTime(this?.org?.last_uploaded_sales_channel_timestamp)}} -->
                  </em></span><br>
                <span>{{this?.org?.last_uploaded_sales_channel_count}} sales channel uploaded</span>
            </div>
          <div class="box_section">
            <div class="box_svg">
             <SalesChannelUploadIcon />
            </div>
            <div class="box_text">
              <h1>Upload sales channel from file</h1>
            </div>
          </div>
        </div>
      </div>
      <!-- v-if="this?.org?.is_shopify_connected" -->
      <div
        class="dts-box-outer"
        type="button"
        data-toggle="modal"
        data-target="#ShopifyReceiptFileModel"
      >
        <div class="dts-box">
          <div class="box_section">
            <div class="box_svg">
              <ShopifyUploadIcon />
            </div>
            <div class="box_text">
              <h1>Upload receipts/orders from shopify exported file</h1>
            </div>
          </div>
        </div>
      </div>
      <div
        class="dts-box-outer"
        type="button"
        data-toggle="modal"
        data-target="#MultipleReceiptFileModel"
        @click="UploadReceiptModalClear"
      >      

        <div class="dts-box">
          <div class="box_section">
            <div class="box_svg">
              <RecieptsUploadIcon />
            </div>
            <div class="box_text">
              <h1>Upload receipts file</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- add article file model  -->
  <div
    class="modal fade"
    id="articleFileImportModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="articleFileImportModelLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="articleFileImportModelLabel">
            Add Articles file
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-success" role="alert">
            You can send store Articles by upload them in MS Excel format
            (.xlxs). This will update the existed Articles, and add newly sold.
            Template for the Excel structure:
            <a href="/assets/files/Example Article File.xlsx" download
              ><ul>
                click here
              </ul></a
            >
          </div>
          <div>
            <div class="d-flex">
              <input
                type="file"
                id="file-upload"
                class="form-control d-none"
                @change="onFileChnage"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <label for="file-upload" class="save-btn" role="button">
                Select File</label
              >
              <span class="p-2">{{ articleFile?.name }}</span>
            </div>
            <div class="form-control-feedback">
              {{ articleFileErrMsg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
        </div>
        <div class="modal-footer">
           <button
            type="submit"
            class="save-btn"
            @click="HandleFileUpload"
          >       
          <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Add File</span>
          </button>
          <button
            type="button"
            class="cancle-btn"
            data-dismiss="modal"
            aria-label="Close"
            id="close-file-import-model"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- add receipt file model  -->
  <div
    class="modal fade"
    id="receiptFileModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="receiptFileModelLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="receiptFileModel">Add Receipt File</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-success" role="alert">
            You can send receipts/orders by upload them in JSON format (.json).
            This will create receipts/order.<br />
            Template for the JSON structure:
            <a href="/assets/files/receipt-data-sample.json" download
              ><ul>
                click here
              </ul></a
            >
          </div>
          <div>
            <div class="d-flex">
              <input
                type="file"
                id="file-upload-receipt"
                class="form-control d-none"
                @change="onReceiptFileChange"
                accept=".json, application/json"
              />
              <label for="file-upload-receipt" class="save-btn" role="button">
                Select File</label
              >
              <span class="p-2">{{ receiptFile?.name }}</span>
            </div>
            <div class="form-control-feedback">
              {{ receiptFileErrMsg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="save-btn"
            @click="HandleReceiptFileUpload"
          >       
          <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Add File</span>
          </button>
          <button
            type="button"
            class="cancle-btn"
            data-dismiss="modal"
            aria-label="Close"
            id="close-receipt-file-import-model"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="ShopifyReceiptFileModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ShopifyReceiptFileModelLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ShopifyReceiptFileModel">Add Receipt CSV File from shopify</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-success" role="alert">
            You can send receipts/orders by upload them in CSV format (.csv).
            This will create receipts/order.<br />
            Template for the CSV structure:
            <a href="/assets/files/Shopify_orders_sample.csv" download
              ><ul>
                click here
              </ul></a
            >
          </div>
          <div>
            <div class="d-flex">
              <input
                type="file"
                id="file-upload-shopify-receipt"
                class="form-control d-none"
                @change="onShopifyReceiptFileChange"
                accept=".csv"
              /> 

              <label for="file-upload-shopify-receipt" class="save-btn" role="button">
                Select File</label  
              >
              <span class="p-2">{{ shopifyReceiptFile?.name }}</span>
            </div>
            <div class="form-control-feedback">
              {{ shopifyReceiptFileErrMsg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="save-btn"
            @click="HandleShopifyReceiptFileUpload"
          >       
          <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Add File</span>
          </button>
          <button
            type="button"
            class="cancle-btn"
            data-dismiss="modal"
            aria-label="Close"
            id="close-shopify-receipt-file-import-model"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- add multiple reciepts model -->

  <div
    class="modal fade"
    id="MultipleReceiptFileModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="MultipleReceiptFileModelLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="MultipleReceiptFileModel">Add Receipt XLSX File</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-success" role="alert">
            You can send receipts/orders by upload them in XLSX format (.xlsx).
            This will create receipts/order.<br />
            Template for the XLSX structure:
            <a href="/assets/files/Receipts_CSV.xlsx" download
              ><ul>
                click here
              </ul></a
            >
          </div>
          <div>
            <div class="">
              <input
                type="file"
                id="multiple-file-upload-receipt"
                class="form-control d-none"
                @change="onMultipleReceiptFileChange"
                webkitdirectory
                multiple
                ref="fileInput" 
              /> 
              <label for="multiple-file-upload-receipt" class="save-btn" role="button">
                Select File...</label  
              >
              <div class="uploded-file-name"> 
                <span class="p-2" v-if="multipleReceiptFileDisplay && multipleReceiptFileDisplay.length > 0"> <span v-for="file in multipleReceiptFileDisplay" :key="file">{{ file }}</span> </span>
              </div>
            </div>
            <div class="form-control-feedback">
              {{ multipleReceiptFileErrMsg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="save-btn"
            @click="HandleMultipleReceiptFileUpload"
          >       
          <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Add File</span>
          </button>
          <button
            type="button"
            class="cancle-btn"
            data-dismiss="modal"
            aria-label="Close"            
            id="close-multiple-receipt-file-import-model"
          >
            Cancel 
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- add customer model -->
   <div
    class="modal fade"
    id="customerFileImportModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="customerFileImportModelLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="customerFileImportModelLabel">
            Add Customers file
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-success" role="alert">
            You can send customers by upload them in MS Excel format
            (.xlxs). This will update the existed customers, and add new customers.
            Template for the Excel structure:
            <a href="/assets/files/customer_final.xlsx" download
              ><ul>
                click here
              </ul></a
            >
          </div>
          <div>
            <div class="d-flex">
              <input
                type="file"
                id="file-upload-customers"
                class="form-control d-none"
                @change="oncustomersFileChange"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <label for="file-upload-customers" class="save-btn" role="button">
                Select File</label
              >
              <span class="p-2">{{ customersFile?.name }}</span>
            </div>
            <div class="form-control-feedback">
              {{ customersFileErrMSg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
        </div>
        <div class="modal-footer">
           <button
            type="submit"
            class="save-btn"
            @click="HandleCustomerFileUpload"
          >       
          <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Add File</span>
          </button>
          <button
            type="button"
            class="cancle-btn"
            data-dismiss="modal"
            aria-label="Close"
            id="close-file-import-model-customer"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- add sales channel model -->
  <div
    class="modal fade"
    id="salesChannelFileImportModel"
    tabindex="-1"
    role="dialog"
    aria-labelledby="salesChannelFileImportModelLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="salesChannelFileImportModelLabel">
            Add sales channel file
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-success" role="alert">
            You can send sales channel by upload them in MS Excel format
            (.xlxs). This will update the existed sales channel, and add new sales channel.
            Template for the Excel structure:
            <a href="/assets/files/Store_example_file.xlsx" download
              ><ul>
                click here
              </ul></a
            >
          </div>
          <div>
            <div class="d-flex">
              <input
                type="file"
                id="file-upload-sales_channel"
                class="form-control d-none"
                @change="onSalesChannelFileChange"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <label for="file-upload-sales_channel" class="save-btn" role="button">
                Select File</label
              >
              <span class="p-2">{{ salesChannelsFile?.name }}</span>
            </div>
            <div class="form-control-feedback">
              {{ salesChannelsFileErrMSg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
        </div>
        <div class="modal-footer">
           <button
            type="submit"
            class="save-btn"
            @click="HandleSalesChannelFileUpload"
          >       
          <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
            <span class="visually-hidden">Loading...</span>
          </div>
          <span v-else>Add File</span>
          </button>
          <button
            type="button"
            class="cancle-btn"
            data-dismiss="modal"
            aria-label="Close"
            id="close-file-import-model-sales-channel"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormManuallyIcon from "../../icons/formManually.vue";
// import FileUploadIcon from "../../icons/fileUpload.vue";
import OrderUploadIcon from "../../icons/orderUpload.vue";
import CustomerUploadIcon from "../../icons/customerUpload.vue"
import SalesChannelUploadIcon from "../../icons/salesChannelUpload.vue"
import ShopifyUploadIcon from "../../icons/shopifyUpload.vue"
import RecieptsUploadIcon from "../../icons/recieptsUploadIcon.vue"
import {
  ARTICLE_FILE_UPLOAD,
  RECEIPT_FILE_UPLOAD,
  CUSTOMER_FILE_UPLOAD,
  UPDATE_ORG_UPLOAD_DATA,
  SALES_CHANNEL_FILE_UPLOAD,
  GET_ORG,
  SHOPIFY_RECEIPT_FILE_UPLOAD,
  MULTIPLE_RECEIPT_FILE_UPLOAD
} from "@/Core/store/action-types";
import { mapActions , mapGetters} from "vuex";
export default {
  name: "Imports",
  components: {
    FormManuallyIcon,
    // FileUploadIcon,
    OrderUploadIcon,
    CustomerUploadIcon,
    SalesChannelUploadIcon,
    ShopifyUploadIcon,
    RecieptsUploadIcon
  },
  data() {
    return {
      articleFile: null,
      articleFileErrMsg: "",
      receiptFile: null,
      receiptFileErrMsg: "",
      shopifyReceiptFile: [],
      shopifyReceiptFileDisplay: [],
      shopifyReceiptFileErrMsg: "",
      multipleReceiptFile: [],
      multipleReceiptFileDisplay: [],
      multipleReceiptFileErrMsg: "",
      customersFile:null,
      customersFileErrMSg:"",
      salesChannelsFile:null,
      salesChannelsFileErrMSg:"",
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("user", { userProfile: "userProfile" }),
    ...mapGetters("org",["org"]),
  },
  methods: {
    ...mapActions("receipt", [ARTICLE_FILE_UPLOAD, RECEIPT_FILE_UPLOAD,SHOPIFY_RECEIPT_FILE_UPLOAD,MULTIPLE_RECEIPT_FILE_UPLOAD]),
    ...mapActions("_store",[SALES_CHANNEL_FILE_UPLOAD]),
    ...mapActions("customer", [CUSTOMER_FILE_UPLOAD]),
    ...mapActions("user", [UPDATE_ORG_UPLOAD_DATA]),
    ...mapActions('org',[GET_ORG]),
    HandleFileUpload() {
      if (this.articleFile) {
        this.isLoading=true
        this[ARTICLE_FILE_UPLOAD](this.articleFile)
          .then(() => {
            // this[UPDATE_ORG_UPLOAD_DATA]()
            this[GET_ORG](this.userProfile.organization.id)
            this.$alertify.notify(
              "Article Uploaded successfully",
              "success",
              3
            );
            // this.articleFile = null;
            document.getElementById("close-file-import-model").click();
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some error, please check your file",
              "error",
              3
            );
          })
          .finally(()=>{
            this.isLoading=false
          })
      } else {
        this.articleFileErrMsg = "Please select file";
      }
    },
    HandleCustomerFileUpload(){
      if (this.customersFile) {
        this.isLoading=true
        this[CUSTOMER_FILE_UPLOAD](this.customersFile)
          .then(() => {
            // this[UPDATE_ORG_UPLOAD_DATA]()
            this[GET_ORG](this.userProfile.organization.id)
            this.$alertify.notify(
              "Customers Uploaded successfully",
              "success",
              3
            );
            // this.articleFile = null;
            document.getElementById("close-file-import-model-customer").click();
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some error, please check your file",
              "error",
              3
            );
          })
          .finally(()=>{
            this.isLoading=false
          })
      } else {
        this.customersFileErrMSg = "Please select file";
      }
    },
    onSalesChannelFileChange(e) {
      console.log("SalesChannelFileChange")
      this.salesChannelsFile= e.target.files[0];
    },
    HandleSalesChannelFileUpload(){
      if (this.salesChannelsFile) {
        console.log("salesChannelsFile",this.salesChannelsFile)
        this.isLoading=true
        this[SALES_CHANNEL_FILE_UPLOAD](this.salesChannelsFile)
          .then(() => {
            // this[UPDATE_ORG_UPLOAD_DATA]()
            this[GET_ORG](this.userProfile.organization.id)
            this.$alertify.notify(
              "Sales channel Uploaded successfully",
              "success",
              3
            );
            // this.articleFile = null;
            document.getElementById("close-file-import-model-sales-channel").click();
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some error, please check your file",
              "error",
              3
            );
          })
          .finally(()=>{
            this.isLoading=false
          })
      } else {
        this.salesChannelsFileErrMSg = "Please select file";
      }
    },
    onFileChnage(e) {
      this.articleFile = e.target.files[0];
      console.log("e", this.articleFile);
    },
    getdate(date) {
      // console.log(new Date(date).toLocaleTimeString('en', {timeZone: 'America/New_York'}))
      return new Date(date)
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, " ");
    },
    getTime(date){
      return new Date(date).toLocaleTimeString('en-US')
    },
    oncustomersFileChange(e) {
      this.customersFile= e.target.files[0];
    },
    HandleReceiptFileUpload() {
      if (this.receiptFile) {
        this.isLoading=true
        this[RECEIPT_FILE_UPLOAD](this.receiptFile)
          .then(() => {
            this.$alertify.notify(
              "Receipt generated successfully",
              "success",
              3
            );
            document.getElementById("close-receipt-file-import-model").click();
            this.receiptFile = null;
          })
          .catch((error) => {
            this.$alertify.notify(
              error.response?.data?.detail || "There is some error, please try again",
              "error",
              3
            )
          })
          .finally(()=>{
            this.isLoading=false
          })
      } else {
        this.receiptFileErrMsg = "Please select file";
      }
    },
    onReceiptFileChange(e) {
      if (e.target.files.length > 0) {
        this.receiptFileErrMsg = "";
        this.receiptFile = e.target.files[0];
        e.target.value = '';
      } else {
        this.receiptFile = null;
        this.receiptFileErrMsg = "Please select a file.";
      }
    },
    onShopifyReceiptFileChange(e) {
      this.shopifyReceiptFile = e.target.files[0];
      console.log("e", this.shopifyReceiptFile);
    },

    UploadReceiptModalClear(){
      this.multipleReceiptFileDisplay = [];
      this.multipleReceiptFile = [];      
    },
    onMultipleReceiptFileChange(e) {
      for (let file of e.target.files) {
        if(!file.name.startsWith("~$")){        
        if (file.name.split(".")[1]==="csv" || file.name.split(".")[1]==="xlsx") {
          this.multipleReceiptFile = [...this.multipleReceiptFile, file];
          this.multipleReceiptFileDisplay = [...this.multipleReceiptFileDisplay, file.name];
        } else {
          this.multipleReceiptFileErrMsg = "We only accept .csv or .xls file format. Files with other extension will be ignored."
        }
      }
      }
      this.$refs.fileInput.value = "";
    },
    HandleShopifyReceiptFileUpload() {
      if (this.shopifyReceiptFile) {
        this.isLoading=true
        this[SHOPIFY_RECEIPT_FILE_UPLOAD](this.shopifyReceiptFile)
          .then(() => {
            this.$alertify.notify(
              "Receipts generated successfully",
              "success",
              3
            );
            // this.shopifyReceiptFile = '';
            document.getElementById("close-shopify-receipt-file-import-model").click();
          })
          .catch((e) => {
            this.$alertify.notify(
              e.response?.data?.detail || "There is some error, please try again",
              "error",
              3
            )
          })
           .finally(()=>{
            this.isLoading=false
          })
      } else {
        this.shopifyReceiptFileErrMsg = "Please select file";
      }
    },
    HandleMultipleReceiptFileUpload() {
      if (this.multipleReceiptFile) {
        this.isLoading=true
        this[MULTIPLE_RECEIPT_FILE_UPLOAD](this.multipleReceiptFile)
          .then(() => {
            this.$alertify.notify(
              "Receipts generated successfully",
              "success",
              3
            );
            // this.shopifyReceiptFile = '';
            document.getElementById("close-multiple-receipt-file-import-model").click();
          })
          .catch((e) => {
            this.$alertify.notify(
              e.response?.data?.detail || "There is some error, please try again",
              "error",
              3
            )
          })
           .finally(()=>{
            this.isLoading=false
          })
      } else {
        this.multipleReceiptFileErrMsg = "Please select file";
      }
    },
    
    // download(fileUrl, fileName) {
    //   var a = document.createElement("a");
    //   a.href = fileUrl;
    //   a.setAttribute("download", fileName);
    //   a.click();
    // },
  },
};
</script>
<style scoped>
.box_section[data-v-436a8b24] {
  background: #fff;
  /* width: 350px; */
  height: 247px;
  margin: 0 auto;
  padding: 5px;
}

.box_text h1 {
  margin: 0;
  padding: 0;
  margin-top: 32px;
  font-size: 20px;
  font-weight: 700;
  color: #3f3f40;
}

.box_text span {
  font-size: 19px;
  font-weight: 400;
}

.uploded-file-name span span {
    margin: 0 7px;
}

.uploded-file-name > span {
    max-height: 200px;
    overflow: auto;
}

.dashboard-top-section .dts-row .dts-box-outer .dts-box {
   background: #ffffff;
  padding: 46px 30px;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
/* .dashboard-top-section .dts-row .dts-box-outer {
  padding: 0 10px;
  width: 50%;
  flex: 0 0 25%;
} */
.upload-info span {
    font-size: 16px !important;
    line-height: 20px !important;
    margin: 0 !important;
    font-weight: 400 !important;
    color: #000 !important;
}

.upload-info {
    position: absolute;
    top: 15px;
    right: 15px;
    width: auto;
    text-align: right;
}

.dts-box {
    position: relative;
}

.upload-info span em {
    font-style: normal;
    color: #8e8e8e;
}
button.save-btn{
  width:105px
}
</style>