<template>
  <div>
    <CustomerModal
      :modalId="createModalId"
      :modalType="2"
      :initcustomers="getCounterCount"
    ></CustomerModal>
    <div>
      <!-- customer statstics section start -->
      <div class="dashboard-top-section pb-3">
        <div class="row">
          <div class="col-md-4">
            <div class="dts-box">
              <span>{{ counterBoard.total_customers }}</span>
              <h6>Total Customers</h6>
            </div>
          </div>
          <div class="col-md-4">
            <div class="dts-box">
              <div>
                <div class="dts-title">{{ counterBoard.new_customers }}</div>
                <div class="main___box">
                  <div class="top__title">New Customers</div>
                  <div class="bottom__title">
                    <span
                      >+{{
                       total_customer_count!==0
                          ? Math.round((counterBoard.new_customers / total_customer_count) * 100).toFixed(2)
                          : 0
                      }}%
                    </span>
                    <span>from {{ date }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="dts-box">
              <span>{{ counterBoard.customer_purchase }}</span>
              <h6>Customer Purchases</h6>
            </div>
          </div>
        </div>
      </div>
      <!-- customer statstics section end -->
      <div class="contant-box-main">
        <div class="data-heading-wrp">
          <!-- search and filter start -->
          <div class="data-heading">
            <div class="search-box-wrp">
              <input
                type="text"
                v-model="search"
                class="form-control"
                name=""
                placeholder="Search for Customer"
              />
                <a class="search_icon" href="#"
                  ><img
                    src="../../../public/assets/images/search_icon.svg"
                    alt=""
                /></a>
            </div>
            <div class="tab-box-wrp">
              <ul>
                <!-- <li :class="status == 'all' ? 'active' : ''"  @click="changeStatus('all')">All</li>
                        <li :class="status == 'active' ? 'active' : ''" @click="changeStatus('active')">Active</li>
                        <li :class="status == 'in-active' ? 'active' : ''" @click="changeStatus('in-active')">Inctive</li> -->
                <li
                  @click="changeStatus('all')"
                  :class="status == 'all' ? 'active' : ''"
                >
                  All
                </li>
                <li
                  @click="changeStatus('active')"
                  :class="status == 'active' ? 'active' : ''"
                >
                  Active
                </li>
                <li
                  @click="changeStatus('in-active')"
                  :class="status == 'in-active' ? 'active' : ''"
                >
                  Inactive
                </li>
              </ul>
            </div>
          </div>
          <!-- search and filter end -->
          <!-- cretae customer button start -->
          <div class="data-heading-btn" v-if="createpermission">
            <button @click="showCreateDialog">+ Add new Customer</button>
          </div>
          <!-- create customer button end -->
        </div>
        <!-- customers table start  -->
        <div class="table-wrp overflow-auto position-relative">
          
          <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
            
          <table class="table my-table-wrp table-sm" id="my-table">
            <thead>
              <tr>
                <th scope="col" v-for="f in fields" v-bind:key="f.id">
                  <div
                    class="table-head"
                    v-if="f === 'First Name'"
                    @click="sortTable()"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="sortCount == 2 ? 'sort-active' : ''"
                        >&#8595;</span
                      >
                      <span :class="sortCount == 3 ? 'sort-active' : ''"
                        >&#8593;</span
                      >
                    </span>
                  </div>
                  <div class="table-head" v-else>
                    {{ f.split("_").join(" ") }}
                  </div>
                  <tr></tr>
                </th>
              </tr>
            </thead>
            
            <tbody>
              <tr
                v-for="customer in items.results"
                v-bind:key="customer.id"
                :class="customer.is_active ? 'active-row' : 'inactive-row'"
              >
                <td class="font-light-text" width="10%">{{ customer.external_id }}</td>
                <td  @click="detail(customer)" class="click_name" width="20%">{{ customer.first_name }}</td>
                <td class="font-light-text" width="10%">{{ customer.last_name }}</td>
                <td class="font-light-text" width="20%">{{ customer.email }}</td>
                <!-- <td class="font-light-text" width="15%">{{ customer.phone }}</td> -->
                <!-- <td class="font-light-text" width="10%">{{ customer.sex }}</td> -->
                <td class="font-light-text" width="15%">{{ customer.company_name }}</td>
                <td width="10%">
                  <label class="status-active" v-if="customer.is_active">
                    Active
                  </label>
                  <label class="status-inactive" v-else title="No purchases in last 12 months">Inactive</label>
                </td>
                <td class="font-light-text " width="5%">
                  <table class="main-button--table" width="50">
                    <tr>
                      <td width="50%" >
                        <button class="eye-view icon-effect" @click="detail(customer)" title="See Customer Details">
                          <EyeIcon />
                        </button>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          
          <Pagination
            :page="page"
            :totalPages="totalPages"
            :name="'Customers'"
            :count="items.count?items.count:0"
            :incrementpage="incrementpage"
            :decrementpage="decrementpage"
            :setpage="setpage"
            :perpage="10"
          />
        </div>
        <!-- customers table end  -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  LIST_CUSTOMERS,
  DELETE_CUSTOMER,
  LIST_RECENT_CUSTOMERS,
  LIST_RECEIPTS,
} from "@/Core/store/action-types";
import { RESOURCE_NAME } from "../customer.vars";
import { deleteItem } from "@/Core/helpers/gridUtils";
import CustomerModal from "./FormModal.vue";
import Pagination from "../../Core/Pagination";
import EyeIcon from "../../icons/eye"
import { subject } from "@casl/ability";
export default {
  name: "CustomersTable",
  components: {
    CustomerModal,
    Pagination,
    EyeIcon
  },
  data() {
    return {
      asc: null,
      des: null,
      params: null,
      page: 1,
      totalPages: [],
      search: null,
      pages: [],
      ascending: false,
      nameSort: false,
      sortCount: 1,
      sortColumn: "",
      counterBoard: {
        total_customers: 0,
        new_customers: 0,
        customer_purchase: 0,
      },
      date: null,
      total_customer_count: 0,
      fields: [
        "ID",
        "First Name",
        "Last Name",
        "Email",
        // "Phone",
        "Company Name",
        "Status",
        "",
      ],
      items: [],
      recentCustomers: [],
      status: "all",
      showModal: false,
      createModalId: "createDialog",
      createpermission:this.$ability.can('create',subject(RESOURCE_NAME,{})),
      loadindShow:false
    };
  },

  methods: {
    ...mapActions("customer", [LIST_CUSTOMERS, DELETE_CUSTOMER, LIST_RECENT_CUSTOMERS]),
    ...mapActions("receipt", [LIST_RECEIPTS]),
    //open create customer dialog
    showCreateDialog() {
      window.$(`#${this.createModalId}`).modal("toggle");
    },
    //get customers data
    initCustomers(pageNumber = null) {
      pageNumber?this.page=pageNumber:null
      let options = {
        persist: false,
        params: {
          q: this.search?.length<3 ? null : this.search,
          is_active:
            this.status == "all"
              ? null
              : this.status == "active"
              ? true
              : false,
          page: this.page,
          asc: this.asc,
          des: this.des,
        },
      };
      this.loadindShow = true
      this[LIST_CUSTOMERS](options).then((resp) => {
        this.counterBoard.total_customers = resp.count;
        if (this.status === "all" && !this.search)
          this.total_customer_count = resp.count;
        this.items = resp;
        this.totalPages = Array(Math.ceil(resp.count / 10))
          .fill(0)
          .map((e, i) => i + 1);
      }).finally(()=>this.loadindShow=false)
    },
    //on eye button click go to customers details page.
    detail(item) {
      this.showModal = true;
      this.$router.push({
        name: "CustomerDetailsGrid",
        params: { customerId: item.id },
      });
    },
    deleteCustomer(item) {
        deleteItem(
        this.$alertify,
        this.$ability,
        this[DELETE_CUSTOMER],
        RESOURCE_NAME,
        item
      );
      setTimeout(() => {
        this.initCustomers();
      }, 2000);
    },
    //on change status
    changeStatus(state) {
      this.status = state;
    },
    //get recent customer count 
    getCounterCount() {
      let options = this.params;
      this[LIST_RECENT_CUSTOMERS](options).then((resp) => {
        this.counterBoard.new_customers = resp.recent_member_count;
        this.recentCustomers = resp.recent_member_count;
      });
    },
    //get customers purchase count
    getPurchaseCount() {
      let options = { params: null };
      this[LIST_RECEIPTS](options)
        .then((resp) => {
          this.counterBoard.customer_purchase = resp.count;
        })
        .catch(() => {
          this.counterBoard.customer_purchase = 0;
        });
    },
    //sort customers table by name
    sortTable() {
      this.sortCount++;
      if (this.sortCount > 3) this.sortCount = 1;
      if (this.sortCount === 1) {
        this.asc = null;
        this.des = null;
      } else if (this.sortCount === 2) {
        this.asc = true;
        this.des = null;
      } else if (this.sortCount === 3) {
        this.asc = null;
        this.des = true;
      } else {
        return 0;
      }
    },
    //set proper date format
    getdate() {
      var d = new Date();
      d.setMonth(d.getMonth() - 1);
      this.date = d
        .toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, " ");
    },
    //go to next page
    incrementpage() {
      this.page = this.page + 1;
      this.initCustomers();
    },
    // go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.initCustomers();
    },
    // go to perticular page
    setpage(page) {
      this.page = page;
      this.initCustomers();
    },
  },
  mounted() {
    this.getCounterCount();
    this.getPurchaseCount();
    this.getdate();
  },
  watch: {
    search() {
      if(this.search.length>=3 || this.search.length==0){
      this.page = 1;
      this.initCustomers();
      }
    },
    status() {
      this.page = 1;
      this.initCustomers();
    },
    sortCount() {
      this.page = 1;
      this.initCustomers();
    },
    recentCustomers() {
      this.initCustomers(1);
    },
  },
};
</script>
<style scoped>
.dashboard-top-section .dts-box {
  background: #ffffff;
  padding: 40px 25px;
  max-height: 150px;
  height: 100%;
}
.dashboard-top-section .dts-box span {
  color: #5e66fa;
  font-weight: 600;
  line-height: 24px;
}
.dashboard-top-section .dts-box h6 {
  margin: 0;
  color: #3f3f40;
  font-weight: 500;
  font-size: 18px;
}
.data-heading-wrp {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.data-heading-wrp .data-heading {
  position: relative;
}

.data-heading-wrp .data-heading-btn button {
  background: #5e66fa;
  border-radius: 4px;
  font-style: normal;
  font-size: 15px;
  line-height: 150%;
  border: 0;
  color: #fff;
  font-weight: 400;
  padding: 11px 19px;
}
.search-box-wrp {
  position: relative;
  min-width: 300px;
  display: inline-block;
  margin-right: 20px;
}
/* .search-box-wrp input {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #aaabad;
}
.search-box-wrp input::placeholder {
  color: #aaabad;
} */
.tab-box-wrp {
  position: relative;
  display: inline-block;
}
.tab-box-wrp ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.tab-box-wrp ul li {
  background: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #3f3f40;
  list-style-type: none;
  border: 1px solid #e2e2e2;
  padding: 9px 20px;
  cursor: pointer;
}
.tab-box-wrp ul li:hover{
  background-color: #E8E9EB
;
}
.tab-box-wrp ul li:first-child {
  border-radius: 4px 0px 0px 4px;
}
.tab-box-wrp ul li:last-child {
  border-radius: 0px 4px 4px 0px;
}
.tab-box-wrp ul li.active {
  background: #5e66fa;
  color: #fff;
  border: 1px solid #5e66fa;
}
.table-wrp table tr:hover {
  background: #f6f6f7;
}
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
.sort-active {
  font-weight: bold;
  color: #000000;
  font-size: 12px;
}
.main___box {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 0px;
}

.main___box .top__title {
  font-size: 18px;
}

.main___box .bottom__title span {
  font-size: 15px !important;
  margin-bottom: 0 !important;
}

.main___box span {
  margin: 0 !important;
  font-weight: normal !important;
}

.main___box span:nth-child(1) {
  color: #0bc984 !important;
  margin-right: 5px !important;
}

.main___box span:nth-child(2) {
  color: #aaabad !important;
}

.dashboard-top-section .dts-box span {
  margin-bottom: 0 !important;
}

.dts-title {
  color: #5e66fa;
  font-weight: 600;
  line-height: 43px;
  margin-bottom: 0 !important;
  font-size: 54px;
}

table.main-button--table button.eye-view {
    margin: auto;
}

table.main-button--table  td {
    padding: 0;
}
.click_name{
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .main___box{display: block;}
}


@media only screen and (max-width: 991px) {
  .dashboard-top-section .dts-box{padding: 20px 20px;}
}



@media only screen and (max-width: 767px) {
  .dashboard-top-section .dts-box{
    max-height: initial;
    height: auto;
    margin-bottom: 10px;
    }
}
.loader-main {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto !important;
  text-align: center;
  background-color: rgb(255 255 255 / 50%);
  width: 100px;
  transform: translateY(-50%);
  top: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>