// User resource
export const SET_USER = 'SET_USER';
export const SET_USERS_LIST = 'SET_USERS_LIST';
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const REMOVE_USER_FROM_LIST = 'REMOVE_USER_FROM_LIST';
export const EDIT_USER_FROM_LIST = 'EDIT_USER_FROM_LIST';
export const DEACTIVATE_USER_FROM_LIST = 'DEACTIVATE_USER_FROM_LIST';
export const EDIT_PROFILE_IMAGE = 'EDIT_PROFILE_IMAGE';
export const SET_ORG_UPLOAD_DATA = 'SET_ORG_UPLOAD_DATA';

// Article resource
export const SET_ARTICLES_LIST = 'SET_ARTICLES_LIST';

// Organization resource
export const SET_ORGS_LIST = 'SET_ORGS_LIST';
export const REMOVE_ORG_FROM_LIST = 'REMOVE_ORG_FROM_LIST';
export const EDIT_ORG_FROM_LIST = 'EDIT_ORG_FROM_LIST';
export const ADD_NEW_ORG = 'ADD_NEW_ORG';
export const SET_ORG = 'SET_ORG'
export const SET_INITIAL_CURRENCY_CLV='SET_INITIAL_CURRENCY_CLV'

// Product resource
export const SET_PRODUCTS_LIST = 'SET_PRODUCTS_LIST';
export const SET_SELECTED_PRODUCTS ='SET_SELECTED_PRODUCTS'
export const REMOVE_SELECTED_PRODUCT = 'REMOVE_SELECTED_PRODUCT'

// Offer resource
export const SET_OFFERS_LIST = 'SET_OFFERS_LIST';
export const REMOVE_OFFER = 'REMOVE_OFFER';
export const EDIT_OFFER = 'EDIT_OFFER';
export const ADD_OFFER = 'ADD_OFFER';
export const  SET_OFFER_DETAILS =' SET_OFFER_DETAILS';

// Audience resource
export const SET_AUDIENCE_LIST = 'SET_AUDIENCE_LIST';
export const REMOVE_AUDIENCE = 'REMOVE_AUDIENCE';
export const EDIT_AUDIENCE = 'EDIT_AUDIENCE';
export const ADD_AUDIENCE = 'ADD_AUDIENCE';
export const SET_AUDIENCE_DETAIL = 'AUDIENCE_DETAIL';

// Member resource
export const SET_CUSTOMERS_LIST = 'SET_CUSTOMERS_LIST';
export const SET_CUSTOMER_RECEIPTS = 'SET_CUSTOMER_RECEIPTS';
export const SET_CUSTOMER_CAMPAIGNS = 'SET_CUSTOMER_CAMPAIGNS';
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const SET_RECENT_CUSTOMERS_LIST= 'SET_RECENT_CUSTOMERS_LIST';
export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS';

// Campaign resource
export const SET_LIST_CAMPAIGNS = 'SET_LIST_CAMPAIGNS';
export const REMOVE_CAMPAIGN = 'REMOVE_CAMPAIGN';
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN';
export const SET_CAMPAIGN_DETAILS = 'SET_CAMPAIGN_DETAILS';

export const SET_CLV_YEAR="SET_CLV_YEAR"
export const SET_CURRENCY="SET_CURRENCY"

// Unlayer email send

export const SET_UNLAYER_TEMPLATES="SET_UNLAYER_TEMPLATES"
