import {
  LIST_CAMPAIGNS,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  CREATE_CAMPAIGN,
  DUPLICATE_CAMPAIGN,
  CAMPAIGN_DETAILS,
  GET_CAMPAIGN_ANALYTICS,
  GET_TOP_PERFORMING_CAMPAIGNS,
  GET_FACEBOOK_CAMPAIGNS_LIST,
  GET_FACEBOOK_ADACCOUNTS_LIST,
  GET_GOOGLE_CAMPAIGNS_LIST,
  GET_FACEBOOK_CAMPAIGN_DETAILS,
  GET_GOOGLE_CAMPAIGN_DETAILS,
  GET_CAMPAIGN_COUNTRY_PERFORMANCE
} from "@/Core/store/action-types";

import {
  SET_LIST_CAMPAIGNS,
  REMOVE_CAMPAIGN,
  ADD_CAMPAIGN,
  EDIT_CAMPAIGN,
  SET_CAMPAIGN_DETAILS,
} from "@/Core/store/mutation-types";

import api from "./campaign.api";

const initialState = {};
const state = { ...initialState };

const actions = {
  async [LIST_CAMPAIGNS]({ commit, rootState }, params) {
    const resp = await api.get(rootState.user.userProfile.access, params);
    if (params !== undefined && params.persist === true)
      commit(SET_LIST_CAMPAIGNS, resp.data);
    return resp.data;
  },
  async [DELETE_CAMPAIGN]({ rootState, commit }, objId) {
    try {
      await api.delete(rootState.user.userProfile.access, objId);
      commit(REMOVE_CAMPAIGN, objId);
    } catch (e) {
      throw new Error("");
    }
  },
  async [CAMPAIGN_DETAILS]({ rootState, commit },payload) {
    try {
      const response = await api.getDetails(
        rootState.user.userProfile.access,
        payload
      );
      commit(SET_CAMPAIGN_DETAILS, response.data);
      return response.data;
    } catch (e) {
      throw new Error("");
    }
  },
  async [CREATE_CAMPAIGN]({ rootState, commit }, obj) {
    const resp = await api.create(rootState.user.userProfile.access, obj);
    commit(ADD_CAMPAIGN, resp.data);
    return resp.data;
  },
  async [DUPLICATE_CAMPAIGN]({ rootState, commit }, obj) {
    const response = await api.duplicate(
      rootState.user.userProfile.access,
      obj
    );
    commit(ADD_CAMPAIGN, response.data);
    return response.data;
  },
  async [UPDATE_CAMPAIGN]({ rootState, commit }, obj) {
    const response = await api.update(rootState.user.userProfile.access, obj);
    commit(EDIT_CAMPAIGN, response.data);
    return response.data;
  },
  async [GET_CAMPAIGN_ANALYTICS]({ rootState },payload) {
    try {
      const response = await api.getAnalyticsData(
        rootState.user.userProfile.access,
        payload
      );
      return response.data;
    } catch (e) {
      throw new Error("");
    }
  },
  async [GET_TOP_PERFORMING_CAMPAIGNS]({ rootState }, params) {
    const response = await api.get_top_performing_campaigns(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [GET_FACEBOOK_CAMPAIGNS_LIST]({ rootState }) {
    const response = await api.get_facebook_campaigns(
      rootState.user.userProfile.access,
    );
    return response.data;
  },
  async [GET_FACEBOOK_ADACCOUNTS_LIST]({ rootState }) {
    const response = await api.get_facebook_adaccounts(
      rootState.user.userProfile.access,
    );
    return response.data;
  },
  async [GET_GOOGLE_CAMPAIGNS_LIST]({rootState}){
    const response = await api.get_google_campaigns(
      rootState.user.userProfile.access,
    );
    return response.data;
  },
  async [GET_FACEBOOK_CAMPAIGN_DETAILS]({ rootState },payload) {
    try {
      const response = await api.getFacebookCampaignData(
        rootState.user.userProfile.access,
        payload
      );
      return response.data;
    } catch (e) {
      throw new Error("");
    }
  },
  async [GET_GOOGLE_CAMPAIGN_DETAILS]({ rootState },payload) {
    try {
      const response = await api.getGoogleCampaignData(
        rootState.user.userProfile.access,
        payload
      );
      return response.data;
    } catch (e) {
      throw new Error("");
    }
  },
  async [GET_CAMPAIGN_COUNTRY_PERFORMANCE]({ rootState },payload) {
    try {
      const response = await api.getCountryPerformance(
        rootState.user.userProfile.access,
        payload
      );
      return response.data;
    } catch (e) {
      throw new Error("");
    }
  },
};

const mutations = {
  [SET_LIST_CAMPAIGNS](state, campaignsList) {
    state.campaignsList = campaignsList;
  },
  [ADD_CAMPAIGN](state, obj) {
    state.campaignsList ? state.campaignsList.push(obj) : "";
  },
  [SET_CAMPAIGN_DETAILS](state, obj) {
    state.campaignDetails = obj;
  },
  [EDIT_CAMPAIGN](state, obj) {
    if (state.campaignsList) {
      let idx = state.campaignsList.findIndex((u) => u.id == obj.id);

      if (idx > -1) {
        state.campaignsList[idx] = obj;
      }
    }
  },
  [REMOVE_CAMPAIGN](state, objId) {
    if (state.campaignsList) {
      let idx = state.campaignsList.findIndex((u) => u.id == objId);

      if (idx > -1) {
        state.campaignsList.splice(idx, 1);
      }
    }
  },
};

const getters = {
  campaignsList(state) {
    return state.campaignsList;
  },
  campaignDetails(state) {
    return state.campaignDetails;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
