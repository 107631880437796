<template>
  <svg
    width="45"
    height="44"
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.2154 4.6377C12.7436 4.6377 5.06523 12.3161 5.06523 21.7878C5.06523 22.7822 5.14985 23.7568 5.31229 24.7048M0.761719 20.1553L5.31229 24.7058L9.86286 20.1553"
      stroke="#49494A"
      stroke-width="1.75"
    />
    <path
      d="M22.0014 38.7656C31.4732 38.7656 39.1516 31.0872 39.1516 21.6155C39.1516 20.6211 39.0669 19.6465 38.9045 18.6985M43.4551 23.2481L38.9045 18.6975L34.3539 23.2481"
      stroke="#49494A"
      stroke-width="1.75"
    />
    <path
      d="M26.5691 16.0626H22.0531M17.5371 27.6951H22.0531M22.0531 16.0626H20.331C18.788 16.0626 17.5371 17.3134 17.5371 18.8565V18.8565C17.5371 20.3995 18.788 21.6504 20.331 21.6504H23.5467C25.2159 21.6504 26.5691 23.0036 26.5691 24.6728V24.6728C26.5691 26.342 25.2159 27.6951 23.5467 27.6951H22.0531M22.0531 16.0626V13.4619M22.0531 27.6951V30.4364"
      stroke="#5E66FA"
      stroke-width="1.75"
    />
  </svg>
</template>


<script>
export default {
  name: "CurrencyIcon",
};
</script>

<style scoped>
</style>