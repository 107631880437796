<template>
  <div>
    <div class="row">
      <!-- audience details box start-->
      <div class="col-lg-6" v-if="audienceDetail">
        <!-- delete audience model -->
        <CustomModel
          :modalId="deleteAudienceModelID"
          :item="audienceDetail"
          :onDelete="deleteAudienceDetail"
          :resourseName="'audience'"
          actionType="Delete"
        ></CustomModel>
        <CustomModel
          :modalId="updateCustomAudienceModelID"
          :item="audienceDetail"
          :onDelete="onCustomAudienceUpdate"
          :resourseName="'facebook custom audience'"
          actionType="Update"
        ></CustomModel>
        <CustomModel
          :modalId="deleteCustomAudienceModelID"
          :item="audienceDetail"
          :onDelete="onCustomAudienceDelete"
          :resourseName="'facebook custom audience'"
          actionType="Delete"
        ></CustomModel>
        <!-- delete audience model close -->
        <div class="contant-box">
          <div class="contant-box-main">
            <div class="contant-header">
              <h6>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFESURBVHgBhVHLUcNADJVkkwk30wElhA5CBxRAfjMJww1TQUIFhGty2RAOHCkhVIA7wHTg4ULi2BJaEzPGxsmb2bGsfW/03gqhhO4ovkHENgh4gmIeZ41F8R7/kK/iN/209AS7Vlar6CznUF50RnHfEljYtwR7BPjW9uzUioAIe2ojeJo3H/LectacqolArbUrAkUEdRD0KgJhWWmiVme49n9tZrW0EMHAvtC4Cy37QltkQQUGjBAxSKgKv0i2cIs/fV88WCchM6zscEL+gBKw6BeRxtrxSolDpU3yBdKP980Eie7VcyCC7c/46EQJKI5uHNC+nukON5NsQv/665TZeVfvZjlvDOAfXA43xu6JOT0nEWdsx2636R3UwE0avj5A5DhOj4ThQm2sns1xWCcwBiMkeLFcFxCnwskrHECapgsiN/wGgX6SW5zKhKcAAAAASUVORK5CYII="
                  alt=""
                />Audience details
              </h6>
              <div class="delete-status-box">
                <button
                  type="button"
                  class="cancle-btn mx-2"
                  data-toggle="modal"
                  v-if="!Object.keys(audienceDetail?.facebook_custom_audience).length>0"
                  data-target="#facebookCustomAudienceModel"
                  @click="getFacebookAdAccounts"
                
                >
                  + Create Facebook Custom Audience
                </button>
                <button class="delete-profile" @click="showDeleteModel" title="Delete Audience"  v-if="deletepermission">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.75 16.5H5.25C4.42157 16.5 3.75 15.8284 3.75 15V5.25H2.25V3.75H5.25V3C5.25 2.17157 5.92157 1.5 6.75 1.5H11.25C12.0784 1.5 12.75 2.17157 12.75 3V3.75H15.75V5.25H14.25V15C14.25 15.8284 13.5784 16.5 12.75 16.5ZM5.25 5.25V15H12.75V5.25H5.25ZM6.75 3V3.75H11.25V3H6.75Z"
                      fill="#EC4424"
                    ></path>
                  </svg>
                </button>
              </div> 
            </div>
            <div class="profile-details">
              <div class="profile-field_wrp">
                <div class="profile_field_data">
                  <h6 class="profile-title">Name</h6>
                  <p class="profile-title">{{ audienceDetail?.name }}</p>
                </div>
                <div class="profile_field_data" v-if="!audienceDetail.is_reference_group && audienceDetail.product_pref_type">
                  <h6 class="profile-title">
                    Product Preferences Type
                  </h6>
                  <p class="profile-title">{{ audienceDetail?.product_pref_type }}</p>
                </div>
                <div class="profile_field_data" v-if="!audienceDetail.is_reference_group && audienceDetail?.product_pref_type">
                  <h6 class="profile-title">
                    Products
                  </h6>
                  <div class="sub_profile-title" >
                    <p
                      class="profile-title sub_p_title"
                      v-for="product in audienceDetail.products"
                      :key="product.id"
                    >
                      {{ product.name }}
                    </p>
                  </div>
                </div>
                <div class="profile_field_data" v-if="!audienceDetail.is_reference_group && audienceDetail?.article_pref_type">
                  <h6 class="profile-title">
                    Article Preferences Type
                  </h6>
                  <p class="profile-title">{{ audienceDetail?.article_pref_type }}</p>
                </div>
                <div class="profile_field_data" v-if="!audienceDetail.is_reference_group && audienceDetail?.article_pref_type">
                  <h6 class="profile-title">
                    Articles
                  </h6>
                  <div class="sub_profile-title" >
                    <p
                      class="profile-title sub_p_title"
                      v-for="article in audienceDetail.articles"
                      :key="article.id"
                    >
                      {{ article.name }}
                    </p>
                  </div>
                </div>
                <div class="profile_field_data"
                     v-if="(audienceDetail.article_pref_type === 'likely_to_buy_again' || audienceDetail.article_pref_type === 'likely_to_buy' || audienceDetail.prouct_pref_type === 'likely_to_buy' || audienceDetail.product_pref_type === 'likely_to_buy') && audienceDetail.buy_again_date">
                  <h6 class="profile-title">Likely purchase period</h6>
                  <p class="profile-title">{{ audienceDetail.buy_again_date }}</p>
                </div>
                <div class="profile_field_data">
                  <h6 class="profile-title">Gender</h6>
                  <p class="profile-title">{{ audienceDetail.gender }}</p>
                </div>
                <div class="profile_field_data">
                  <h6 class="profile-title">Age</h6>
                  <p class="profile-title">
                    {{ audienceDetail.age_min }}-{{ audienceDetail.age_max }}
                    Years
                  </p>
                </div>
                <div class="profile_field_data"  v-if="!audienceDetail.is_reference_group">
                  <h6 class="profile-title">Churn status</h6>
                  <p class="profile-title">{{ audienceDetail.churn_status }}</p>
                </div>
                <div class="profile_field_data">
                  <h6 class="profile-title">Reference group</h6>
                  <p class="profile-title">{{ audienceDetail.is_reference_group?'Yes':'No' }}</p>
                </div>
                <div class="profile_field_data" v-if="audienceDetail.is_reference_group">
                  <h6 class="profile-title">Customer amount</h6>
                  <p class="profile-title">{{ audienceDetail.customer_amount }}</p>
                </div>
                <div class="profile_field_data" v-if="Object.keys(audienceDetail?.facebook_custom_audience).length>0">
                  <h6 class="profile-title">Facebook custom audience</h6>
                  <p class="profile-title">{{ audienceDetail?.facebook_custom_audience?.audience_id  }} | 
                    {{ audienceDetail?.facebook_custom_audience?.title }}
                  </p>
                  <p class="d-flex gap-3 mb-0">
                    <span class="action-btn" @click="showUpdateCustomAudienceModel"><RefreshIcon /></span>
                    <span class="action-btn" @click="showDeleteCustomAudienceModel"><DeleteIcon /></span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="edit-button form-btns">
                <router-link
                  :to="{
                    name: `AudienceEdit`,
                    params: { audienceid: $route?.params?.audienceid },
                  }"
                >
                  <button type="submit" class="save-btn">
                    <EditIcon />
                    Edit
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- audience details box end -->
      <!-- audeince customer list start -->
      <div class="col-lg-6">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <img
                src="../../../public/assets/images/Members.png"
                alt=""
              />Customers
            </h6>
            <div>
              <button
                type="button"
                class="cancle-btn mx-2"
                data-toggle="modal"
                data-target="#exportCsvModel"
               
              >
                Export
              </button>
              <button
                type="button"
                class="cancle-btn"
                data-toggle="modal"
                data-target="#sendMailModel"
                @click="getTemplateAndContactList"
              >
                Upload List    
              </button>
            </div>
            <!-- export csv Modal start -->
            <div
              class="modal fade"
              id="exportCsvModel"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exportCsvModelTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content custom-modal-content">
                  <div class="modal-body cusom-main-title">
                    <h5 class="modal-title" id="exportCsvModelTitle">
                      Do you want to export data in CSV?
                    </h5>
                    <!-- <p>Select Data That You want Include To Export</p>
                    <div class="form-check custom-check-title">
                      <label class="checkbox-container">
                        Article Preferences (has bought)
                        <input
                          type="checkbox"
                          :checked="checkStatus_bought"
                          @change="checkStatus_bought = !checkStatus_bought"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-check custom-check-title">
                      <label class="checkbox-container">
                        Article Preferences (most likely to buy)
                        <input
                          type="checkbox"
                          :checked="checkStatus_likely"
                          @change="checkStatus_likely = !checkStatus_likely"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-check custom-check-title">
                      <label class="checkbox-container">
                        Manually added customers
                        <input
                          type="checkbox"
                          :checked="checkStatus_manual"
                          @change="checkStatus_manual = !checkStatus_manual"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div> -->
                    <div class="modal-footer custom-button">
                      <button
                        type="button"
                        class="cancle-btn"
                        data-dismiss="modal"
                        id="export-modal-close"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        class="save-btn"
                        @click="generateMemberCSV"
                      >
                        Yes,export CSV
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- export csv Modal end -->

             <div
                class="modal fade"
                id="sendMailModel"
                tabindex="-1"
                role="dialog"
                aria-labelledby="sendMailModelmodelLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="sendMailModelmodelLabel">
                        Select Sendgrid template
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <Form
                      @submit="onSend"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                      class="form-horizontal"
                      action="#"
                    >
                      <div class="modal-body p-3">
                        <div class="loader-main" v-if="isLoadingShow">
                          <div
                            class="spinner-border"
                            role="status"
                            style="width: 36px; height: 36px; color: #5e66fa"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                        <div class="form-group add-contactlist" :class="{ 'has-error': errors.contact_list_id }">
                          <label for="account"
                            >Sendgrid Contact List<span class="required-field text-danger"
                              >*</span
                            ></label
                          >
                          <Field v-model="sendgrid.contact_list_id" name="contact_list_id">
                            <Multiselect
                              :id="'account-id'"
                              v-model="sendgrid.contact_list_id"
                              :options="[{ id: 0, name: '+ Add new contact list' },...this.sendgrid_contact_list_data]"
                              valueProp="id"
                              label="name"
                              trackBy="name"
                              :canClear="false"
                              @change="
                                () => {
                                  sendgrid.contact_list_id = '';
                                }
                              "
                              placeholder="please select sendgrid contact"
                            />
                          </Field>
                          <div class="form-control-feedback">{{ errors.contact_list_id }}</div>
                        </div>
                        <div v-show="sendgrid.contact_list_id == 0">
                          <div class="d-flex align-item-center justify-content-between mb-3">
                            <h6 class="m-0 offer-header">New Contact List Details</h6>
                            <span class="offer-close" @click="sendgrid.contact_list_id = null">
                              <!-- <img
                                src="../../../public/assets/images/cancle-icon.png"
                                alt=""
                              /> -->
                              <CrossIcon />
                            </span>
                          </div>
                          <Form
                            :validation-schema="product_schema"
                            v-slot="{ errors }"
                            class="form-horizontal offer-form"
                            action="#"
                            ref="productForm"
                          >
                            <div class="modal-body offer-body-modal">
                              <div class="form-group" :class="{ 'has-error': errors.name }">
                                <label for="name"
                                  >contact list name<span class="required-field text-danger"
                                    >*</span
                                  ></label
                                >
                                <Field
                                  as="input"
                                  type="text"
                                  class="form-control"
                                  name="name"
                                  placeholder="Enter contact list name"
                                  v-model="new_contactlist.name"
                                />
                                <div class="form-control-feedback">{{ errors.name }}</div>
                              </div>
                              </div>
                              <div class="form-group" :class="{ 'has-error': errors.detail }">
                              <Field as="input" type="hidden" class="form-control" name="detail" />
                              <div class="form-control-feedback">{{ errors.detail }}</div>
                            </div>
                            <button
                              type="submit"
                              ref="submit"
                              class="save-btn"
                              v-show="false"
                            >
                              create
                            </button>
                          </Form>
                        </div>
                        <!-- <div class="form-group" :class="{ 'has-error': errors.template_id }">
                          <label for="account"
                            >Sendgrid template<span class="required-field text-danger"
                              >*</span
                            ></label
                          >
                          <Field v-model="sendgrid.template_id" name="template_id">
                            <Multiselect
                              :id="'account-id'"
                              v-model="sendgrid.template_id"
                              :options="this.sendgrid_template_data"
                              valueProp="id"
                              label="name"
                              trackBy="name"
                              :canClear="false"
                              @change="
                                () => {
                                  sendgrid.version_id = '';
                                }
                              "
                              placeholder="please select sendgrid template"
                            />
                          </Field>
                          <div class="form-control-feedback">{{ errors.template_id }}</div>
                        </div>
                        <div class="form-group" :class="{ 'has-error': errors.version_id }">
                          <label for="property"
                            >template version<span class="required-field text-danger"
                              >*</span
                            ></label
                          >
                          <Field v-model="sendgrid.version_id" name="version_id">
                            <Multiselect
                              :id="'id'"
                              v-model="sendgrid.version_id"
                              :options="versionOptions"
                              valueProp="id"
                              label="name"
                              trackBy="name"
                              :canClear="false"
                              placeholder="please select version"
                            />
                          </Field>
                          <div class="form-control-feedback">{{ errors.version_id }}</div>
                        </div> 
                        <img :src="sendgrid_template_version_data?.thumbnail_url">
                        <div>
                          <h6 class="" v-if="sendgrid_template_version_data">
                          Dynamic Fields :
                          </h6>
                          <ul >
                            <li v-for="field in version_dynamic_fields" :key="field">{{field}}</li>
                          </ul>
                        </div>-->
                        
                      </div>
                      <div class="modal-footer">
                        <button type="submit" class="save-btn">Upload</button>
                        <button
                          type="button"
                          class="cancle-btn"
                          data-dismiss="modal"
                          aria-label="Close"
                          id="close-mail-model"
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
          </div>
          <div class="contant-details">
            <div class="overflow-auto position-relativ">
               <div class="loader-main" v-if="loadindShow" >
              <div
                  class="spinner-border"
                  role="status"
                  style="width: 36px; height: 36px; color: #5e66fa"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
             </div>
              <table class="table table-sm" id="my-table">
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Date Of Birth</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="member in audienceCustomers.results"
                    :key="member.id"
                  >
                    <td class="font-light-text" width="15%">{{ member.id }}</td>
                    <td width="30%" class="font-text"><router-link
                    :to="{
                      name: `CustomerDetailsGrid`,
                      params: { customerId: member.id },
                    }"
                    >{{ member.first_name }}</router-link></td>
                    <td class="font-light-text" width="20%">
                      {{ member.last_name }}
                    </td>
                    <td class="font-light-text" width="20%">
                      {{ member.sex }}
                    </td>
                    <td class="font-light-text" width="25%">
                      {{ member.birth_date }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination
                :page="page"
                :totalPages="totalPages"
                :name="'Customers'"
                :count="audienceCustomers ? audienceCustomers?.count : 0"
                :incrementpage="incrementpage"
                :decrementpage="decrementpage"
                :setpage="setpage"
                :perpage="10"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- audeince customer list end -->
    </div>
    <div
      class="modal fade"
      id="facebookCustomAudienceModel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="facebookCustomAudienceModelLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="facebookCustomAudienceModelLabel">
              Create Facebook custom audience
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Form
            @submit="onCustomAudienceCreate"
            :validation-schema="facebook_custom_aud_schema"
            v-slot="{ errors }"
            class="form-horizontal"
            action="#"
          >
            <div class="modal-body p-3">
              <div class="loader-main" v-if="isLoadingShow">
                <div
                  class="spinner-border"
                  role="status"
                  style="width: 36px; height: 36px; color: #5e66fa"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div class="form-group" :class="{ 'has-error': errors.ad_account_id }">
                <label for="account"
                  >Ad Account<span class="required-field text-danger"
                    >*</span
                  ></label
                >
                <Field v-model="custom_audience.ad_account_id" name="ad_account_id">
                  <Multiselect
                    :id="'ad-account-id'"
                    v-model="custom_audience.ad_account_id"
                    :options="facebook_adaccount_list"
                    valueProp="id"
                    label="name"
                    trackBy="name"
                    :canClear="false"
                    placeholder="please select facebook ad account"
                  />
                </Field>
                <div class="form-control-feedback">{{ errors.ad_account_id }}</div>
              </div>
              <div class="form-group" :class="{ 'has-error': errors.title }">
                <label for="title">Title<span class="required-field text-danger"
                    >*</span
                  ></label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="title"
                  placeholder="Enter title"
                  v-model="custom_audience.title"
                />
                <div class="form-control-feedback">{{ errors.title }}</div>
              </div>
              <div class="form-group" :class="{ 'has-error': errors.description }">
                <label for="description">Description<span class="required-field text-danger"
                    >*</span
                  ></label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="description"
                  placeholder="Enter Description"
                  v-model="custom_audience.description"
                />
                <div class="form-control-feedback">{{ errors.description }}</div>
              </div>

              
            </div>
            <div class="modal-footer">
              <button type="submit" class="save-btn">Create</button>
              <button
                type="button"
                class="cancle-btn"
                data-dismiss="modal"
                aria-label="Close"
                id="close-custom-model"
              >
                Cancel
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import { mapActions } from "vuex";
import Pagination from "../../Core/Pagination.vue";
import CustomModel from "../../Core/CustomModel.vue";
import { Form, Field } from "vee-validate";

import * as yup from "yup";
import { subject } from "@casl/ability";
import { RESOURCE_NAME } from "../audience.vars";
import router from "../../Router";
import EditIcon from "../../icons/edit.vue";
import RefreshIcon from "../../icons/refresh.vue";
import DeleteIcon from "../../icons/delete.vue";
import Multiselect from "@vueform/multiselect";
import CrossIcon from "../../icons/cross.vue";
import {
  DELETE_AUDIENCE,
  AUDIENCE_DETAIL,
  AUDIENCE_CUSTOMER_DETAIL,
  GET_SENDGRID_TEMPLATES,
  GET_SENDGRID_CONTACT_LIST,
  GET_FACEBOOK_ADACCOUNTS_LIST,
  GET_SENDGRID_TEMPLATE_VERSION,
  CREATE_FACEBOOK_CUSTOM_AUDIENCE,
  DELETE_FACEBOOK_CUSTOM_AUDIENCE,
  UPDATE_FACEBOOK_CUSTOM_AUDIENCE,
  SAVE_CONTACT_LIST
} from "@/Core/store/action-types";
export default {
  name: "AudienceDetailGrid",
  components: {
    Pagination,
    CustomModel,
    EditIcon,
    Multiselect,
    Form,
    Field,
    RefreshIcon,
    DeleteIcon,
    CrossIcon
  },
  data() {
    return {
      page: 1,
      new_contactlist: {},
      totalPages: [],
      audienceCustomers: [],
      deleteAudienceModelID: "deleteAudienceModel",
      deleteCustomAudienceModelID:"deleteCustomAudienceModel",
      updateCustomAudienceModelID:"updateCustomAudienceModel",
      deletepermission: this.$ability.can("delete", subject(RESOURCE_NAME, {})),
      loadindShow:false,
      isLoadingShow:false,
      sendgrid_template_data: [],
      sendgrid_contact_list_data: [],
      version_dynamic_fields: [],
      facebook_adaccount_list:[],
      selectedAudienceId: '',
      // sendgrid_template_version_data:null,
      schema: yup.object().shape({
        // template_id: yup.string().required('sendgrid template is required').nullable(),
        // version_id: yup.string().required().nullable('version is required').nullable(),
        contact_list_id: yup.string().required().nullable('version is required').nullable(),
        // view: yup.string().required().nullable(),
      }),
      facebook_custom_aud_schema: yup.object().shape({
        ad_account_id: yup.string().required('facebook ad account required').nullable('facebook ad account required'),
        title: yup.string().required().nullable('title is required').min(6),
        description: yup.string().required().min(6)
        // view: yup.string().required().nullable(),
      }),
      sendgrid:{
        // template_id:'',
        // version_id:'',
        // dynamic_fields:{}
        // contact_list_id : ''
      },
      custom_audience:{
        ad_account_id:'',
        title:'',
        description:''
      }
      // checkStatus_bought: false,
      // checkStatus_likely: false,
      // checkStatus_manual: false,
    };
  },
  computed: {
    ...mapGetters("audience", ["audienceDetail"]),
      // versionOptions() {
      //   const selectedTemplateId = this.sendgrid.template_id;
        
      //   if (selectedTemplateId && this.sendgrid_template_data) {
      //     const selectedTemplate = this.sendgrid_template_data.find(
      //       (item) => item.id === selectedTemplateId
      //     );
          
      //     if (selectedTemplate) {
      //       return selectedTemplate.versions || [];
      //     }
      //   }
        
        // return [];
      // },
  },
  methods: {
    ...mapActions("audience", [
      AUDIENCE_DETAIL,
      DELETE_AUDIENCE,
      AUDIENCE_CUSTOMER_DETAIL,
      CREATE_FACEBOOK_CUSTOM_AUDIENCE,
      UPDATE_FACEBOOK_CUSTOM_AUDIENCE,
      DELETE_FACEBOOK_CUSTOM_AUDIENCE,
      SAVE_CONTACT_LIST
    ]),
    ...mapActions("org", [
     GET_SENDGRID_TEMPLATES,
     GET_SENDGRID_CONTACT_LIST,
     GET_SENDGRID_TEMPLATE_VERSION
    ]),
    ...mapActions('campaign',[GET_FACEBOOK_ADACCOUNTS_LIST]),
    //get audience details
    initAudienceDetails() {
      if (
        this?.$route?.params?.audienceid &&
        this.$route.params.audienceid != ""
      ) {
        const audience_id = this.$route.params.audienceid;
        this[AUDIENCE_DETAIL](audience_id)
          .then(() => {
            console.log("audience details success");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    //get audeince customers data
    initAudienceCustomers() {
      if (
        this?.$route?.params?.audienceid &&
        this.$route.params.audienceid != ""
      ) {
        const audience_id = this.$route.params.audienceid;
        let options = {
          audience_id: audience_id,
          params: {
            page: this.page,
          },
        };
        this.loadindShow = true
        this[AUDIENCE_CUSTOMER_DETAIL](options).then((resp) => {
          this.totalPages = Array(Math.ceil(resp.count / 10))
            .fill(0)
            .map((e, i) => i + 1);
          this.audienceCustomers = resp;
        }).finally(()=>this.loadindShow=false)
      }
    },
    //delete audience
    deleteAudienceDetail() {
      window.$(`#${this.deleteAudienceModelID}`).modal("hide");
      this[DELETE_AUDIENCE](this.audienceDetail.id).then(() => {
        this.$alertify.notify(
          `${this.audienceDetail.name} successfully removed.`,
          "success",
          3
        );
        router.push("/Audience");
      });
    },
    getTemplateAndContactList() {
      this.getSendgridContactList();
      // this.getSendgridTemplates();
    },
    // getSendgridTemplates() {
    //   if (!this.sendgrid_template_data || this.sendgrid_template_data.length == 0) this.isLoadingShow = true;
    //   this[GET_SENDGRID_TEMPLATES]()
    //     .then((resp) => {
    //       if (resp["templates"].length == 0) {
    //         this.$alertify.notify(
    //           "There is no template found,Please check your account",
    //           "error",
    //           3
    //         );
    //       }
    //       this.sendgrid_template_data = resp["templates"];
    //     })
    //     .catch((e) => {
    //       this.$alertify.notify(
    //         e?.response?.data?.detail || "There is some error to load the templates, Try again",
    //         "error",
    //         3
    //       );
    //       this.sendgrid_template_data = [];
    //     })
    //     .finally(() => (this.isLoadingShow = false));
    // },
    getSendgridContactList() {
      if (!this.sendgrid_contact_list_data || this.sendgrid_contact_list_data.length == 0) this.isLoadingShow = true;
      this[GET_SENDGRID_CONTACT_LIST]()
        .then((resp) => {
          if (resp["result"].length == 0) {
            this.$alertify.notify(
              "There is no contacts found,Please check your account",
              "error",
              3
            );
          }
          this.sendgrid_contact_list_data = resp["result"];
        })
        .catch((e) => {
          this.$alertify.notify(
            e?.response?.data?.detail || "There is some error to load the contacts, Try again",
            "error",
            3
          );
          this.sendgrid_contact_list_data = [];
        })
        .finally(() => (this.isLoadingShow = false));
    },
    getFacebookAdAccounts() {
    this.isLoadingShow = true;
     this[GET_FACEBOOK_ADACCOUNTS_LIST]().then((response)=>{
          this.facebook_adaccount_list=response
        }).catch((e)=>{
          this.$alertify.notify(
                e?.response?.data?.detail || "Some error occured with Facebook Ads connection, please try again",
                "error",
                3
              );
        }).finally(() => (this.isLoadingShow = false));
    },
    onCustomAudienceCreate(values){
      values.id = this.audienceDetail.id
      this[CREATE_FACEBOOK_CUSTOM_AUDIENCE](values).then(()=>{
        this.initAudienceDetails();
        this.$alertify.notify(
          `facebook custom audience created successfully`,
          "success",
          3
        );
      }).catch((e)=>{
          this.$alertify.notify(
                e?.response?.data?.detail || "Some error occured with Facebook Ads connection, please try again",
                "error",
                3
              );
        }).finally(()=> document.getElementById("close-custom-model").click())

    },

    onCustomAudienceUpdate(){
      let obj={
        id:this.audienceDetail.id
      }
      this[UPDATE_FACEBOOK_CUSTOM_AUDIENCE](obj).then(()=>{
        this.$alertify.notify(
          `facebook custom audience updated successfully`,
          "success",
          3
        );
      }).catch((e)=>{
          this.$alertify.notify(
                e?.response?.data?.detail || "Some error occured with Facebook Ads connection, please try again",
                "error",
                3
              );
        }).finally(()=>window.$(`#${this.updateCustomAudienceModelID}`).modal("hide"))
    },

    onCustomAudienceDelete(){
      let obj={
        id:this.audienceDetail.id
      }
      this[DELETE_FACEBOOK_CUSTOM_AUDIENCE](obj).then(()=>{
        this.initAudienceDetails();
        this.$alertify.notify(
          `facebook custom audience deleted successfully`,
          "success",
          3
        );
      }).catch((e)=>{
          this.$alertify.notify(
                e?.response?.data?.detail || "Some error occured with Facebook Ads connection, please try again",
                "error",
                3
              );
        }).finally(()=>window.$(`#${this.deleteCustomAudienceModelID}`).modal("hide"))
    },

    // getSendgridVersion() {
    //   let options = {
    //      template_id:this.sendgrid.template_id,
    //      version_id:this.sendgrid.version_id
    //     };
    //   this.isLoadingShow = true
    //   this[GET_SENDGRID_TEMPLATE_VERSION](options)
    //     .then((resp) => {
    //       if (!resp["template_data"]) {
    //         this.$alertify.notify(
    //           "There is no template found,Please check your account",
    //           "error",
    //           3
    //         );
    //       }
    //       this.sendgrid_template_version_data = resp["template_data"]
    //       this.version_dynamic_fields=resp['dynamic_fields']
    //     })
    //     .catch(() => {
    //       this.$alertify.notify(
    //         "There is some error to load the version, Try again",
    //         "error",
    //         3
    //       );
    //       this.sendgrid_template_version_data = null;
    //     })
    //     .finally(() => (this.isLoadingShow = false));
    // },
    //open delete model
    showDeleteModel() {
      window.$(`#${this.deleteAudienceModelID}`).modal("toggle");
    },
    showDeleteCustomAudienceModel() {
      window.$(`#${this.deleteCustomAudienceModelID}`).modal("toggle");
    },
    showUpdateCustomAudienceModel() {
      window.$(`#${this.updateCustomAudienceModelID}`).modal("toggle");
    },
    //go to next page
    incrementpage() {
      this.page = this.page + 1;
      this.initAudienceCustomers();
    },
    //go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.initAudienceCustomers();
    },
    //go to perticular page
    setpage(page) {
      this.page = page;
      this.initAudienceCustomers();
    },
    //generate CSV of customers
    generateMemberCSV() {
        const audience_id = this.$route.params.audienceid;
        let options = {
          audience_id: audience_id,
        };
        this[AUDIENCE_CUSTOMER_DETAIL](options).then((resp) => {
          let blankData = [
            {
              id: "",
              name: "",
              email: "",
              birth_date: "",
              is_active: "",
              phone: "",
              sex: "",
              external_id: "",
              created_at: "",
              store: null,
            },
          ];
          let arrData = [
            {
              customers: resp.length > 0 ? resp : blankData,
            },
          ];
          console.log("array---->", arrData)
          // // Assuming the name field is in the format "First Last"
          // let modifiedData = arrData[0].customers.map((customer) => {
          //   let nameParts = customer.name.split(" ");
          //   return {
          //     first_name: nameParts[0],
          //     last_name: nameParts.slice(1).join(" "),
          //     ...customer,
          //   };
          // });
          // console.log("modifiedData---->", modifiedData)
          let csvContent = "data:text/csv;charset=utf-8,";
          csvContent += arrData[0].customers.map((item) =>
            Object.values(item).join(",")
          ).join("\n");
          const data = encodeURI(csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", data);
          link.setAttribute(
            "download",
            `${this.audienceDetail.id}-${this.audienceDetail.name}-customers.csv`
          );
          link.click();
          // window.$('#exportCsvModel').modal("hide");
          document.getElementById("export-modal-close").click()
        });
    },
    
    //articles for multiselect options
    getprefarticle() {
      let preArr = [];
      this.audienceDetail.article_boughts.map((article) => {
        preArr.push(article.id);
      });
      
      return preArr;
    },
    onSend(values){
      let ids = []
      for (let customer of this.audienceCustomers.results) {
        ids.push(customer.id);
      }
      values['ids'] = ids
      values['dynamic_fields'] = this.version_dynamic_fields
      values['new_contact_list'] = this.new_contactlist
      values['selected_audience_id'] = this.selectedAudienceId
      this[SAVE_CONTACT_LIST](values).then((resp)=>{
        this.$alertify.notify(
          `${resp.Success}`,
          "success",
          3
        );
      }).catch((e)=>{
          this.$alertify.notify(
                e?.response?.data?.detail || "Some error occured while sending Email, please try again!",
                "error",
                3
              );
        }).finally(()=> document.getElementById("close-mail-model").click())
    }
  },
  mounted() {
    this.initAudienceDetails();
    this.initAudienceCustomers();
  },
  // watch:{
  //   sendgrid:{
      
  //     handler(){
  //       this.sendgrid_template_version_data=null
  //      if(this.sendgrid.template_id && this.sendgrid.version_id)
  //        this.getSendgridVersion()
  //     },
  //     deep: true
  //    },
     
      
  // }
};
</script>
<style scoped>
.audiance_section {
  display: flex;
  justify-content: space-between;
}

.title_section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.icon_title h1 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.sub_title {
  display: flex;
  gap: 4rem;
  border-bottom: 1px solid #dee2e6;
}

.second_underline_section {
  margin-top: 3rem;
}

.sub_title h6 {
  font-size: 12px;
  color: #aaabad;
}

.sub_profile-title {
  width: 70%;
}

p.profile-title {
  margin: 0;
  width: 70%;
  color: #3f3f40;
  line-height: 28px;
  font-size: 15px;
  font-weight: 600;
}
#my-table tbody tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #3f3f40;
  padding: 6px 0;
  line-height: 36px;
}
.modal-footer.custom-button {
  background-color: #ffff;
  border-color: #fff;
  float: left;
}
.modal-content.custom-modal-content {
  padding: 3%;
  border-radius: 0;
}
.custom-check-title {
  padding: 10px;
}
.cusom-main-title {
  padding-bottom: 15px;
}
#exportCsvModel .modal-title {
  font-size: 25px;
  padding-bottom: 10px;
  font-weight: 600;
}
.modal-content .modal-body p,
.modal-content h4 {
  color: #7081b9;
  font-size: 16px;
  color: #3f3f40;
}
.loader-main {
  z-index:99
}
.action-btn{
  cursor: pointer;
}
</style>
