<template>
  <!-- Edit user modal window -->
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="editDialogLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editDialogLabel">Edit Product</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ProductForm
          v-on:form-change="onFormChange"
          :onSubmit="onSubmit"
          :isEditMode="true"
          :initData="productToEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ProductForm from "./ProductForm.vue";
import { UPDATE_PRODUCT } from "@/Core/store/action-types";

export default {
  name: "EditProduct",
  components: {
    ProductForm,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
    productToEdit: {
      required: true,
      type: Object,
    },
  },

  methods: {
    ...mapActions("product", [UPDATE_PRODUCT]),

    onSubmit(values,actions) {
      console.log(values,this.productToEdit.id);
      values.id = this.productToEdit.id;
      this[UPDATE_PRODUCT ](values)
        .then(() => {
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify(
            "product successfully updated.",
            "success",
            3
          );
          actions.resetForm();
          this.$parent.initProducts(1);
        })
        .catch((e) => {
          this.errMsg = e.message;
          actions.setFieldError('detail', e.message || 'something went wrong');
        });
    },

    onFormChange(form) {
      this.formValues = form;
    },
  },

  data() {
    return {
      formValues: {},
    };
  },
};
</script>