<template>
  <Form
    @submit="onSubmit"
    :validation-schema="schema"
    v-slot="{ errors }"
    class="form-horizontal"
    action="#"
  >
    <div class="modal-body">
      <span as="button" class="d-flex justify-content-end file-upload-title" v-if="!isFileUploadMode && !isEditMode" @click="isFileUploadMode = true"><i class="bi bi-file-earmark-arrow-up-fill"></i>Import From File</span>
      <span as="button" class="d-flex justify-content-end file-upload-title mb-2" v-if="isFileUploadMode" @click="isFileUploadMode = false"><i class="bi bi-file-text"></i>Add manually</span>
     <div v-if="!isFileUploadMode">
      <div
        class="form-group"
        :class="{ 'has-error': errors.country_name }"
      >
        <label for="country_name"
          >Country name<span class="required-field text-danger">*</span></label
        >
        <Field
          name="country_name"
          v-model="formValues.country_name"
        >
        <Multiselect
            :id="isEditMode+'country-name'"
            valueProp="name"
            label="name"
            trackBy="name"
            :searchable="true"
            v-model="formValues.country_name"
            :options="country_list"
            placeholder="please select country"
          />
        </Field>
        <div class="form-control-feedback">{{ errors.country_name }}</div>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.amount }">
        <label for="amount"
          >Amount<span class="required-field text-danger"
            >*</span
          ></label
        >
        <Field
          as="input"
          type="number"
          step="0.01"
          class="form-control"
          name="amount"
          placeholder="Enter shipping amount"
          v-model="formValues.amount"
        />
        <div class="form-control-feedback">{{ errors.amount }}</div>
      </div>
        <div class="form-group" :class="{ 'has-error': errors.currency }">
        <label for="amount"
          >currency<span class="required-field text-danger"
            >*</span
          ></label
        >
        <Field v-model="formValues.currency" name="currency">
          <Multiselect
            :id="'-currency'"
            v-model="formValues.currency"
            :options="['SEK','USD','EUR','GBP']"
            placeholder="please select an currency"
          />
        </Field>
        <div class="form-control-feedback">{{ errors.currency }}</div>
      </div>
     </div>
     <div v-else>
       <div class="alert alert-success" role="alert">
            You can send shipping charges by upload them in MS Excel format
            (.xlxs). This will update the existed shipping charges, and add new shipping charges.
            Template for the Excel structure:
            <a href="/assets/files/shipping_charges.xlsx" download
              ><ul>
                click here
              </ul></a
            >
          </div>
          <div>
            <div class="d-flex">
              <input
                type="file"
                id="file-upload-customers"
                class="form-control d-none"
                @change="onShippingFileChange"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <label for="file-upload-customers" class="save-btn" role="button">
                Select File</label
              >
              <span class="p-2">{{ shippingChargesFile?.name }}</span>
            </div>
            <div class="form-control-feedback">
              {{ shippingChargesFileErrMSg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
     </div>
     <div class="form-group" :class="{ 'has-error': errors.detail }">
        <Field as="input" type="hidden" class="form-control" name="detail" />
        <div class="form-control-feedback">{{ errors.detail }}</div>
      </div>
      <!--end form-group-->
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        class="save-btn"
        v-if="isFileUploadMode"
        @click="HandleShippingFileUpload"
      >       
        <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
          <span class="visually-hidden">Loading...</span>
        </div>
        <span v-else>Add File</span>
      </button>
      <button v-else type="submit" class="save-btn">
        {{ isEditMode ? "Save" : "Create" }}
      </button>
      <button type="button" class="cancle-btn" data-bs-dismiss="modal">
        Cancel
      </button>
    </div> </Form
  ><!--end form-->
</template>

<script>
import { Field, Form } from "vee-validate";
import { mapGetters} from 'vuex';
import Multiselect from "@vueform/multiselect";
// import {PHONE_REGEX} from "../../Core/helpers/utils"
import * as yup from "yup";
import countryListAllIsoData from "../../Core/helpers/countryList"
export default {
  name: "ShippingChargeForm",
  components: {
    Form,
    Multiselect,
    Field,
  },
  props: {
    isEditMode: {
      required: false,
      default: false,
    },
    onSubmit: {
      required: true,
    },
    initData: {
      required: false,
    },
    onFileUpload:{
      required: false,
    }
  },
  emits: ["form-change"],
   computed: {
    ...mapGetters("org",["org","default_currency"]),
   },
  methods:{
    onShippingFileChange(e){
      this.shippingChargesFile= e.target.files[0];
    },
     HandleShippingFileUpload(){
      this.isLoading=true
      if (this.shippingChargesFile) {
        this.onFileUpload(this.shippingChargesFile)
      } else {
        this.shippingChargesFileErrMSg= "Please select file";
      }
      this.isLoading=false
    }
  },
  watch: {
    formValues: {
      handler: function (newForm) {
        this.$emit("form-change", newForm);
      },
      deep: true,
    },
    initData: function (newStore) {
      this.formValues = {
        country_name: newStore.country_name,
        amount: newStore.amount,
        currency:newStore.currency || this.default_currency,
      };
    },
  },

  data() {
    return {
      formValues: {},
      country_list:countryListAllIsoData,
      shippingChargesFile:null,
      isLoading:false,
      shippingChargesFileErrMSg:"",
      isFileUploadMode:false,
      schema: yup.object().shape({
        country_name: yup.string().required("please select country").nullable(true,"please select country"),
        currency: yup.string().required("please select currency").nullable(true,"please select currency"),
        amount: yup.number().typeError('Please enter valid shipping amount').required("Please enter shipping amount").nullable(true,"Please enter shipping amount"),
        detail: yup
          .string() // use it for backend errors
          .nullable()
          .notRequired(),
      }),
    };
  },
}
</script>
