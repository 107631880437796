<template>
  <div class="row">
    <!-- edit audience form start -->
    <div class="col-lg-8">
      <div class="contant-box">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFESURBVHgBhVHLUcNADJVkkwk30wElhA5CBxRAfjMJww1TQUIFhGty2RAOHCkhVIA7wHTg4ULi2BJaEzPGxsmb2bGsfW/03gqhhO4ovkHENgh4gmIeZ41F8R7/kK/iN/209AS7Vlar6CznUF50RnHfEljYtwR7BPjW9uzUioAIe2ojeJo3H/LectacqolArbUrAkUEdRD0KgJhWWmiVme49n9tZrW0EMHAvtC4Cy37QltkQQUGjBAxSKgKv0i2cIs/fV88WCchM6zscEL+gBKw6BeRxtrxSolDpU3yBdKP980Eie7VcyCC7c/46EQJKI5uHNC+nukON5NsQv/665TZeVfvZjlvDOAfXA43xu6JOT0nEWdsx2636R3UwE0avj5A5DhOj4ThQm2sns1xWCcwBiMkeLFcFxCnwskrHECapgsiN/wGgX6SW5zKhKcAAAAASUVORK5CYII="
                alt=""
              />Edit audience details
            </h6>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-12">
                  <div class="form-group" :class="nameError ? 'has-error' : ''">
                    <label class="form-label w-100"
                      >Name<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      v-model="details.name"
                      @keyup="validateName"
                      @blur="validateName"
                    /><span class="error">{{ errMsg }}</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="contant-header">
            <h6>
              <img
                src="../../../public/assets/images/Members.png"
                alt=""
              />Customers
            </h6>
            <div class="members_view">
              <p>
                Customers selected: {{ this.details.customers?.length }}
                <span>(from {{ totalCustomers }})</span>
              </p>
              <button
                class="btn add_members btn-demo"
                data-toggle="modal"
                data-target="#myModal2"
                @click="addCustomerMannually"
              >
                Add customers manually
              </button>
            </div>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-lg-12" v-if="!is_reference_group">
                  <div class="form-group">
                    <label for="apref_type"
                        >Article Preference Type</label
                      >
                      <Field
                        v-model="pref_type"
                        name="pref_type"
                      >
                      <!-- <Multiselect
                        v-model="articles_most_likely_buy"
                        mode="tags"
                        :minChars="3"
                        :resolveOnLoad="false"
                        :filterResults="false"
                        label="name"
                        valueProp="id"
                        :delay="0"
                        :searchable="true"
                        :options="[...[fetchArticles],...article_default_options]"
                        placeholder="Select articles"
                        ref="article_select"
                      /> -->
                      <Multiselect
                          v-model="pref_type"
                          :options="pref_options"
                          label="key"
                          valueProp="value"
                          placeholder="Select article preference type"
                          @select="onPrefChange"
                          @close="onPrefChange"
                      />
                        <span class="error">{{ pref_type_error }}</span>
                    </Field>
                  </div>
                </div>
                <div class="col-lg-12" v-if="!is_reference_group">

                  <div class="row">

                    <div class="form-group col">
                      <label for="articles"
                        >Article Preferences</label
                      >
                      <Field v-model="articles" name="articles">
                        <Multiselect
                          v-model="articles"
                          mode="tags"
                          :filterResults="false"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          @search-change="fetchArticles"
                          :options="article_options"
                          placeholder="Select articles"
                          ref="article_select"
                        />
                      </Field>
                    </div>

                    <div class="form-group col-5" v-show="pref_type === 'likely_to_buy_again' || pref_type === 'likely_to_buy'">
                      <label for="date">Likely purchase period</label>
                      <div class="input-group">
                        <input type="text" ref="date" placeholder="Enter date" class="form-control" v-model="buy_again_date"/>
                        <span class="input-group-append">
                          <button class="close border border-secondary border-1 rounded-right" type="button" @click="(e) => buy_again_date = ''">
                            <i class="bi bi-x"></i>
                          </button>
                        </span>
                      </div>
                    </div>

                  </div>

                </div>

                <div class="col-12" v-if="is_reference_group">
                  <div class="form-group">
                    <label class="form-label w-100"
                      >Customer Amount</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="customer amount"
                      v-model="customer_amount"
                      @change="onAmountChange"
                    />
                  </div>
                </div>
                <!-- <div class="audience-checkbox">
                  <label class="checkbox-container"
                    >Bought all selected articles
                    <input
                      type="checkbox"
                      :checked="checkStatus"
                      @change="onCheckStatusChanged"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div> -->
                <div class="col-4">
                  <div class="form-group">
                    <label class="form-label w-100">Gender</label>
                    <select
                      class="form-control custom-select"
                      v-model="gender"
                      @change="ongenderChange"
                    >
                      <option value="All">All</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Corporate">Corporate</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-8">
                  <div class="form-group">
                    <label class="form-label w-100">Age</label>
                    <div class="slider_form">
                      <input
                        disabled
                        type="text"
                        class="form-control ageSliderinput"
                        v-model="ageSlider[0]"
                      />
                      <Slider v-model="ageSlider" @change="ageChanged" />
                      <input
                        disabled
                        type="text"
                        class="form-control ageSliderinput"
                        v-model="ageSlider[1]"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-4" v-if="!is_reference_group">
                  <div class="form-group">
                    <label class="form-label w-100">Churn status</label>
                    <select
                      class="form-control custom-select"
                      v-model="churn_status"
                      @change="onchurn_statusChange"
                    >
                      <option value="All">All</option>
                      <option value="churned">Churned</option>
                      <option value="possible_churn">Possible churn</option>
                      <option value="soon_to_churn">Soon To Churn</option>
                      <option value="no_churn">No churn</option>
                    </select>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-btns">
                    <button type="button" class="cancle-btn" @click="oncancel">
                      Cancel</button
                    ><button
                      type="submit"
                      class="save-btn"
                      @click.prevent="updateAudienceDetail"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- edit audience form end -->
    <!-- add customer modal start -->
    <div
      class="modal right fade"
      id="myModal2"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel2"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="contant-header">
            <h6>
              <img src="../../../public/assets/images/Members.png" alt="" />Add
              customer manually
            </h6>
            <div class="model_close">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>

          <div class="modal-body">
            <div class="modal_search_filter">
              <div class="search-box-wrp">
                <input
                  type="text"
                  class="form-control"
                  name=""
                  placeholder="Search by the member name"
                  v-model="search"
                />
                <a class="search_icon" href="#"
                  ><img
                    src="../../../public/assets/images/search_icon.svg"
                    alt=""
                /></a>
              </div>
              <div class="tab-box-wrp">
                <ul>
                  <li
                    :class="selected == 'all' ? 'active' : 'inactive'"
                    @click="selected = 'all'"
                  >
                    All
                  </li>
                  <li
                    :class="selected == 1 ? 'active' : 'inactive'"
                    @click="selected = 1"
                  >
                    Selected
                  </li>
                  <li
                    :class="selected == 0 ? 'active' : 'inactive'"
                    @click="selected = 0"
                  >
                    Not selected
                  </li>
                </ul>
              </div>
            </div>
            <div class="model_table">
              <div class="table-wrp overflow-auto">
                <table class="table my-table-wrp table-sm" id="my-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th width="10%">ID</th>
                      <th width="50%" @click="sortTable">
                        Name
                        <span>
                          <span :class="sortCount == 2 ? 'sort-active' : ''"
                            >&#8595;</span
                          >
                          <span :class="sortCount == 3 ? 'sort-active' : ''"
                            >&#8593;</span
                          >
                        </span>
                      </th>
                      <th width="20%">Gender</th>
                      <th class="birth_date" width="20%">Date of Birth</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- :class="
                        selected == true ? 'active-row' : 'active-row selected'
                      " -->
                    <tr
                      :class="
                        tempCustomers?.includes(member.id)
                          ? 'active-row selected'
                          : 'active-row'
                      "
                      v-for="member in customersList
                        ? filterMember.slice((page - 1) * 10, page * 10)
                        : []"
                      :key="member.id"
                    >
                      <td>
                        <div class="checkbox-list">
                          <label class="checkbox">
                            <input
                              @change="AddRemoveCustomer(member.id)"
                              class="checkbox-input"
                              type="checkbox"
                              :checked="checkedFilter(member.id)"
                            />
                            <span class="checkbox-checkmark-box">
                              <span class="checkbox-checkmark"></span>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td class="font-light-text">{{ member.id }}</td>
                      <td>{{ member.name }}</td>
                      <td class="font-light-text">{{ member.sex }}</td>
                      <td class="font-light-text birth_date">
                        {{ member.birth_date }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Pagination
                :page="page"
                :totalPages="
                  customersList
                    ? Array(Math.ceil(filterMember.length / 10))
                        .fill(0)
                        .map((e, i) => i + 1)
                    : []
                "
                :name="'Customers'"
                :count="customersList ? filterMember.length : 0"
                :incrementpage="incrementpage"
                :decrementpage="decrementpage"
                :setpage="setpage"
                :perpage="10"
              />
            </div>
            <div class="col-12">
              <div class="form-btns">
                <button
                  type="button"
                  class="cancle-btn"
                  @click="oncancelModel"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel</button
                ><button
                  type="submit"
                  class="save-btn"
                  @click="onsave"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal-content end-->
      </div>
      <!-- modal-dialog end-->
    </div>
    <!-- add customer modal end -->
  </div>
</template>
<script>
import Slider from "@vueform/slider";
import { Field } from "vee-validate";
import Pagination from "../../Core/Pagination.vue";
import { mapActions, mapGetters } from "vuex";
import Multiselect from "@vueform/multiselect";
import {
  LIST_AUDIENCE,
  UPDATE_AUDIENCE,
  LIST_ARTICLES,
  AUDIENCE_DETAIL,
  LIST_CUSTOMERS,
} from "@/Core/store/action-types";
// import { deleteItem } from "@/Core/helpers/gridUtils";
const DEFAULT_AGE_SLIDER = [0, 100];
export default {
  name: "EditAudience",
  components: {
    Field,
    Slider,
    Pagination,
    Multiselect,
  },
  data() {
    return {
      ageSlider: [...DEFAULT_AGE_SLIDER],
      selected: "all",
      page: 1,
      totalPages: [],
      details: {
        name: "",
        customers: [],
      },
      gender: "All",
      churn_status: "All",
      search: "",
      members: null,
      tempCustomers: [],
      nameError: false,
      totalCustomers: 0,
      asc: null,
      des: null,
      sortCount: 1,
      articles: [],
      articles_most_likely_buy: [],
      buy_again_date: "",
      errMsg: "",
      checkStatus: false,
      article_default_options:[],
      article_options:[],
      pref_options: [
        { value: "has_bought", key: "Has bought" },
        {
          value: "likely_to_buy",
          key: "Likely to buy ",
        },
        {
          value: "similar_articles",
          key: "Similar articles",
        },
        {
          value: "likely_to_buy_again",
          key: "Likely to buy Again",
        },
        { value: "all_fields", key: "All Fields" },
      ],
      pref_type: "",
      pref_type_error: "",
      choosencustomer: [],
      is_reference_group: false,
      customer_amount:null,
    };
  },
  computed: {
    ...mapGetters("audience", ["audienceDetail", "audienceList"]),
    ...mapGetters("article", ["articlesList"]),
    ...mapGetters("customer", ["customersList"]),
    //filter customer based on slection.
    filterMember() {
      if (this.customersList) {
        if (this.selected == 1)
          return this.customersList.filter((member) =>
            this.tempCustomers.includes(member.id)
          );
        else if (this.selected == 0)
          return this.customersList.filter(
            (member) => !this.tempCustomers.includes(member.id)
          );
        else return this.customersList;
      }
      return [];
    },
  },
  methods: {
    ...mapActions("audience", [
      AUDIENCE_DETAIL,
      UPDATE_AUDIENCE,
      LIST_AUDIENCE,
    ]),
    ...mapActions("customer", [LIST_CUSTOMERS]),
    ...mapActions("article", [LIST_ARTICLES]),
    //checkbox filter
    checkedFilter(id) {
      if (this.tempCustomers.includes(id)) return true;
      else return false;
    },
    //get audience details.
    initAudienceDetails() {
      if (
        this?.$route?.params?.audienceid &&
        this.$route.params.audienceid != ""
      ) {
        const audience_id = this.$route.params.audienceid;
        this[AUDIENCE_DETAIL](audience_id)
          .then((resp) => {
            // this.details = JSON.parse(JSON.stringify(resp));
            this.details.name = resp.name;
            this.details.customers = resp.members;
            this.details.id = resp.id;
            this.gender = resp.gender;
            this.churn_status = resp.churn_status;
            this.buy_again_date = resp.buy_again_date ? resp.buy_again_date : "";
            this.ageSlider[0] = resp.age_min;
            this.ageSlider[1] = resp.age_max;
            this.articles = this.getarticleIDs(resp.articles);
            this.article_default_options=resp.articles
            this.article_options=this.article_default_options
            this.pref_type=resp.article_pref_type
            this.customer_amount=resp.customer_amount
            this.is_reference_group=resp.is_reference_group
            // this.articles_most_likely_buy = this.getarticleIDs(
            //   resp.article_most_likelies
            // );
            this.details.customers.map((member) =>
              this.tempCustomers.push(member)
            );
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    //get customers list
    initCustomerList(count = false) {
      if (count) {
        let options = {
          persist: true,
        };
        this[LIST_CUSTOMERS](options)
          .then((resp) => {
            this.totalCustomers = resp.length;
          })
          .catch((err) => {
            console.log("error", err);
          });
      } else {
      let options = {
        persist: count,
        params: {
          age_min: this.ageSlider[0] > 0 ? this.ageSlider[0] : null,
          age_max: this.ageSlider[1] < 100 ? this.ageSlider[1] : null,
          sex: this.gender != "All" ? this.gender : null,
          churn_status: this.churn_status != "All" ? this.churn_status : null,
          pref_type: this.articles.length != 0 ? this.pref_type : null,
          prefs: this.articles.length != 0 ? this.articles : null,
          customer_amount:this.customer_amount,
          buy_again_date: ((this.pref_type === 'likely_to_buy_again' || this.pref_type === 'likely_to_buy') &&
              this.articles.length && this.buy_again_date) ? this.buy_again_date : null,
        },
      };
      if (Object.values(options.params).some((v) => v))
      this[LIST_CUSTOMERS](options)
        .then((resp) => {
            this.details.customers = [];
            resp.map((member) => this.details.customers.push(member.id));
        })
        .catch((err) => {
          console.log("error", err);
        });
        else {
          (this.details.customers = []), (this.details.customers = []);
        }
      }
    },
    onPrefChange(){
      console.log("fsfdsf111")
      if (this.articles.length > 0 && !this.pref_type) {
        this.details.customers = [];
        this.pref_type_error = "please select articles preference type";
      } else {
        this.pref_type_error = "";
        if (this.articles.length > 0) this.initCustomerList();
      }
    },
    //on add customers
    addCustomerMannually() {
      this.tempCustomers = [];
      this.details.customers.map((member) => this.tempCustomers.push(member));
    },
    //on add or remove customers
    AddRemoveCustomer(id) {
      setTimeout(() => {
        if (this.tempCustomers.includes(id)) {
          this.tempCustomers = this.tempCustomers.filter(
            (member_id) => member_id != id
          );
        } else {
          this.tempCustomers.push(id);
        }
      }, 500);
    },
    // on gender change get updated customers
    ongenderChange() {
      this.initCustomerList();
    },
    // on churn_status change get updated customers
    onchurn_statusChange() {
      this.initCustomerList();
    },
    // on age change get updated customers
    ageChanged() {
      this.page = 1;
      this.initCustomerList();
    },
    onAmountChange(){
      this.page = 1;
      this.initCustomerList();
    },
    //on checkbox status change
    onCheckStatusChanged() {
      this.checkStatus = !this.checkStatus;
      this.initCustomerList();
    },
    //on cancel button go to audience details page.
    oncancel() {
      this.$router.push({
        name: "AudienceDetails",
      });
    },
    //on cancel add customer manually model
    oncancelModel() {
      this.tempCustomers = [];
      this.details.customers.map((member_id) =>
        this.tempCustomers.push(member_id)
      );
    },
    //on save add customer manually model
    onsave() {
      this.details.customers = [];
      this.tempCustomers.map((member_id) =>
        this.details.customers.push(member_id)
      );
    },
    //name validation
    validateName() {
      this.errMsg = "";
      if (!this.details.name) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.details.name.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else this.nameError = false;
    },
    //update functioanlity
    updateAudienceDetail() {
      this.errMsg = "";
      if (!this.details.name) {
        this.nameError = true;
        this.errMsg = "Please enter audience name";
      } else if (this.details.name.trim().length < 6) {
        this.nameError = true;
        this.errMsg = "Name must be at least 6 characters";
      } else if (this.details.customers.length <= 0) {
        this.$alertify.notify("Please select customers", "error", 3);
      } else {
        let payload = {
          id: this.details.id,
          name: this.details.name,
          members: this.details.customers,
          articles: this.articles,
          article_pref_type:this.pref_type,
          // article_most_likely: this.articles_most_likely_buy,
          gender: this.gender,
          churn_status: this.churn_status,
          age_min: this.ageSlider[0],
          age_max: this.ageSlider[1],
          is_reference_group:this.is_reference_group,
          customer_amount:this.customer_amount,
          buy_again_date: ((this.pref_type === 'likely_to_buy_again' || this.pref_type === 'likely_to_buy') &&
              this.articles.length && this.buy_again_date) ? this.buy_again_date : null,
        };
        this[UPDATE_AUDIENCE](payload)
          .then(() => {
            (this.ageSlider = DEFAULT_AGE_SLIDER), (this.gender = "All");
            this.articles = [];
            this.$alertify.notify(
              `${this.details.name} updated successfully. `,
              "success",
              3
            );
            this.$router.push({
              name: "AudienceDetails",
            });
          })
          .catch(() => this.$alertify.notify("Action Forbidden!", "error", 3));
      }
    },
    //get article ID from article object
    getarticleIDs(data) {
      let preArr = [];
      data.map((article) => {
        preArr.push(article.id);
      });
      return preArr;
    },
    // async test(q){
    //   if(q.length>3){

    //   } 
    //     this.re
    // },
    //fetch article for multiselect
    async fetchArticles(query) {
      console.log("Fdfsdf",query);
      if(query.length>2){
        let result = await this[LIST_ARTICLES]({ params: { name: query } })
        this.article_options = [...this.article_default_options,...result];
      }
      else this.article_options=this.article_default_options
      return 
    },
    //sort customers table
    sortTable() {
      this.sortCount++;
      if (this.sortCount > 3) this.sortCount = 1;
      if (this.sortCount === 1) {
        this.asc = null;
        this.des = null;
      } else if (this.sortCount === 2) {
        this.asc = true;
        this.des = null;
      } else if (this.sortCount === 3) {
        this.asc = null;
        this.des = true;
      } else {
        return 0;
      }
    },
    //go to next page
    incrementpage() {
      this.page = this.page + 1;
    },
    //go to previous oage
    decrementpage() {
      this.page = this.page - 1;
    },
    //go to perticular page
    setpage(page) {
      this.page = page;
    },
    onDateChange(start_date, end_date) {
      let date_str = start_date.format('YYYY/MM/DD') + " - " + end_date.format('YYYY/MM/DD');
      this.buy_again_date = date_str;
      this.setDateRange(date_str);
    },
    setDateRange(dates_str) {
      this.datepicker.val(dates_str);
    },
  },
  created() {
    this.initAudienceDetails();
    this.initCustomerList(true);
    // this.audienceList ? "" : this[LIST_AUDIENCE](null);
  },
  mounted() {
    this.datepicker = window.$(this.$refs.date);
    this.datepicker.daterangepicker({
      opens: 'left',
      autoUpdateInput: false,
      autoApply: true
    }, this.onDateChange);
  },
  watch: {
    sortCount() {
      this[LIST_CUSTOMERS]({
        params: {
          asc: this.asc,
          des: this.des,
          q: this.search == "" ? null : this.search,
        },
        persist: true,
      });
    },
    articles() {
      if (this.articles.length > 0 && !this.pref_type) {
        this.details.customers = [];
        this.pref_type_error = "please select articles preference type";
      } else {
        this.pref_type_error = "";
        if (this.flag) this.initCustomerList();
        else this.flag = true;
      }
    },
    search() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this[LIST_CUSTOMERS]({
          params: {
            q: this.search == "" ? null : this.search,
            asc: this.asc,
            des: this.des,
          },
          persist: true,
        });
        this.page = 1;
      }
    },
    // pref_type(){
    //   if (this.articles.length > 0 && !this.pref_type) 
    //     this.pref_type_error = "please select articles preference type";
    //   else 
    //     this.pref_type_error = "";
    // },
    selected() {
      this.page = 1;
    },
    buy_again_date() {
      if (this.buy_again_date.length < 23 && this.buy_again_date.length) {
        this.buy_again_date = "";
        this.setDateRange("");
      }
      if ((this.pref_type === 'likely_to_buy_again' || this.pref_type === 'likely_to_buy') && this.articles.length) {
        this.initCustomerList();
      }
      this.page = 1;
    },
  },
};
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style >
.tab-box-wrp ul {
  width: 120%;
}
.multiselect-tag {
  margin: 10;
  font-weight: 500;
  font-size: 15px;
  padding: 8px 13px;
  color: #ffffff;
  background: #5e66fa;
  display: flex;
  align-items: center;
  border-radius: 100px;
  margin-right: 12px;
}
.audience-checkbox {
  margin-bottom: 12px;
}
</style>