<template>
  <!-- <div class="contant-header">
      <h6>
        <img
          src="./../../../public/assets/images/coolicon-icon.png"
          alt=""
        />Customer analytics
      </h6>
    </div> -->
  <div
    class="dashboard-top-section analytics-section customer-analytics"
  >
    <div class="row first-row">
      <!-- {{customerDetails}} -->
      <div class="col-md-6">
        <div class="dts-box">
          <!-- <div class="main___box">  -->
          <h6 class="mb-3">Customer Lifetime Value</h6>
          <div>
            <span class="black-text mr-2">{{ default_currency }}</span>
            <span
              >{{ ((customerDetails?.customer_value * default_clv_year) || 0)?.toFixed(0) }}</span
            >
          </div>
          <div class="bottom__title">
            <DownArrow v-if="customerDetails?.clv_diffrence < 0" />
            <UpArrow v-else />
            <span
              :class="
                customerDetails?.clv_diffrence < 0 ? 'red-text' : 'green-text'
              "
              >{{ customerDetails?.clv_diffrence.toFixed(2) || 0 }}%
            </span>
            <span class="gray-text">from {{ last_month_last_date() }}</span>
          </div>
          <!-- </div> -->
        </div>
      </div>
      <div class="col-md-6">
        <div class="dts-box">
          <div class="main___box">
            <h6 class="mb-3">Customer ranking</h6>
          <div v-if="customerDetails?.customer_ranking <= 50">
            <span class="black-text mr-2">Top</span>
             <span>{{customerDetails?.customer_ranking || 0}}%</span>
           </div>
           <div v-else> 
            <span class="black-text mr-2">Bottom</span>
             <span>{{100-customerDetails?.customer_ranking || 0}}%</span>
           </div>   
            <div class="bottom__title">
            <DownArrow v-if="customerDetails?.ranking_diffrence < 0" />
            <UpArrow v-else />
            <span
              :class="
                customerDetails?.ranking_diffrence < 0 ? 'red-text' : 'green-text'
              "
              >{{ customerDetails?.ranking_diffrence.toFixed(2) }}%
            </span>
            <span class="gray-text">from {{ last_month_last_date() }}</span>
          </div>
          </div>
        </div>
    </div>
    </div>
    <div class="row second-row">
      <div class="col-md-6">
        <div class="dts-box">
          <h6 class="mb-3">Average Order Value</h6>
          <div>
            <span class="black-text mr-2">{{ default_currency }} </span>
            <span
              >{{  customerDetails?.avg_order_value?.toFixed(0) || 0}}</span
            >
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="dts-box">
          <h6 class="mb-3">Days left towards CLV year</h6>
          <span>{{
            customerDetails?.first_order_date ? getDaysLeftfromCLV() : "-"
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import UpArrow from "../../icons/upArrow.vue";
import DownArrow from "../../icons/downArrow.vue";
export default {
  name: "CustomerAnalytics",
  components: {
    UpArrow,
    DownArrow,
  },
  computed: {
    ...mapGetters("customer", ["customerDetails"]),
    ...mapGetters("org", ["default_clv_year", "default_currency"]),
  },
  methods: {
    getDaysLeftfromCLV() {
      var clv_expiration_date = new Date(this.customerDetails.first_order_date);
      clv_expiration_date.setFullYear(
        clv_expiration_date.getFullYear() + parseInt(this.default_clv_year)
      );
      var one_day = 1000 * 60 * 60 * 24;
      var present_date = new Date();
      var Result =
        Math.round(clv_expiration_date.getTime() - present_date.getTime()) /
        one_day;
      return Result.toFixed(0);
    },
    last_month_last_date() {
      var d = new Date(); // current date
      d.setDate(1); // going to 1st of the month
      d.setHours(-1); // going to last hour before this date even started.
      return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    },
  }
};
</script>
<style scoped>
.analytics-section .dts-box {
  min-height: 300px;
  font-size: large;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: flex-start;
  height: 100%;
}
.bottom__title span {
  font-size: 15px !important;
  margin-bottom: 0 !important;
}

.bottom__title span:nth-child(2) {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.customer-analytics {
  height: 100%;
}
.customer-analytics .row.first-row{
  height:47%
}
.customer-analytics .row.second-row{
  height:53%;
  padding-top:3%
}
</style>