import { GET_METRICS } from "@/Core/store/action-types";

import offerApi from "@/Offers/offer.api";
import orderApi from "@/Orders/order.api";
import campaignApi from "@/Campaigns/campaign.api";
//import paymentApi from '@/Payments/payment.api';
//import storeApi from '@/Stores/store.api';
import memberApi from "@/Customers/customer.api";
import productApi from "@/Products/product.api";
import articleApi from "@/Articles/article.api";

const initialState = {};
const state = { ...initialState };

const actions = {
  async [GET_METRICS]({ rootState }, params) {
    let options2 = [rootState.user.userProfile.access];
    let options1 = [rootState.user.userProfile.access, params];
    let options = [rootState.user.userProfile.access, { is_active:1, page: 1 }];
    const [offer, campaign, order, member, product, article] =
      await Promise.all([
        //storeApi.metrics(...options),
        //paymentApi.metrics(...options),
        offerApi.metrics(...options),
        campaignApi.metrics(...options),
        orderApi.metrics(...options2),
        memberApi.metrics(...options),
        productApi.metrics(...options2),
        articleApi.metrics(...options2),
      ]);
    const [get_avg_order_value, get_avg_clv_value, get_median_clv_value,get_cac_value] =
      await Promise.all([
        //storeApi.metrics(...options),
        //paymentApi.metrics(...options),
        orderApi.get_avg_order_value(...options1),
        memberApi.get_avg_clv_value(...options1),
        memberApi.get_median_clv_value(...options1),
        campaignApi.get_cac_value(...options1)
      ]);
    // const [ campaign]=await Promise.all([
    //     campaignApi.metrics(...options),
    // ])
    // const [ order,member]=await Promise.all([
    //     orderApi.metrics(...options),
    //     memberApi.metrics(...options),
    // ])
    // const [ member]=await Promise.all([
    //     memberApi.metrics(...options),
    // ])
    // const [product] =await Promise.all([
    //     productApi.metrics(...options)
    // ])
    return {
      //totalStores: store.headers['x-total-count'],
      //totalEarnings: payment.headers['x-total-price'],
      totalOrders: order.data.count,
      totalCampaigns: campaign.data.count,
      totalOffers: offer.data.count,
      totalMembers: member.data.count,
      totalProducts: product.data.length,
      totalArticles: article.data.count,
      get_avg_order_value: get_avg_order_value.data,
      get_avg_clv_value: get_avg_clv_value.data,
      get_median_clv_value: get_median_clv_value.data,
      get_cac_value:get_cac_value.data
    };
  },
};

const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
