<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5C1 3.89543 1.89543 3 3 3H23C24.1046 3 25 3.89543 25 5V17C25 18.1046 24.1046 19 23 19H3C1.89543 19 1 18.1046 1 17V5Z"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M14 9H21"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M14 13H21"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M4 23H22"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <circle
      cx="7"
      cy="11"
      r="2.25"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
</template>


<script>
export default {
  name: "ArticlesIcon",
};
</script>

<style scoped>
</style>