<template>
  <nav class="main-pagination" aria-label="Page navigation">
    <div class="main-title">
      showed
      {{ (page - 1) * perpage + 1 > count ? 0 : (page - 1) * perpage + 1 }}
      to
      {{ page * perpage > count ? count : page * perpage }}
      out of {{ count ? count : 0 }} {{ name }}
    </div>
    <div class="main-pagination">
      <ul class="pagination">
        <li class="page-item">
          <button
            type="button"
            class="page-link"
            v-if="page != 1"
            @click="decrementpage"
          >
           <LeftArrow />
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            class="page-link"
            @click="setpage(1)"
            v-if="page > 1"
          >
            1
          </button>
          <button
            type="button"
            class="page-link"
            @click="setpage(2)"
            v-if="page == 3"
          >
            2
          </button>
          <button class="page-link" v-if="page > 3">...</button>
          <button
            type="button"
            v-for="pageNumber in totalPages.slice(page - 1, page + 2)"
            @click="setpage(pageNumber)"
            :key="pageNumber"
            :class="page == pageNumber ? 'page-link-active' : 'page-link'"
          >
            {{ pageNumber }}
          </button>
        </li>
        <li>
          <button class="page-link" v-if="page < totalPages.length - 3">
            ...
          </button>
        </li>
        <li>
          <button
            type="button"
            class="page-link"
            @click="setpage(totalPages.length)"
            v-if="page < totalPages.length - 2"
          >
            {{ totalPages.length }}
          </button>
        </li>

        <li class="page-item">
          <button
            type="button"
            @click="incrementpage"
            v-if="page < totalPages.length"
            class="page-link"
          >
            <RightArrow />
          </button>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import RightArrow from '../icons/rightarrow.vue';
import LeftArrow from '../icons/leftarrow.vue';
export default {
  name: "pagination",
  components: {
    RightArrow,LeftArrow
  },
  props: {
    page: { //current page no
      required: true,
      type: Number,
    },
    totalPages: { // total pages
      required: true,
      type: Array,
    },
    count: {//total data length
      required: true,
      type: Number,
    },
    name: {// name of entity
      required: true,
      type: String,
    },
    incrementpage: {// on increment page(click on nect arrow)
      required: true,
      type: Function,
    },
    decrementpage: { // on decrement page(click on previous arrow)
      required: true,
      type: Function,
    },
    setpage: {// click on any perticular page
      required: true,
      type: Function,
    },
    perpage: {// per page count
      required: true,
      type: Number,
    },
  },
};
</script>