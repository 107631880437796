<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar
      title="AudienceDetails"
      :author="{
        home: audienceDetail ? audienceDetail.name : '',
        cart: 'Audience',
      }"
    />
    <AudienceDetailGrid />
  </div>
</template>
<script>
import AudienceDetailGrid from "./components/AudienceDetailGrid.vue";
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
export default {
  name: "AudienceDetails",
  components: {
    AudienceDetailGrid,
    Loading,
    MainMenu,
    TopBar,
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
    };
  },
  computed: {
    ...mapGetters("audience", ["audienceDetail"]),
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  },
};
</script>
