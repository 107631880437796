<template>
  <div class="contant-box-main customer-list-box">
    <div class="h-100">
      <div class="contant-header">
        <h6>
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAYAAAAmL5yKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHXSURBVHgBjVJBbtpQEJ0Z29+N2lS+QeEGcIKSE5TuqihBlhrSRReQEwROELproBWmRN2GngBuEHIDjkClVBQb/8kMwY4SJQqz8HyP/3vz3owRNrEf3pQc3zTAQkFeB8OeiWCLIH18ChcF1zNjtFDVCiL0D+txf2sCz3dOAWFOTloenps9YPgmJOHnr/xuKwLpGQhoHn3fmembZRxpXi7/F18icNdwtteiu1o7Tk4t4DWBPZPy7OLHziS7WKvHDUktURowYGfY9U5yBb+6fktlA3BLwJdyDojjjxn44CipCLDDAFO5M0DgZu142YJ7C0LSM02itLjiuEyvvWLUezPNfZKtaJbN7EmzUI5TZnyfW6iGHLw1q4ZNuemiE6T/ksnB0aKdWbApTMkRGxuLUikRQZQr2PWSS5XPyALgtmygQOSMhWTd+eKnP3pgUeaDmLbX81N/RDxmsCfD7quOFkNRZE1yxQwzlZ1Z2a/flDT/fmAv87cBa0QRzqXjH1FSySyqfBfM2EVzdfgl7uvPl8/gyUCey2N93PWTMyEMUXzLJv4iwwdjnIooLT9PsAntJIBQZzM4l3WDrnUx0hmlXlIlZjtjhMljYFYnzwk0rzgZZd90O3eYFG4Bk3jRLFulvBYAAAAASUVORK5CYII="
            alt=""
          />Top Performing Audiences
        </h6>
        <div class="">
          <!-- <label for="adminfilter">Organization</label> -->
          <select
            class="for"
            id="adminfilter"
            v-model="time"
            @change="onTimeChange"
          >
            <option v-for="option in timeOptions" :key="option">
              {{ option }}
            </option>
          </select>
        </div>
        <!-- <span class="gray-text">from {{ one_month_ago_date() }}</span> -->
      </div>
       <div class="contant-details h-100">
        <div class="overflow-auto position-relative" :class="loadindShow?'h-100':''">
          <div class="loader-main" v-if="loadindShow">
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
           </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Order Count</th>
                <th>Amount</th>
                <th>Currency</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="audience in top_performing_audiences"
                :key="audience.id"
              >
                <td class="font-light-text" width="10%">
                  {{ audience.id }}
                </td>
                <td width="40%" class="font-text">
                  <router-link
                    :to="{
                      name: `AudienceDetails`,
                      params: { audienceid: audience.id },
                    }"
                    >{{ audience.name }}</router-link
                  >
                </td>
                <td class="font-light-text" width="20%">
                  {{ audience.order_count }}
                </td>
                <td class="font-light-text" width="20%">
                  {{ audience.total_amount.toFixed(0) }}
                </td>
                <td class="font-light-text" width="10%">
                  {{ audience.currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- most valuable customer list model -->
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import { GET_TOP_PERFORMING_AUDIENCES } from "@/Core/store/action-types";
export default {
  name: "TopPerformingAudiences",

  data() {
    return {
      top_performing_audiences: [],
      time: "month",
      timeOptions: ["week", "month", "quater", "half a year", "year"],
       loadindShow:false,
    };
  },
  computed: {
    ...mapGetters("org", ["default_currency"]),
  },
  methods: {
    ...mapActions("audience", [GET_TOP_PERFORMING_AUDIENCES]),
    initPerformingAudiences() {
      let params = {
        duration:
          this.time == "month"
            ? 30
            : this.time == "week"
            ? 7
            : this.time == "quater"
            ? 90
            : this.time == "half a year"
            ? 180
            : 365,
        currency:this.default_currency    
      };
      this.loadindShow = true
      this[GET_TOP_PERFORMING_AUDIENCES](params)
        .then((result) => {
          this.top_performing_audiences = result;
        })
        .catch(() => (this.top_performing_audiences = []))
        .finally(()=>this.loadindShow=false)
    },
    onTimeChange() {
      this.initPerformingAudiences();
    },
  },
  mounted() {
    this.initPerformingAudiences();
  },
};
</script>
<style scoped>
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>