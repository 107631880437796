import { AbilityBuilder, Ability } from "@casl/ability";
import {
  WORKER_GROUP,
  UserRoleMixin,
  ADMIN,
  SUPER_ADMIN,
  MERCHANT,
  WORKER,
} from "../mixins/UserRoleMixin";

export function defineRulesFor(user) {
  const userRole = UserRoleMixin.methods.getUserRole(user);
  const { can, rules, cannot } = new AbilityBuilder();

  if (userRole == SUPER_ADMIN.name) {
    can(["list", "delete", "read", "deactivate"], "User");
    can(["update", "create"], "User", { is_superuser: false, is_staff: true });
    can(["update", "create"], "User", { is_superuser: false, is_staff: false });
    can(["list", "read", "edit", "create"], "Merchant");
    can(["list", "read", "edit", "delete"], "Admin");
    cannot(["deactivate"], "User", { is_active: false });
    cannot(["deactivate"], "User", { delete_request: user.id });
  } else if (userRole == ADMIN.name) {
    can(["list", "read"], "User");
    can(["list", "read", "create", "edit"], "Merchant");
    can(["update", "create"], "User", { is_superuser: false, is_staff: false });
    can(["deactivate"], "User", {
      is_superuser: false,
      is_staff: false,
      delete_request: null,
      is_active: true,
    });
  } else if (userRole == WORKER.name) {
    can("read", "User");
  } else if (userRole == MERCHANT.name) {
    can("read", "User");
    can(["list", "update", "delete", "create", "deactivate"], "User", {
      groups: { $elemMatch: { name: WORKER_GROUP } },
    });
    cannot(["delete", "update"], "User", { id: user.id });
  }

  if ([SUPER_ADMIN.name, ADMIN.name].includes(userRole)) {
    can(["list", "update", "delete", "read", "create"], "Org");

    cannot(["delete", "update"], "User", { id: user.id });
    cannot(["create"], "User", {
      groups: { $elemMatch: { name: WORKER_GROUP } },
    });
  }

  if ([SUPER_ADMIN.name, ADMIN.name].includes(userRole)) {
    can(["list", "delete"], "Offer");
    can(["update"], "Offer", { is_active: false });

    can(["list", "delete", "update"], "Audience");
    can(["list", "delete", "update"], "Email");

    can(["list", "update", "delete"], "Customer");
    can(["list", "delete", "read", "update"], "Store");
    can(["list", "delete", "read", "update"], "Shipping Charge");
    // can(['delete'], 'Customer',{is_active: true});
    // {is_active: true}

    can(["list", "delete"], "Campaign");
    can(["update", "duplicate"], "Campaign", { is_active: false });
  }
  if ([WORKER.name].includes(userRole)) {
    can(["list", "read", "create"], "Offer");
    can(["update"], "Offer", { is_active: false });

    can(["list", "read", "create", "update"], "Audience");
    can(["list", "read", "create", "update"], "Email");

    can(["list", "read", "create", "update"], "Customer");
    can(["update"], "Store");
    // can(['delete'], 'Member',{is_active: true});
    // {is_active: true}

    can(["list", "read", "create"], "Campaign");
    can(["update", "duplicate"], "Campaign", { is_active: false });
  }
  if ([MERCHANT.name].includes(userRole)) {
    can(["list", "delete", "read", "create"], "Offer");
    can(["update"], "Offer", { is_active: false });

    can(["list", "delete", "read", "create", "update"], "Audience");
    can(["list", "delete", "read", "create", "update"], "Email");

    can(["list", "read", "create", "update", "delete"], "Customer");
    can(["list", "read", "create", "update", "delete"], "Store");
    // can(['delete'], 'Customer',{is_active: true});
    // {is_active: true}
    can(["list", "delete", "read", "update","create"], "Shipping Charge");
    can(["list", "delete", "read", "update","create"], "Product");
    can(["list", "delete", "read", "update","create"], "Article");
    can(["list", "delete", "read", "create"], "Campaign");
    can(["update", "duplicate"], "Campaign", { is_active: false });
  }
  return rules;
}

export const ability = new Ability([]);
