<template>
  <!-- Register modal window -->
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createDialogLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createDialogLabel">
            Create New Sales Channel
          </h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <StoreForm :onSubmit="onSubmit" :isEditMode="false" :onFileUpload="HandleSalesChannelFileUpload" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { CREATE_STORE,SALES_CHANNEL_FILE_UPLOAD } from "@/Core/store/action-types";
import {getCountryIdByName} from "../../Core/helpers/countryList"
// import { UserRoleMixin } from '@/Core/mixins/UserRoleMixin';
import StoreForm from "./StoreForm";

export default {
  name: "Registration",
  components: {
    StoreForm,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
  },
  methods: {
    ...mapActions("_store", [CREATE_STORE,SALES_CHANNEL_FILE_UPLOAD]),

    onSubmit(values, actions) {
      if (!values.address) values.address = null;
      console.log("values", values, actions);
      values.country_code = values.country?getCountryIdByName(values.country):null
      this[CREATE_STORE](values)
        .then(() => {
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify(
            "sales channel successfully created.",
            "success",
            3
          );
          actions.resetForm();
          this.$parent.initStores(1);
        })
        .catch((e) => {
          console.log("in store", e);
          this.$alertify.notify(
            "sales channel with this reg number already exists.",
            "error",
            3
          );
          // this.errMsg = e.message;
          // actions.setFieldError('detail', "sales channel with this reg number already exists.");
        });
    },
    HandleSalesChannelFileUpload(file){
      if (file) {
        this[SALES_CHANNEL_FILE_UPLOAD](file)
          .then(() => {
            // this[UPDATE_ORG_UPLOAD_DATA]()
            // this[GET_ORG](this.userProfile.organization.id)
            this.$parent.initStores(1);
            this.$alertify.notify(
              "Sales channel Uploaded successfully",
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some error, please check your file",
              "error",
              3
            );
          })
      }
  },
  },

  data() {
    return {};
  },
};
</script>