<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    version="1.1"
  >
    <g id="surface1">
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(97.647059%, 67.058824%, 0%);
          fill-opacity: 1;
        "
        d="M 33 5.726562 L 33 41.726562 C 33 45.753906 35.78125 48 38.726562 48 C 41.453125 48 44.453125 46.089844 44.453125 41.726562 L 44.453125 6 C 44.453125 2.308594 41.726562 0 38.726562 0 C 35.726562 0 33 2.542969 33 5.726562 Z M 33 5.726562 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(89.019608%, 45.490196%, 0%);
          fill-opacity: 1;
        "
        d="M 18 24 L 18 41.726562 C 18 45.753906 20.78125 48 23.726562 48 C 26.453125 48 29.453125 46.089844 29.453125 41.726562 L 29.453125 24.273438 C 29.453125 20.578125 26.726562 18.273438 23.726562 18.273438 C 20.726562 18.273438 18 20.816406 18 24 Z M 18 24 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(89.019608%, 45.490196%, 0%);
          fill-opacity: 1;
        "
        d="M 14.453125 42.273438 C 14.453125 45.4375 11.890625 48 8.726562 48 C 5.5625 48 3 45.4375 3 42.273438 C 3 39.109375 5.5625 36.546875 8.726562 36.546875 C 11.890625 36.546875 14.453125 39.109375 14.453125 42.273438 Z M 14.453125 42.273438 "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "GoogleAnalyticsIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>