<template>
  <div class="main-contant-box">
    <div class="main-contant-box-inner">
      <div class="contant-box">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <img
                src="./../../../public/assets/images/coolicon-icon.png"
                alt=""
              />Edit Customer details
            </h6>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-12">
                  <div
                    class="form-group"
                    :class="errMsg.first_name != '' ? 'has-error' : ''"
                  >
                    <label class="form-label w-100">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      v-model="formData.first_name"
                      @keyup="validateName"
                      @blur="validateName"
                    />
                    <span class="error">{{ errMsg.first_name }}</span>
                  </div>
                </div>
                <div class="col-12">
                  <div
                    class="form-group"
                    :class="errMsg.last_name != '' ? 'has-error' : ''"
                  >
                    <label class="form-label w-100">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      v-model="formData.last_name"
                      @keyup="validateName"
                      @blur="validateName"
                    />
                    <span class="error">{{ errMsg.last_name }}</span>
                  </div>
                </div>
                <div class="col-12">
                  <div
                    class="form-group"
                    :class="errMsg.email != '' ? 'has-error' : ''"
                  >
                    <label class="form-label w-100">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      v-model="formData.email"
                      @keyup="validateEmail"
                      @blur="validateEmail"
                    />
                    <span class="error">{{ errMsg.email }}</span>
                  </div>
                </div>
                <div
                  class="col-12"
                  :class="errMsg.phone != '' ? 'has-error' : ''"
                >
                  <div class="form-group">
                    <label class="form-label w-100">Phone</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Phone"
                      v-model="formData.phone"
                      @keyup="validatePhone"
                      @blur="validatePhone"
                    />
                    <span class="error">{{ errMsg.phone }}</span>
                  </div>
                </div>
                <div class="col-12">
                  <div
                    class="form-group"
                  >
                    <label class="form-label w-100">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Address"
                      v-model="formData.address"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div
                    class="form-group"
                    :class="errMsg.postal_id != '' ? 'has-error' : ''"
                  >
                    <label class="form-label w-100">Postal Code</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Postal code"
                      v-model="formData.postal_id"
                      @keyup="validatePostalCode"
                      @blur="validatePostalCode"
                    />
                    <span class="error">{{ errMsg.postal_id }}</span>
                  </div>
                </div>
                <div class="col-6">
                  <div
                    class="form-group"
                  >
                    <label class="form-label w-100">City</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="City"
                      v-model="formData.city"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div
                    class="form-group"
                  >
                    <label class="form-label w-100">Country</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      v-model="formData.country"
                    />
                  </div>
                </div>
                <div
                  class="col-lg-6"
                  :class="errMsg.dob != '' ? 'has-error' : ''"
                >
                  <div class="form-group">
                    <label class="form-label w-100">Date of birth</label>
                    <input
                      ref="date"
                      type="hidden"
                      class="form-control"
                      placeholder="Date of birth"
                      v-model="formData.birth_date"
                    />
                    <input type="text" ref="date" placeholder="Enter Birth date" class="form-control"/>
                    <span class="error">{{ errMsg.dob }}</span>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="form-label w-100">Gender</label>
                    <!-- <select class="form-control" v-model="formData.sex">
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Corporate">Corporate</option>
                    </select> -->
                    <Multiselect
                      v-model="formData.sex"
                      :options="sexChoices"
                      placeholder="please select a sex"
                    />
                  </div>
                </div>
                <div
                  :class="
                    formData.external_id == null ? 'col-lg-12' : 'col-lg-6'
                  "
                >
                  <div class="form-group">
                    <label class="form-label w-100">Internal ID</label>
                    <input
                      disabled
                      type="text"
                      class="form-control disabledinput"
                      placeholder="Internal ID"
                      v-model="formData.id"
                    />
                  </div>
                </div>
                <div class="col-lg-6" v-if="formData.external_id != null">
                  <div class="form-group">
                    <label class="form-label w-100">External ID</label>
                    <input
                      disabled
                      type="text"
                      class="form-control disabledinput"
                      placeholder="External ID"
                      v-model="formData.external_id"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="model-checkbox justify-content-start" >
                    <label class="checkbox-container"
                      >Allows Marketing Campaigns
                      <input
                        type="checkbox"
                        name="allow_marketing"
                        v-model="formData.allow_marketing"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-btns">
                    <button
                      type="button"
                      class="cancle-btn"
                      @click="
                        this.$router.push({
                          name: 'CustomerDetailsGrid',
                          params: { customerId: formData.id },
                        })
                      "
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="save-btn"
                      @click.prevent="updateCustomerDetails"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { CUSTOMER_DETAILS, UPDATE_CUSTOMER } from "@/Core/store/action-types";
import { PHONE_REGEX, EMAIL_REGEX } from "@/Core/helpers/utils";
import Multiselect from "@vueform/multiselect";
import {  DATE_FORMAT } from '@/Core/helpers/utils';

export default {
  name: "EditCustomerForm",
  components:{
    Multiselect
  },
  data() {
    return {
      sexChoices:["Female", "Male","Corporate"],
      formData:{
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        country: "",
        birth_date: "",
        allow_marketing: true,
        postal_id: "",
        sex:""
      },
      errMsg: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        dob: "",
        postal_id: "",
      },
    };
  },
  computed: {
    ...mapGetters("customer", ["customerDetails"]),
    ...mapGetters("org", ["default_currency"]),
  },
  methods: {
    ...mapActions("customer", [CUSTOMER_DETAILS, UPDATE_CUSTOMER]),
    //get customer details
    InItCustomerDetails() {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") {
         const params={
          customerId:this.$route.params.customerId,
          currency:this.default_currency
        }
        this[CUSTOMER_DETAILS](params).then(() => {
          this.formData = JSON.parse(JSON.stringify(this.customerDetails));
          this.setDatepicker(this.formData?.birth_date);
        });
      }
    },
    //check name valid or not
    validateFirstName() {
      this.errMsg.first_name = "";
      if (this.formData.first_name.trim().length < 6) {
        this.errMsg.first_name = "First Name must be at least 6 characters";
      } else {
        this.errMsg.first_name = "";
      }
    },
    //check name valid or not
    validateLastName() {
      this.errMsg.last_name = "";
      if (this.formData.last_name.trim().length < 6) {
        this.errMsg.last_name = "Last Name must be at least 6 characters";
      } else {
        this.errMsg.last_name = "";
      }
    },
    //check DOB valid or not
    validateDOB() {
      this.errMsg.dob = "";
      // if (!this.formData.birth_date) {
      //   this.errMsg.dob = "Please select birth date";
      // } else {
      //   this.errMsg.dob = "";
      // }
    },
    //check email is valid or not 
    validateEmail() {
      this.errMsg.email = "";
      if (this.formData.email.length>0 && !EMAIL_REGEX.test(this.formData.email)) {
        this.errMsg.email = "Email is invalid";
      } else {
        this.errMsg.email = "";
      }
    },
    //check phone No is valid or not
    validatePhone() {
     if (this.formData.phone.length>0 &&!PHONE_REGEX.test(this.formData.phone)) {
        this.errMsg.phone = "Phone is invalid";
      } else {
        this.errMsg.phone = "";
      }
    },
    validatePostalCode(){
      if (this.formData.postal_id.length>0 &&!/^[0-9]{5}/.test(this.formData.postal_id)) {
        this.errMsg.postal_id = "Postal code is invalid";
      } else {
        this.errMsg.postal_id = "";
      }
    },
    onDateChange(start) {
      this.formData.birth_date = start.format(DATE_FORMAT);
      this.setDatepicker(this.formData.birth_date);
    },

    setDatepicker(date) {
      this.datepicker.val(date);
    },
    //update customer details
    async updateCustomerDetails() {
      if (
    this.errMsg.email == "" &&
        this.errMsg.phone == "" && this.errMsg.postal_id == ""
      ) {
        this[UPDATE_CUSTOMER](this.formData).then(() => {
          this.$alertify.notify(`Customer updated successfully. `, "success", 3);
          this.$router.push({
            name: "CustomerDetailsGrid",
            params: { customerId: this.formData.id },
          });
        });
      }
    },
  },
   mounted() {
    this.datepicker = window.$(this.$refs.date);
    this.datepicker.daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      autoUpdateInput: false
    }, this.onDateChange);
    this.setDatepicker(this.formData?.birth_date);

  },
  created() {
    this.customerDetails
      ? (this.formData = JSON.parse(JSON.stringify(this.customerDetails)))
      : this.InItCustomerDetails();
  },
};
</script>
    
      

      
        
        

        
