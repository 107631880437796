<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.25 6.5C2.25 8.29493 3.70507 9.75 5.5 9.75C7.29493 9.75 8.75 8.29493 8.75 6.5C8.75 4.70507 7.29493 3.25 5.5 3.25C3.70507 3.25 2.25 4.70507 2.25 6.5ZM5.5 2C3.01472 2 1 4.01472 1 6.5C1 8.98528 3.01472 11 5.5 11C7.98528 11 10 8.98528 10 6.5C10 4.01472 7.98528 2 5.5 2Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.25 6.5C15.25 8.29493 16.7051 9.75 18.5 9.75C20.2949 9.75 21.75 8.29493 21.75 6.5C21.75 4.70507 20.2949 3.25 18.5 3.25C16.7051 3.25 15.25 4.70507 15.25 6.5ZM18.5 2C16.0147 2 14 4.01472 14 6.5C14 8.98528 16.0147 11 18.5 11C20.9853 11 23 8.98528 23 6.5C23 4.01472 20.9853 2 18.5 2Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.75 20V17.5C9.75 15.1528 7.84721 13.25 5.5 13.25C3.15279 13.25 1.25 15.1528 1.25 17.5V20C1.25 20.4142 1.58579 20.75 2 20.75H9C9.41421 20.75 9.75 20.4142 9.75 20ZM5.5 12C2.46243 12 0 14.4624 0 17.5V20C0 21.1046 0.895431 22 2 22H9C10.1046 22 11 21.1046 11 20V17.5C11 14.4624 8.53757 12 5.5 12Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.75 20V17.5C22.75 15.1528 20.8472 13.25 18.5 13.25C16.1528 13.25 14.25 15.1528 14.25 17.5V20C14.25 20.4142 14.5858 20.75 15 20.75H22C22.4142 20.75 22.75 20.4142 22.75 20ZM18.5 12C15.4624 12 13 14.4624 13 17.5V20C13 21.1046 13.8954 22 15 22H22C23.1046 22 24 21.1046 24 20V17.5C24 14.4624 21.5376 12 18.5 12Z"
      fill="#49494A"
    />
  </svg>
</template>

<script>
export default {
  name: "AudienceIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>