<template>
  <div>
    <TopBar
      title="Customers"
      :author="{
        home: customerDetails ? customerDetails : '',
        cart: 'Customers',
      }"
    />
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <div class="main-contant-box">
      <div class="main-contant-box-inner">
        <div class="contant-box">
          <CustomerAnalytics></CustomerAnalytics>
        </div>
        <div class="contant-box">
          <MostLikelyToBuy></MostLikelyToBuy>
        </div>
        <div class="contant-box">
          <CustomerDetails></CustomerDetails>
        </div>
        <div class="contant-box">
          <SimilarItems></SimilarItems>
        </div>
        <div class="contant-box">
          <Campaigns></Campaigns>
        </div>
        <div class="contant-box">
          <Purchases></Purchases>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomerDetails from "./components/CustomersDetails.vue";
import MostLikelyToBuy from "./components/MostLikelyToBuy.vue";
import CustomerAnalytics from "./components/CustomerAnalytics.vue";
import Campaigns from "./components/Campaigns.vue";
import SimilarItems from "./components/SimilarItems.vue"
import Purchases from "./components/Purchases.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TopBar from "../Menu/TopBar.vue";
import { mapGetters } from "vuex";
export default {
  name: "CustomerDetailsGrid",
  components: {
    CustomerDetails,
    Campaigns,
    Purchases,
    CustomerAnalytics,
    MostLikelyToBuy,
    Loading,
    TopBar,
    SimilarItems
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
      name: "",
    };
  },
  computed: {
    ...mapGetters("customer", ["customerDetails"]),
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>

