<template>
  <!-- Log In page -->
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-12 align-self-center">
        <div class="">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-5 mx-auto">
                <div class="media mb-5 justify-content-center">
                  <img
                    src="../../public/assets/images/logo-sm.png"
                    height="55"
                    alt="logo"
                    class="auth-logo"
                  />
                </div>
                <div class="card">
                  <div class="card-body">
                    <div class="forgot-title-box">
                      <h2>Forgot your password?</h2>
                      <p>
                        Please enter the email you are registered with. We’ll
                        send the password recovery link there.
                      </p>
                    </div>
                    <Form
                      @submit="onSubmit"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                      class="form-horizontal auth-form my-4"
                      action="index.html"
                    >
                      <div
                        class="form-group"
                        :class="{ 'has-error': errors.email }"
                      >
                        <label for="email">Email</label>
                        <Field
                          as="input"
                          type="email"
                          class="form-control form-control-danger"
                          name="email"
                          placeholder="Enter Email"
                        />
                        <div class="form-control-feedback">
                          {{ errors.email }}
                        </div>
                      </div>
                      <!--end form-group-->

                      <div
                        class="form-group"
                        :class="{ 'has-error': errors.detail }"
                      >
                        <Field
                          as="input"
                          type="hidden"
                          class="form-control"
                          name="detail"
                        />
                        <div class="form-control-feedback">
                          {{ errors.detail }}
                        </div>
                      </div>
                      <!--end form-group-->

                      <div class="form-group mb-0 row">
                        <div class="col-12 mt-2 d-flex align-items-center">
                          <button
                            class="
                              w-auto
                              btn btn-block
                              waves-effect waves-light
                              login-button
                            "
                            type="submit"
                          >
                            Reset Password
                          </button>
                          <router-link to="/login" class="login-page-link"
                            >Sign in</router-link
                          >
                        </div>
                        <!--end col-->
                      </div>
                      <!--end form-group--> </Form
                    ><!--end form-->
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- End Log In page -->
</template>

<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

import { FORGOT_PASSWORD } from "@/Core/store/action-types";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";

export default {
  name: "ForgotPassword",
  components: {
    Form,
    Field,
  },
  mixins: [UserRoleMixin],
  methods: {
    ...mapActions("user", [FORGOT_PASSWORD]),

    onSubmit(values, actions) {
      console.log("egfewgfeg", values);
      this[FORGOT_PASSWORD]({ values, actions })
        .then(() => {
          this.$alertify.notify(
            `reset password link sent to your email.please check`,
            "success",
            3
          );
          this.$router.push("/login");
        })
        .catch(() => {}); //stay on a page
    },
  },
  data() {
    const schema = yup.object().shape({
      email: this.emailValidator(),
      detail: yup
        .string() // use it for backend errors
        .nullable()
        .notRequired(),
    });

    return {
      schema,
    };
  },
};
</script>
<style scoped>
.login-button {
  background-color: #5e66fa;
  color: #ffffff;
}
.login-button:hover {
  background-color: #727aff;
  color: #fff;
}
.login-page-link {
  margin-left: 35px;
  color: #3f3f40;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}
.login-page-link {
  margin-left: 35px;
  color: #3f3f40;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.forgot-title-box {
  text-align: center;
  padding-top: 15px;
}

.forgot-title-box h2 {
  color: #3f3f40;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 15px;
}

.forgot-title-box p {
  color: #3f3f40;
  font-weight: 400;
  font-size: 14px;
}
</style>