<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="42px" height="48px" viewBox="0 0 42 47" version="1.1">
<g id="surface1">
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(58.431373%,74.901961%,27.843137%);fill-opacity:1;" d="M 36.785156 9.121094 C 36.75 8.886719 36.542969 8.757812 36.367188 8.742188 C 36.195312 8.726562 32.808594 8.675781 32.808594 8.675781 C 32.808594 8.675781 29.972656 5.976562 29.695312 5.695312 C 29.417969 5.421875 28.871094 5.507812 28.65625 5.566406 C 28.652344 5.566406 28.125 5.730469 27.230469 6 C 27.085938 5.527344 26.863281 4.945312 26.550781 4.359375 C 25.542969 2.464844 24.0625 1.464844 22.277344 1.460938 C 22.273438 1.460938 22.273438 1.460938 22.269531 1.460938 C 22.144531 1.460938 22.023438 1.472656 21.898438 1.484375 C 21.84375 1.421875 21.792969 1.363281 21.734375 1.300781 C 20.957031 0.484375 19.960938 0.0859375 18.765625 0.121094 C 16.460938 0.1875 14.164062 1.824219 12.300781 4.730469 C 10.992188 6.773438 9.992188 9.34375 9.710938 11.332031 C 7.066406 12.140625 5.214844 12.703125 5.171875 12.714844 C 3.835938 13.125 3.792969 13.167969 3.617188 14.40625 C 3.496094 15.335938 0 41.902344 0 41.902344 L 29.300781 46.882812 L 42 43.78125 C 42 43.78125 36.816406 9.359375 36.785156 9.121094 Z M 25.765625 6.445312 C 25.089844 6.652344 24.324219 6.882812 23.492188 7.140625 C 23.476562 5.992188 23.335938 4.394531 22.789062 3.019531 C 24.539062 3.339844 25.398438 5.285156 25.765625 6.445312 Z M 21.957031 7.609375 C 20.421875 8.074219 18.746094 8.585938 17.066406 9.09375 C 17.539062 7.320312 18.4375 5.546875 19.535156 4.382812 C 19.945312 3.949219 20.519531 3.472656 21.199219 3.195312 C 21.835938 4.503906 21.972656 6.355469 21.957031 7.609375 Z M 18.816406 1.628906 C 19.359375 1.617188 19.8125 1.734375 20.203125 1.988281 C 19.578125 2.300781 18.976562 2.757812 18.414062 3.351562 C 16.949219 4.898438 15.824219 7.292969 15.378906 9.605469 C 13.984375 10.027344 12.617188 10.445312 11.363281 10.828125 C 12.152344 7.1875 15.261719 1.726562 18.816406 1.628906 Z M 18.816406 1.628906 "/>
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(36.862745%,55.686275%,24.313725%);fill-opacity:1;" d="M 36.367188 8.742188 C 36.195312 8.726562 32.808594 8.675781 32.808594 8.675781 C 32.808594 8.675781 29.972656 5.976562 29.695312 5.695312 C 29.589844 5.59375 29.449219 5.542969 29.300781 5.519531 L 29.300781 46.878906 L 41.996094 43.777344 C 41.996094 43.777344 36.8125 9.359375 36.78125 9.121094 C 36.753906 8.886719 36.542969 8.757812 36.367188 8.742188 Z M 36.367188 8.742188 "/>
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(100%,100%,100%);fill-opacity:1;" d="M 22.261719 15.15625 L 20.785156 20.582031 C 20.785156 20.582031 19.140625 19.847656 17.191406 19.964844 C 14.335938 20.144531 14.304688 21.914062 14.332031 22.359375 C 14.484375 24.78125 20.976562 25.3125 21.34375 30.992188 C 21.632812 35.460938 18.933594 38.515625 15.042969 38.761719 C 10.375 39.050781 7.804688 36.34375 7.804688 36.34375 L 8.792969 32.210938 C 8.792969 32.210938 11.378906 34.128906 13.449219 34 C 14.800781 33.917969 15.285156 32.835938 15.234375 32.070312 C 15.03125 28.90625 9.742188 29.09375 9.40625 23.894531 C 9.125 19.523438 12.050781 15.089844 18.5 14.691406 C 20.992188 14.535156 22.261719 15.15625 22.261719 15.15625 Z M 22.261719 15.15625 "/>
</g>
</svg>
</template>

<script>
export default {
  name: "ShopifyIcon",
};
</script>

<style scoped>
</style>