<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>

    <MainMenu />
    <TopBar
      title="Campaigns"
      :author="{
        home: campaignDetails ? campaignDetails : '',
        cart: 'Campaigns',
      }"
    />
    <div class="tabbable">
      <ul class="nav nav-tabs">
        <li class="active">
          <a
            @click.prevent="setActive('overview')"
            :class="{ active: isActive('overview') }"
            href="#overview"
            >Campaign Overview</a
          >
        </li>
        <li>
          <a
            @click.prevent="setActive('countryPerformance')"
            :class="{ active: isActive('countryPerformance') }"
            href="#countryPerformance"
            >Country Performance</a
          >
        </li>
      </ul>
    </div>
    <div class="tab-content">
      <div
        class="tab-pane"
        :class="{ 'active show': isActive('overview') }"
        id="overview"
      >
        <CampaignAnalyticsGrid />
      </div>
      <div
        class="tab-pane"
        :class="{ 'active show': isActive('countryPerformance') }"
        id="countryPerformance"
      >
        <CampaignCountryPerformance />
      </div>
    </div>
    <!-- <CampaignAnalyticsGrid /> -->
  </div>
</template>
<script>
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CampaignAnalyticsGrid from "./components/AnalyticsGrid.vue";
import CampaignCountryPerformance from "./components/CountryPerformance.vue"
import { mapGetters } from "vuex";
export default {
  name: "CampaignAnalytics",
  components: {
    MainMenu,
    TopBar,
    Loading,
    CampaignAnalyticsGrid,
    CampaignCountryPerformance
  },
  computed: {
    ...mapGetters("campaign", ["campaignDetails"]),
  },
  data() {
    return {
      fullPage: true,
      isLoading: true,
      activeItem: "overview",
    };
  },
  methods:{
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>
<style scoped>
.tabbable {
  background: #fff;
  margin: 0;
  padding: 0;
  width: 105%;
  height: 100%;
  margin-left: -12px;
  margin-top: -10px;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
  gap: 20px;
}

.tabbable ul li a {
  padding: 15px 28px;
  color: #3f3f40;
  font-weight: 500;
}

.tab-content {
  margin-top: 15px;
}

.tabbable ul li a.active {
  border-bottom: 3px solid #5e66fa;
  color: #5e66fa;
}

/* .tab-content {
  background: #fff;
} */
</style>