<template>
  <div>
    <div class="row">
      <!-- audience details box start-->
      <div class="col-lg-6">
        <!-- delete audience model -->
        <CustomModel
          v-if="userDetails"
          :modalId="deleteUserModelID"
          :item="userDetails"
          :onDelete="deleteUser"
          :resourseName="'Merchant'"
          actionType="Delete"
        />
        <CustomModel
          v-if="userDetails"
          :modalId="deactivateModelID"
          :item="userDetails"
          :onDelete="deactivateUser"
          :resourseName="'Merchant'"
          actionType="Deactivate"
        />
        <!-- delete audience model close -->
        <div class="contant-box" v-if="userDetails">
          <div class="contant-box-main">
            <div class="contant-header">
              <h6>
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFESURBVHgBhVHLUcNADJVkkwk30wElhA5CBxRAfjMJww1TQUIFhGty2RAOHCkhVIA7wHTg4ULi2BJaEzPGxsmb2bGsfW/03gqhhO4ovkHENgh4gmIeZ41F8R7/kK/iN/209AS7Vlar6CznUF50RnHfEljYtwR7BPjW9uzUioAIe2ojeJo3H/LectacqolArbUrAkUEdRD0KgJhWWmiVme49n9tZrW0EMHAvtC4Cy37QltkQQUGjBAxSKgKv0i2cIs/fV88WCchM6zscEL+gBKw6BeRxtrxSolDpU3yBdKP980Eie7VcyCC7c/46EQJKI5uHNC+nukON5NsQv/665TZeVfvZjlvDOAfXA43xu6JOT0nEWdsx2636R3UwE0avj5A5DhOj4ThQm2sns1xWCcwBiMkeLFcFxCnwskrHECapgsiN/wGgX6SW5zKhKcAAAAASUVORK5CYII="
                  alt=""
                />Merchant details
              </h6>
              <div class="delete-status-box" @click="showDeleteModel">
                <div class="status-box">
                  <label
                    :class="
                      userDetails?.is_active ? 'active-status m-0' : 'm-0'
                    "
                  >
                    <span>Active</span>
                  </label>
                  <label
                    :class="
                      userDetails?.is_active ? 'm-0' : 'inactive-status m-0'
                    "
                  >
                    <span>Inactive</span>
                  </label>
                </div>
                <button class="delete-profile">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.75 16.5H5.25C4.42157 16.5 3.75 15.8284 3.75 15V5.25H2.25V3.75H5.25V3C5.25 2.17157 5.92157 1.5 6.75 1.5H11.25C12.0784 1.5 12.75 2.17157 12.75 3V3.75H15.75V5.25H14.25V15C14.25 15.8284 13.5784 16.5 12.75 16.5ZM5.25 5.25V15H12.75V5.25H5.25ZM6.75 3V3.75H11.25V3H6.75Z"
                      fill="#EC4424"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="profile-details">
              <div class="profile-field_wrp">
                <div class="profile_field_data">
                  <h6 class="profile-title">User ID</h6>
                  <p class="profile-title">{{ userDetails.id }}</p>
                </div>
                <div class="profile_field_data">
                  <h6 class="profile-title">Company ID</h6>
                  <p class="profile-title">{{ userDetails.organization.id }}</p>
                </div>
                <div class="profile_field_data">
                  <h6 class="profile-title">Compnay Name</h6>
                  <p class="profile-title">
                    {{ userDetails.organization.name }}
                  </p>
                </div>
                <div class="profile_field_data">
                  <h6 class="profile-title">Email</h6>
                  <p class="profile-title">{{ userDetails.email }}</p>
                </div>
                <!-- <div class="profile_field_data">
                  <h6 class="profile-title">Billing Address</h6>
                  <p class="profile-title">{{userDetails.billing_address}}</p>
                </div>
                <div class="profile_field_data">
                  <h6 class="profile-title">Postal Code</h6>
                  <p class="profile-title">{{userDetails.postal_id}}</p>
                </div>
                <div class="profile_field_data">
                  <h6 class="profile-title">City</h6>
                  <p class="profile-title">{{userDetails.city}}</p>
                </div>
                <div class="profile_field_data">
                  <h6 class="profile-title">Country</h6>
                  <p class="profile-title">{{userDetails.country}}</p>
                </div>
                <div class="profile_field_data">
                  <h6 class="profile-title">VAT No.</h6>
                  <p class="profile-title">{{userDetails.vat_no || '-'}}</p>
                </div> -->
                <div class="profile_field_data">
                  <h6 class="profile-title">Phone No.</h6>
                  <p class="profile-title">{{ userDetails.phone || "-" }}</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="edit-button form-btns">
                <router-link
                  :to="{
                    name: `EditMerchant`,
                    params: { merchantid: $route.params.merchantid },
                  }"
                >
                  <button type="submit" class="save-btn">
                    <EditIcon />
                    Edit
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <img
                src="../../../public/assets/images/Members.png"
                alt=""
              />Users
            </h6>
          </div>
          <div class="contant-details">
            <div class="overflow-auto">
              <table class="table table-sm" id="my-table">
                <thead>
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">email</th>
                    <th scope="col">Role</th>
                    <th scope="col">Status</th>
                    <th scope="col">Last Login</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="user in userDetails?.users
                      ? userDetails?.users.slice((page - 1) * 10, page * 10)
                      : []"
                    :key="user.id"
                  >
                    <td class="font-light-text" width="10%">{{ user.id }}</td>
                    <td width="30%">{{ user.email }}</td>
                    <td class="font-light-text" width="20%">User</td>
                    <td width="20%">
                      <label class="status-active" v-if="user.is_active">
                        Active
                      </label>
                      <label class="status-inactive" v-else>Inactive</label>
                    </td>
                    <td class="font-light-text" width="20%">
                      {{ user.last_login || "-" }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <Pagination
                :page="page"
                :totalPages="
                  userDetails?.users
                    ? Array(Math.ceil(userDetails?.users?.length / 10))
                        .fill(0)
                        .map((e, i) => i + 1)
                    : []
                "
                :name="'users'"
                :count="userDetails?.users ? userDetails?.users.length : 0"
                :incrementpage="incrementpage"
                :decrementpage="decrementpage"
                :setpage="setpage"
                :perpage="10"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Pagination from "../../Core/Pagination.vue";
import CustomModel from "../../Core/CustomModel.vue";
import { subject } from "@casl/ability";
import { UserRoleMixin, MERCHANT } from "@/Core/mixins/UserRoleMixin";
import { RESOURCE_NAME } from "../user.vars";
import EditIcon from "../../icons/edit.vue";
import router from "../../Router";
import {
  DEACTIVATE_USER,
  FULL_DELETE_USER,
  GET_USER_DETAILS,
} from "@/Core/store/action-types";
export default {
  name: "MerchantDetailGrid",
  components: {
    Pagination,
    CustomModel,
    EditIcon,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      page: 1,
      totalPages: [],
      merchantUSers: [],
      deleteUserModelID: "deleteuserModel",
      deactivateModelID: "deactivateuserModel",
    };
  },
  computed: {
    ...mapGetters("user", ["userDetails"]),
  },
  methods: {
    ...mapActions("user", [
      GET_USER_DETAILS,
      DEACTIVATE_USER,
      FULL_DELETE_USER,
    ]),
    // get user details
    initUserDetails() {
      if (
        this?.$route?.params?.merchantid &&
        this.$route.params.merchantid != ""
      ) {
        const merchant_id = this.$route.params.merchantid;
        this[GET_USER_DETAILS](merchant_id)
          .then(() => {
            console.log("audience details success");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    // delete audience
    showDeleteModel() {
      if (this.getUserRole(this.userDetails) == MERCHANT.name) {
        window.$(`#${this.deactivateModelID}`).modal("toggle");
      } else {
        window.$(`#${this.deleteUserModelID}`).modal("toggle");
      }
    },
    async deleteUser() {
      window.$(`#${this.deleteUserModelID}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "delete",
        subject(RESOURCE_NAME, this.userDetails)
      );
      if (hasPermission) {
        this[FULL_DELETE_USER](this.userDetails.id)
          .then(() => {
            this.$alertify.notify(
              `${this.userDetails.email} successfully removed.`,
              "success",
              3
            );
            router.push("/Users");
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    async deactivateUser() {
      window.$(`#${this.deactivateModelID}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "deactivate",
        subject(RESOURCE_NAME, this.userDetails)
      );
      if (hasPermission) {
        this[DEACTIVATE_USER](this.userDetails.id)
          .then(() => {
            this.$alertify.notify(
              `${this.userDetails.email} successfully deactivated.`,
              "success",
              3
            );
            router.push("/Users");
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    // go to next page
    incrementpage() {
      this.page = this.page + 1;
    },
    // go to previous page
    decrementpage() {
      this.page = this.page - 1;
    },
    // go to perticular page
    setpage(page) {
      this.page = page;
    },
    // generate CSV of customers
  },
  mounted() {
    this.initUserDetails();
  },
};
</script>
<style scoped>
.audiance_section {
  display: flex;
  justify-content: space-between;
}

.title_section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.icon_title h1 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}

.sub_title {
  display: flex;
  gap: 4rem;
  border-bottom: 1px solid #dee2e6;
}

.second_underline_section {
  margin-top: 3rem;
}

.sub_title h6 {
  font-size: 12px;
  color: #aaabad;
}

.sub_profile-title {
  width: 70%;
}

p.profile-title {
  margin: 0;
  width: 70%;
  color: #3f3f40;
  line-height: 28px;
  font-size: 15px;
  font-weight: 600;
}
#my-table tbody tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #3f3f40;
  padding: 6px 0;
  line-height: 36px;
}
.modal-footer.custom-button {
  background-color: #ffff;
  border-color: #fff;
  float: left;
}
.modal-content.custom-modal-content {
  padding: 3%;
  border-radius: 0;
}
.custom-check-title {
  padding: 10px;
}
.cusom-main-title {
  padding-bottom: 15px;
}
.modal-title {
  font-size: 25px;
  padding-bottom: 10px;
  font-weight: 600;
}
.modal-content .modal-body p,
.modal-content h4 {
  color: #7081b9;
  font-size: 16px;
  color: #3f3f40;
}
</style>
