<template>
  <div class="contant-box-main">
    <div class="contant-header">
      <h6>
        <img
          src="./../../../public/assets/images/purchases-icon.png"
          alt=""
        />Purchases
      </h6>
    </div>
    <div class="contant-details">
      <!-- purchase list start -->
      <div class="table-wrp overflow-auto position-relative">
        <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <table class="table table-sm" id="my-table">
          <thead>
            <tr>
              <th scope="col" v-for="f in fields" v-bind:key="f.id">
                {{ f.split("_").join(" ") }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items.results" v-bind:key="item.id"  class="button-tr">
              <td class="font-light-text" width="15%"> {{ item?.merchant_receipt_id }}</td>
              <td class="font-light-text" width="20%">{{ item?.order_date}}</td>
              <!-- <td class="font-light-text" width="20%">{{ new Date(item?.order_date)?.toISOString()?.split('T')[0]?.split('-').reverse().join('/')}}</td> -->
              <td width="35%">{{ item?.purchases[0]?.store?.name}}</td>
              <td class="font-light-text" width="20%"> {{ item?.total_amount?.toFixed(0)}}</td>
              <!-- <td class="font-light-text" width="20%"> {{ item?.currency}}</td> -->
              <!-- <td class="font-light-text">{{ Math.round(item.item_price*item.count)}}</td> -->
              <td class="font-light-text"  width="10%">{{ item.currency}}</td>
              <td class="font-light-text" width="5%" data-toggle="modal" data-target="#exampleModal" @click="tempItem=item">
                <button class="eye-view">
                <EyeIcon />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :page="page"
          :totalPages="totalPages"
          :name="'Purchases'"
          :count="items.count ? items.count : 0"
          :incrementpage="incrementpage"
          :decrementpage="decrementpage"
          :setpage="setpage"
          :perpage="10"
        />
      </div>
      <!-- purchase list end -->
    </div>
    <!-- purchase details model start -->
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Receipt Details ({{tempItem.merchant_receipt_id}})</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- {{tempItem}} -->
        <table class="table">
  <thead>
    <tr>
      <th scope="col">Article ID</th>
      <th scope="col">Article Name</th>
      <th scope="col">Category</th>
      <th scope="col">Count</th>
      <th scope="col">Item Price</th>
      <th scope="col">Total Price</th>
      <th scope="col">Currency</th>
      <!-- <th scope="col">Sales employee name</th> -->
      <th scope="col">Country</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="purchase in tempItem.purchases" v-bind:key="purchase.id">
      <td class="font-light-text" width="10%">{{purchase?.article?.number}}</td>
      <!-- <td class="font-light-text" width="7%">{{purchase?.article.id}}</td> -->
      <td class="font-light-text" width="30%">{{purchase?.article?.name}}</td>
      <td class="font-light-text" width="15%">{{purchase?.article?.product?.category?.name }}</td>
      <td class="font-light-text" width="7.5%">{{purchase?.count}}</td>
      <td class="font-light-text" width="7.5%">{{purchase?.item_price?.toFixed(0)}}</td>
      <td class="font-light-text" width="10%">{{(purchase?.item_price*purchase?.count + purchase?.item_vat)?.toFixed(0)}}</td>
      <td class="font-light-text" width="10%">{{purchase?.currency}}</td>
      <!-- <td  width="15%">{{tempItem?.receipt__sales_employee_name}}</td> -->
      <td class="font-light-text" width="10%">{{purchase?.store?.country}}</td>
    </tr>
    <tr class="hidden-border">
      <td width="10%"></td>
      <td width="30%"></td>
      <td  width="15%"></td>
      <td  width="5%"></td>
      <td  width="5%">Total</td>
      <td  width="10%">{{tempItem?.total_amount?.toFixed(0)}}</td>
      <td  width="10%">{{tempItem?.currency}}</td>
      <td  width="10%"></td>
    </tr>
  </tbody>
</table>
      </div>
      <div class="modal-footer">
        <button type="button" class="cancle-btn" data-dismiss="modal" >Cancel</button>
      </div>
    </div>
  </div>
</div>
 <!-- purchase details model end -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Pagination from "../../Core/Pagination";
import EyeIcon from "../../icons/eye.vue";
import { LIST_CUSTOMER_PURCHASES } from "@/Core/store/action-types";
export default {
  name: "Purchases",
  components: {
    Pagination,
    EyeIcon
  },
  data() {
    return {
      page: 1,
      totalPages: [],
      tempItem:{},
      fields: ["Receipt ID", "Date","Store Name", "Total Order Amount","currency",""],
      items: [],
      loadindShow:false,
    };
  },
  methods: {
    ...mapActions("customer", [LIST_CUSTOMER_PURCHASES]),
    //get customer purchase details
    InItCustomerPurchaseDetails() {
      if (this?.$route?.params?.customerId && this.$route.params.customerId != "") {
        const customerId = this.$route.params.customerId;
        const payload = {
          params: {
            page: this.page,
          },
          objId: customerId,
        };
        this.loadindShow = true
        this[LIST_CUSTOMER_PURCHASES](payload)
          .then((res) => {
            console.log("PurchaseDetails=>", res);
            this.items = res;
            var total = Math.ceil(res.count / 10);
            console.log("total=>", total);
            this.totalPages = Array(total)
              .fill(0)
              .map((e, i) => i + 1);
          })
          .catch((e) => {
            console.log("error=>", e);
          }).finally(()=>this.loadindShow=false)
      }
    },
    //go to next page
    incrementpage() {
      this.page = this.page + 1;
      this.InItCustomerPurchaseDetails();
    },
    //go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.InItCustomerPurchaseDetails();
    },
    // go to perticular page
    setpage(page) {
      this.page = page;
      this.InItCustomerPurchaseDetails();
    },
  },
  mounted() {
    this.InItCustomerPurchaseDetails();
  },
};
</script>
<style scoped>
.modal-dialog {
    min-width: 68%!important;
}
tr.hidden-border {
    border-style: hidden;
    border-top: 1px solid;
}
/* .button-tr{
  cursor: pointer;
} */
</style>