<template>
    <div>
        <div class="vld-parent">
            <loading v-model:active="isLoading" :is-full-page="fullPage" />
        </div>
        <MainMenu />
        <TopBar title="Email" :author="{
            home: 'Home',
            cart: 'Email',
        }" />
        <EmailTable></EmailTable>
    </div>
</template>
<script>
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";
import EmailTable from "./components/EmailTable.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    name: "Email",
    components: {
        MainMenu,
        TopBar,
        Loading,
        EmailTable,
    },
    data() {
        return {
            fullPage: true,
            isLoading: true,
        };
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 2000);
    },
};
</script>