<template>
  <div>
    <div>
      <CustomModel
        v-if="deletedresource"
        :modalId="deleteModalId"
        :item="deletedresource"
        :onDelete="deleteOrg"
        :resourseName="'Company'"
        actionType="Delete"
      ></CustomModel>

      <OrgModal
        :modalId="editModalId"
        :orgToEdit="editedResource"
        :modalType="1"
        :initOrgData="initOrgData"
      />

      <!-- <OrgModal :modalId="createModalId" :initOrgData="initOrgData" :modalType="2"/> -->
      <RegistrationModal
        v-bind:modalId="createModalId"
        name="Company"
        selectedRole="Merchant"
        :initFunction="initOrgData"
      />
      <div>
        <div class="contant-box-main">
          <div class="data-heading-wrp">
            <div class="data-heading">
              <!-- search box start -->
              <div class="search-box-wrp">
                <input
                  type="text"
                  class="form-control"
                  v-model="search"
                  name=""
                  placeholder="Search by the company name"
                />
                <a class="search_icon" href="#"
                  ><img
                    src="../../../public/assets/images/search_icon.svg"
                    alt=""
                /></a>
              </div>
            </div>
            <!-- create button start -->
            <div class="data-heading-btn">
              <button @click="showCreateDialog">+ create new company</button>
            </div>
            <!-- create button end -->
          </div>
          <!-- org table start -->
          <div class="table-wrp overflow-auto position-relative">
            <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
            <table class="table my-table-wrp table-sm" id="my-table">
              <thead>
                <tr>
                  <th scope="col" v-for="f in fields" v-bind:key="f.id">
                    <div class="table-head">{{ f.split("_").join(" ") }}</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="org in orgs.results"
                  v-bind:key="org.id"
                  class="active-row"
                >
                  <td width="5%" class="font-light-text">{{ org.id }}</td>
                 
                  <td width="20%" class="font-text">
                     <router-link
                        :to="{
                          name: `OrgDetails`,
                          params: { id: org.id },
                        }"
                      >{{ org.name }}</router-link></td>
                  
                  <td width="20%" class="font-light-text">
                    {{ org.billing_address }}
                  </td>
                  <td width="10%" class="font-light-text">
                    {{ org.postal_id }}
                  </td>
                  <td width="15%" class="font-light-text">{{ org.city }}</td>
                  <td width="12%" class="font-light-text">{{ org.country }}</td>
                  <td width="11%" class="font-light-text">
                    {{ org.vat_no || "-" }}
                  </td>
                  <td class="btn-align" width="7%">
                    <div class="d-flex align-item-center">
                      <router-link
                        :to="{
                          name: `OrgDetails`,
                          params: { id: org.id },
                        }"
                      >
                        <button class="action-btns ms-2 icon-effect" title="See Campaign Details">
                          <!-- <DisableEyeIcon /> -->
                          <!-- <EyeIcon v-on:click="editCampaign(campaign)" /> -->
                          <EyeIcon />
                        </button>
                      </router-link>
                      <button
                        class="action-btns ms-2 icon-effect"
                        @click="showEditDialog(org)"
                      >
                        <PenIcon />
                      </button>
                      <!-- <button
                        class="action-btns ms-2 icon-effect"
                        @click="showDeleteModel(org)"
                      >
                        <DeleteIcon />
                      </button> -->
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination
              :page="page"
              :totalPages="totalPages"
              name="Companies"
              :count="orgs.count ? orgs.count : 0"
              :incrementpage="incrementpage"
              :decrementpage="decrementpage"
              :setpage="setpage"
              :perpage="10"
            />
          </div>
          <!-- org table end -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import DeleteIcon from "../../icons/cancel";
import EyeIcon from "../../icons/eye.vue"
import PenIcon from "../../icons/pen.vue";
import Pagination from "../../Core/Pagination";
import OrgModal from "./OrgModal";
import { mapActions } from "vuex";
import { subject } from "@casl/ability";
import { RESOURCE_NAME } from "../org.vars";
import CustomModel from "../../Core/CustomModel.vue";
import RegistrationModal from "../../User/components/RegistrationModal.vue";

import { LIST_ORGS, DELETE_ORG } from "@/Core/store/action-types";
export default {
  name: "OrgTable",
  components: {
    // DeleteIcon,
    PenIcon,
    EyeIcon,
    Pagination,
    OrgModal,
    CustomModel,
    RegistrationModal,
  },
  data() {
    return {
      fields: [
        "ID",
        "Name",
        "Billing Address",
        "Postal Code",
        "City",
        "Country",
        "Vat No",
        "",
      ],
      orgs: [],
      params: { persist: true },
      search: "",
      page: 1,
      totalPages: [],
      createModalId: "createDialog",
      deleteModalId: "deleteDialog",
      editModalId: "editDialog",
      editedResource: {},
      deletedresource: {},
      duplicateResource: {},
      createpermission: this.$ability.can("create", subject(RESOURCE_NAME, {})),
      loadindShow:false
    };
  },
  methods: {
    ...mapActions("org", [LIST_ORGS, DELETE_ORG]),
    //get org data
    initOrgData(pageNumber = null) {
      pageNumber ? (this.page = pageNumber) : null;
      let options = {
        q: this.search.length < 3 ? null : this.search,
        page: this.page,
      };
      this.loadindShow = true
      this[LIST_ORGS](options).then((res) => {
        this.orgs = res;
        this.totalPages = Array(Math.ceil(res.count / 10))
          .fill(0)
          .map((e, i) => i + 1);
      })
      .finally(()=>this.loadindShow=false)
      
    },
    //open craete org model
    showCreateDialog() {
      window.$(`#${this.createModalId}`).modal("toggle");
    },
    showEditDialog(org) {
      this.editedResource = org;
      window.$(`#${this.editModalId}`).modal("toggle");
    },
    showDeleteModel(item) {
      this.deletedresource = item;
      window.$(`#${this.deleteModalId}`).modal("toggle");
    },
    async deleteOrg() {
      window.$(`#${this.deleteModalId}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "delete",
        subject(RESOURCE_NAME, this.deletedresource)
      );
      if (hasPermission) {
        this[DELETE_ORG](this.deletedresource.id)
          .then(() => {
            this.initOrgData(1);
            this.$alertify.notify(
              `${this.deletedresource.name} successfully removed.`,
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    //go to next page
    incrementpage() {
      this.page = this.page + 1;
      this.initOrgData();
    },
    //go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.initOrgData();
    },
    // go to perticular page
    setpage(page) {
      this.page = page;
      this.initOrgData();
    },
  },
  mounted() {
    this.initOrgData();
  },
  watch: {
    search() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this.page = 1;
        this.initOrgData();
      }
    },
  },
};
</script>
