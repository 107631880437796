<template>
  <div>
    <TopBar
      title="Customers"
      :author="{
        home: customerDetails ? customerDetails : '',
        cart: 'Customers',
      }"
    />
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <EditCustomerForm />
  </div>
</template>
<script>
import EditCustomerForm from "./components/EditCustomerForm.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import TopBar from "../Menu/TopBar.vue";
import { mapGetters } from "vuex";
export default {
  name: "EditCustomer",
  components: {
    EditCustomerForm,
    Loading,
    TopBar,
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
    };
  },
  computed: {
    ...mapGetters("customer", ["customerDetails"]),
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>