<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 16H15V6H18V16ZM13 16H10V3H13V16ZM8 16H5V0H8V16ZM3 16H0V8H3V16Z"
      fill="#2E3A59"
    />
  </svg>
</template>


<script>
export default {
  name: "AnalyticsIcon",
};
</script>

<style scoped>
</style>