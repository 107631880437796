<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar
      title="Offers"
      :author="{
        home: 'Home',
        cart: 'Offers',
      }"
    />
    <OfferTable />
  </div>
</template>
<script>
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";
import OfferTable from "./components/offerTable.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";

export default {
  name: "offers",
  components: {
    MainMenu,
    TopBar,
    OfferTable,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
    };
  },
  created() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>