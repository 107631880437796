<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="42px" height="42px" viewBox="0 0 42 42" version="1.1">
<g id="surface1">
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(31.764706%,40.784314%,96.078431%);fill-opacity:1;" d="M 21.644531 28.855469 C 21.285156 28.863281 20.996094 29.160156 21 29.523438 L 21 32.148438 C 20.996094 32.382812 21.121094 32.605469 21.324219 32.722656 C 21.53125 32.84375 21.78125 32.84375 21.988281 32.722656 C 22.191406 32.605469 22.316406 32.382812 22.3125 32.148438 L 22.3125 29.523438 C 22.316406 29.34375 22.246094 29.175781 22.121094 29.046875 C 21.996094 28.921875 21.824219 28.851562 21.644531 28.855469 Z M 24.269531 28.855469 C 23.910156 28.863281 23.621094 29.160156 23.625 29.523438 L 23.625 32.148438 C 23.621094 32.382812 23.746094 32.605469 23.949219 32.722656 C 24.15625 32.84375 24.40625 32.84375 24.613281 32.722656 C 24.816406 32.605469 24.941406 32.382812 24.9375 32.148438 L 24.9375 29.523438 C 24.941406 29.34375 24.871094 29.175781 24.746094 29.046875 C 24.621094 28.921875 24.449219 28.851562 24.269531 28.855469 Z M 24.269531 28.855469 "/>
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(31.764706%,40.784314%,96.078431%);fill-opacity:1;" d="M 22.933594 30.84375 C 22.84375 30.847656 22.75 30.871094 22.667969 30.914062 L 21.355469 31.570312 C 21.144531 31.671875 21.003906 31.882812 20.988281 32.117188 C 20.976562 32.351562 21.085938 32.574219 21.285156 32.707031 C 21.480469 32.835938 21.730469 32.847656 21.941406 32.742188 L 22.957031 32.234375 L 23.980469 32.742188 C 24.1875 32.847656 24.4375 32.835938 24.636719 32.707031 C 24.832031 32.574219 24.945312 32.351562 24.929688 32.117188 C 24.914062 31.882812 24.777344 31.671875 24.566406 31.570312 L 23.253906 30.914062 C 23.15625 30.863281 23.046875 30.839844 22.933594 30.84375 Z M 21.644531 22.304688 C 21.285156 22.308594 20.996094 22.605469 21 22.96875 L 21 25.582031 C 20.996094 25.816406 21.121094 26.035156 21.324219 26.15625 C 21.53125 26.277344 21.78125 26.277344 21.988281 26.15625 C 22.191406 26.035156 22.316406 25.816406 22.3125 25.582031 L 22.3125 22.96875 C 22.316406 22.789062 22.246094 22.621094 22.121094 22.496094 C 21.992188 22.371094 21.824219 22.300781 21.644531 22.304688 Z M 24.269531 22.304688 C 23.910156 22.308594 23.621094 22.605469 23.625 22.96875 L 23.625 25.582031 C 23.621094 25.816406 23.746094 26.035156 23.949219 26.15625 C 24.15625 26.277344 24.40625 26.277344 24.613281 26.15625 C 24.816406 26.035156 24.941406 25.816406 24.9375 25.582031 L 24.9375 22.96875 C 24.941406 22.789062 24.871094 22.621094 24.746094 22.496094 C 24.617188 22.371094 24.449219 22.300781 24.269531 22.304688 Z M 24.269531 22.304688 "/>
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(31.764706%,40.784314%,96.078431%);fill-opacity:1;" d="M 22.933594 24.277344 C 22.839844 24.28125 22.75 24.304688 22.667969 24.347656 L 21.355469 25.003906 C 21.144531 25.109375 21.003906 25.316406 20.988281 25.550781 C 20.976562 25.785156 21.085938 26.011719 21.285156 26.140625 C 21.480469 26.269531 21.730469 26.285156 21.941406 26.175781 L 22.957031 25.667969 L 23.980469 26.175781 C 24.1875 26.285156 24.4375 26.269531 24.636719 26.140625 C 24.832031 26.011719 24.945312 25.785156 24.929688 25.550781 C 24.914062 25.316406 24.777344 25.109375 24.566406 25.003906 L 23.253906 24.347656 C 23.15625 24.296875 23.046875 24.273438 22.933594 24.277344 Z M 22.933594 24.277344 "/>
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(28.235294%,28.235294%,29.803922%);fill-opacity:1;" d="M 20.972656 3.9375 C 20.859375 3.945312 20.75 3.976562 20.652344 4.035156 L 1.628906 15.84375 C 1.382812 16 1.265625 16.300781 1.347656 16.578125 C 1.429688 16.859375 1.683594 17.054688 1.976562 17.054688 L 3.9375 17.054688 L 3.9375 37.40625 C 3.9375 37.765625 4.230469 38.0625 4.59375 38.0625 L 8.53125 38.0625 C 8.894531 38.0625 9.1875 37.765625 9.1875 37.40625 L 9.1875 19.679688 L 32.8125 19.679688 L 32.8125 37.40625 C 32.8125 37.765625 33.105469 38.0625 33.46875 38.0625 L 37.40625 38.0625 C 37.769531 38.0625 38.0625 37.765625 38.0625 37.40625 L 38.0625 17.054688 L 40.023438 17.054688 C 40.316406 17.054688 40.570312 16.859375 40.652344 16.578125 C 40.734375 16.300781 40.617188 16 40.371094 15.84375 L 21.347656 4.035156 C 21.234375 3.96875 21.101562 3.933594 20.972656 3.9375 Z M 21 5.367188 L 37.71875 15.742188 L 37.40625 15.742188 C 37.042969 15.742188 36.75 16.039062 36.75 16.398438 L 36.75 36.75 L 34.125 36.75 L 34.125 19.023438 C 34.125 18.664062 33.832031 18.367188 33.46875 18.367188 L 8.53125 18.367188 C 8.167969 18.367188 7.875 18.664062 7.875 19.023438 L 7.875 36.75 L 5.25 36.75 L 5.25 16.398438 C 5.25 16.039062 4.957031 15.742188 4.59375 15.742188 L 4.28125 15.742188 Z M 21 5.367188 "/>
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(31.764706%,40.784314%,96.078431%);fill-opacity:1;" d="M 15.082031 30.324219 C 14.722656 30.332031 14.433594 30.628906 14.4375 30.992188 L 14.4375 36.082031 C 14.433594 36.320312 14.558594 36.539062 14.761719 36.660156 C 14.96875 36.777344 15.21875 36.777344 15.425781 36.660156 C 15.628906 36.539062 15.753906 36.320312 15.75 36.082031 L 15.75 30.992188 C 15.753906 30.8125 15.683594 30.644531 15.558594 30.515625 C 15.433594 30.390625 15.261719 30.324219 15.082031 30.324219 Z M 11.148438 28.628906 C 10.789062 28.636719 10.5 28.933594 10.503906 29.292969 L 10.503906 34.386719 C 10.5 34.621094 10.625 34.84375 10.828125 34.960938 C 11.03125 35.082031 11.285156 35.082031 11.488281 34.960938 C 11.695312 34.84375 11.820312 34.621094 11.816406 34.386719 L 11.816406 29.292969 C 11.816406 29.117188 11.746094 28.945312 11.621094 28.820312 C 11.496094 28.695312 11.324219 28.628906 11.148438 28.628906 Z M 19.019531 28.628906 C 18.65625 28.636719 18.367188 28.933594 18.371094 29.292969 L 18.371094 34.386719 C 18.367188 34.621094 18.492188 34.84375 18.699219 34.960938 C 18.902344 35.082031 19.15625 35.082031 19.359375 34.960938 C 19.5625 34.84375 19.6875 34.621094 19.683594 34.386719 L 19.683594 29.292969 C 19.6875 29.117188 19.617188 28.945312 19.492188 28.820312 C 19.367188 28.695312 19.195312 28.628906 19.019531 28.628906 Z M 19.019531 28.628906 "/>
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(31.764706%,40.784314%,96.078431%);fill-opacity:1;" d="M 11.167969 33.730469 C 10.851562 33.726562 10.578125 33.945312 10.515625 34.253906 C 10.449219 34.5625 10.613281 34.871094 10.902344 34.992188 L 14.828125 36.6875 C 15.046875 36.785156 15.296875 36.757812 15.488281 36.617188 C 15.679688 36.476562 15.78125 36.246094 15.75 36.011719 C 15.722656 35.773438 15.570312 35.574219 15.351562 35.484375 L 11.421875 33.789062 C 11.34375 33.753906 11.253906 33.734375 11.167969 33.730469 Z M 11.167969 33.730469 "/>
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(31.764706%,40.784314%,96.078431%);fill-opacity:1;" d="M 19 33.730469 C 18.917969 33.734375 18.839844 33.753906 18.765625 33.789062 L 14.835938 35.484375 C 14.617188 35.574219 14.464844 35.773438 14.4375 36.011719 C 14.40625 36.246094 14.507812 36.476562 14.699219 36.617188 C 14.890625 36.757812 15.140625 36.785156 15.359375 36.6875 L 19.285156 34.992188 C 19.578125 34.871094 19.742188 34.554688 19.671875 34.242188 C 19.601562 33.933594 19.320312 33.714844 19 33.730469 Z M 19 28.640625 C 18.917969 28.644531 18.839844 28.664062 18.765625 28.699219 L 14.835938 30.390625 C 14.617188 30.480469 14.460938 30.683594 14.433594 30.917969 C 14.402344 31.15625 14.503906 31.386719 14.695312 31.527344 C 14.886719 31.667969 15.140625 31.695312 15.359375 31.597656 L 19.285156 29.90625 C 19.582031 29.785156 19.75 29.464844 19.679688 29.152344 C 19.609375 28.839844 19.320312 28.621094 19 28.640625 Z M 15.066406 26.945312 C 14.984375 26.949219 14.902344 26.96875 14.828125 27 L 10.902344 28.695312 C 10.683594 28.785156 10.53125 28.988281 10.5 29.222656 C 10.472656 29.457031 10.574219 29.691406 10.765625 29.832031 C 10.953125 29.96875 11.207031 29.996094 11.421875 29.898438 L 15.351562 28.207031 C 15.648438 28.085938 15.816406 27.769531 15.746094 27.453125 C 15.675781 27.140625 15.386719 26.925781 15.066406 26.945312 Z M 15.066406 26.945312 "/>
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(31.764706%,40.784314%,96.078431%);fill-opacity:1;" d="M 15.101562 26.945312 C 14.785156 26.9375 14.507812 27.15625 14.441406 27.464844 C 14.378906 27.777344 14.542969 28.089844 14.835938 28.207031 L 18.765625 29.898438 C 18.980469 29.996094 19.234375 29.96875 19.421875 29.832031 C 19.613281 29.691406 19.714844 29.457031 19.6875 29.222656 C 19.65625 28.988281 19.503906 28.785156 19.285156 28.695312 L 15.359375 27 C 15.277344 26.964844 15.191406 26.945312 15.101562 26.945312 Z M 11.167969 28.640625 C 10.851562 28.632812 10.574219 28.851562 10.507812 29.164062 C 10.441406 29.472656 10.609375 29.785156 10.902344 29.90625 L 12.210938 30.46875 L 13.644531 31.085938 L 14.828125 31.597656 C 15.046875 31.695312 15.300781 31.667969 15.492188 31.527344 C 15.683594 31.386719 15.785156 31.15625 15.753906 30.917969 C 15.726562 30.683594 15.570312 30.480469 15.351562 30.390625 L 14.164062 29.882812 L 12.734375 29.265625 L 11.421875 28.699219 C 11.34375 28.660156 11.253906 28.640625 11.167969 28.640625 Z M 11.167969 28.640625 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(31.764706%,40.784314%,96.078431%);fill-opacity:1;" d="M 19.03125 28.875 C 18.667969 28.875 18.375 29.167969 18.375 29.53125 L 18.375 36.09375 C 18.375 36.457031 18.667969 36.75 19.03125 36.75 L 26.90625 36.75 C 27.269531 36.75 27.5625 36.457031 27.5625 36.09375 L 27.5625 29.53125 C 27.5625 29.167969 27.269531 28.875 26.90625 28.875 Z M 19.6875 30.1875 L 26.25 30.1875 L 26.25 35.4375 L 19.6875 35.4375 Z M 19.6875 30.1875 "/>
<path style=" stroke:none;fill-rule:evenodd;fill:rgb(31.764706%,40.784314%,96.078431%);fill-opacity:1;" d="M 17.0625 10.5 C 16.824219 10.496094 16.605469 10.621094 16.488281 10.824219 C 16.367188 11.027344 16.367188 11.28125 16.488281 11.484375 C 16.605469 11.691406 16.824219 11.816406 17.0625 11.8125 L 24.9375 11.8125 C 25.175781 11.816406 25.394531 11.691406 25.511719 11.484375 C 25.632812 11.28125 25.632812 11.027344 25.511719 10.824219 C 25.394531 10.621094 25.175781 10.496094 24.9375 10.5 Z M 14.4375 13.125 C 14.199219 13.121094 13.980469 13.246094 13.863281 13.449219 C 13.742188 13.652344 13.742188 13.90625 13.863281 14.109375 C 13.980469 14.316406 14.199219 14.441406 14.4375 14.4375 L 27.5625 14.4375 C 27.800781 14.441406 28.019531 14.316406 28.136719 14.109375 C 28.257812 13.90625 28.257812 13.652344 28.136719 13.449219 C 28.019531 13.246094 27.800781 13.121094 27.5625 13.125 Z M 14.4375 13.125 "/>
<path style=" stroke:none;fill-rule:nonzero;fill:rgb(31.764706%,40.784314%,96.078431%);fill-opacity:1;" d="M 19.03125 22.3125 C 18.667969 22.3125 18.375 22.605469 18.375 22.96875 L 18.375 29.53125 C 18.375 29.894531 18.667969 30.1875 19.03125 30.1875 L 26.90625 30.1875 C 27.269531 30.1875 27.5625 29.894531 27.5625 29.53125 L 27.5625 22.96875 C 27.5625 22.605469 27.269531 22.3125 26.90625 22.3125 Z M 19.6875 23.625 L 26.25 23.625 L 26.25 28.875 L 19.6875 28.875 Z M 19.6875 23.625 "/>
</g>
</svg>

</template>

<script>
export default {
  name: "ShippingIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>