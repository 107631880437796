<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.5 7.75C3.70507 7.75 2.25 6.29493 2.25 4.5C2.25 2.70507 3.70507 1.25 5.5 1.25C7.29493 1.25 8.75 2.70507 8.75 4.5C8.75 6.29493 7.29493 7.75 5.5 7.75ZM1 4.5C1 2.01472 3.01472 0 5.5 0C7.98528 0 10 2.01472 10 4.5C10 6.98528 7.98528 9 5.5 9C3.01472 9 1 6.98528 1 4.5ZM18.5 7.75C16.7051 7.75 15.25 6.29493 15.25 4.5C15.25 2.70507 16.7051 1.25 18.5 1.25C20.2949 1.25 21.75 2.70507 21.75 4.5C21.75 6.29493 20.2949 7.75 18.5 7.75ZM14 4.5C14 2.01472 16.0147 0 18.5 0C20.9853 0 23 2.01472 23 4.5C23 6.98528 20.9853 9 18.5 9C16.0147 9 14 6.98528 14 4.5ZM5.03971 18.75H2C1.58579 18.75 1.25 18.4142 1.25 18V15.5C1.25 13.1528 3.15279 11.25 5.5 11.25C6.49456 11.25 7.40933 11.5916 8.13336 12.1639C8.50295 11.9186 8.89718 11.7074 9.31155 11.5349C8.32296 10.5844 6.97972 10 5.5 10C2.46243 10 0 12.4624 0 15.5V18C0 19.1046 0.895431 20 2 20H5.28988C5.17004 19.5973 5.08545 19.1795 5.03971 18.75ZM18.7101 20H22C23.1046 20 24 19.1046 24 18V15.5C24 12.4624 21.5376 10 18.5 10C17.0203 10 15.677 10.5844 14.6885 11.5349C15.1028 11.7074 15.4971 11.9186 15.8666 12.1639C16.5907 11.5916 17.5054 11.25 18.5 11.25C20.8472 11.25 22.75 13.1528 22.75 15.5V18C22.75 18.4142 22.4142 18.75 22 18.75H18.9603C18.9145 19.1795 18.83 19.5973 18.7101 20Z"
        fill="#49494A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 20.75C13.5188 20.75 14.75 19.5188 14.75 18C14.75 16.4812 13.5188 15.25 12 15.25C10.4812 15.25 9.25 16.4812 9.25 18C9.25 19.5188 10.4812 20.75 12 20.75ZM12 22C14.2091 22 16 20.2091 16 18C16 15.7909 14.2091 14 12 14C9.79086 14 8 15.7909 8 18C8 20.2091 9.79086 22 12 22Z"
        fill="#49494A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.625 13V14.5H11.375V13H12.625ZM7 17.375H8.5V18.625H7V17.375ZM17 18.625H15.5V17.375H17V18.625ZM11.375 23V21.5H12.625V23H11.375Z"
        fill="#49494A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9775 14.9064L14.9168 15.9671L14.0329 15.0832L15.0936 14.0225L15.9775 14.9064ZM8.90641 14.0225L9.96707 15.0832L9.08318 15.9671L8.02252 14.9064L8.90641 14.0225ZM15.0936 21.9775L14.0329 20.9168L14.9168 20.033L15.9775 21.0936L15.0936 21.9775ZM8.02252 21.0936L9.08318 20.033L9.96707 20.9168L8.90641 21.9775L8.02252 21.0936Z"
        fill="#49494A"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AdminIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>