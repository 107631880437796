<template>
  <div class="contant-box-main customer-list-box">
    <div class="h-100">
      <div class="contant-header">
        <h6>
          <img
            src="../../../public/assets/images/campaigns-icon.png"
            alt=""
          />Most likely Article to be sold
          <span class="font-weight-normal font-italic">&nbsp; in {{ most_likely_periods }}</span>
        </h6>
      </div>
      <div class="contant-details h-100">
        <div class="overflow-auto position-relative" :class="loadindShow?'h-100':''" >
          <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <table class="table" id="my-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>No. of Likely customers</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="article in most_likely_articles" :key="article.id">
                <td class="font-light-text" width="20%">
                  {{ article.number }}
                </td>
                <td width="60%"> {{ article.name }}</td>
                <td>{{ article.customers_count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import { ORG_MOST_LIKELY_TO_BUY_ARTICLES } from "@/Core/store/action-types";
export default {
  name: "MostLikelyToBuyArticles",

  data() {
    return {
      loadindShow:false,
      most_likely_articles: [],
    };
  },
  computed:{
    ...mapGetters("user", ["userProfile"]),
    most_likely_periods() {
      let first_date = new Date();
      first_date.setDate(first_date.getDate() + 7);
      let second_date = new Date();
      second_date.setDate(second_date.getDate() + 21);
      let first_date_str = first_date.getDate() + "." + (first_date.getMonth() + 1) + "." + String(first_date.getFullYear()).slice(-2);
      let second_date_str = second_date.getDate() + "." + (second_date.getMonth() + 1) + "." + String(second_date.getFullYear()).slice(-2);
      return first_date_str + " - " + second_date_str;
    },
  },
  methods: {
    ...mapActions("article", [ORG_MOST_LIKELY_TO_BUY_ARTICLES]),
    initMostLikelyArticles() {
      this.loadindShow = true
      this[ORG_MOST_LIKELY_TO_BUY_ARTICLES](this.userProfile?.organization?.id)
        .then((result) => {
          this.most_likely_articles = result.sort((first, second) => second.customers_count - first.customers_count);
        })
        .catch(() => (this.most_likely_articles = []))
        .finally(()=>this.loadindShow=false)
    }
  },
  mounted() {
    this.initMostLikelyArticles();
  },
};
</script>
<style scoped>
select#adminfilter {
  border-radius: 3px;
  padding: 10px 10px 10px 10px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
.customer-list-box {
  height: 100%;
  background: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>