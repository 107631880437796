<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <MainMenu />
    <TopBar
      title="Companies"
      :author="{
        home: 'Home',
        cart: 'Companies',
      }"
    />
    <OrgTable />
  </div>
</template>

<script>
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// import Grid from './components/OrgGrid';
import OrgTable from "./components/OrgTable.vue";

export default {
  name: "Organizations",
  components: {
    MainMenu,
    TopBar,
    Loading,
    // Grid,
    OrgTable,
  },
  data() {
    return {
      fullPage: true,
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>