 <template>
<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.43815 7.0415C2.43815 8.98601 4.01448 10.5623 5.95898 10.5623C7.90349 10.5623 9.47982 8.98601 9.47982 7.0415C9.47982 5.097 7.90349 3.52067 5.95898 3.52067C4.01448 3.52067 2.43815 5.097 2.43815 7.0415ZM5.95898 2.1665C3.2666 2.1665 1.08398 4.34912 1.08398 7.0415C1.08398 9.73389 3.2666 11.9165 5.95898 11.9165C8.65137 11.9165 10.834 9.73389 10.834 7.0415C10.834 4.34912 8.65137 2.1665 5.95898 2.1665Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.5202 7.0415C16.5202 8.98601 18.0965 10.5623 20.041 10.5623C21.9855 10.5623 23.5618 8.98601 23.5618 7.0415C23.5618 5.097 21.9855 3.52067 20.041 3.52067C18.0965 3.52067 16.5202 5.097 16.5202 7.0415ZM20.041 2.1665C17.3486 2.1665 15.166 4.34912 15.166 7.0415C15.166 9.73389 17.3486 11.9165 20.041 11.9165C22.7334 11.9165 24.916 9.73389 24.916 7.0415C24.916 4.34912 22.7334 2.1665 20.041 2.1665Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.5625 21.6667V18.9583C10.5625 16.4155 8.50114 14.3542 5.95833 14.3542C3.41552 14.3542 1.35417 16.4155 1.35417 18.9583V21.6667C1.35417 22.1154 1.71794 22.4792 2.16667 22.4792H9.75C10.1987 22.4792 10.5625 22.1154 10.5625 21.6667ZM5.95833 13C2.66764 13 0 15.6676 0 18.9583V21.6667C0 22.8633 0.97005 23.8333 2.16667 23.8333H9.75C10.9466 23.8333 11.9167 22.8633 11.9167 21.6667V18.9583C11.9167 15.6676 9.24903 13 5.95833 13Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.6465 21.6667V18.9583C24.6465 16.4155 22.5851 14.3542 20.0423 14.3542C17.4995 14.3542 15.4382 16.4155 15.4382 18.9583V21.6667C15.4382 22.1154 15.8019 22.4792 16.2507 22.4792H23.834C24.2827 22.4792 24.6465 22.1154 24.6465 21.6667ZM20.0423 13C16.7516 13 14.084 15.6676 14.084 18.9583V21.6667C14.084 22.8633 15.054 23.8333 16.2507 23.8333H23.834C25.0306 23.8333 26.0007 22.8633 26.0007 21.6667V18.9583C26.0007 15.6676 23.333 13 20.0423 13Z" fill="white"/>
</svg>

</template>


<script>
export default {
  name: "CustomersIcon",
};
</script>

<style scoped>
</style>