import { API_URL, authHeader } from "@/Core/helpers/utils";
import axios from "axios";

const RESOURCE_URL = `${API_URL}/api/v1/orgs/`;

export default {
  get(token, params) {
    return axios.get(RESOURCE_URL, {
      headers: authHeader(token),
      params: params,
    });
  },
  get_unlayer_templates(token) {
    let url = `${RESOURCE_URL}get_unlayer_templates/`;
    return axios.get(url, {
      headers: authHeader(token),
    });
  },
  get_all_single_send(token, params) {
    let url = `${RESOURCE_URL}get_all_single_send/`;
    return axios.get(url, {
      headers: authHeader(token),
      params: params,
    });
  },
  GET_SENDGRID_CONTACT_LIST(token) {
    let url = `${RESOURCE_URL}get_sendgrid_contact_list/`;
    return axios.get(url, {
      headers: authHeader(token),
    });
  },
  send_email_with_template_and_contact(token, obj) {
    return axios.post(`${RESOURCE_URL}send_email_with_template_and_contact/`, obj, {
      headers: authHeader(token),
    });
  },
};
