import {
  LIST_RECEIPT_ORDERS,
  GENERATE_RECEIPT_API_TOKEN,
  GENERATE_RECOMMENDATIONS_API_TOKEN,
  ARTICLE_FILE_UPLOAD,
  RECEIPT_FILE_UPLOAD,
  SHOPIFY_RECEIPT_FILE_UPLOAD,
  MULTIPLE_RECEIPT_FILE_UPLOAD,
  LIST_RECEIPTS,
  GET_RECEIPTS_STATS
} from "@/Core/store/action-types";

import api from "./receipt.api";

const initialState = {};
const state = { ...initialState };

const actions = {
  async [LIST_RECEIPT_ORDERS]({ rootState }, { params, objId }) {
    const resp = await api.get_detail(
      rootState.user.userProfile.access,
      params,
      objId
    );
    return resp.data;
  },
  async [LIST_RECEIPTS]({ rootState }) {
    const resp = await api.get_receipts(rootState.user.userProfile.access);
    return resp.data;
  },
  async [GET_RECEIPTS_STATS]({ rootState }, org_id) {
    let response = await api.get_receipt_stats(
      rootState.user.userProfile.access,
      org_id
    );
    return response.data;
  },
  async [GENERATE_RECEIPT_API_TOKEN]({ rootState }, store_online_status) {
    const resp = await api.generate_add_receipt_token(
      rootState.user.userProfile.access,
      store_online_status
    );
    return resp.data;
  },
  async [GENERATE_RECOMMENDATIONS_API_TOKEN]({ rootState }) {
    const resp = await api.generate_recommendations_token(
      rootState.user.userProfile.access,
    );
    return resp.data;
  },
  async [ARTICLE_FILE_UPLOAD]({ rootState }, file) {
    const resp = await api.article_file_upload(
      rootState.user.userProfile.access,
      file
    );
    return resp.data;
  },
  async [RECEIPT_FILE_UPLOAD]({ rootState }, file) {
    const resp = await api.receipt_file_upload(
      rootState.user.userProfile.access,
      file
    );
    return resp.data;
  },
  async [SHOPIFY_RECEIPT_FILE_UPLOAD]({ rootState }, file) {
    const resp = await api.shopify_receipt_file_upload(
      rootState.user.userProfile.access,
      file
    );
    return resp.data;
  },
  async [MULTIPLE_RECEIPT_FILE_UPLOAD]({ rootState }, file) {
    const resp = await api.multiple_receipt_file_upload(
      rootState.user.userProfile.access,
      file
    );
    return resp.data;
  },
};

const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
