<template>
  <div id="recommendations">
    <h2>Product recommendations</h2>
    <div class="main-articles-sold">
      <div class="product-recommendations-section">
        <div class="prs-top">
          <div class="prst-bottom">
            <button
              type="button"
              data-toggle="collapse"
              data-target="#mostsoldarticles"
              aria-expanded="false"
              class="msa-btn collapsed"
              aria-controls="mostsoldarticles"
            >
              Most sold articles
              <img src="../../image/down-sky.png" alt="" />
            </button>

            <div class="msa-content collapse" id="mostsoldarticles">
              <div class="sold-articles-section">
                <div
                  class="sold-articles-box"
                  v-for="(product, index) in selectedProducts"
                  :key="index"
                >
                  <div class="sold-articles-box-title">
                    <h6>{{ product.name }}</h6>
                    <button
                      type="button"
                      data-toggle="collapse"
                      :data-target="'#' + 'product' + product.id"
                      aria-expanded="false"
                      class="collapsed"
                      :aria-controls="'product' + product.id"
                      @click="initMostSoldArticle(product.id)"
                    >
                      <span class="hide-articles">Hide articles</span>
                      <span class="show-articles">Show articles</span>
                      <img src="../../image/right-sky.png" alt="" />
                    </button>
                  </div>
                  <div
                    class="sold-articles-details collapse"
                    :id="'product' + product.id"
                  >
                    <div class="table-responsive-lg">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Article number</th>
                            <th scope="col">Article name</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(articles, index) in mostsoldarticles[
                              product.id
                            ]"
                            :key="index"
                          >
                            <td width="30%">{{ articles.article__number }}</td>
                            <td>{{ articles.article__name }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="prs-bottom">
            <div class="contant-box-main">
              <div class="contant-header">
                <h6>
                  <img
                    src="../../image/recommended-icon.png"
                    alt=""
                  />Recommended
                </h6>
              </div>
              <div class="recommended-section">
                <div class="recommended-section-row">
                  <div
                    class="recommended-section-table"
                    v-for="(conn_articles, index) in connectedArticles.results"
                    :key="conn_articles"
                  >
                    <button
                      type="button"
                      data-toggle="collapse"
                      :data-target="'#recommended' + index"
                      aria-expanded="true"
                      class="collapsed"
                      :aria-controls="'recommended' + index"
                      @click="
                        initArticleBoughtTogather(
                          ObjToIDs(conn_articles.articles)
                        )
                      "
                    >
                      <span class="hide-articles">Hide</span>
                      <span class="show-articles">Show</span>
                      <img src="../../image/right-sky.png" alt="" />
                    </button>
                    <div class="table-responsive-lg">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col" width="10%">Article number</th>
                            <th scope="col" width="45%">Article Name</th>
                            <th scope="col" width="30%">Сategory</th>
                            <th scope="col" width="15%">Article connection</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(article, index) in conn_articles.articles"
                            :key="article.id"
                          >
                            <td>{{ article.number }}</td>
                            <td>{{ article.name }}</td>
                            <td>{{ article.product }}</td>
                            <td
                              :rowspan="conn_articles.articles.length"
                              v-show="index == 0"
                              class="article-connection"
                            >
                              {{ Math.round(conn_articles.correlation) }}%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="recommended-section-details collapse"
                      :id="'recommended' + index"
                    >
                      <h6>Recommended associated articles</h6>
                      <div class="table-responsive-lg">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Article name</th>
                              <th scope="col">Category</th>
                              <th scope="col">Probability to sell</th>
                              <!-- <th scope="col"></th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                articles, index
                              ) in boughtTogatherArticles[
                                ObjToIDs(conn_articles.articles)
                              ]"
                              :key="index"
                            >
                              <td>{{ articles.number }}</td>
                              <td>{{ articles.article_name }}</td>
                              <td>{{ articles.category }}</td>
                              <td>
                                <h6
                                  class="high-article"
                                  v-if="articles.rank == 1"
                                >
                                  High
                                </h6>
                                <h6
                                  class="medium-article"
                                  v-if="articles.rank == 2"
                                >
                                  Medium
                                </h6>
                                <h6
                                  class="low-article"
                                  v-if="articles.rank == 3"
                                >
                                  Low
                                </h6>
                              </td>
                              <!-- <td>
                            <a href="#" class="add-article-btn"
                              ><img src="../../image/plus-sky.png" /> Add
                              article</a
                            >
                          </td> -->
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Pagination
                :page="page"
                :totalPages="totalPages"
                :name="'conected articles'"
                :count="connectedArticles.count ? connectedArticles.count : 0"
                :incrementpage="incrementpage"
                :decrementpage="decrementpage"
                :setpage="setpage"
                :perpage="5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CancelIcon from '../../icons/cancel.vue'
// import RecommendedIcon from "../../icons/recommended.vue";
import Pagination from "../../Core/Pagination";
import { mapGetters, mapActions } from "vuex";
import {
  MOST_SOLD_ARTICLES,
  CONNECTED_ARTICLES,
  BOUGHT_TOGATHER_ARTICLES,
} from "@/Core/store/action-types";
export default {
  name: "Product Recommendations",
  props: ["selectedProducts", "recProducts"],
  data() {
    return {
      mostsoldarticles: {},
      connectedArticles: [],
      page: 1,
      totalPages: [],
      boughtTogatherArticles: {},
    };
  },
  components: {
    // CancelIcon,
    // RecommendedIcon,
    Pagination,
  },
  computed: {
    ...mapGetters("product", ["getProdById"]),
  },
  methods: {
    ...mapActions("article", [
      MOST_SOLD_ARTICLES,
      CONNECTED_ARTICLES,
      BOUGHT_TOGATHER_ARTICLES,
    ]),

    initMostSoldArticle(product_id) {
      if (this.mostsoldarticles[product_id]) {
        return;
      }
      this[MOST_SOLD_ARTICLES](product_id).then((resp) => {
        this.mostsoldarticles[product_id] = resp;
      });
    },
    ObjToIDs(obj) {
      let ids = [];
      obj.map((ob) => {
        ids.push(ob.id);
      });
      return ids;
    },
    initConnectedArticles() {
      if (this.selectedProducts && this.selectedProducts.length > 0) {
        this[CONNECTED_ARTICLES]({
          product_ids: this.ObjToIDs(this.selectedProducts),
          page: this.page,
        }).then((resp) => {
          this.totalPages = Array(Math.ceil(resp.count / 5))
            .fill(0)
            .map((e, i) => i + 1);
          this.connectedArticles = resp;
          if(resp.results?.length==0)this.$alertify.notify(`We don't found any data for the selected products`, "error", 3);
        });
      } else {
        this.$alertify.notify(`Please select atleast 1 product. `, "error", 3);
        this.connectedArticles = [];
      }
    },
    //get bought togather article data
    initArticleBoughtTogather(article_ids) {
      if (this.boughtTogatherArticles[article_ids]) {
        return;
      }
      this[BOUGHT_TOGATHER_ARTICLES](article_ids).then((resp) => {
        this.boughtTogatherArticles[article_ids] = resp;
      });
    },
    incrementpage() {
      this.page = this.page + 1;
      this.initConnectedArticles();
    },
    //go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.initConnectedArticles();
    },
    //go to perticular page
    setpage(page) {
      this.page = page;
      this.initConnectedArticles();
    },
    getRecommendedProducts() {
      this.page = 1;
      this.initConnectedArticles();
    },
  },
  mounted() {
    //    this.initConnectedArticles();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#recommendations .main-articles-sold .badges .badge {
  background: #5e66fa !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin: 4px;
}
</style>
