<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.66602 3H9.66602C10.4024 3 10.9993 3.59696 10.9993 4.33334V12.3333C10.9993 13.0697 10.4024 13.6667 9.66602 13.6667H1.66602C0.929635 13.6667 0.332682 13.0697 0.332682 12.3333V4.33334C0.332682 3.59696 0.929635 3 1.66602 3ZM9.66602 12.3333V4.33334H1.66602V12.3333H9.66602Z"
      fill="#3F3F40"
    />
    <path
      d="M12.3327 9.66667H13.666V1.66667C13.666 0.930291 13.0691 0.333338 12.3327 0.333338H4.33268V1.66667H12.3327V9.66667Z"
      fill="#3F3F40"
    />
  </svg>
</template>

<script>
export default {
  name: "CopyIcon",
};
</script>