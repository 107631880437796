<template>
  <!-- Edit user modal window -->
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="editDialogLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editDialogLabel">Edit {{ name }}</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <UserForm
          v-on:form-change="onFormChange"
          :onSubmit="onSubmit"
          :isEditMode="true"
          :initData="userToEdit"
          :roles="getAvailableRoles('update')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import UserForm from "./UserForm";
import { UserRoleMixin } from "@/Core/mixins/UserRoleMixin";
import { UPDATE_USER } from "@/Core/store/action-types";

export default {
  name: "EditUser",
  mixins: [UserRoleMixin],
  components: {
    UserForm,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
    userToEdit: {
      required: true,
      type: Object,
    },
    name: {
      required: false,
      type: String,
      default: "account",
    },
  },
  computed: {
    ...mapGetters("user", ["userProfile"]),
    ...mapGetters("org", ["orgsList"]),
  },

  methods: {
    ...mapActions("user", [UPDATE_USER]),

    onSubmit(values, actions) {
      let userToUpdate = this.getUser(values.role);
      userToUpdate.email = values.email;
      userToUpdate.id = this.userToEdit.id;
      if (this.isMerchantSelected()) {
        userToUpdate.organization = parseInt(values.organization);
        userToUpdate.billing_address = values.billing_address || null;
        userToUpdate.city = values.city || null;
        userToUpdate.country = values.country || null;
        userToUpdate.postal_id = values.postal_id || null;
        userToUpdate.company_name = values.company_name || null;
        userToUpdate.vat_no = values.vat_no || null;
      }

      if (this.formValues.isPasswordEdited && values.password)
        userToUpdate.password = values.password;

      this[UPDATE_USER]({ userToUpdate, actions })
        .then(() => {
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify("User successfully updated.", "success", 3);
          actions.resetForm();
          this.$parent.initUser();
        })
        .catch(() => {});
    },

    onFormChange(form) {
      this.formValues = form;
    },
  },

  data() {
    return {
      formValues: {},
    };
  },
};
</script>