import { API_URL, authHeader } from "@/Core/helpers/utils";
import axios from "axios";

const RESOURCE_URL = `${API_URL}/api/v1/articles/`;
const RESOURCE_TREND_URL = `${API_URL}/api/v1/trends/article/`;
const RESOURCE_COUNT_URL = `${API_URL}/api/v1/getArticleCount/`;

export default {
  get(token, params) {
    return axios.get(RESOURCE_URL, {
      params,
      headers: authHeader(token),
    });
  },
  create(token, obj) {
    return axios.post(RESOURCE_URL, obj, {
      headers: authHeader(token),
    });
  },
  update(token, obj) {
    let url = `${RESOURCE_URL}${obj.id}/`;
    return axios.patch(url, obj, {
      headers: authHeader(token),
    });
  },
  get_member_articles(token, member_id) {
    return axios.get(`${API_URL}/api/v1/member_article/${member_id}`, {
      headers: authHeader(token),
    });
  },
  get_member_most_likely_articles(token, member_id) {
    return axios.get(`${API_URL}/api/v1/most_likely_to_buy/${member_id}`, {
      headers: authHeader(token),
    });
  },
  get_member_most_likely_products(token, member_id) {
    return axios.get(`${API_URL}/api/v1/most_likely_to_buy_product/${member_id}`, {
      headers: authHeader(token),
    });
  },
  get_org_most_likely_articles(token, org_id) {
    return axios.get(`${API_URL}/api/v1/orgs/most_likely_to_buy_articles/${org_id}/`, {
      headers: authHeader(token),
    });
  },
  get_member_similar_articles(token, member_id) {
    return axios.get(`${API_URL}/api/v1/get_similar_items/${member_id}`, {
      headers: authHeader(token),
    });
  },
  get_member_buy_again_articles(token, member_id, params) {
    return axios.get(`${API_URL}/api/v1/get_buy_again_items/${member_id}`, {
      headers: authHeader(token),
      params: params,
    });
  },
  get_most_sold_articles(token, product_id) {
    return axios.get(`${API_URL}/api/v1/mostSoldArticles/${product_id}`, {
      headers: authHeader(token),
    });
  },
  trend(token, direction, params) {
    return axios.get(`${RESOURCE_TREND_URL}${direction}`, {
      params,
      headers: authHeader(token),
    });
  },
  recommendations(token, article_ids, page) {
    let url = `${RESOURCE_URL}rec/?page=${page}`;
    article_ids.forEach((id) => {
      url += `&article=${id}`;
    });
    return axios.get(url, { headers: authHeader(token) });
  },
  connected(token, product_ids, page,max_articles) {
    let url = `${RESOURCE_URL}connected/?page=${page}&max_articles=${max_articles}`;
    product_ids.forEach((id) => {
      url += `&product=${id}`;
    });
    return axios.get(url, { headers: authHeader(token) });
  },
  bought_togather(token, article_ids) {
    let url = `${RESOURCE_URL}bought_together/?`;
    article_ids.forEach((id) => {
      url += `&article=${id}`;
    });
    return axios.get(url, { headers: authHeader(token) });
  },
  metrics(token) {
    return axios.get(RESOURCE_COUNT_URL, {
      headers: authHeader(token),
    });
  },
  get_top_selling_articles(token, params) {
    let url = `${API_URL}/api/v1/top_selling_articles/`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
  // get_count(token,params){
  //   return axios.get(RESOURCE_COUNT_URL,{params,headers: authHeader(token)});
  // }
};
