<template>
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.946662 12.7193C0.759651 12.719 0.581374 12.6402 0.455329 12.502C0.326959 12.365 0.263172 12.1797 0.279996 11.9927L0.443329 10.1967L7.98866 2.65401L10.3467 5.01134L2.80333 12.5533L1.00733 12.7167C0.986662 12.7187 0.965995 12.7193 0.946662 12.7193ZM10.8173 4.54001L8.45999 2.18268L9.87399 0.768675C9.99904 0.64349 10.1687 0.573151 10.3457 0.573151C10.5226 0.573151 10.6923 0.64349 10.8173 0.768675L12.2313 2.18268C12.3565 2.30772 12.4269 2.4774 12.4269 2.65434C12.4269 2.83128 12.3565 3.00096 12.2313 3.12601L10.818 4.53934L10.8173 4.54001Z"
      fill="#3F3F40"
    />
  </svg>
</template>

<script>
export default {
  name: "PenIcon",
};
</script>

<style scoped>
</style>