import {
  GET_UNLAYER_TEMPLATES,
  GET_SENDGRID_CONTACT_LIST,
  SEND_EMAIL_TO_CUSTOMER,
  GET_SINGLE_SENDS
} from "@/Core/store/action-types";

import {
  SET_UNLAYER_TEMPLATES
} from "@/Core/store/mutation-types";

import api from "./email.api";

const initialState = {};
const state = { ...initialState };

const actions = {
  async [GET_UNLAYER_TEMPLATES]({ rootState }, params) {
    const response = await api.get_unlayer_templates(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [GET_SINGLE_SENDS]({ rootState }, params) {
    const response = await api.get_all_single_send(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [GET_SENDGRID_CONTACT_LIST]({ rootState }, params) {
    const response = await api.GET_SENDGRID_CONTACT_LIST(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
  async [SEND_EMAIL_TO_CUSTOMER]({ rootState }, obj) {
    return await api
      .send_email_with_template_and_contact(rootState.user.userProfile.access, obj)
      .then(function (response) {
        return response.data;
      })
      .catch(function (e) {
        throw new Error(e.response.data.details);
      });
  },
};

const mutations = {
  [SET_UNLAYER_TEMPLATES](state, unlayerTemplateList) {
    state.unlayerTemplateList = unlayerTemplateList;
  },
  // [SET_AUDIENCE_DETAIL](state, audienceDetail) {
  //   state.audienceDetail = audienceDetail;
  // },
  // [ADD_AUDIENCE](state, obj) {
  //   state.audienceList.push(obj);
  // },
  // [EDIT_AUDIENCE](state, obj) {
  //   let idx = state.audienceList.findIndex((u) => u.id == obj.id);

  //   if (idx > -1) {
  //     state.audienceList[idx] = obj;
  //   }
  // },
  // [REMOVE_AUDIENCE](state, objId) {
  //   let idx = state.audienceList.findIndex((u) => u.id == objId);

  //   if (idx > -1) {
  //     state.audienceList.splice(idx, 1);
  //   }
  //   router.push("/Audience");
  // },
};

const getters = {
  unlayerTemplateList(state) {
    return state.unlayerTemplateList;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
