<template>
<svg fill="#5e66fa" width="64px" height="64px" viewBox="0 0 24 24" id="add-file-11" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" class="icon flat-line" transform="rotate(0)">

<g id="SVGRepo_bgCarrier" stroke-width="0"/>

<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192"/>

<g id="SVGRepo_iconCarrier">

<path id="secondary" d="M12.5,19A6.5,6.5,0,0,0,6,12.5V5H8V3H19a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H12.18A6.3,6.3,0,0,0,12.5,19Z" style="fill: #ebf3f4; stroke-width:1px;"/>

<path id="primary" d="M4,19H8M6,21V17m8-4h2m0-4H10" style="fill: none; stroke: #5e66fa; stroke-linecap: round; stroke-linejoin: round; stroke-width:1px;"/>

<path id="primary-2" data-name="primary" d="M12,21h7a1,1,0,0,0,1-1V4a1,1,0,0,0-1-1H8L6,5v8" style="fill: none; stroke: #5e66fa; stroke-linecap: round; stroke-linejoin: round; stroke-width:1px;"/>

<polygon id="primary-3" data-name="primary" points="6 5 8 5 8 3 6 5" style="fill: none; stroke: #5e66fa; stroke-linecap: round; stroke-linejoin: round; stroke-width:1px;"/>

</g>

</svg>
</template>

<script>
export default {
  name: "ShopifyUploadIcon",
};
</script>

<style scoped>
</style>