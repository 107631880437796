<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.2095 0.277303C22.2954 -0.320246 23.625 0.466245 23.625 1.70531V19.6588C23.625 20.9472 22.2009 21.7234 21.1163 21.0411C18.9544 19.681 15.1726 17.3685 13.261 16.5775C11.8664 16.0004 10.7619 15.6326 10.0106 15.4099C9.89033 15.3742 9.77906 15.3423 9.67712 15.3138C9.73324 15.7963 9.83925 16.3681 10.0166 16.9601C10.3108 17.9418 10.7869 18.9319 11.5108 19.6685C11.8757 20.0398 12.1188 20.5892 12.02 21.1815L11.7946 22.5343C11.7174 22.9976 11.4452 23.3973 11.0602 23.6228C10.6633 23.8552 10.1549 23.8931 9.69312 23.6306C7.6929 22.4936 5.56678 19.6727 5.38723 15.125H5C2.44568 15.125 0.375 13.0543 0.375 10.5C0.375 7.94569 2.44568 5.875 5 5.875H8.95147C8.95743 5.87401 8.96407 5.87289 8.9714 5.87163C9.01575 5.86401 9.0851 5.85134 9.1785 5.83204C9.36527 5.79345 9.6482 5.72835 10.0194 5.62419C10.7618 5.4159 11.8577 5.05128 13.2443 4.42968C15.2065 3.55009 19.0689 1.45526 21.2095 0.277303ZM8.375 7.125H5C3.13604 7.125 1.625 8.63604 1.625 10.5C1.625 12.364 3.13604 13.875 5 13.875H8.375V7.125ZM8.4024 15.125H6.63823C6.81559 19.2689 8.74207 21.6521 10.3109 22.5439C10.3413 22.5612 10.3607 22.5629 10.3717 22.5626C10.3846 22.5623 10.404 22.5586 10.4285 22.5442C10.4806 22.5136 10.5428 22.4413 10.5616 22.3288L10.787 20.976C10.8074 20.8536 10.7622 20.6901 10.6192 20.5446C9.70419 19.6135 9.14785 18.4158 8.81921 17.3188C8.57932 16.5182 8.4532 15.7461 8.4024 15.125ZM9.625 7.01426V14.0056C9.81909 14.0554 10.0672 14.1229 10.3659 14.2114C11.1568 14.4459 12.3023 14.828 13.739 15.4225C15.7703 16.2631 19.6525 18.6433 21.782 19.983C22.0401 20.1454 22.375 19.9566 22.375 19.6588V1.70531C22.375 1.41677 22.0646 1.2335 21.8122 1.37243C19.6864 2.54224 15.7745 4.66533 13.7557 5.57032C12.311 6.21793 11.1569 6.60331 10.3571 6.82772C10.0646 6.90979 9.81951 6.97032 9.625 7.01426Z"
      fill="#49494A"
    />
  </svg>
</template>

<script>
export default {
  name: "CampaignIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>