import {
  LIST_SHIPPING_CHARGES,
  GET_SHIPPING_CHARGE_DETAILS,
  CREATE_SHIPPING_CHARGE,
  UPDATE_SHIPPING_CHARGE,
  DELETE_SHIPPING_CHARGE,
  SHIPPING_CHARGES_FILE_UPLOAD
} from "@/Core/store/action-types";
import api from "./shipping.api";

const initialState = {};
const state = { ...initialState };

const actions = {
  async [LIST_SHIPPING_CHARGES]({ rootState }, params) {
    let response = await api.get(rootState.user.userProfile.access, params);
    return response.data;
  },
  async [GET_SHIPPING_CHARGE_DETAILS]({ rootState },objId) {
    const resp = await api.getDetails(
      rootState.user.userProfile.access,
      objId
    );
    return resp.data;
  },

  async [CREATE_SHIPPING_CHARGE]({ rootState }, obj) {
    return await api
      .create(rootState.user.userProfile.access, obj)
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        throw new Error(e);
      });
  },
  async [UPDATE_SHIPPING_CHARGE]({ rootState }, obj) {
    return await api
      .update(rootState.user.userProfile.access, obj)
      .then(function (response) {
        return response.data;
      })
      .catch((e) => {
        throw new Error(e);
      });
  },
  async [DELETE_SHIPPING_CHARGE]({ rootState }, objId) {
    try {
      await api.delete(rootState.user.userProfile.access, objId);
    } catch (e) {
      throw new Error("");
    }
  },
  async [SHIPPING_CHARGES_FILE_UPLOAD]({ rootState }, file) {
     return await api.shipping_charges_file_upload(
      rootState.user.userProfile.access,
      file
    ) .then(function (response) {
      return response.data;
    })
    .catch((e) => {
      throw new Error(e.response.data.detail);
    });
  
  },
};

const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
