<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 11L11 6M11 6L6 1M11 6L1 6"
      stroke="#49494A"
      stroke-width="1.25"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "RightArrow",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>