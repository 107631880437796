import {
  LIST_STORES,
  GET_STORE_BY_MERCHANT_ID,
  CREATE_STORE,
  UPDATE_STORE,
  DELETE_STORE,
  SALES_CHANNEL_FILE_UPLOAD
} from "@/Core/store/action-types";
import api from "./store.api";

const initialState = {};
const state = { ...initialState };

const actions = {
  async [LIST_STORES]({ rootState }, params) {
    let response = await api.get(rootState.user.userProfile.access, params);
    return response.data;
  },
  async [GET_STORE_BY_MERCHANT_ID]({ rootState }) {
    const resp = await api.get_store_by_merchant(
      rootState.user.userProfile.access,
      rootState.user.userProfile.id
    );
    return resp.data;
  },

  async [CREATE_STORE]({ rootState }, obj) {
    return await api
      .create(rootState.user.userProfile.access, obj)
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        throw new Error(e);
      });
  },
  async [UPDATE_STORE]({ rootState }, obj) {
    return await api
      .update(rootState.user.userProfile.access, obj)
      .then(function (response) {
        return response.data;
      })
      .catch((e) => {
        throw new Error(e);
      });
  },
  async [DELETE_STORE]({ rootState }, objId) {
    try {
      await api.delete(rootState.user.userProfile.access, objId);
    } catch (e) {
      throw new Error("");
    }
  },
  async [SALES_CHANNEL_FILE_UPLOAD]({ rootState }, file) {
    const resp = await api.sales_channel_file_upload(
      rootState.user.userProfile.access,
      file
    );
    return resp.data;
  },
};

const mutations = {};
const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
