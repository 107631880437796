import { API_URL, authHeader } from "@/Core/helpers/utils";
import axios from "axios";

const RESOURCE_URL = `${API_URL}/api/v1/products/`;
const RESOURCE_CATEGORIES_URL = `${API_URL}/api/v1/productCategories/`;
const RESOURCE_TREND_URL = `${API_URL}/api/v1/trends/product/`;

export default {
  get(token, params) {
    return axios.get(RESOURCE_URL, {
      params,
      headers: authHeader(token),
    });
  },
  create(token, obj) {
    return axios.post(RESOURCE_URL, obj, {
      headers: authHeader(token),
    });
  },
  update(token, obj) {
    let url = `${RESOURCE_URL}${obj.id}/`;
    return axios.patch(url, obj, {
      headers: authHeader(token),
    });
  },
  delete(token, objId) {
    let url = `${RESOURCE_URL}${objId}/`;
    return axios.delete(url, {
      headers: authHeader(token),
    });
  },
  get_categories(token, params) {
    return axios.get(RESOURCE_CATEGORIES_URL, {
      params,
      headers: authHeader(token),
    });
  },
  trend(token, direction, params) {
    return axios.get(`${RESOURCE_TREND_URL}${direction}`, {
      params,
      headers: authHeader(token),
    });
  },
  connected(token, params) {
    return axios.get(`${RESOURCE_URL}connected/`, {
      params,
      headers: authHeader(token),
    });
  },
  recommendations(token, product_ids) {
    let url = `${RESOURCE_URL}rec/?`;
    product_ids.forEach((id) => {
      url += `&product=${id}`;
    });
    return axios.get(url, { headers: authHeader(token) });
  },
  metrics(token, params) {
    return axios.get(RESOURCE_URL, {
      params,
      headers: authHeader(token),
    });
  },
  get_categoriesvisecount(token, id) {
    let url = `${API_URL}/api/v1/categoriesvisecount/${id}`;
    return axios.get(url, {
      headers: authHeader(token),
    });
  },
  get_top_selling_products(token, params) {
    let url = `${API_URL}/api/v1/top_selling_products/`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
};
