<template>
  <div>
    <RegistrationModal v-bind:modalId="createModalId" name="User" />
    <EditUserModal
      v-if='editedResource'
      v-bind:modalId="editModalId"
      v-bind:userToEdit="editedResource"
      name="User"
    />
    <CustomModel
      v-if="deletedresource"
      :modalId="deleteUserModelID"
      :item="deletedresource"
      :onDelete="deleteUser"
      :resourseName="'User'"
      actionType="Delete"
    />
    <CustomModel
      v-if="deletedresource"
      :modalId="deactivateModelID"
      :item="deletedresource"
      :onDelete="deactivateUser"
      :resourseName="'User'"
      actionType="Deactivate"
    />
    <div>
      <div class="contant-box-main">
        <div class="data-heading-wrp">
          <div class="data-heading">
            <div class="search-box-wrp">
              <input
                type="text"
                class="form-control"
                v-model="search"
                name=""
                placeholder="Search by Email "
              />
              <a class="search_icon" href="#"
                ><img
                  src="../../../public/assets/images/search_icon.svg"
                  alt=""
              /></a>
            </div>
            <div class="tab-box-wrp">
              <ul>
                <li
                  @click="changeStatus('all')"
                  :class="status == 'all' ? 'active' : ''"
                >
                  All
                </li>
                <li
                  @click="changeStatus('active')"
                  :class="status == 'active' ? 'active' : ''"
                >
                  Active
                </li>
                <li
                  @click="changeStatus('inactive')"
                  :class="status == 'inactive' ? 'active' : ''"
                >
                  Inactive
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="col-lg-2 col-md-3 dt-col inc-index">
            <div class="form">
              <select class="for" id="adminfilter" v-model="role">
                <option selected value="all">ALL</option>
                <option
                  :value="role"
                  v-for="role in getAvailableRoles('list')"
                  :key="role"
                >
                  {{ role }}
                </option>
              </select>
            </div>
          </div> -->
          <div
            class="col-lg-2 col-md-3 dt-col inc-index"
            v-if="
              getUserRole(userProfile) == 'Admin' ||
              getUserRole(userProfile) == 'Super Admin'
            "
          >
            <div class="">
              <!-- <label for="adminfilter">Organization</label> -->
              <select
                class="for"
                id="adminfilter"
                v-model="organization"
                v-if="orgsList"
              >
                <option selected value="all">ALL</option>
                <option :value="org.id" v-for="org in orgsList" :key="org.id">
                  {{ org.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="data-heading-btn" @click="showCreateDialog">
            <button>+ create New User</button>
          </div>
        </div>
        <div class="table-wrp overflow-auto position-relative">
          <div class="loader-main" v-if="loadindShow" >
            <div
              class="spinner-border"
              role="status"
              style="width: 36px; height: 36px; color: #5e66fa"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
         </div>
          <table class="table my-table-wrp table-sm" id="my-table">
            <thead>
              <tr>
                <th scope="col" v-for="f in fields" v-bind:key="f.id">
                  <div
                    class="table-head"
                    v-if="f === 'ID'"
                    @click="sortTable('id')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_id ? 'sort-active' : ''">&#8595;</span>
                      <span :class="desc_id ? 'sort-active' : ''">&#8593;</span>
                    </span>
                  </div>
                  <div
                    class="table-head"
                    v-else-if="f === 'Email'"
                    @click="sortTable('email')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_email ? 'sort-active' : ''"
                        >&#8595;</span
                      >
                      <span :class="desc_email ? 'sort-active' : ''"
                        >&#8593;</span
                      >
                    </span>
                  </div>
                  <div
                    class="table-head"
                    v-else-if="f === 'Created by'"
                    @click="sortTable('created')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_created ? 'sort-active' : ''"
                        >&#8595;</span
                      >
                      <span :class="desc_created ? 'sort-active' : ''"
                        >&#8593;</span
                      >
                    </span>
                  </div>
                  <div
                    class="table-head"
                    v-else-if="f === 'Status'"
                    @click="sortTable('status')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_status ? 'sort-active' : ''"
                        >&#8595;</span
                      >
                      <span :class="desc_status ? 'sort-active' : ''"
                        >&#8593;</span
                      >
                    </span>
                  </div>
                  <div
                    class="table-head"
                    v-else-if="f === 'Pending delete'"
                    @click="sortTable('pending_delete')"
                  >
                    {{ f.split("_").join(" ") }}
                    <span>
                      <span :class="asc_delete ? 'sort-active' : ''"
                        >&#8595;</span
                      >
                      <span :class="desc_delete ? 'sort-active' : ''"
                        >&#8593;</span
                      >
                    </span>
                  </div>
                  <div class="table-head" v-else>
                    {{ f.split("_").join(" ") }}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="user?.is_active ? 'active-row' : 'inactive-row'"
                v-for="user in users?.results"
                v-bind:key="user?.id"
              >
                <td width="5%" class="font-light-text">{{ user?.id }}</td>
                <td width="30%">{{ user?.email }}</td>
                <td width="10%" class="font-light-text">
                  {{ getUserRole(user)=='Worker'?'User':getUserRole(user) }}
                </td>
                <td width="20%" class="font-light-text">
                  {{ user?.organization?.name }}
                </td>
                <td width="25%" class="font-light-text">
                  {{ user?.created_by?.email }}
                </td>
                <td class="btn-align" width="7%">
                  <div class="d-flex align-item-center">
                    <button
                      title="Edit User Details"
                      class="action-btns ms-2 icon-effect"
                      @click="editUser(user)"
                    >
                      <PenIcon />
                    </button>
                    <button
                      title="Delete User"
                      class="action-btns ms-2 delete-profile"
                      @click="showDeleteModel(user)"
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            :page="page"
            :totalPages="totalPages"
            :name="'Users'"
            :count="users ? users.count : 0"
            :incrementpage="incrementpage"
            :decrementpage="decrementpage"
            :setpage="setpage"
            :perpage="10"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PenIcon from "../../icons/pen.vue";
import DeleteIcon from "../../icons/cancel";
import {
  LIST_USERS,
  LIST_ORGS,
  DEACTIVATE_USER,
  FULL_DELETE_USER,
} from "@/Core/store/action-types";
import { mapActions, mapGetters } from "vuex";
import { UserRoleMixin, MERCHANT } from "@/Core/mixins/UserRoleMixin";
import Pagination from "../../Core/Pagination";
import RegistrationModal from "./RegistrationModal";
import EditUserModal from "./EditUserModal";
// import FormModal from "./FormModal";
import { subject } from "@casl/ability";
import { RESOURCE_NAME } from "../user.vars";
import CustomModel from "../../Core/CustomModel.vue";
export default {
  name: "offerTable",
  components: {
    Pagination,
    // FormModal,
    CustomModel,
    PenIcon,
    DeleteIcon,
    RegistrationModal,
    EditUserModal,
  },
  mixins: [UserRoleMixin],
  computed: {
    ...mapGetters("org", ["getOrgById", "orgsList"]),
    ...mapGetters("user", { userProfile: "userProfile" }),
  },
  data() {
    return {
      fields: ["ID", "Email", "Role", "Company Name", "Created by", ""],
      status: "all",
      search: "",
      page: 1,
      users: null,
      totalPages: [],
      role: "all",
      createModalId: "createDialog",
      editModalId: "editDialog",
      editedResource: null,
      deleteUserModelID: "deleteuserModel",
      deactivateModelID: "deactivateuserModel",
      deletedresource: {},
      organization: "all",
      asc_email: null,
      desc_email: null,
      asc_id: null,
      desc_id: true,
      asc_created: null,
      desc_created: null,
      asc_status: null,
      desc_status: null,
      asc_delete: null,
      desc_delete: null,
      loadindShow:false,
    };
  },
  methods: {
    ...mapActions("user", [LIST_USERS, DEACTIVATE_USER, FULL_DELETE_USER]),
    ...mapActions("org", [LIST_ORGS]),
    initUser(pageNumber = null) {
      pageNumber ? (this.page = pageNumber) : null;
      let options = {
        persist: false,
        page: this.page,
        is_active:
          this.status == "all" ? null : this.status == "active" ? 1 : 0,
        q: this.search?.length < 3 ? null : this.search,
        merchant: this.role == "Merchant" ? 1 : null,
        worker: this.role == "Worker" ? 1 : null,
        superadmin:
          this.role == "Super Admin" ? 1 : this.role == "Admin" ? 0 : null,
        admin: this.role == "Admin" || this.role == "Super Admin" ? 1 : null,
        organization: this.organization !== "all" ? this.organization : null,
        asc_email: this.asc_email ? this.asc_email : null,
        desc_email: this.desc_email ? this.desc_email : null,
        asc_id: this.asc_id ? this.asc_id : null,
        desc_id: this.desc_id ? this.desc_id : null,
        asc_created: this.asc_created ? this.asc_created : null,
        desc_created: this.desc_created ? this.desc_created : null,
        asc_status: this.asc_status ? this.asc_status : null,
        desc_status: this.desc_status ? this.desc_status : null,
        asc_delete: this.asc_delete ? this.asc_delete : null,
        desc_delete: this.desc_delete ? this.desc_delete : null,
      };
      this.loadindShow = true
      this[LIST_USERS](options)
        .then((res) => {
          this.users = res;
          this.totalPages = Array(Math.ceil(res.count / 10))
            .fill(0)
            .map((e, i) => i + 1);
        })
        .catch((err) => {
          console.log(err);
        }).finally(()=>this.loadindShow=false)
    },
    editUser(user) {
      // this.onUpdateItem(RESOURCE_NAME, window.$(`#${this.editModalId}`), user);
      (this.editedResource = user),
        window.$(`#${this.editModalId}`).modal("toggle");
    },
    showDeleteModel(item) {
      this.deletedresource = item;
      if (this.getUserRole(item) == MERCHANT.name) {
        window.$(`#${this.deactivateModelID}`).modal("toggle");
      } else {
        window.$(`#${this.deleteUserModelID}`).modal("toggle");
      }
    },
    async deleteUser() {
      window.$(`#${this.deleteUserModelID}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "delete",
        subject(RESOURCE_NAME, this.deletedresource)
      );
      if (hasPermission) {
        this[FULL_DELETE_USER](this.deletedresource.id)
          .then(() => {
            this.initUser(1);
            this.$alertify.notify(
              `${this.deletedresource.email} successfully removed.`,
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    async deactivateUser() {
      window.$(`#${this.deactivateModelID}`).modal("hide");
      let hasPermission = await this.$ability.can(
        "deactivate",
        subject(RESOURCE_NAME, this.deletedresource)
      );
      if (hasPermission) {
        this[DEACTIVATE_USER](this.deletedresource.id)
          .then(() => {
            this.initUser(1);
            this.$alertify.notify(
              `${this.deletedresource.email} successfully deactivated.`,
              "success",
              3
            );
          })
          .catch(() => {
            this.$alertify.notify("Action Forbidden", "error", 3);
          });
      } else {
        this.$alertify.notify("Action Forbidden", "error", 3);
      }
    },
    changeStatus(state) {
      this.status = state;
    },
    incrementpage() {
      this.page = this.page + 1;
      this.initUser();
    },
    decrementpage() {
      this.page = this.page - 1;
      this.initUser();
    },
    setpage(page) {
      this.page = page;
      this.initUser();
    },
    showCreateDialog() {
      window.$(`#${this.createModalId}`).modal("toggle");
    },
    sortTable(item) {
      if (item == "id") {
        (this.asc_email = null),
          (this.desc_email = null),
          (this.asc_created = null),
          (this.desc_created = null),
          (this.asc_status = null),
          (this.desc_status = null),
          (this.asc_delete = null),
          (this.desc_delete = null);
        if (this.asc_id) {
          this.asc_id = null;
          this.desc_id = true;
        } else if (this.desc_id) {
          this.desc_id = null;
          this.asc_id = true;
        } else {
          this.asc_id = true;
          this.desc_id = null;
        }
      } else if (item == "email") {
        (this.asc_id = null),
          (this.desc_id = null),
          (this.asc_created = null),
          (this.desc_created = null),
          (this.asc_status = null),
          (this.desc_status = null),
          (this.asc_delete = null),
          (this.desc_delete = null);
        if (!this.asc_email && !this.desc_email) {
          this.asc_email = true;
          this.desc_email = null;
        } else if (this.asc_email) {
          this.asc_email = null;
          this.desc_email = true;
        } else {
          this.asc_email = true;
          this.desc_email = null;
        }
      } else if (item == "created") {
        (this.asc_email = null),
          (this.desc_email = null),
          (this.asc_id = null),
          (this.desc_id = null),
          (this.asc_status = null),
          (this.desc_status = null),
          (this.asc_delete = null),
          (this.desc_delete = null);
        if (!this.asc_created && !this.desc_created) {
          this.asc_created = true;
          this.desc_created = null;
        } else if (this.asc_created) {
          this.asc_created = null;
          this.desc_created = true;
        } else {
          this.asc_created = true;
          this.desc_created = null;
        }
      } else if (item == "status") {
        (this.asc_email = null),
          (this.desc_email = null),
          (this.asc_id = null),
          (this.desc_id = null),
          (this.asc_created = null),
          (this.desc_created = null),
          (this.asc_delete = null),
          (this.desc_delete = null);
        if (!this.asc_status && !this.desc_status) {
          this.asc_status = true;
          this.desc_status = null;
        } else if (this.asc_status) {
          this.asc_status = null;
          this.desc_status = true;
        } else {
          this.asc_status = true;
          this.desc_status = null;
        }
      } else if (item == "pending_delete") {
        (this.asc_email = null),
          (this.desc_email = null),
          (this.asc_id = null),
          (this.desc_id = null),
          (this.asc_created = null),
          (this.desc_created = null),
          (this.asc_status = null),
          (this.desc_status = null);
        if (!this.asc_delete && !this.desc_delete) {
          this.asc_delete = true;
          this.desc_delete = null;
        } else if (this.asc_delete) {
          this.asc_delete = null;
          this.desc_delete = true;
        } else {
          this.asc_delete = true;
          this.desc_delete = null;
        }
      } else {
        return;
      }
      this.initUser(1);
    },
  },
  mounted() {},
  created() {
    this.initUser(), this[LIST_ORGS]();
  },
  watch: {
    status() {
      this.page = 1;
      this.initUser();
    },
    search() {
      if (this.search.length >= 3 || this.search.length == 0) {
        this.page = 1;
        this.initUser();
      }
    },
    role() {
      this.page = 1;
      this.initUser();
    },
    organization() {
      this.page = 1;
      this.initUser();
    },
  },
};
</script>
<style scoped>
.data-heading-wrp {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.data-heading-wrp .data-heading {
  position: relative;
}

.data-heading-wrp .data-heading-btn button {
  background: #5e66fa;
  border-radius: 4px;
  font-style: normal;
  font-size: 15px;
  line-height: 150%;
  border: 0;
  color: #fff;
  font-weight: 400;
  padding: 11px 19px;
}
.search-box-wrp {
  position: relative;
  min-width: 300px;
  display: inline-block;
  margin-right: 20px;
}
.search-box-wrp input {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #aaabad;
}
.search-box-wrp input::placeholder {
  color: #aaabad;
}
.tab-box-wrp {
  position: relative;
  display: inline-block;
}
.tab-box-wrp ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}
.tab-box-wrp ul li {
  background: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #3f3f40;
  list-style-type: none;
  border: 1px solid #e2e2e2;
  padding: 9px 20px;
  cursor: pointer;
}
.tab-box-wrp ul li:first-child {
  border-radius: 4px 0px 0px 4px;
}
.tab-box-wrp ul li:last-child {
  border-radius: 0px 4px 4px 0px;
}
.tab-box-wrp ul li.active {
  background: #5e66fa;
  color: #fff;
  border: 1px solid #5e66fa;
}
.table-wrp table tr:hover {
  background: #f6f6f7;
}
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
.sort-active {
  font-weight: bold;
  color: #000000;
  font-size: 12px;
}
#icon-date {
  background: url("../../../public/assets/images/calendar.png") no-repeat scroll
    left center / 15px auto;
  background-position: 95%;
}
.res-date-field {
  top: -16px;
}

select#adminfilter {
  border-radius: 3px;
  padding: 10px 122px 10px 8px;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  font-weight: 300;
}

select#adminfilter:hover {
  border: 1px solid #000;
}
</style>