<template>
  <div id="recommendations">
    <h2>Article recommendations</h2>
    <div class="main-articles-sold">
      <div class="product-recommendations-section">
        <div class="prs-top">
          <div class="prs-bottom">
            <div class="contant-box-main">
              <div class="contant-header">
                <h6>
                  <img
                    src="../../image/recommended-icon.png"
                    alt=""
                  />Recommended
                </h6>
              </div>
              <div class="recommended-section">
                <div class="recommended-section-row">
                  <div
                    class="recommended-section-table"
                    v-for="(recArticle, index) in recommendedArticles.results"
                    :key="recArticle"
                  >
                    <button
                      type="button"
                      data-toggle="collapse"
                      :data-target="'#recommended' + index"
                      aria-expanded="true"
                      class="collapsed"
                      :aria-controls="'recommended' + index"
                      @click="initArticleBoughtTogather([recArticle.id])"
                    >
                      <span class="hide-articles">Hide</span>
                      <span class="show-articles">Show</span>
                      <img src="../../image/right-sky.png" alt="" />
                    </button>
                    <div class="table-responsive-lg">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col" width="10%">Article ID</th>
                            <th scope="col" width="10%">Product ID</th>
                            <th scope="col" width="45%">Article Name</th>
                            <th scope="col" width="10%">No. of<br>Likely / Buy Again<br>customers</th>
                            <th scope="col" width="15%">Article connection</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{{ recArticle.number }}</td>
                            <td>{{ recArticle.product }}</td>
                            <td>{{ recArticle.name }}</td>
                            <td>{{ recArticle.likely_customers_num }} / {{ recArticle.buy_again_customers_num }} </td>
                            <td class="article-connection">
                              {{ Math.round(recArticle.percent) }}%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="recommended-section-details collapse"
                      :id="'recommended' + index"
                    >
                      <h6>Recommended associated articles</h6>
                      <div class="table-responsive-lg">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Article name</th>
                              <th scope="col">Category</th>
                              <th scope="col">No. of<br>Likely / Buy Again<br>customers</th>
                              <th scope="col">Probability to sell</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                articles, index
                              ) in boughtTogatherArticles[[recArticle.id]]"
                              :key="index"
                            >
                              <td>{{ articles.number }}</td>
                              <td>{{ articles.article_name }}</td>
                              <td>{{ articles.category }}</td>
                              <td>{{ articles.likely_customers_num }} / {{ articles.buy_again_customers_num }}</td>
                              <td>
                                <h6
                                  class="high-article"
                                  v-if="articles.rank == 1"
                                >
                                  High
                                </h6>
                                <h6
                                  class="medium-article"
                                  v-if="articles.rank == 2"
                                >
                                  Medium
                                </h6>
                                <h6
                                  class="low-article"
                                  v-if="articles.rank == 3"
                                >
                                  Low
                                </h6>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Pagination
                :page="page"
                :totalPages="totalPages"
                :name="' recommended articles'"
                :count="
                  recommendedArticles.count ? recommendedArticles.count : 0
                "
                :incrementpage="incrementpage"
                :decrementpage="decrementpage"
                :setpage="setpage"
                :perpage="5"
                v-if="recommendedArticles.count"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import CancelIcon from '../../icons/cancel.vue'
// import RecommendedIcon from '../../icons/recommended.vue'
import Pagination from "../../Core/Pagination";
import { mapActions } from "vuex";
import {
  RECOMMENDED_ARTICLES,
  BOUGHT_TOGATHER_ARTICLES,
} from "@/Core/store/action-types";
export default {
  name: "Recommendations",
  props: ["selectedArticles", "recArticles"],
  data() {
    return {
      recommendedArticles: [],
      page: 1,
      totalPages: [],
      boughtTogatherArticles: {},
    };
  },
  components: {
    // CancelIcon,
    // RecommendedIcon,
    Pagination,
  },
  methods: {
    ...mapActions("article", [RECOMMENDED_ARTICLES, BOUGHT_TOGATHER_ARTICLES]),
    ObjToIDs(obj) {
      let ids = [];
      obj.map((ob) => {
        ids.push(ob.id);
      });
      return ids;
    },
    initRecommendedArticles() {
      if (this.selectedArticles && this.selectedArticles.length > 0) {
        this[RECOMMENDED_ARTICLES]({
          article_ids: this.ObjToIDs(this.selectedArticles),
          page: this.page,
        }).then((resp) => {
          this.totalPages = Array(Math.ceil(resp.count / 5))
            .fill(0)
            .map((e, i) => i + 1);
          this.recommendedArticles = resp;
          if(resp.results?.length==0)this.$alertify.notify(`We don't found any data for the selected articles`, "error", 3);
        });
      } else {
        this.$alertify.notify(`Please select atleast 1 Article. `, "error", 3);
        this.recommendedArticles = [];
      }
    },
    //get bought togather article data
    initArticleBoughtTogather(article_ids) {
      if (this.boughtTogatherArticles[article_ids]) {
        return;
      }
      this[BOUGHT_TOGATHER_ARTICLES](article_ids).then((resp) => {
        this.boughtTogatherArticles[article_ids] = resp;
      });
    },
    incrementpage() {
      this.page = this.page + 1;
      this.initRecommendedArticles();
    },
    //go to previous page
    decrementpage() {
      this.page = this.page - 1;
      this.initRecommendedArticles();
    },
    //go to perticular page
    setpage(page) {
      this.page = page;
      this.initRecommendedArticles();
    },
    getRecommendedArticles() {
      this.page = 1;
      this.initRecommendedArticles();
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
