<template>
  <div class="main-contant-box">
    <div class="main-contant-box-inner" v-if="campaignDetails">
      <div class="contant-box">
        <div class="contant-box-main">
          <div class="contant-header">
            <h6>
              <!-- src="./../../../public/assets/images/pr-icon.png" -->
              <img
                src="./../../../public/assets/images/campaigns-icon.png"
                alt=""
              />Edit campaign details
            </h6>
          </div>
          <div class="contant-details">
            <form class="profile-form">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group" :class="{ 'has-error': errors.name }">
                    <label class="form-label w-100"
                      >Name<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      v-model="formValues.name"
                      @keyup="validateName"
                      @blur="validateName"
                    />
                    <div class="form-control-feedback">{{ errors.name }}</div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label class="form-label w-100">status</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      :value="status"
                      disabled
                    />

                    <!-- <span class="error">{{ errMsg.email }}</span> -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="form-group"
                    :class="{ 'has-error': errors.offer }"
                  >
                    <label for="offer"
                      >Offer<span class="required-field text-danger"
                        >*</span
                      ></label
                    >
                    <Field v-model="formValues.offer" name="offer">
                      <Multiselect
                        :id="modalId + '-offer'"
                        v-if="offersList"
                        valueProp="id"
                        label="name"
                        trackBy="name"
                        v-model="formValues.offer"
                        :options="offersList"
                        placeholder="please select an offer"
                        @close="validateOffer"
                      />
                    </Field>
                    <div class="form-control-feedback">{{ errors.offer }}</div>
                    <!-- <span class="error">{{ errMsg.email }}</span> -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="form-group"
                    :class="{ 'has-error': errors.audience }"
                  >
                    <label for="audience"
                      >Audience</label
                    >
                    <Field v-model="formValues.audience" name="audience">
                      <Multiselect
                        :id="modalId + '-audience'"
                        valueProp="id"
                        label="name"
                        trackBy="name"
                        v-model="formValues.audience"
                        :options="audienceGroupList"
                        placeholder="please select an audience"
                      />
                    </Field>
                    <div class="form-control-feedback">
                      {{ errors.audience }}
                    </div>
                    <!-- <span class="error">{{ errMsg.email }}</span> -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="form-group"
                  >
                    <label for="audience"
                      >Facebook ads campaign</label
                    >
                    <Field v-model="formValues.facebook_campaign" name="facebook_campaign">
                      <Multiselect
                        :id="modalId + '-facebook_campaign'"
                        valueProp="id"
                        label="name"
                        trackBy="name"
                        :searchable="true"
                        v-model="formValues.facebook_campaign"
                        :options="facebook_campaigns_list"
                        placeholder="select an facebook campaign"
                      />
                    </Field>
                    <div class="form-control-feedback">
                      {{ errors.facebook_campaign }}
                    </div>
                    <!-- <span class="error">{{ errMsg.email }}</span> -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="form-group"
                  >
                    <label for="audience"
                      >Google ads campaign</label
                    >
                    <Field v-model="formValues.google_campaign_data" name="google_campaign">
                      <Multiselect
                        :id="modalId + '-google_campaign'"
                        label="campaign_name"
                        valueProp="campaign_id"
                        trackBy="campaign_name"
                        :searchable="true"
                        :object = "true"
                        v-model="formValues.google_campaign_data"
                        :options="google_campaigns_list"
                        placeholder="select an google campaign"
                      />
                    </Field>
                    <div class="form-control-feedback">
                      {{ errors.facebook_campaign }}
                    </div>
                    <!-- <span class="error">{{ errMsg.email }}</span> -->
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group" :class="{ 'has-error':  errors.offline_budget }">
                    <label class="form-label w-100"
                      >Offline Marketing Budget</label
                    >
                    <div class="d-flex align-items-center">
                    <input
                      type="number"
                       step="0.01"
                      class="form-control "
                      name="offline_budget"
                      placeholder="Enter Offline Marketing Budget"
                      v-model="formValues.offline_budget"
                    />
                    <span class="position-absolute currency-title">{{default_currency}}</span>
                    </div>
                    <div class="form-control-feedback">{{ errors.offline_budget }}</div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div
                    class="form-group"
                  >
                    <label for="audience"
                      >Reference Group</label
                    >
                    <Field v-model="formValues.reference_group" name="reference_group">
                      <Multiselect
                        :id="modalId + '-reference_group'"
                        valueProp="id"
                        label="name"
                        trackBy="name"
                        v-model="formValues.reference_group"
                        :options="referenceGroupList"
                        placeholder="please select an reference group"
                      />
                    </Field>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-btns">
                    <button type="button" class="cancle-btn" @click="onCancel">
                      Cancel
                    </button>
                    <button
                      type="submit"
                      class="save-btn"
                      @click.prevent="OnCampaignUpdate"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Field } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import {
  UPDATE_CAMPAIGN,
  LIST_OFFERS,
  LIST_AUDIENCE,
  CAMPAIGN_DETAILS,
  GET_FACEBOOK_CAMPAIGNS_LIST,
  GET_GOOGLE_CAMPAIGNS_LIST,
  GET_ORG
} from "@/Core/store/action-types";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "EditCampaignForm",
  components: {
    Field,
    Multiselect,
  },
  data() {
    return {
      formValues: {
        id: this?.$route?.params?.campaignId,
        name: "",
        offer: null,
        audience: null,
        facebook_campaign:null,
        offline_budget:null,
        reference_group:null,
      },
      status: "",
      errors: {
        name: null,
        offer: null,
        audience: null,
        offline_budget:null
      },
      facebook_campaigns_list:[],
      google_campaigns_list:[]
    };
  },
  computed: {
    ...mapGetters("offer", ["offersList"]),
    ...mapGetters("org",["org","default_currency"]),
    ...mapGetters("user", ["userProfile"]),
    ...mapGetters("audience", ["audienceList"]),
    ...mapGetters("campaign", ["campaignDetails"]),
    audienceGroupList(){
      console.log(this.audienceList.filter((audience) =>!audience.is_reference_group))
      return this.audienceList.filter((audience) =>!audience.is_reference_group)
    },
    referenceGroupList(){
      return this.audienceList.filter((audience) =>audience.is_reference_group)
    }
  },
  methods: {
    ...mapActions("org", [GET_ORG]),
    ...mapActions("offer", [LIST_OFFERS]),
    ...mapActions("audience", [LIST_AUDIENCE]),
    ...mapActions("campaign", [CAMPAIGN_DETAILS, UPDATE_CAMPAIGN,GET_FACEBOOK_CAMPAIGNS_LIST,GET_GOOGLE_CAMPAIGNS_LIST]),
    InItCampaignDetails(){
      if (
        this?.$route?.params?.campaignId &&
        this.$route.params.campaignId != ""
      ) {
        let payload={
          objId:this?.$route?.params?.campaignId,
          params:{
            currency:this.default_currency
          }
        }
        this[CAMPAIGN_DETAILS](payload).then((res) => {
          this.formValues.name = res.name;
          this.formValues.offer = res.offer.id;
          this.formValues.audience = res.audience.id;
          this.formValues.reference_group = res.reference_group.id;
          this.formValues.facebook_campaign=res.facebook_campaign;
          this.formValues.google_campaign_data=res.google_campaign_data
          this.formValues.offline_budget=res.offline_budget?.toFixed(2) || 0
          if (res.is_active === true) this.status = "Active";
          else if (res.is_active === false && res.is_archive === false)
            this.status = "Pending";
          else this.status = "Expired";
        });
      }
    },
    initFacebookCampaigns(){
      if(this?.org?.is_facebook_ads_connected){
        this[GET_FACEBOOK_CAMPAIGNS_LIST]().then((response)=>{
          this.facebook_campaigns_list=response
          console.log("in----")
        }).catch((e)=>{
          console.log("rrrr=>",e.response)
          this.$alertify.notify(
                e?.response?.data?.detail || "Some error occured with Facebook Ads connection, please try again",
                "error",
                3
              );
        })
      }
    },
    initGoogleCampaigns(){
      if(this?.org?.is_google_ads_connected)
        this[GET_GOOGLE_CAMPAIGNS_LIST]().then((response)=>{
          this.google_campaigns_list=response
        }).catch((e)=>{
          this.$alertify.notify(
                e?.response?.data?.detail || "Some error occured with Google Ads connection, please try again",
                "error",
                3
              );
        })
    },
    validateName() {
      this.errors.name = null;
      if (!this.formValues.name) {
        this.errors.name = "Please enter campaign name";
      } else if (this.formValues.name.trim().length < 6) {
        this.errors.name = "Name must be at least 6 characters";
      } else null;
    },
    validateOffer() {
      this.errors.offer = null;
      if (!this.formValues.offer) this.errors.offer = "Please enter offer";
    },
    validateAudience() {
      this.errors.audience = null;
      // if (!this.formValues.audience)
      //   this.errors.audience = "Please enter audience";
    },
    OnCampaignUpdate() {
      this.validateName();
      this.validateOffer();
      this.validateAudience();
      if (!this.errors.name && !this.errors.audience && !this.errors.offer) {
        this.formValues.offline_budget_currency=this.default_currency
        this[UPDATE_CAMPAIGN](this.formValues).then(() => {
          this.$alertify.notify("Campaign successfully updated.", "success", 3);
          this.$router.push({
            name: "CampaignDetails",
          });
        });
      }
    },
    onCancel() {
      this.$router.push({
        name: "CampaignDetails",
      });
    },
  },
  async mounted() {
    this.offersList ? null : this[LIST_OFFERS]({ persist: true });
    this.audienceList ? null : this[LIST_AUDIENCE](null);
    this.InItCampaignDetails();
    this.initGoogleCampaigns()
    if(!this.org){
      await this[GET_ORG](this.userProfile?.organization.id)
      this.initFacebookCampaigns()
    } 
    else this.initFacebookCampaigns()
  },
};
</script>