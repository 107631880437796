<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>
    <TopBar
      title="Article Recommendations"
      :author="{
        home: selectedProducts,
        cart: 'Cart',
      }"
    />
    <MainMenu />
    <Recommendations />
  </div>
</template>
<script>
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";
import Recommendations from "./components/Recommendations";
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";
import { mapGetters } from "vuex";
export default {
  name: "CartRecommendation",
  components: {
    TopBar,
    MainMenu,
    Recommendations,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      productNames: "",
      fullPage: true,
    };
  },
  computed: {
    ...mapGetters("product", ["selectedProducts", "getProdById"]),
  },
  created() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>