<template>
  <div>
    <div class="vld-parent">
      <loading v-model:active="isLoading" :is-full-page="fullPage" />
    </div>

    <MainMenu />
    <TopBar
      title="Campaigns"
      :author="{
        home: offerDetails ? offerDetails : '',
        cart: 'Campaigns',
      }"
    />
    <OfferDetailsGrid />
  </div>
</template>
<script>
import MainMenu from "../Menu/MainMenu.vue";
import TopBar from "../Menu/TopBar.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import OfferDetailsGrid from "./components/OfferDetailsGrid.vue";
import { mapGetters } from "vuex";

export default {
  name: "EditCampaign",
  components: {
    MainMenu,
    TopBar,
    Loading,
    OfferDetailsGrid,
  },
  computed: {
    ...mapGetters("offer", ["offerDetails"]),
  },
  data() {
    return {
      fullPage: true,
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>