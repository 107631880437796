import { API_URL, authHeader } from "@/Core/helpers/utils";
import axios from "axios";

const RESOURCE_URL = `${API_URL}/api/v1/offers/`;

export default {
  get(token, params) {
    return axios.get(RESOURCE_URL, {
      params,
      headers: authHeader(token),
    });
  },
  getDetails(token, objId) {
    let url = `${RESOURCE_URL}${objId}/`;
    return axios.get(url, {
      headers: authHeader(token),
    });
  },
  update(token, obj) {
    console.log("DEfewf", obj);
    let url = `${RESOURCE_URL}${obj.id}/`;
    return axios.patch(url, obj, {
      headers: authHeader(token),
    });
  },
  create(token, obj) {
    return axios.post(RESOURCE_URL, obj, {
      headers: authHeader(token),
    });
  },
  delete(token, objId) {
    let url = `${RESOURCE_URL}${objId}/`;
    return axios.delete(url, {
      headers: authHeader(token),
    });
  },
  metrics(token, params) {
    return axios.get(RESOURCE_URL, {
      params,
      headers: authHeader(token),
    });
  },
  get_top_performing_offers(token, params) {
    let url = `${API_URL}/api/v1/top_performing_offers/`;
    return axios.get(url, {
      params,
      headers: authHeader(token),
    });
  },
};
