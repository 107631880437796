<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 13.25H2C1.58579 13.25 1.25 13.5858 1.25 14V22C1.25 22.4142 1.58579 22.75 2 22.75H4C4.41421 22.75 4.75 22.4142 4.75 22V14C4.75 13.5858 4.41421 13.25 4 13.25ZM2 12C0.895431 12 0 12.8954 0 14V22C0 23.1046 0.89543 24 2 24H4C5.10457 24 6 23.1046 6 22V14C6 12.8954 5.10457 12 4 12H2Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 1.25H11C10.5858 1.25 10.25 1.58579 10.25 2V22C10.25 22.4142 10.5858 22.75 11 22.75H13C13.4142 22.75 13.75 22.4142 13.75 22V2C13.75 1.58579 13.4142 1.25 13 1.25ZM11 0C9.89543 0 9 0.89543 9 2V22C9 23.1046 9.89543 24 11 24H13C14.1046 24 15 23.1046 15 22V2C15 0.895431 14.1046 0 13 0H11Z"
      fill="#49494A"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 9.25H20C19.5858 9.25 19.25 9.58579 19.25 10V22C19.25 22.4142 19.5858 22.75 20 22.75H22C22.4142 22.75 22.75 22.4142 22.75 22V10C22.75 9.58579 22.4142 9.25 22 9.25ZM20 8C18.8954 8 18 8.89543 18 10V22C18 23.1046 18.8954 24 20 24H22C23.1046 24 24 23.1046 24 22V10C24 8.89543 23.1046 8 22 8H20Z"
      fill="#49494A"
    />
  </svg>
</template>

<script>
export default {
  name: "AnalyticsIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>