<template>
  <svg
    width="42"
    height="39"
    viewBox="0 0 42 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.7513 8.5C4.7513 11.4915 7.17643 13.9167 10.168 13.9167C13.1595 13.9167 15.5846 11.4915 15.5846 8.5C15.5846 5.50846 13.1595 3.08333 10.168 3.08333C7.17643 3.08333 4.7513 5.50846 4.7513 8.5ZM10.168 1C6.02583 1 2.66797 4.35786 2.66797 8.5C2.66797 12.6421 6.02583 16 10.168 16C14.3101 16 17.668 12.6421 17.668 8.5C17.668 4.35786 14.3101 1 10.168 1Z"
      fill="#49494A"
      stroke="white"
      stroke-width="0.25"
    />
    <path
      d="M10.1667 19.7503C6.25465 19.7503 3.08333 22.9216 3.08333 26.8337V31.0003C3.08333 31.6907 3.64298 32.2503 4.33333 32.2503H9.93012L11.8333 34.3337H4.33333C2.49238 34.3337 1 32.8413 1 31.0003V26.8337C1 21.771 5.10406 17.667 10.1667 17.667C13.0616 17.667 15.6432 19.009 17.3231 21.1048H14.3333C13.1641 20.2529 11.7241 19.7503 10.1667 19.7503Z"
      fill="#49494A"
      stroke="white"
      stroke-width="0.25"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.2507 8.5C37.2507 11.4915 34.8255 13.9167 31.834 13.9167C28.8424 13.9167 26.4173 11.4915 26.4173 8.5C26.4173 5.50846 28.8424 3.08333 31.834 3.08333C34.8255 3.08333 37.2507 5.50846 37.2507 8.5ZM31.834 1C35.9761 1 39.334 4.35786 39.334 8.5C39.334 12.6421 35.9761 16 31.834 16C27.6918 16 24.334 12.6421 24.334 8.5C24.334 4.35786 27.6918 1 31.834 1Z"
      fill="#49494A"
      stroke="white"
      stroke-width="0.25"
    />
    <path
      d="M31.8333 19.7503C35.7454 19.7503 38.9167 22.9216 38.9167 26.8337V31.0003C38.9167 31.6907 38.357 32.2503 37.6667 32.2503H32.0699L30.1667 34.3337H37.6667C39.5076 34.3337 41 32.8413 41 31.0003V26.8337C41 21.771 36.8959 17.667 31.8333 17.667C28.9384 17.667 26.3568 19.009 24.6769 21.1048H27.6667C28.8359 20.2529 30.2759 19.7503 31.8333 19.7503Z"
      fill="#49494A"
      stroke="white"
      stroke-width="0.25"
    />
    <path
      d="M21 37.6663L11 27.816M21 37.6663L17.6851 27.816M21 37.6663L31 27.816M21 37.6663L24.3149 27.816M11 27.816H17.6851M11 27.816L14.0939 24.333H20.8895M17.6851 27.816L20.8895 24.333M17.6851 27.816H24.3149M31 27.816H24.3149M31 27.816L27.9061 24.333H20.8895M24.3149 27.816L20.8895 24.333"
      stroke="#5E66FA"
      stroke-width="1.75"
    />
  </svg>
</template>

<script>
export default {
  name: "ClvIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>