<template>
  <Form
    @submit="onSubmit"
    :validation-schema="schema"
    v-slot="{ errors }"
    class="form-horizontal"
    action="#"
  >
    <div class="modal-body">
      <div class="form-group" :class="{ 'has-error': errors.email }">
        <label for="email"
          >Email<span class="required-field text-danger">*</span></label
        >
        <Field
          as="input"
          type="email"
          class="form-control"
          name="email"
          placeholder="Enter Email"
          v-model="formValues.email"
        />
        <div class="form-control-feedback">{{ errors.email }}</div>
      </div>
      <!--end form-group-->
      <div
        class="form-group"
        :class="{ 'has-error': errors.company_name }"
        v-if="isMerchantSelected() && !existingUser"
      >
        <label for="email"
          >Company Name<span class="required-field text-danger">*</span></label
        >
        <Field
          as="input"
          type="text"
          class="form-control"
          name="company_name"
          placeholder="Enter Company  Name"
          v-model="formValues.company_name"
        />
        <div class="form-control-feedback">{{ errors.company_name }}</div>
      </div>

      <div
        class="form-group"
        :class="{ 'has-error': errors.role }"
        v-show="false"
      >
        <label for="role">Role</label>
        <Field
          as="select"
          class="form-control"
          name="role"
          v-model="formValues.role"
        >
          <option v-for="role in roles" v-bind:key="role" :value="role">
            {{ role }}
          </option>
        </Field>
        <div class="form-control-feedback">{{ errors.role }}</div>
      </div>
      <!--end form-group-->
      <div
        class="form-group"
        :class="{ 'has-error': errors.billing_address }"
        v-if="isMerchantSelected() && !existingUser"
      >
        <label for="email"
          >Billing Address<span class="required-field text-danger"
            >*</span
          ></label
        >
        <Field
          as="input"
          type="text"
          class="form-control"
          name="billing_address"
          placeholder="Enter Billing Address"
          v-model="formValues.billing_address"
        />
        <div class="form-control-feedback">{{ errors.billing_address }}</div>
      </div>
      <div
        class="form-group"
        :class="{ 'has-error': errors.postal_id }"
        v-if="isMerchantSelected() && !existingUser"
      >
        <label for="email"
          >Postal Code<span class="required-field text-danger">*</span></label
        >
        <Field
          as="input"
          type="text"
          class="form-control"
          name="postal_id"
          placeholder="Enter Postal Code"
          v-model="formValues.postal_id"
        />
        <div class="form-control-feedback">{{ errors.postal_id }}</div>
      </div>
      <div
        class="form-group"
        :class="{ 'has-error': errors.city }"
        v-if="isMerchantSelected() && !existingUser"
      >
        <label for="email"
          >City<span class="required-field text-danger">*</span></label
        >
        <Field
          as="input"
          type="text"
          class="form-control"
          name="city"
          placeholder="Enter City"
          v-model="formValues.city"
        />
        <div class="form-control-feedback">{{ errors.city }}</div>
      </div>
      <div
        class="form-group"
        :class="{ 'has-error': errors.country }"
        v-if="isMerchantSelected() && !existingUser"
      >
        <label for="email"
          >Country<span class="required-field text-danger">*</span></label
        >
        <Field
          as="input"
          type="text"
          class="form-control"
          name="country"
          placeholder="Enter Country"
          v-model="formValues.country"
        />
        <div class="form-control-feedback">{{ errors.country }}</div>
      </div>
      <div
        class="form-group"
        :class="{ 'has-error': errors.vat_no }"
        v-if="isMerchantSelected() && !existingUser"
      >
        <label for="email">Vat No.</label>
        <Field
          as="input"
          type="text"
          class="form-control"
          name="vat_no"
          placeholder="Enter Vat No."
          v-model="formValues.vat_no"
        />
        <div class="form-control-feedback">{{ errors.vat_no }}</div>
      </div>
      <div
        class="form-group"
        :class="{ 'has-error': errors.phone }"
        v-if="isMerchantSelected()"
      >
        <label for="email">Phone No.</label>
        <Field
          as="input"
          type="text"
          class="form-control"
          name="phone"
          placeholder="Enter Phone No"
          v-model="formValues.phone"
        />
        <div class="form-control-feedback">{{ errors.phone }}</div>
      </div>
      <div
        class="form-group"
        :class="{ 'has-error': errors.organization }"
        v-if="existingUser"
      >
        <label for="organization"
          >Organization<span class="required-field text-danger">*</span></label
        >
        <!-- <Field as="select" class="form-control" name="organization" v-model="formValues.organization">
            <option v-for="org in orgsList" v-bind:key="org.id" v-bind:value="org.id">
                {{org.name}}
            </option>
            <Multiselect
              :id="'-org'"
              v-if="orgsList"
              valueProp="id"
              label="name"
              trackBy="name"
              v-model="formValues.organization"
              :options="orgsList"
              placeholder="please select an organization"
            />
            </Field> -->
        <Field v-model="formValues.organization" name="organization">
          <Multiselect
            :id="'orgs'"
            valueProp="id"
            label="name"
            trackBy="name"
            v-model="formValues.organization"
            :options="orgsList"
            :searchable="true"
            placeholder="please select an organization"
          />
        </Field>

        <div class="form-control-feedback">{{ errors.organization }}</div>
      </div>
      <!-- {{orgsList}} -->
      <div class="form-group form-check" v-if="isEditMode">
        <input
          type="checkbox"
          class="form-check-input"
          v-model="formValues.isPasswordEdited"
        />
        <label for="checkbox" class="form-check-label">Edit password</label>
      </div>
      <!--end form-group-->

      <div
        class="form-group"
        :class="{ 'has-error': errors.password }"
        v-if="formValues.isPasswordEdited"
      >
        <label for="password"
          >Password<span class="required-field text-danger">*</span></label
        >
        <Field
          as="input"
          type="password"
          class="form-control"
          name="password"
          placeholder="Enter password"
          autocomplete="off"
        />
        <div class="form-control-feedback">{{ errors.password }}</div>
      </div>
      <!--end form-group-->
      <div
        class="form-group"
        :class="{ 'has-error': errors.confirmPassword }"
        v-if="formValues.isPasswordEdited"
      >
        <label for="confirmPassword"
          >Confirm Password<span class="required-field text-danger"
            >*</span
          ></label
        >
        <Field
          as="input"
          type="password"
          class="form-control"
          name="confirmPassword"
          placeholder="Enter Confirm Password"
          autocomplete="off"
        />
        <div class="form-control-feedback">{{ errors.confirmPassword }}</div>
      </div>
      <!--end form-group-->

      <div class="form-group" :class="{ 'has-error': errors.detail }">
        <Field as="input" type="hidden" class="form-control" name="detail" />
        <div class="form-control-feedback">{{ errors.detail }}</div>
      </div>
      <!--end form-group-->
    </div>
    <div class="modal-footer">
      <button type="submit" class="save-btn">
        {{ isEditMode ? "Save" : "Create" }}
      </button>
      <button type="button" class="cancle-btn" data-bs-dismiss="modal">
        Cancel
      </button>
    </div> </Form
  ><!--end form-->
</template>

<script>
import { Field, Form } from "vee-validate";
import { mapGetters } from "vuex";
import * as yup from "yup";
import { PHONE_REGEX } from "@/Core/helpers/utils";
import Multiselect from "@vueform/multiselect";
import { UserRoleMixin, MERCHANT } from "@/Core/mixins/UserRoleMixin";

export default {
  name: "UserForm",
  mixins: [UserRoleMixin],
  components: {
    Form,
    Field,
    Multiselect,
  },
  props: {
    isEditMode: {
      required: false,
      default: false,
    },
    onSubmit: {
      required: true,
    },
    roles: {
      required: true,
      type: Array,
    },
    initData: {
      required: false,
    },
    existingUser: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  emits: ["form-change"],
  computed: {
    ...mapGetters("org", ["orgsList"]),
  },

  watch: {
    formValues: {
      handler: function (newForm) {
        this.$emit("form-change", newForm);
      },
      deep: true,
    },
    initData: function (newUser) {
      this.formValues = {
        email: newUser.email,
        role: this.getUserRole(newUser),
        organization: newUser.organization,
      };
    },
  },

  data() {
    return {
      formValues: {
        isPasswordEdited: this.isEditMode ? false : true,
        role: this.roles ? this.roles[0] : null,
      },
      schema: yup.object().shape({
        email: this.emailValidator(),
        phone: yup.lazy(() => {
          if (this.formValues.role == MERCHANT.name && this.formValues.phone) {
            return yup
              .string()
              .matches(PHONE_REGEX, "Phone number is not valid");
          }
          return yup.string().notRequired();
        }),
        organization: this.orgValidator(),
        company_name: yup.lazy(() => {
          if (this.formValues.role == MERCHANT.name && !this.existingUser) {
            return yup
              .string()
              .min(3, "company name must be at least 3 characters")
              .required("company name is required");
          }
          return yup.string().notRequired();
        }),
        billing_address: yup.lazy(() => {
          if (this.formValues.role == MERCHANT.name && !this.existingUser) {
            return yup.string().required("billing address is required");
          }
          return yup.string().notRequired();
        }),
        country: yup.lazy(() => {
          if (this.formValues.role == MERCHANT.name && !this.existingUser) {
            return yup.string().required("country is required");
          }
          return yup.string().notRequired();
        }),
        city: yup.lazy(() => {
          if (this.formValues.role == MERCHANT.name && !this.existingUser) {
            return yup.string().required("city is required");
          }
          return yup.string().notRequired();
        }),
        postal_id: yup.lazy(() => {
          if (this.formValues.role == MERCHANT.name && !this.existingUser) {
            return yup.string().length(5).matches(/^[0-9]{5}/,"Postal code is not valid");
          }
          return yup.number().notRequired();
        }),
        role: this.roleValidator(),
        password: yup.lazy(() => {
          if (this.formValues.isPasswordEdited) {
            return yup
              .string()
              .min(6, "Password must be at least 6 characters")
              .required("Password is required");
          }
          return yup.string().notRequired();
        }),
        confirmPassword: yup.lazy(() => {
          if (this.formValues.isPasswordEdited) {
            return yup
              .string()
              .oneOf([yup.ref("password"), null], "Passwords must match")
              .required("Confirm Password is required");
          }
          return yup.string().notRequired();
        }),
        detail: yup
          .string() // use it for backend errors
          .nullable()
          .notRequired(),
      }),
    };
  },
};
</script>
