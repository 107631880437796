<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="modalId"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="modalId">{{ header_text }}</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form
          @submit="onSubmit"
          :validation-schema="schema"
          v-slot="{ errors }"
          class="form-horizontal"
          action="#"
        >
          <div class="modal-body">
            <span
              as="button"
              class="d-flex justify-content-end file-upload-title"
              v-if="!isFileUploadMode"
              @click="isFileUploadMode = true"
              ><i class="bi bi-file-earmark-arrow-up-fill"></i>Import From
              File</span
            >
            <span
              as="button"
              class="d-flex justify-content-end file-upload-title mb-2"
              v-if="isFileUploadMode"
              @click="isFileUploadMode = false"
              ><i class="bi bi-file-text"></i>Add manually</span
            >
            <div v-if="!isFileUploadMode">
              <div class="form-group" :class="{ 'has-error': errors.first_name }">
                <label for="first_name">First Name</label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="first_name"
                  placeholder="Enter First Name"
                  v-model="formValues.first_name"
                />
                <div class="form-control-feedback">{{ errors.first_name }}</div>
              </div>

              <div class="form-group" :class="{ 'has-error': errors.last_name }">
                <label for="last_name">Last Name</label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="last_name"
                  placeholder="Enter Last Name"
                  v-model="formValues.last_name"
                />
                <div class="form-control-feedback">{{ errors.last_name }}</div>
              </div>

              <div class="form-group" :class="{ 'has-error': errors.email }">
                <label for="email">Email</label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="email"
                  placeholder="Enter Email"
                  v-model="formValues.email"
                />
                <div class="form-control-feedback">{{ errors.email }}</div>
              </div>
              <div
                class="form-group"
                :class="{ 'has-error': errors.birth_date }"
              >
                <label for="date">Birth Date</label>
                <input
                  type="text"
                  ref="date"
                  placeholder="Enter Birth date"
                  class="form-control"
                />
                <Field
                  as="input"
                  type="hidden"
                  class="form-control"
                  name="birth_date"
                  v-model="formValues.birth_date"
                />
                <div class="form-control-feedback">{{ errors.birth_date }}</div>
              </div>

              <div class="form-group">
                <Field
                  as="input"
                  type="hidden"
                  class="form-control"
                  name="birth_date"
                  v-model="formValues.birth_date"
                />
              </div>
              <div class="form-group" :class="{ 'has-error': errors.phone }">
                <label for="name">Phone</label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="phone"
                  placeholder="Enter Phone"
                  v-model="formValues.phone"
                />
                <div class="form-control-feedback">{{ errors.phone }}</div>
              </div>
              <div class="form-group" :class="{ 'has-error': errors.address }">
                <label for="email">Address </label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="address"
                  placeholder="Enter Address"
                  v-model="formValues.address"
                />
                <div class="form-control-feedback">{{ errors.address }}</div>
              </div>
              <div
                class="form-group"
                :class="{ 'has-error': errors.postal_id }"
              >
                <label for="email">Postal Code</label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="postal_id"
                  placeholder="Enter Postal Code"
                  v-model="formValues.postal_id"
                />
                <div class="form-control-feedback">{{ errors.postal_id }}</div>
              </div>
              <div class="form-group" :class="{ 'has-error': errors.city }">
                <label for="email">City</label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="city"
                  placeholder="Enter City"
                  v-model="formValues.city"
                />
                <div class="form-control-feedback">{{ errors.city }}</div>
              </div>
              <div class="form-group" :class="{ 'has-error': errors.country }">
                <label for="email">Country</label>
                <Field
                  as="input"
                  type="text"
                  class="form-control"
                  name="country"
                  placeholder="Enter Country"
                  v-model="formValues.country"
                />
                <div class="form-control-feedback">{{ errors.country }}</div>
              </div>
              <div class="form-group" :class="{ 'has-error': errors.sex }">
                <label for="name">Sex</label>
                <!-- <Field as="select" class="form-control" name="sex" v-model="formValues.sex"  >
              <option value="" disabled selected>Select sex</option>
              <option v-for="sex in sexChoices" v-bind:key="sex" v-bind:value="sex">
                {{sex}}
              </option>
            </Field> -->
                <Field v-model="formValues.sex" name="sex">
                  <Multiselect
                    v-model="formValues.sex"
                    :options="sexChoices"
                    placeholder="please select a sex"
                  />
                </Field>
                <div class="form-control-feedback">{{ errors.sex }}</div>
              </div>

              <!-- <div class="form-group" :class="{ 'has-error': errors.external_id }">
            <label for="name">External ID</label>
            <Field as="input" type="text" class="form-control" name="external_id" placeholder="Enter External ID" v-model="formValues.external_id"/>
            <div class="form-control-feedback">{{errors.external_id}}</div>
          </div> -->

              <!-- <div class="form-group form-check" >
        <input
          type="checkbox"
          class="form-check-input"
          v-model="formValues.allow_marketing"
        />

        
        <label for="checkbox" class="form-check-label">Allows Marketing Campaigns</label>
      </div> -->
              <div class="model-checkbox justify-content-start">
                <Field
                  v-model="formValues.allow_marketing"
                  name="allow_marketing"
                >
                  <label class="checkbox-container"
                    >Allows Marketing Campaigns
                    <input
                      type="checkbox"
                      name="allow_marketing"
                      v-model="formValues.allow_marketing"
                    />
                    <span class="checkmark"></span>
                  </label>
                </Field>
              </div>
            </div>
             <div v-else>
                <div class="alert alert-success" role="alert">
            You can send customers by upload them in MS Excel format
            (.xlxs). This will update the existed customers, and add new customers.
            Template for the Excel structure:
            <a href="/assets/files/customer_final.xlsx" download
              ><ul>
                click here
              </ul></a
            >
          </div>
          <div>
            <div class="d-flex">
              <input
                type="file"
                id="file-upload-customers"
                class="form-control d-none"
                @change="oncustomersFileChange"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <label for="file-upload-customers" class="save-btn" role="button">
                Select File</label
              >
              <span class="p-2">{{ customersFile?.name }}</span>
            </div>
            <div class="form-control-feedback">
              {{ customersFileErrMSg }}
            </div>
            <!-- <div>
                    <a href="../../../public/assets/files/sample.xlsx" download>sample file</a>
                  </div> -->
          </div>
          </div>
            <div class="form-group" :class="{ 'has-error': errors.detail }">
              <Field
                as="input"
                type="hidden"
                class="form-control"
                name="detail"
              />
              <div class="form-control-feedback">{{ errors.detail }}</div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="save-btn"
              v-if="isFileUploadMode"
              @click="HandleShippingFileUpload"
            >       
              <div class="spinner-border text-light" role="status" style="width: 14px; height: 14px;" v-if="isLoading">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-else>Add File</span>
          </button>
            <button type="submit" v-else class="save-btn">{{ btn_name }}</button>
            <button
              type="button"
              class="cancle-btn"
              data-bs-dismiss="modal"
              v-on:click="clearForm()"
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import { PHONE_REGEX } from "@/Core/helpers/utils";
import { mapGetters, mapActions } from "vuex";
import Multiselect from "@vueform/multiselect";
import { UPDATE_CUSTOMER, CREATE_CUSTOMER,CUSTOMER_FILE_UPLOAD} from "@/Core/store/action-types";
import ServerErrorHandler from "@/Core/helpers/ServerErrorHandler";
import { DATE_FORMAT } from "@/Core/helpers/utils";
import { RESOURCE_NAME } from "../customer.vars";

export default {
  name: "CustomerModal",
  components: {
    Form,
    Field,
    Multiselect,
  },
  props: {
    modalId: {
      required: true,
      type: String,
    },
    memberToEdit: {
      required: false,
      type: Object,
      default: function () {
        return undefined;
      },
    },
    modalType: {
      required: true,
      type: Number,
    },
    initcustomers: {
      required: false,
      type: Function,
    },
  },
  computed: {
    ...mapGetters("user", ["userProfile"]),
  },
  watch: {
    memberToEdit: function (obj) {
      this.originalResource = obj;
      this.formValues = this.initForm();
    },
  },
  methods: {
    ...mapActions("customer", [UPDATE_CUSTOMER, CREATE_CUSTOMER,CUSTOMER_FILE_UPLOAD]),

    onSubmit(values, actions) {
      if (this.modalType == 1) this.updateCustomer(values, actions);
      if (this.modalType == 2) this.createCustomer(values, actions);
    },

    oncustomersFileChange(e) {
      this.shippingChargesFile = e.target.files[0];
    },
   HandleCustomerFileUpload(){
      if (this.customersFile) {
        this.isLoading=true
        this[CUSTOMER_FILE_UPLOAD](this.customersFile)
          .then(() => {
            // this[UPDATE_ORG_UPLOAD_DATA]()
            // this[GET_ORG](this.userProfile.organization.id)
            this.$alertify.notify(
              "Customers Uploaded successfully",
              "success",
              3
            );
            // this.articleFile = null;
            window.$(`#${this.modalId}`).modal("hide");
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some error, please check your file",
              "error",
              3
            );
          })
          .finally(()=>{
            this.isLoading=false
          })
      } else {
        this.customersFileErrMSg = "Please select file";
      }
    },

    updateCustomer(obj, actions) {
      obj.id = this.memberToEdit.id;
      this[UPDATE_CUSTOMER](obj)
        .then(() => {
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify(
            `${RESOURCE_NAME} successfully updated.`,
            "success",
            3
          );
          this.clearForm(actions);
        })
        .catch(function (error) {
          ServerErrorHandler(actions, error.response);
          throw new Error("");
        });
    },

    createCustomer(obj, actions) {
      console.log("Dewdwe", obj);
      this[CREATE_CUSTOMER](obj)
        .then(() => {
          window.$(`#${this.modalId}`).modal("hide");
          this.$alertify.notify(
            `${RESOURCE_NAME} successfully created.`,
            "success",
            3
          );
          this.clearForm(actions);
          if (this.initcustomers) {
            this.initcustomers();
          }
        })
        .catch(function (error) {
          ServerErrorHandler(actions, error.response);
          throw new Error("");
        });
    },

    clearForm(actions) {
      this.formValues = this.initForm();
      if (actions) actions.resetForm();
    },

    initForm() {
      if (this.originalResource) {
        this.setDatepicker(this.originalResource.birth_date);
        return Object.assign({}, this.originalResource);
      }
      return {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        sex: null,
        allow_marketing: true,
        // external_id: '',
        birth_date: null,
      };
    },

    onDateChange(start) {
      this.formValues.birth_date = start.format(DATE_FORMAT);
      this.setDatepicker(this.formValues.birth_date);
    },

    setDatepicker(date) {
      this.datepicker.val(date);
    },
  },

  mounted() {
    this.datepicker = window.$(this.$refs.date);
    this.datepicker.daterangepicker(
      {
        singleDatePicker: true,
        showDropdowns: true,
        autoUpdateInput: false,
      },
      this.onDateChange
    );
  },

  beforeCreate() {
    if (this.modalType == 1) {
      // Edit mode
      this.btn_name = "Edit";
      this.header_text = `Edit ${RESOURCE_NAME}`;
    } else if (this.modalType == 2) {
      // Create mode
      this.btn_name = "Create";
      this.header_text = `Create new ${RESOURCE_NAME}`;
    }
  },

  data() {
    var sexChoices = ["Female", "Male", "Corporate"];
    return {
      resourceName: RESOURCE_NAME,
      sexChoices: sexChoices,
      customersFile: null,
      customersFileErrMSg: "",
      isFileUploadMode: false,
      isLoading: false,
      formValues: this.initForm(),
      schema: yup.object().shape({
        first_name: yup.string().nullable().notRequired(),
        last_name: yup.string().nullable().notRequired(),
        // .min(6, 'Name must be at least 6 characters'),
        email: yup.string().email("Email is invalid").notRequired(),
        phone: yup.lazy(() => {
          if (this.formValues.phone) {
            return yup
              .string()
              .matches(PHONE_REGEX, "Phone number is not valid");
          }
          return yup.string().notRequired();
        }),
        address: yup.string().nullable(),
        country: yup.string().nullable(),
        city: yup.string().nullable(),
        postal_id: yup.lazy(() => {
          if (this.formValues.postal_id) {
            return yup
              .string()
              .length(5)
              .matches(/^[0-9]{5}/, "Postal code is not valid");
          }
          return yup.string().notRequired();
        }),

        // .matches(PHONE_REGEX, 'Phone number is not valid'),
        // external_id: yup.string()
        //   .min(6, 'External ID must be at least 6 characters')
        //   .required(`Please enter ${RESOURCE_NAME.toLowerCase()} External ID`),
        birth_date: yup
          .string()
          // .matches(DATE_REGEX, 'Please select valid date')
          .nullable(),
        sex: yup.string().nullable().notRequired(),
        allow_marketing: yup.boolean(),
        detail: yup
          .string() // use it for backend errors
          .nullable()
          .notRequired(),
      }),
    };
  },
};
</script>
