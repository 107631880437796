import {
  LIST_OFFERS,
  CREATE_OFFER,
  UPDATE_OFFER,
  DELETE_OFFER,
  OFFER_DETAILS,
  GET_TOP_PERFORMING_OFFERS,
} from "@/Core/store/action-types";

import {
  SET_OFFERS_LIST,
  ADD_OFFER,
  EDIT_OFFER,
  REMOVE_OFFER,
  SET_OFFER_DETAILS,
} from "@/Core/store/mutation-types";

import api from "./offer.api";

const initialState = {};
const state = { ...initialState };

const actions = {
  async [LIST_OFFERS]({ commit, rootState }, params) {
    const response = await api.get(rootState.user.userProfile.access, params);
    if (params !== undefined && params.persist === true)
      commit(SET_OFFERS_LIST, response.data);
    return response.data;
  },

  async [CREATE_OFFER]({ commit, rootState }, { obj }) {
    const response = await api.create(rootState.user.userProfile.access, obj);
    commit(ADD_OFFER, response.data);
    return response.data;
  },
  async [OFFER_DETAILS]({ rootState, commit }, objId) {
    try {
      const response = await api.getDetails(
        rootState.user.userProfile.access,
        objId
      );
      commit(SET_OFFER_DETAILS, response.data);
      return response.data;
    } catch (e) {
      throw new Error("");
    }
  },
  async [UPDATE_OFFER]({ commit, rootState }, { obj }) {
    const response = await api.update(rootState.user.userProfile.access, obj);
    commit(EDIT_OFFER, response.data);
    return response.data;
  },

  async [DELETE_OFFER]({ commit, rootState }, objId) {
    try {
      await api.delete(rootState.user.userProfile.access, objId);
      commit(REMOVE_OFFER, objId);
    } catch (e) {
      throw new Error("");
    }
  },
  async [GET_TOP_PERFORMING_OFFERS]({ rootState }, params) {
    const response = await api.get_top_performing_offers(
      rootState.user.userProfile.access,
      params
    );
    return response.data;
  },
};

const mutations = {
  [SET_OFFERS_LIST](state, offersList) {
    state.offersList = offersList;
  },
  [ADD_OFFER](state, offer) {
    state.offersList ? state.offersList.push(offer) : "";
  },
  [EDIT_OFFER](state, offer) {
    if (state.offersList) {
      let idx = state.offersList.findIndex((u) => u.id == offer.id);

      if (idx > -1) {
        state.offersList[idx] = offer;
      }
    }
  },
  [REMOVE_OFFER](state, offerId) {
    if (state.offersList) {
      let idx = state.offersList.findIndex((u) => u.id == offerId);

      if (idx > -1) {
        state.offersList.splice(idx, 1);
      }
    }
  },
  [SET_OFFER_DETAILS](state, obj) {
    state.offerDetails = obj;
  },
};
const getters = {
  offersList(state) {
    return state.offersList;
  },
  getOfferById(id) {
    return state.offersList
      ? state.offersList.find((offer) => offer.id === id)
      : [];
  },
  offerDetails(state) {
    return state.offerDetails;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
