<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="46px"
    height="46px"
    viewBox="0 0 46 46"
    version="1.1"
  >
    <g id="surface1">
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(28.627451%, 28.627451%, 29.019608%);
          fill-opacity: 1;
        "
        d="M 37.800781 11.546875 C 37.0625 11.546875 36.324219 11.828125 35.765625 12.386719 L 34.242188 13.910156 C 33.960938 14.191406 33.960938 14.644531 34.242188 14.925781 L 38.304688 18.992188 C 38.585938 19.273438 39.042969 19.273438 39.324219 18.992188 L 40.847656 17.46875 C 41.964844 16.34375 41.964844 14.527344 40.847656 13.402344 L 39.832031 12.386719 C 39.292969 11.847656 38.5625 11.546875 37.800781 11.546875 Z M 37.800781 12.972656 C 38.164062 12.972656 38.53125 13.117188 38.816406 13.402344 L 39.832031 14.417969 C 40.105469 14.6875 40.261719 15.054688 40.261719 15.4375 C 40.261719 15.820312 40.105469 16.183594 39.832031 16.453125 L 38.816406 17.46875 L 35.765625 14.417969 L 36.78125 13.402344 C 37.050781 13.128906 37.417969 12.972656 37.800781 12.972656 Z M 37.800781 12.972656 "
      />
      <path
        style="
          stroke: none;
          fill-rule: evenodd;
          fill: rgb(28.627451%, 28.627451%, 29.019608%);
          fill-opacity: 1;
        "
        d="M 26.582031 2.875 C 26.191406 2.878906 25.875 3.199219 25.875 3.59375 L 25.875 9.390625 C 25.875 9.789062 26.195312 10.109375 26.59375 10.109375 L 32.390625 10.109375 C 32.683594 10.109375 32.945312 9.933594 33.054688 9.667969 C 33.167969 9.398438 33.105469 9.089844 32.898438 8.882812 L 27.101562 3.085938 C 26.964844 2.945312 26.777344 2.871094 26.582031 2.875 Z M 27.3125 5.328125 L 30.65625 8.671875 L 27.3125 8.671875 Z M 27.3125 5.328125 "
      />
      <path
        style="
          stroke: none;
          fill-rule: evenodd;
          fill: rgb(36.862745%, 40%, 98.039216%);
          fill-opacity: 1;
        "
        d="M 8.625 17.296875 L 8.625 18.734375 L 27.3125 18.734375 L 27.3125 17.296875 Z M 8.625 12.984375 L 8.625 14.421875 L 24.4375 14.421875 L 24.4375 12.984375 Z M 8.625 8.671875 L 8.625 10.109375 L 21.5625 10.109375 L 21.5625 8.671875 Z M 8.625 8.671875 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(28.627451%, 28.627451%, 29.019608%);
          fill-opacity: 1;
        "
        d="M 5.03125 2.921875 C 4.632812 2.921875 4.3125 3.242188 4.3125 3.640625 L 4.3125 39.5625 C 4.3125 39.957031 4.632812 40.28125 5.03125 40.28125 L 11.207031 40.28125 C 11.605469 40.28125 11.925781 39.957031 11.925781 39.5625 C 11.925781 39.164062 11.605469 38.84375 11.207031 38.84375 L 5.75 38.84375 L 5.75 4.359375 L 23.71875 4.359375 C 24.117188 4.359375 24.4375 4.039062 24.4375 3.640625 C 24.4375 3.246094 24.117188 2.921875 23.71875 2.921875 Z M 32.332031 11.582031 C 31.933594 11.585938 31.617188 11.914062 31.625 12.3125 L 31.625 16.746094 C 31.625 17.144531 31.945312 17.464844 32.34375 17.464844 C 32.742188 17.464844 33.0625 17.144531 33.0625 16.746094 L 33.0625 12.3125 C 33.066406 12.117188 32.988281 11.929688 32.851562 11.789062 C 32.714844 11.652344 32.527344 11.578125 32.332031 11.582031 Z M 32.332031 24.246094 C 31.933594 24.25 31.617188 24.578125 31.625 24.976562 L 31.625 38.84375 L 18.285156 38.84375 C 17.886719 38.84375 17.566406 39.164062 17.566406 39.5625 C 17.566406 39.957031 17.886719 40.28125 18.285156 40.28125 L 32.34375 40.28125 C 32.742188 40.28125 33.0625 39.957031 33.0625 39.5625 L 33.0625 24.976562 C 33.066406 24.78125 32.988281 24.59375 32.851562 24.457031 C 32.714844 24.316406 32.527344 24.242188 32.332031 24.246094 Z M 32.332031 24.246094 "
      />
      <path
        style="
          stroke: none;
          fill-rule: evenodd;
          fill: rgb(36.862745%, 40%, 98.039216%);
          fill-opacity: 1;
        "
        d="M 36.78125 15.4375 L 14.417969 37.792969 L 15.4375 38.816406 L 37.800781 16.453125 Z M 36.78125 15.4375 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(28.627451%, 28.627451%, 29.019608%);
          fill-opacity: 1;
        "
        d="M 12.378906 36.0625 C 12.074219 36.070312 11.804688 36.265625 11.707031 36.554688 L 9.671875 42.652344 C 9.585938 42.910156 9.652344 43.195312 9.847656 43.386719 C 10.039062 43.582031 10.324219 43.648438 10.582031 43.5625 L 16.679688 41.527344 C 16.917969 41.449219 17.09375 41.253906 17.152344 41.011719 C 17.210938 40.769531 17.136719 40.515625 16.964844 40.339844 L 12.894531 36.269531 C 12.757812 36.132812 12.570312 36.058594 12.378906 36.0625 Z M 12.703125 38.109375 L 15.125 40.53125 L 11.492188 41.742188 Z M 12.703125 38.109375 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(36.862745%, 40%, 98.039216%);
          fill-opacity: 1;
        "
        d="M 34.742188 13.699219 C 34.554688 13.703125 34.375 13.777344 34.242188 13.910156 L 11.878906 36.269531 C 11.597656 36.550781 11.597656 37.007812 11.878906 37.289062 L 15.949219 41.355469 C 16.226562 41.636719 16.683594 41.636719 16.964844 41.355469 L 39.324219 18.992188 C 39.601562 18.710938 39.601562 18.257812 39.324219 17.976562 L 35.257812 13.910156 C 35.121094 13.773438 34.933594 13.699219 34.742188 13.699219 Z M 34.75 15.4375 L 37.800781 18.484375 L 16.453125 39.832031 L 13.402344 36.777344 Z M 34.75 15.4375 "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "FormManuallyIcon",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>