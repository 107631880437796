<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="42px"
    height="42px"
    viewBox="0 0 42 42"
    version="1.1"
  >
    <g id="surface1">
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(95.294118%, 90.588235%, 91.764706%);
          fill-opacity: 1;
        "
        d="M 37.78125 27.582031 L 13.269531 27.582031 L 8.453125 8.589844 L 42 8.589844 Z M 37.78125 27.582031 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(36.862745%, 40%, 98.039216%);
          fill-opacity: 1;
        "
        d="M 37.792969 34.382812 L 14.5625 34.382812 C 13.867188 34.382812 13.257812 33.910156 13.085938 33.234375 L 6.089844 5.21875 L 1.515625 5.21875 C 0.683594 5.21875 0 4.535156 0 3.703125 C 0 2.859375 0.683594 2.183594 1.515625 2.183594 L 7.269531 2.183594 C 7.96875 2.183594 8.578125 2.65625 8.746094 3.332031 L 15.75 31.34375 L 37.792969 31.34375 C 38.632812 31.34375 39.308594 32.023438 39.308594 32.859375 C 39.3125 33.261719 39.152344 33.648438 38.867188 33.933594 C 38.582031 34.222656 38.195312 34.382812 37.792969 34.382812 Z M 37.792969 34.382812 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(36.862745%, 40%, 98.039216%);
          fill-opacity: 1;
        "
        d="M 29.847656 13.464844 C 32.398438 16.015625 32.398438 20.15625 29.847656 22.707031 C 27.292969 25.261719 23.15625 25.261719 20.601562 22.707031 C 18.050781 20.15625 18.050781 16.015625 20.601562 13.464844 C 23.15625 10.910156 27.292969 10.910156 29.847656 13.464844 Z M 29.847656 13.464844 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(98.431373%, 96.470588%, 96.470588%);
          fill-opacity: 1;
        "
        d="M 27.4375 15.71875 L 24.273438 18.878906 L 23.25 17.855469 C 22.949219 17.554688 22.457031 17.554688 22.15625 17.855469 C 21.851562 18.160156 21.851562 18.652344 22.15625 18.960938 L 23.5 20.304688 C 23.847656 20.652344 24.40625 20.652344 24.753906 20.304688 L 28.390625 16.667969 C 28.652344 16.40625 28.652344 15.984375 28.390625 15.722656 C 28.121094 15.460938 27.699219 15.460938 27.4375 15.71875 Z M 27.4375 15.71875 "
      />
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(36.862745%, 40%, 98.039216%);
          fill-opacity: 1;
        "
        d="M 22.101562 37.375 C 22.101562 38.726562 21.007812 39.820312 19.65625 39.820312 C 18.304688 39.820312 17.207031 38.726562 17.207031 37.375 C 17.207031 36.023438 18.304688 34.925781 19.65625 34.925781 C 21.007812 34.925781 22.101562 36.023438 22.101562 37.375 Z M 35.792969 37.375 C 35.792969 38.726562 34.695312 39.820312 33.34375 39.820312 C 31.992188 39.820312 30.894531 38.726562 30.894531 37.375 C 30.894531 36.023438 31.992188 34.925781 33.34375 34.925781 C 34.695312 34.925781 35.792969 36.023438 35.792969 37.375 Z M 35.792969 37.375 "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "OrderUploadIcon",
};
</script>

<style scoped>
</style>