<template>
  <div class="dashboard-top-section">
    <div class="row">
      <!-- {{facebookCampaignData}} 
      {{getROI()}} -->
      <div class="col-lg-8">
        <div class="dts-row dts-row-half">
          <div class="dts-box-outer w-100 campaign-kpi-section">
            <div class="dts-box d-flex justify-content-around">
              <div class="campaign-kpi-status">
                <span
                  :class="
                    'campaign-light ' +
                    (campaignDetails?.trafic_light_color?.net_profit_margin ||
                      'red')
                  "
                ></span>
                <h6>Net profit margin</h6>
              </div>
              <div class="campaign-kpi-status">
                <span
                  :class="
                    'campaign-light ' +
                    (campaignDetails?.trafic_light_color?.gross_profit_margin ||
                      'red')
                  "
                ></span>
                <h6>Gross profit margin</h6>
              </div>
              <div class="campaign-kpi-status">
                <span
                  :class="
                    'campaign-light ' +
                    (campaignDetails?.trafic_light_color?.roi || 'red')
                  "
                ></span>
                <h6>Return on investment</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="dts-row dts-row-half">
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="net_profit_tooltip"
                  id="net_profit_tooltip"
                ></i>
              </em>
              <h6>Total Net Profit</h6>
              <div>
                <span class="black-text mr-2">{{ default_currency }} </span>
                <span class="green-text mt-3">{{
                  (analyticsData?.total_net_profit || 0)?.toFixed(0)
                }}</span>
              </div>
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="net_profit_margin_tooltip"
                  id="gross_profit_margin_tooltip"
                ></i
              ></em>
              <h6>Net Sales Profit Margin</h6>
              <span class="mt-3"
                >{{
                  (analyticsData?.net_profit_margin || 0)?.toFixed(0)
                }}%</span
              >
              <!-- <span class="green-text">({{analyticsData?.offline_sales || 0}})</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="analytics-chart-box">
          <span class="box-title">Audience Group</span>
          <div class="d-flex mb-3 gap-2">
            <div class="analytics-chart-wrap">
              <DoughnutChart
                :chartData="testData"
                :options="options"
                class="canvas-outer"
              />
              <div class="analytics-chart-numbers">
                <div class="analytics-chart-numbers-inner">
                  <h4>{{ analyticsData?.success_deals_percent || 0 }}%</h4>
                  <p>Success ratio</p>
                </div>
              </div>
            </div>
           
            <div>
              <ul class="ul-table">
                <li>
                  <div class="detail-title">
                    <span style="background-color: #0bc984"></span>
                    <p>Total Customers Participated :</p>
                  </div>
                  <span class="percentage-data">{{
                    analyticsData?.total_members_purchased || 0
                  }}</span>
                </li>
                <li>
                  <div class="detail-title">
                    <span style="background-color: #f0f1f2"></span>
                    <p>Total Customers Offered :</p>
                  </div>
                  <span class="percentage-data">{{
                    analyticsData?.total_members || 0
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
           <span class="box-title">Reference Group</span>
          <div class="d-flex mb-3 gap-2" v-if="analyticsData?.total_reference_group_member != null">
            <div class="analytics-chart-wrap">
              <DoughnutChart
                :chartData="referenceGroupData"
                :options="options"
                class="canvas-outer"
              />
              <div class="analytics-chart-numbers">
                <div class="analytics-chart-numbers-inner">
                  <h4>{{ analyticsData?.reference_group_success_deals_percent || 0 }}%</h4>
                  <p>Success ratio</p>
                </div>
              </div>
            </div>
            <div>
              <ul class="ul-table">
                <li>
                  <div class="detail-title">
                    <span style="background-color: #0bc984"></span>
                    <p>Total Customers Participated :</p>
                  </div>
                  <span class="percentage-data">{{
                    analyticsData?.total_reference_group_member_purchased || 0
                  }}</span>
                </li>
                <li>
                  <div class="detail-title">
                    <span style="background-color: #f0f1f2"></span>
                    <p>Total Customers Offered :</p>
                  </div>
                  <span class="percentage-data">{{
                    analyticsData?.total_reference_group_member || 0
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div v-else class="no-group-title">
            <span>No reference group connected</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="dts-row dts-row-three">
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="revenue_tooltip"
                  id="revenue_tooltip"
                ></i>
              </em>
              <h6>Total campaign revenue</h6>
              <div>
                <span class="black-text mr-2">{{ default_currency }} </span>
                <span class="green-text mt-3">{{
                  analyticsData?.total_orders_value?.toFixed(0) || 0
                }}</span>
              </div>
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="gross_profit_margin_tooltip"
                  id="gross_profit_margin_tooltip"
                ></i
              ></em>
              <h6>Gross Sales Profit Margin</h6>
              <span class="mt-3"
                >{{ (analyticsData?.gross_profit_margin || 0)?.toFixed(0) }}%
              </span>
              <!-- <span class="green-text">({{analyticsData?.offline_sales || 0}})</span> -->
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="roi_tooltip"
                  id="roi-tooltip"
                ></i>
              </em>
              <h6>ROI</h6>
              <span class="mt-3"
                >{{ (this.analyticsData?.roi || 0).toFixed(0) }}%</span
              >
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="avg_purchase_tooltip"
                  id="avg_purchase_tooltip"
                ></i>
              </em>
              <h6>Average purchase value</h6>
              <div>
                <span class="black-text mr-2">{{ default_currency }} </span>
                <span class="mt-3">{{
                  analyticsData?.avg_order_value?.toFixed(0) || 0
                }}</span>
              </div>
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="cart_comparison_tooltip"
                  id="cart_comparison_tooltip"
                ></i
              ></em>
              <h6>Campaign Cart Size Vs Average Cart Size</h6>
              <span class="mt-3"
                >{{
                  (
                    (analyticsData?.avg_order_value * 100) / avg_order_value ||
                    0
                  ).toFixed(0) || 0
                }}%</span
              >
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <h6>Number of purchases</h6>
              <span class="mt-3">{{ analyticsData?.total_orders || 0 }}</span>
              <!-- <p><strong>+25%</strong> offers used</p> -->
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="offline_net_profit_tooltip"
                  id="offline_net_profit_tooltip"
                ></i
              ></em>
              <h6>Offline Sales Channel Profit</h6>
              <div>
                <span class="black-text mr-2">{{ default_currency }} </span>
                <span class="mt-3">{{
                  (analyticsData?.offline_net_profit || 0)?.toFixed(0)
                }}</span>
              </div>
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="online_sales_channel_profit_tooltip"
                  id="online_sales_channel_profit_tooltip"
                ></i>
              </em>
              <h6>Online Sales Channel Profit</h6>
              <div>
                <span class="black-text mr-2">{{ default_currency }} </span>
                <span class="mt-3">{{
                  (analyticsData?.online_net_profit || 0)?.toFixed(0)
                }}</span>
              </div>
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="total_marketing_tooltip"
                  id="total_marketing_tooltip"
                ></i>
              </em>
              <h6>Total Marketing Cost</h6>
              <div>
                <span class="black-text mr-2">{{ default_currency }} </span>
                <span class="green-text mt-3">{{
                  (
                    (campaignDetails?.offline_budget || 0) +
                    (facebookCampaignData?.spend || 0) +
                    (googleCampaignData?.Cost || 0)
                  ).toFixed(0)
                }}</span>
              </div>
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <h6>Total Customers offered</h6>
              <span class="mt-3">{{ analyticsData?.total_members || 0 }}</span>
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="ads_conversion_tooltip"
                  id="ads_conversion_tooltip"
                ></i>
              </em>
              <h6>Ads conversion</h6>
              <span class="mt-3"
                >{{
                  (
                    (analyticsData?.online_sales * 100) /
                      (facebookCampaignData?.clicks +
                        googleCampaignData?.Clicks) || 0
                  )?.toFixed(2)
                }}%</span
              >
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="online_sales_tooltip"
                  id="online_sales_tooltip"
                ></i>
              </em>
              <h6>Online Sales</h6>
              <span class="mt-3"
                >{{
                  (
                    (100 * analyticsData?.online_sales) /
                      analyticsData?.total_orders || 0
                  ).toFixed(0)
                }}%</span
              >
              <!-- <span class="green-text">({{analyticsData?.online_sales || 0}})</span> -->
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="offline_sales_tooltip"
                  id="offline_sales_tooltip"
                ></i>
              </em>
              <h6>Offline Sales</h6>
              <span class="mt-3"
                >{{
                  (
                    (100 * analyticsData?.offline_sales) /
                      analyticsData?.total_orders || 0
                  ).toFixed(0)
                }}%</span
              >
              <!-- <span class="green-text">({{analyticsData?.offline_sales || 0}})</span> -->
            </div>
          </div>
          <div class="dts-box-outer">
            <div class="dts-box">
              <em class="box-info"
                ><i
                  class="fa fa-info-circle"
                  data-toggle="tooltip"
                  :title="ctr_tooltip"
                  id="ctr_tooltip"
                ></i>
              </em>
              <h6>CTR</h6>
              <span
                class="mt-3"
                v-if="facebookCampaignData?.ctr && !googleCampaignData?.ctr"
                >{{
                  parseFloat(facebookCampaignData?.ctr || 0)?.toFixed(2) * 100
                }}%</span
              >
              <span
                class="mt-3"
                v-else-if="
                  !facebookCampaignData?.ctr && googleCampaignData?.ctr
                "
                >{{
                  parseFloat(googleCampaignData?.ctr || 0)?.toFixed(2) * 100
                }}%</span
              >
              <span
                class="mt-3"
                v-else-if="facebookCampaignData?.ctr && googleCampaignData?.ctr"
                >{{
                  parseFloat(
                    (facebookCampaignData?.ctr ||
                      0 + googleCampaignData?.ctr ||
                      0) / 2 || 0
                  )?.toFixed(2) * 100
                }}%</span
              >
              <span class="mt-3" v-else>0.00%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import {
  CAMPAIGN_DETAILS,
  GET_CAMPAIGN_ANALYTICS,
  GET_FACEBOOK_CAMPAIGN_DETAILS,
  GET_GOOGLE_CAMPAIGN_DETAILS,
  GET_AVG_ORDER_VALUE,
} from "@/Core/store/action-types";
import { mapActions, mapGetters } from "vuex";
// import currencyConverter from "../../Core/helpers/currencyConverter";
Chart.register(...registerables);
export default {
  name: "AnalyticsGrid",
  components: { DoughnutChart },
  data() {
    return {
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      facebookCampaignData: null,
      googleCampaignData: null,
      testData: {
        numbers: [],
        labels: [],
        datasets: [
          {
            data: [0, 100],
            backgroundColor: ["#0BC984", "#F0F1F2"],
          },
        ],
      },
      referenceGroupData: {
        numbers: [],
        labels: [],
        datasets: [
          {
            data: [0, 100],
            backgroundColor: ["#0BC984", "#F0F1F2"],
          },
        ],
      },
      avg_order_value: 0,
      analyticsData: null,
      revenue_tooltip: `Total campaign revenue:

          Total Campaign Revenue typically refers to the overall revenue generated by a marketing campaign, 
          encompassing all the sales or other revenue streams associated with the campaign. 
          It represents the sum of all the monetary amounts resulting directly from the campaign's efforts.
      `,
      avg_purchase_tooltip: `Average purchase value:

          The Average Campaign Purchase Value refers to the average amount spent by customers during a specific 
          marketing campaign.It helps measure the average transaction value and provides insights into customer 
          behavior and spending patterns during the campaign.

          Here's an example to illustrate the calculation:

            Suppose an e-commerce company runs a month-long campaign and generates a total revenue of $10,000. 
            During the campaign, there were 200 purchases made.

            To calculate the Average Campaign Purchase Value:

              Average Campaign Purchase Value = Total Revenue / Total Number of Purchases = $10,000 / 200 = $50                                      
      `,
      total_marketing_tooltip: `Total marketing cost:

          Total marketing cost" refers to the overall expenses incurred by a company or organization for all 
          marketing activities during a specific period, campaign, or across multiple channels. It encompasses the 
          costs associated with various marketing strategies, initiatives, and campaigns aimed at promoting products or 
          services and reaching the target audience.

          To calculate the total marketing cost including Facebook, Google, and offline channels, 
          you need to consider the expenses incurred in each advertising platform and offline marketing efforts.

          To calculate the total marketing cost:

            Total Marketing Cost = Facebook Advertising Cost + Google Advertising Cost + Offline Marketing Cost

      `,
      online_sales_tooltip: `Online Sales:

          online sale percentage on a campaign refers to the proportion or percentage of total sales that are 
          generated through online channels during a specific marketing campaign. 

          To calculate the online sale percentage on a campaign, you need total sales and online sales.

          Here's an example to illustrate the calculation:

            Suppose a company runs a marketing campaign and generates a total of $100,000 in sales. 
            Out of this total, $75,000 worth of sales come from online transactions.

            To calculate the online sale percentage on the campaign:

              Online Sale Percentage = (Online Sales / Total Sales) * 100

              Online Sale Percentage = ($75,000 / $100,000) * 100 = 75%
      `,
      offline_sales_tooltip: `Offline Sales:

          Offline sales refer to the portion of total sales that are generated through traditional brick-and-mortar retail 
          channels or other offline methods, rather than online platforms. 

          To calculate the offline sale percentage on a campaign, you need total sales and offline sales.

          Here's an example to illustrate the calculation:

            Suppose a company runs a marketing campaign and generates a total of $100,000 in sales. 
            Out of this total, $25,000 worth of sales come from offline transactions.

            To calculate the offline sale percentage on the campaign:

              Offline Sale Percentage = (Offline Sales / Total Sales) * 100

              Offline Sale Percentage = ($25,000 / $100,000) * 100 = 25%
      `,
      net_profit_tooltip: `Total Net Profit: 

          Total Net Profit is a financial metric that represents the final profitability of a business or a 
          specific endeavor. It is calculated by subtracting all the expenses, including production costs, 
          operating expenses, taxes, and other deductions, from the total revenue generated.

          The formula for calculating online sales channel profit is:

             total Net Profit = Total Revenue - Cost of Goods Sold - Online/Offline Marketing Expenses - Shipping and Handling Costs - 

          Here's an example:

              Suppose your sales channel generates $100,000 in revenue for the month of January. 
              The cost of goods sold is $50,000,and you spent $20,000 on online marketing expenses, 
              $5,000 on shipping and handling costs,$2000 is a offline marketing budget

                  total_net_profit = $100,000 - $50,000 - $20,000 - $5,000 - $2000 = $19,000`,
      offline_net_profit_tooltip: `Offline Sales Channel Profit: 

          The Offline Sales Channel Profit refers to the profit generated specifically through offline sales channels, 
          such as brick-and-mortar stores, physical retail locations, or other traditional sales methods. 
          It represents the financial gain resulting from sales made through offline channels after deducting all 
          associated costs and expenses.

          The formula for calculating online sales channel profit is:

             Offline Sales Channel Profit = Offlibe Revenue - Cost of Goods Sold - offline Marketing Expenses

          Here's an example:

              Suppose your sales channel generates $50,000 in offline revenue for the month of January. 
              The cost of goods sold is $30,000,and you spent $5,000 on offline marketing expenses, 

                  Offline Sales Channel Profit = $50,000 - $30,000 - $5,000 = $15,000`,
      cart_comparison_tooltip: `Campaign Cart Size Vs Average Cart Size:

          This percentage-based KPI provides insights into the campaign's ability to encourage customers to spend 
          more per order during the campaign period compared to their average spending across all transactions.

          Calculation: (Campaign Average Purchase Value / Average Order Value) * 100

          Example:
              Let's consider an online retailer that runs a marketing campaign to promote a new product line. 
              The data for the campaign and overall average order value are as follows:

              Campaign Average Purchase Value: $80
              Average Order Value: $60

              To calculate the Campaign Average Purchase Value as a percentage of the Average Order Value:

              Campaign Cart Size Vs Average Cart Size = (Campaign Average Purchase Value / Average Order Value) * 100
              Campaign Cart Size Vs Average Cart Size = ($80 / $60) * 100 =133.33%
      `,
      gross_profit_margin_tooltip: `Gross Sales Profit Margin:
      
          Gross Sales Profit Margin refers to the percentage of profit a company generates from its sales after 
          deducting the cost of goods sold (COGS). It is a financial metric used to assess a company's profitability 
          and efficiency in producing and selling its products or services.

          The formula for calculating the Gross Sales Profit Margin is as follows:

              Gross Sales Profit Margin = ((Gross Sales Revenue - COGS) / Gross Sales Revenue) * 100

          Here's an example:

              Suppose ABC Clothing Company generates $500,000 in gross sales revenue for a specific period. 
              The cost of goods sold (COGS) for the same period amounts to $350,000.

              Gross Sales Profit Margin = (($500,000 - $350,000) / $500,000) * 100
                                        = ($150,000 / $500,000) * 100
                                        = 0.3 * 100
                                        = 30%
          `,
      net_profit_margin_tooltip: `Net Sales Profit Margin:
      
          Sales Net Profit, also known as Net Profit Margin, is a financial metric that indicates the percentage of 
          profit a company generates from its total sales revenue after deducting all expenses, including COGS, 
          operating costs, taxes, and interest.

          The formula for calculating the Sales Net Profit Margin is as follows:
          
              Sales Net Profit Margin = (Net Profit / Total Sales Revenue) * 100

          Here's an example:

              Suppose XYZ Electronics Company has total sales revenue of $1,000,000 for a specific period. 
              After deducting all expenses, including COGS, operating costs, taxes, and interest, the company 
              has a net profit of $200,000.

              Sales Net Profit Margin = ($200,000 / $1,000,000) * 100
                                      = 0.2 * 100
                                      = 20%`,
      roi_tooltip: `ROI :

          ROI stands for Return on Investment, which is a financial metric used to evaluate the profitability of an investment. 
          It measures the ratio of net profit (or return) to the total cost of the investment, expressed as a percentage

          The formula for calculating ROI is:

              ROI = (Net Profit / Total Cost of Investment) x 100%

              where:

                  Net Profit = Revenue - Cost of Goods Sold - Operating Expenses
                  Total Cost of Investment = Cost of Goods Sold + Operating Expenses + Additional Investment

          Here's an example:

              Suppose you invest $10,000 in a marketing campaign, and it generates $15,000 in revenue. 
              The cost of goods sold is $2,000, and the operating expenses are $3,000.

                  Net Profit = $15,000 - $2,000 - $3,000 = $10,000

                  Total Cost of Investment = $2,000 + $3,000 + $10,000 = $15,000

                  ROI = ($10,000 / $15,000) x 100% = 66.67%`,
      ctr_tooltip: `CTR : 

          CTR stands for Click-Through Rate, which is a digital marketing metric used to measure the effectiveness of an online 
          advertising campaign.It is calculated by dividing the number of clicks an ad receives by the number of times the ad was
          shown (impressions), expressed as a percentage.
          
          The formula for calculating CTR is:

              CTR = (Number of clicks / Number of impressions) x 100%

          For example, 
            
              Suppose an ad was displayed 10,000 times and received 500 clicks. The CTR would be:

                    CTR = (500 / 10,000) x 100% = 5% `,
      ads_conversion_tooltip: `Ads conversion : 

          Ad conversion is a digital marketing metric that measures the number of times a user completes a desired action after 
          clicking on an ad, such as making a purchase, add to cart,etc. It is a measure of the effectiveness of an ad campaign 
          in generating desired actions from users.

          The formula for calculating  conversion rate is:

              Ad Conversion Rate = (Number of Conversions / Number of Ad Clicks) x 100%

          For example, 

              Suppose,if your ad generates 100 clicks, and out of those clicks, 10 people complete the desired action, 
              then the conversion rate would be:

                    Conversion Rate = (10 / 100) x 100% = 10%`,
      online_sales_channel_profit_tooltip: `Online Sales Channel Profit : 

          Online Sales Channel Profit is a financial metric that measures the profit generated from a specific online sales channel, 
          such as a website, social media platform, or online marketplace. It takes into account the revenue generated by the 
          channel and the associated costs, such as marketing expenses and shipping and handling costs.

          The formula for calculating online sales channel profit is:

              Online Sales Channel Profit = Online Revenue - Cost of Goods Sold - Online Marketing Expenses - Shipping and Handling Costs

          Here's an example:

              Suppose your e-commerce website generates $100,000 in revenue for the month of January. 
              The cost of goods sold is $50,000,and you spent $20,000 on online marketing expenses, 
              $5,000 on shipping and handling costs

                  Online Sales Channel Profit = $100,000 - $50,000 - $20,000 - $5,000 = $21,000`,
    };
  },
  computed: {
    ...mapGetters("org", ["default_clv_year", "default_currency"]),
    ...mapGetters("campaign", ["campaignDetails"]),
  },
  methods: {
    ...mapActions("campaign", [
      CAMPAIGN_DETAILS,
      GET_CAMPAIGN_ANALYTICS,
      GET_FACEBOOK_CAMPAIGN_DETAILS,
      GET_GOOGLE_CAMPAIGN_DETAILS,
    ]),
    ...mapActions("order", [GET_AVG_ORDER_VALUE]),
    // async convertCurrency() {
    //   this.convertedValues.total_orders_value = await currencyConverter(
    //     this.analyticsData?.currency,
    //     this.default_currency,
    //     this.analyticsData?.total_orders_value
    //   );
    //   this.convertedValues.avg_order_value = await currencyConverter(
    //     this.analyticsData?.currency,
    //     this.default_currency,
    //     this.analyticsData?.avg_order_value
    //   );
    // },
    getAvgOrderValue() {
      let params = {
        currency: this.default_currency,
      };
      this[GET_AVG_ORDER_VALUE](params)
        .then((data) => {
          this.avg_order_value = data.avg_receipt_value;
        })
        .catch(() => (this.avg_receipt_value = 0));
    },
    getROI() {
      let ROI =
        (this.analyticsData?.total_orders_value -
          (this.campaignDetails?.offline_budget +
            (this.facebookCampaignData?.spend || 0) +
            (this.googleCampaignData?.Cost || 0))) /
        ((this.campaignDetails?.offline_budget || 0) +
          (this.facebookCampaignData?.spend || 0) +
          (this.googleCampaignData?.Cost || 0));
      return isFinite(ROI) ? ROI : 0;
    },

    getGrossMargin() {
      let grossMargin = (
        ((this.analyticsData?.total_net_profit || 0) * 100) /
          (this.analyticsData?.total_orders_value || 0) || 0
      ).toFixed(0);
      return grossMargin;
    },
    getNetMargin() {
      let netMargin = (
        ((this.analyticsData?.total_net_profit -
          (this.facebookCampaignData?.spend || 0) -
          (this.googleCampaignData?.Cost || 0) -
          (this.campaignDetails?.offline_budget || 0) || 0) *
          100) /
          (this.analyticsData?.total_orders_value || 0) || 0
      ).toFixed(0);
      return netMargin;
    },
  },

  mounted() {
    let payload = {
      objId: this?.$route?.params?.campaignId,
      params: {
        currency: this.default_currency,
      },
    };
    this.getAvgOrderValue();
    this[CAMPAIGN_DETAILS](payload).then((response) => {
      if (response.facebook_campaign) {
        this[GET_FACEBOOK_CAMPAIGN_DETAILS](payload)
          .then((response) => {
            this.facebookCampaignData = response;
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some issue, Please connect Facebook Ads account again !",
              "error",
              3
            );
          });
      }
      if (Object.keys(response.google_campaign_data).length > 0) {
        this[GET_GOOGLE_CAMPAIGN_DETAILS](payload)
          .then((response) => {
            this.googleCampaignData = response;
          })
          .catch(() => {
            this.$alertify.notify(
              "There is some issue, Please connect Google Ads account again !",
              "error",
              3
            );
          });
      }
    });

    // this[CAMPAIGN_DETAILS](id).then((response)=>{
    //   this.analyticsData = response.metadata
    //     this.testData.datasets[0].data=[response?.metadata?.members_participated || 0 ,response?.audience?.member_count || 0]
    //     if (this.analyticsData)
    //       this.convertCurrency()
    // }).catch((error) =>
    // console.error(error))
    this[GET_CAMPAIGN_ANALYTICS](payload)
      .then((response) => {
        this.analyticsData = response;
        // this.convertCurrency();
        this.testData.datasets[0].data = [
          response?.success_deals_percent || 0,
          100 - response?.success_deals_percent || 0,
        ];
        this.referenceGroupData.datasets[0].data = [
          response?.reference_group_success_deals_percent || 0,
          100 - response?.reference_group_success_deals_percent || 0,
        ];
      })
      .catch((error) => console.error(error));
  },
};
</script>
<style scoped>
em.box-info {
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
}
em.box-info i {
  margin-top: -30px;
  margin-left: -16px;
}
.campaign-kpi-section {
  flex: 0 0 100% !important;
}
.campaign-light {
  height: 48px;
  width: 54px;
  border-radius: 100%;
  margin-top: 5px;
}
.campaign-light.green {
  background: #48b13b;
}
.campaign-light.yellow {
  background: #f7b500;
}
.campaign-light.red {
  background: #dd1212;
}
.campaign-kpi-status {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>